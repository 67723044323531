import classNames from 'classnames';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Detective from '../../shared/helpers/Detective';
import urlMode from '../../shared/helpers/Mode';
import { HIDE_BOOK_ANOTHER_APPOINTMENT_LINK } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import { Anchor } from './Anchor';
import BookAnotherAppointmentButton from './BookAnotherAppointmentButton';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  homepage: {
    marginTop: '1.125rem',
  },
  mobile: {
    marginBottom: '1.5rem',
  },
  root: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    marginTop: '0.5rem',
    padding: '1.5rem',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '1.25rem',
  },
}));

const WhatNow = () => {
  const classes = useStyles({ theme: useTheme() });

  const { clientViewWithoutBookingShortcut, homepage } =
    useContext(SettingsContext);
  const mode = useContext(ViewModeContext);
  const [{ service }] = useContext(SelectionContext);

  if (urlMode.isKiosk()) {
    return null;
  }

  const show =
    clientViewWithoutBookingShortcut &&
    service?.bookAnotherAppointmentPrefillSetting !==
      HIDE_BOOK_ANOTHER_APPOINTMENT_LINK;

  return show ? (
    <div
      className={classNames(classes.root, mode === MOBILE && classes.mobile)}
    >
      <Typography classes={{ root: classes.title }} component="h4" variant="h6">
        <FormattedMessage id="WhatNow.title" />
      </Typography>
      <BookAnotherAppointmentButton anchor />
      {homepage && !Detective.inIFrame() ? (
        <div className={classes.homepage} data-testid="homepage-link">
          <Anchor href={homepage} type="noUnderline">
            <FormattedMessage
              id="WhatNow.homepage"
              values={{ vendor: window.state.vendor_name }}
            />
          </Anchor>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default WhatNow;
