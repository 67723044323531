const VerifiedFiles = (client) => ({
  /**
   * @returns {Promise<any>}
   */
  verify(key, file) {
    const formData = new FormData();
    formData.append(key, file);

    return client.postWithFormData('/api/v3/open/verify-file', formData);
  },
});

export default VerifiedFiles;
