const WaitTimes = (client) => ({
  /**
   * @returns {Promise<any>}
   */
  find(service, locations) {
    const locationQuery = locations.reduce(
      (query, id) => `${query}&locations[]=${id}`,
      '',
    );

    return client.get(
      `/api/v2/open/wait-times?service=${service}${locationQuery}`,
    );
  },
});

export default WaitTimes;
