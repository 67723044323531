import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import DownChevron from '../../shared/icons/DownChevron';
import UpChevron from '../../shared/icons/UpChevron';
import { SettingsContext } from '../contexts/SettingsContext';
import Button from './Button';
import CollapsibleLocationInfo from './CollapsibleLocationInfo';
import LocationHours from './LocationHours';
import Typography from './Typography';
import ViewOnMapButton from './ViewOnMapButton';
import WaitTime from './WaitTime';

const useStyles = createUseStyles((theme) => ({
  root: {
    borderBottom: '1px solid #E0E0E0',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    background: 'none',
    border: 'none',
    display: 'contents',
    margin: 0,
    padding: 0,
    textAlign: 'left',
  },
  chevron: {
    fill: theme.palette.neutral[300],
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    margin: '0 1rem',
    maxWidth: 'calc(100vw - 2rem)',
    padding: '1.25rem 0',
  },
  contentButton: {
    appearance: 'none',
    background: 'transparent',
    border: '0 none',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'block',
    flexGrow: 1,
    margin: 0,
    minWidth: 0,
    padding: 0,
    textAlign: 'left',
    width: 'auto',
  },
  isMapView: {
    maxHeight: 'calc(50vh - 4rem)',
    overflow: 'scroll',
    border: 'none',
  },
  open: {
    display: 'inline',
    marginLeft: '1.25rem',
    maxHeight: '31.25rem !important',

    '& > p': {
      whiteSpace: 'inherit',
    },
  },
  selectButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: '1rem',
    maxHeight: '3rem',
  },
  virtual: {
    display: 'flex',
    transform: 'translateY(10px)',

    '& > h2': {
      flexGrow: 1,
    },
  },
}));

const LocationListCard = ({
  location,
  isMapView,
  selectLocation,
  setFocused,
  initOpen,
  viewOnMap,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });
  const { hideLocationHours, showWaitTime } = useContext(SettingsContext);

  const [open, setOpen] = useState(initOpen);

  const handleMouseEnter = () => {
    setFocused(location);
  };

  const handleMouseLeave = () => {
    setFocused({});
  };

  const onClick = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <div
      className={classnames(classes.root, isMapView ? classes.isMapView : '')}
      data-id={location.id}
    >
      <div
        className={classes.button}
        data-testid={`location-info-${location.id}`}
      >
        <div className={classes.content}>
          <button
            aria-expanded={open ? 'true' : 'false'}
            aria-label={intl.formatMessage(
              {
                id: location.formattedAddress
                  ? 'Ui.details_about_name_and_address'
                  : 'Ui.details_about_name',
              },
              {
                name: location.name,
                address: location.formattedAddress,
              },
            )}
            className={classes.contentButton}
            data-testid={`select-location-${location.id}`}
            onBlur={handleMouseLeave}
            onClick={onClick}
            onFocus={handleMouseEnter}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            type="button"
          >
            {location.physical ? (
              <Typography component="h2" variant="title">
                {location.name}
              </Typography>
            ) : (
              <div className={classes.virtual}>
                <Typography component="h2" variant="title">
                  {location.name}
                </Typography>
                <div className={classes.chevron}>
                  {open ? <UpChevron /> : <DownChevron />}
                </div>
              </div>
            )}
            {location.physical ? (
              <CollapsibleLocationInfo location={location} open={open} />
            ) : null}
          </button>
          <span className={classes.selectButton}>
            <Button
              aria-label={intl.formatMessage(
                { id: 'Ui.select_name' },
                { name: location.name },
              )}
              fullWidth={false}
              onClick={() => selectLocation(location)}
              variant="primary"
            >
              <FormattedMessage id="Ui.select" />
            </Button>
          </span>
        </div>
      </div>
      <div
        aria-atomic="false"
        className={open ? classes.open : ''}
        role="status"
      >
        {open ? (
          <>
            {viewOnMap !== null ? (
              <div className="pb-4">
                <ViewOnMapButton
                  location={location}
                  onClick={() => viewOnMap(location)}
                />
              </div>
            ) : null}
            {showWaitTime ? (
              <WaitTime location={location} setLocation={setFocused} />
            ) : null}
            {hideLocationHours ? null : (
              <div className="pt-4">
                <LocationHours hours={location.hours} titleVariant="label" />
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

LocationListCard.propTypes = {
  location: PropTypes.shape({
    formattedAddress: PropTypes.string,
    hours: PropTypes.objectOf(
      PropTypes.shape({ closed: PropTypes.string, open: PropTypes.string }),
    ),
    id: PropTypes.string,
    name: PropTypes.string,
    physical: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  }).isRequired,
  isMapView: PropTypes.bool,
  initOpen: PropTypes.bool,
  selectLocation: PropTypes.func.isRequired,
  setFocused: PropTypes.func.isRequired,
  viewOnMap: PropTypes.func,
};

LocationListCard.defaultProps = {
  initOpen: false,
  isMapView: false,
  viewOnMap: null,
};

export default LocationListCard;
