import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { SHORTCUTS, USER_PREFERENCE } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { UsersContext } from '../contexts/UsersContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Shortcuts from '../helpers/Shortcuts';
import DateTimeFilter from './DateTimeFilter';
import PersonOutline from './icons/PersonOutline';
import PreferencePicker from './PreferencePicker';
import SupportedLanguagePreferencePicker from './SupportedLanguagePreferencePicker';
import Typography from './Typography';

const SupportedLanguageFilter = ({ preferences, resetRange }) => {
  const mode = useContext(ViewModeContext);
  const { showStaffPicker } = useContext(SettingsContext);
  const { supportedLanguages } = useContext(UsersContext);
  const [{ settings, user, userPreference }] = useContext(SelectionContext);

  const hasUserShortcut = Shortcuts.exists(SHORTCUTS.USER);
  const hasUserCategory = Shortcuts.exists(SHORTCUTS.USER_CATEGORY);

  const preferredCategory =
    Shortcuts.exists(SHORTCUTS.USER_CATEGORY) && settings.preferred_staff;
  let languagePreferences = preferences.filter(
    (preference) => preference.id !== USER_PREFERENCE.SPECIFIC,
  );

  if (preferredCategory) {
    languagePreferences = languagePreferences.map((preference) => ({
      ...preference,
      title: preference.values?.language
        ? 'UserPreference.assign_me_short_title'
        : preference.title,
    }));
  }

  if (hasUserShortcut && settings.preferred_staff) {
    languagePreferences = [
      {
        id: USER_PREFERENCE.SPECIFIC,
        title: 'UserPreference.assigned_title',
      },
      ...languagePreferences,
    ];
  } else if (hasUserCategory) {
    // remove any random option already in place since
    // we will determine that option in this block of code
    languagePreferences = languagePreferences.filter(
      (preference) => preference.id !== USER_PREFERENCE.RANDOM,
    );

    languagePreferences = [
      {
        id: USER_PREFERENCE.RANDOM,
        title: settings.preferred_staff
          ? 'UserPreference.no_preference'
          : 'UserPreference.anyone_available',
      },
      ...languagePreferences,
    ];
  }

  // TODO: remove this variable and its usage when implementing CORE-11440
  const showPicker = mode === MOBILE && showStaffPicker === 1;

  const canChangeUserShortcut = settings?.preferred_staff && hasUserShortcut;
  const canChangeLanguage =
    (hasUserCategory && !showPicker) ||
    (!user && supportedLanguages?.length > 1);

  const showSinglePicker = !hasUserCategory || !settings.preferred_staff;

  if (showSinglePicker && languagePreferences.length <= 1) {
    return null;
  }

  return canChangeUserShortcut || canChangeLanguage ? (
    <DateTimeFilter
      content={
        showSinglePicker ? (
          <>
            <Typography component="div" grey variant="caption1">
              <FormattedMessage id="DateTime.showing_times_for" />
            </Typography>
            <span>&nbsp;</span>
            <SupportedLanguagePreferencePicker
              preferences={languagePreferences}
              resetRange={resetRange}
            />
          </>
        ) : (
          <Typography component="div" grey variant="caption1">
            <FormattedMessage id="DateTime.showing_times_for" />
            <span>&nbsp;</span>
            <PreferencePicker resetRange={resetRange} />
            {languagePreferences.length > 1 ? (
              <FormattedMessage
                id={
                  !userPreference ||
                  userPreference.id === USER_PREFERENCE.RANDOM
                    ? 'DateTime.no_language_preference'
                    : 'DateTime.who_speaks'
                }
                values={{
                  locale: (
                    <SupportedLanguagePreferencePicker
                      preferences={languagePreferences}
                      resetRange={resetRange}
                    />
                  ),
                }}
              />
            ) : null}
          </Typography>
        )
      }
      icon={<PersonOutline />}
    />
  ) : null;
};

SupportedLanguageFilter.propTypes = {
  preferences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string,
      values: PropTypes.shape({ language: PropTypes.string }),
    }),
  ).isRequired,
  resetRange: PropTypes.func.isRequired,
};

export default SupportedLanguageFilter;
