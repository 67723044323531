import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import AppointmentSummary from './AppointmentSummary';
import Footer from './Footer';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    margin: '1.25rem',
    maxWidth: '16.5rem',
    minWidth: '13.75rem',
    width: '25%',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    marginBottom: '1.25rem',
    marginTop: 'auto',
    overflow: 'hidden',
    paddingLeft: '1.25rem',
    paddingTop: '0.75rem',
  },
  title: {
    marginBottom: '1.25rem',
  },
}));

const RescheduleSidebar = () => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <>
      <section className={classes.root} data-testid="reschedule-sidebar">
        <Typography
          classes={{ root: classes.title }}
          component="h3"
          variant="h5"
        >
          <FormattedMessage id="Reschedule.appointment_details" />
        </Typography>
        <AppointmentSummary reschedule />
      </section>
      <Footer classes={{ root: classes.footer }} />
    </>
  );
};

export default RescheduleSidebar;
