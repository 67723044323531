import React, { Fragment, useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import ForwardChevron from '../../shared/icons/ForwardChevron';
import Button from '../components/Button';
import TextInput from '../components/forms/TextInput';
import Landing from '../components/Landing';
import TrackPageView from '../components/TrackPageView';
import Typography from '../components/Typography';
import { LANDING_PAGES, PAGES } from '../constants';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import Api from '../helpers/Api';
import Resources from '../helpers/Resources';

const ERRORS = {
  EMPTY: [
    <FormattedMessage id="LocationIdentifier.empty_error" key="empty_error" />,
  ],
  NOT_FOUND: [
    <FormattedMessage
      id="LocationIdentifier.not_found_error"
      key="not_found_error"
    />,
  ],
};

const useStyles = createUseStyles((theme) => ({
  arrow: {
    height: '1.5rem',
  },
  buttonContent: {
    display: 'flex',
  },
  buttonText: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    fontSize: theme.textSizes.base,
    justifyContent: 'center',
  },
  row: {
    marginTop: '1.5rem',
  },
  withError: {
    marginTop: '1rem',
  },
}));

const LocationIdentifier = () => {
  const classes = useStyles({ theme: useTheme() });

  const history = useHistory();
  const [locale] = useContext(LocaleContext);
  const [{ location }, setSelections] = useContext(SelectionContext);

  const [identifier, setIdentifier] = useState('');
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (location) {
      setSelections({ location: null });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = ({ target: { value } }) => {
    setIdentifier(value);

    if (
      (errors === ERRORS.EMPTY && value !== '') ||
      errors === ERRORS.NOT_FOUND
    ) {
      setErrors([]);
    }
  };

  const handleClick = () => {
    if (identifier === '') {
      setErrors(ERRORS.EMPTY);
    } else {
      Api.locale(locale)
        .locations()
        .findByExternalId(identifier)
        .then((data) => {
          if (data.length) {
            setSelections({ location: Resources.formatV3Location(data[0]) });
            history.push(`${LANDING_PAGES.CHOICES}${window.location.search}`);
          } else {
            setErrors(ERRORS.NOT_FOUND);
          }
        });
    }
  };

  return (
    <Landing>
      <>
        <TrackPageView identifier={PAGES.WHERE} />
        <Typography component="h1" variant="h5">
          <FormattedMessage id="LocationIdentifier.title" />
        </Typography>
        <div className={classes.row}>
          <Typography grey variant="regular">
            <FormattedMessage id="LocationIdentifier.help_text" />
          </Typography>
        </div>
        <div className={classes.row}>
          <TextInput
            errors={errors}
            id="locationIdentifier"
            label={<FormattedMessage id="LocationIdentifier.location_number" />}
            name="locationIdentifier"
            onChange={handleChange}
            value={identifier}
          />
        </div>
        <Button
          classes={{
            override: errors.length ? classes.withError : classes.row,
          }}
          fullWidth
          onClick={handleClick}
          type="button"
          variant="primary"
        >
          <span className={classes.buttonContent}>
            <span className={classes.buttonText}>
              <FormattedMessage id="Ui.next" />
            </span>
            <span className={classes.arrow}>
              <ForwardChevron />
            </span>
          </span>
        </Button>
      </>
    </Landing>
  );
};

export default LocationIdentifier;
