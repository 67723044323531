import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { SHORTCUTS } from '../constants';
import Shortcuts from '../helpers/Shortcuts';
import Storage from '../helpers/Storage';
import { getCanonicalTimezone } from '../helpers/Timezone';
import { DetectedTimezoneContext } from './DetectedTimezoneContext';
import { SelectionContext } from './SelectionContext';
import { TimezonesContext } from './TimezonesContext';

const TimezoneContext = createContext(['UTC', () => 'UTC']);

const TimezoneProvider = ({ children }) => {
  const [{ location }] = useContext(SelectionContext);
  const [detected] = useContext(DetectedTimezoneContext);
  const timezones = useContext(TimezonesContext);
  const providedTz = Shortcuts.get(SHORTCUTS.TIMEZONE, detected);
  const loadedFromStorage = useMemo(
    () => Boolean(Storage.get(SHORTCUTS.TIMEZONE, false)),
    [],
  );
  const [timezone, setTimezone] = useReducer((state, newState) => {
    if (newState === undefined) {
      return state; // This is needed for IE
    }

    Storage.set(SHORTCUTS.TIMEZONE, newState);

    return newState;
  }, Storage.get(SHORTCUTS.TIMEZONE) || providedTz || 'UTC');
  const useDetectedTimezone = () => setTimezone(detected);

  useEffect(() => {
    if (detected && !loadedFromStorage && (!location || !location.physical)) {
      setTimezone(providedTz);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detected]);

  useEffect(() => {
    const timezoneArray = Object.keys(timezones);
    const timezonesLoading = timezoneArray.length === 0;

    if (!timezonesLoading && !timezoneArray.includes(timezone)) {
      setTimezone(getCanonicalTimezone(timezone));
    }
  }, [timezones]);

  return (
    <TimezoneContext.Provider
      value={[timezone, setTimezone, useDetectedTimezone]}
    >
      {children}
    </TimezoneContext.Provider>
  );
};

TimezoneProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { TimezoneContext, TimezoneProvider };
