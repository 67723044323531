import { SUPPORTED_LOCALES } from '../constants';

export default {
  /**
   * @param {string} query
   *
   * @return {Object<string, string|number>}
   */
  params(query) {
    return query
      .slice(1)
      .split('&')
      .filter(Boolean)
      .reduce((accumulator, value) => {
        const pair = value.split('=');

        accumulator[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);

        return accumulator;
      }, {});
  },

  /**
   * @param {string} base
   * @param {Object} filters
   * @param {Array} sort
   *
   * @returns {string}
   */
  url(base, filters, sort = []) {
    if (Object.keys(filters).length === 0 && sort.length === 0) {
      return base;
    }

    let url = `${base}?`;
    let first = true;

    Object.keys(filters).forEach((key) => {
      if (!first) {
        url += '&';
      }
      url += `filter[${key}]=${filters[key]}`;
      first = false;
    });

    if (sort.length > 0) {
      if (!first) {
        url += '&';
      }
      url += `sort=${sort.join(',')}`;
      first = false;
    }

    return url;
  },

  /**
   * Takes a filter object and parses it and returns it in
   * query parameter form
   *
   * @param {Object} filters
   *
   * @returns {string}
   */
  filter(filters) {
    return filters
      .map((filter) => {
        const joinedFilters = filter.filters.join(',');

        return `filter[${filter.key}]=${joinedFilters}`;
      })
      .join('&');
  },

  /**
   * Takes in a url and locale and will navigate to it and add lang param.
   * Also supports passing in a custom method for navigation.
   *
   * @param {string} base
   * @param {string} locale
   * @param {function} action An optional method to use to navigate
   */
  goWithLang(base, locale = 'en', action) {
    const sanitizedBase = typeof base === 'string' ? base : '/';
    const loc = Object.values(SUPPORTED_LOCALES).includes(locale)
      ? locale
      : SUPPORTED_LOCALES.en;
    const url = `${sanitizedBase}?lang=${loc}`;

    return action ? action(url) : window.location.assign(url);
  },
};
