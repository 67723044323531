import parse from 'parse-color';

/**
 * Used to calculate a passable contrast level based on the luminance of the given colour.
 * There are some magic numbers used in this function which were defined by the W3C to
 * calculate accessible colours, do not change them in any way.
 *
 * @returns {string}
 */
const calculate = ({ rgb }) => {
  const [red, green, blue] = rgb.map((colour) => {
    colour = colour / 255;

    if (colour <= 0.03928) {
      return colour / 12.92;
    }

    return Math.pow((colour + 0.055) / 1.055, 2.4);
  });

  const luminance = 0.2126 * red + 0.7152 * green + 0.0722 * blue;

  return luminance > 0.179 ? 'rgb(33,33,33)' : 'rgb(255,255,255)';
};

export default {
  DEFAULT_LARGE: [35, 56],
  LARGE: [64, 64],
  POSITION: [26, 26],
  SMALL: [48, 48],

  /**
   * @returns {window.google.maps.LatLngBounds}
   */
  bounds() {
    return new window.google.maps.LatLngBounds();
  },

  /**
   * @param {{ lat: number, lng: number }} coordinates
   *
   * @returns {window.google.maps.LatLng}
   */
  coordinates(coordinates) {
    return new window.google.maps.LatLng(coordinates);
  },

  /**
   * @param {string} destination
   *
   * @returns {string}
   */
  directions(destination) {
    return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destination,
    )}`;
  },

  /**
   * @param {LARGE|SMALL} size
   * @param {string} colour
   *
   * @returns {{ scaledSize: window.google.maps.Size, url: string }}
   */
  icon(size, colour) {
    const center = calculate(parse(colour));
    const icon = encodeURIComponent(
      `<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="58" viewBox="0 0 48 58"><defs><style>.a{fill:${colour};}.b{fill:none;}.c{filter:url(#a);}</style><filter id="a" x="1" y="0" width="46" height="58" filterUnits="userSpaceOnUse"><feOffset dy="3" input="SourceAlpha"/><feGaussianBlur stdDeviation="3" result="b"/><feFlood flood-opacity="0.161"/><feComposite operator="in" in2="b"/><feComposite in="SourceGraphic"/></filter></defs><g transform="translate(0 2)"><g class="c" transform="matrix(1, 0, 0, 1, 0, -2)"><path stroke="white" stroke-width="1" class="a" d="M19,2A13.99,13.99,0,0,0,5,16C5,26.5,19,42,19,42S33,26.5,33,16A13.99,13.99,0,0,0,19,2Zm0,19a5,5,0,1,1,5-5A5,5,0,0,1,19,21Z" transform="translate(5 4)"/></g><path class="b" d="M0,0H48V48H0Z"/></g><circle fill="${center}" cx="24" cy="20" r="6"/></svg>`,
    );

    return {
      url: `data:image/svg+xml;charset=UTF-8,${icon}`,
      scaledSize: new window.google.maps.Size(...size),
    };
  },

  /**
   * @returns {{ scaledSize: window.google.maps.Size, url: string }}
   */
  defaultIcon() {
    return {
      url: 'https://static.coconutcalendar.com/img/icons/google_maps_mcv_pin.svg',
      scaledSize: new window.google.maps.Size(...this.DEFAULT_LARGE),
    };
  },

  /**
   * @returns {{url: string}}
   */
  position() {
    const icon = encodeURIComponent(
      '<svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d)"><circle cx="13" cy="12" r="8" fill="#2979FF"/><circle cx="13" cy="12" r="9.5" stroke="#fff" stroke-width="3"/></g><defs><filter id="filter0_d" x="0" y="0" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="1"/><feGaussianBlur stdDeviation="1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.239 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter></defs></svg>',
    );

    return {
      url: `data:image/svg+xml;charset=UTF-8,${icon}`,
      scaledSize: new window.google.maps.Size(...this.POSITION),
    };
  },
};
