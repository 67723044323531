import { isPast, parseISO } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Client from '../../shared/helpers/api/Client';
import mode from '../../shared/helpers/Mode';
import { GOOGLE_CLIENT_STORAGE_KEY } from '../constants';
import { FeatureContext } from '../contexts/FeatureContext';
import { SelectionContext } from '../contexts/SelectionContext';
import Storage from '../helpers/Storage';
import Button from './Button';
import CheckboxInput from './forms/CheckboxInput';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  button: {
    '&:focus': {
      outline: 0,
      boxShadow: theme.shadows.input,
      borderColor: theme.palette.secondary[500],
    },
  },
  buttonContainer: {
    display: 'inline-flex',
  },
  buttonContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  checkboxLabel: {
    padding: { left: '0.25rem' },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    borderRadius: '0.125rem',
    padding: { right: '0.75rem' },
    alignItems: 'center',
    '& svg': {
      width: '1.25rem',
    },
  },
}));

function LoginWithGoogle() {
  const classes = useStyles({ theme: useTheme() });

  const features = useContext(FeatureContext);
  const [selections, setSelections] = useContext(SelectionContext);

  const receiveMessage = ({ data: { user, action, error }, origin }) => {
    if (origin === window.origin && action === 'google-login') {
      window.removeEventListener('message', receiveMessage);

      if (error) {
        Storage.clearPermanent(GOOGLE_CLIENT_STORAGE_KEY);

        setSelections({
          attendee: selections.googleUser.prefill ? null : selections.attendee,
          googleUser: null,
        });

        return;
      }

      if (selections.googleUser?.refreshing) {
        const googleUser = {
          ...selections.googleUser,
          token: user.token,
          expires_at: user.expires_at,
          refreshing: false,
        };

        Storage.setPermanent(GOOGLE_CLIENT_STORAGE_KEY, googleUser);
        setSelections({ googleUser });
      } else if (user?.name) {
        const [firstName, ...lastName] = user.name.split(' ');
        const googleUser = {
          ...selections.googleUser,
          ...user,
          matchAvailability: true,
          prefill: !selections.unvalidatedAttendee && !selections.attendee,
          refreshing: false,
        };

        Storage.setPermanent(GOOGLE_CLIENT_STORAGE_KEY, googleUser);

        setSelections({
          googleUser,
          attendee: selections.attendee || {
            email: user.email,
            firstName,
            lastName: lastName.join(' '),
            cellPhone: user.cellPhone,
          },
        });
      }
    }
  };

  const loginWithGoogle = () => {
    window.addEventListener('message', receiveMessage);

    window.open(
      'client-oauth/login/google?purpose=google',
      null,
      'menubar=no,toolbar=no,width=600,height=700,top=100,left=100',
    );
  };

  const refreshGoogleToken = () => {
    new Client()
      .get(
        `client-oauth/refresh/google?purpose=google&refresh_token=${selections.googleUser?.refresh_token}`,
      )
      .then(({ data }) => {
        receiveMessage({
          data: { ...data, action: 'google-login' },
          origin: window.origin,
        });
      })
      .catch((error) => {
        receiveMessage({
          data: { error, action: 'google-login' },
          origin: window.origin,
        });
      });
  };

  const handleChange = (event) => {
    const googleUser = {
      ...selections.googleUser,
      matchAvailability: event.currentTarget.checked,
      refreshing: isPast(parseISO(selections.googleUser?.expires_at)),
    };

    Storage.setPermanent(GOOGLE_CLIENT_STORAGE_KEY, googleUser);
    setSelections({ googleUser });
  };

  useEffect(() => {
    if (selections.googleUser?.refreshing) {
      refreshGoogleToken();
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections.googleUser?.refreshing]);

  // We are temporarily ignoring the destructuring-assignment rule explicitly.
  // There is a bug that was solved in a newer version of this plugin which
  // we will eventually be able to upgrade to once we can move off of
  // the current version of NodeJS in use.
  //
  // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
  //
  // eslint-disable-next-line react/destructuring-assignment
  if (!features.clientGoogleLogin || mode.isEmbedded()) {
    return null;
  }

  return (
    <div className={classes.container}>
      {selections.googleUser ? (
        <CheckboxInput
          defaultChecked={selections.googleUser.matchAvailability}
          label={
            <Typography component="div" grey variant="caption1">
              <span className={classes.checkboxLabel}>
                <FormattedMessage id="LoginWithGoogle.showing_times_that_match" />
              </span>
            </Typography>
          }
          name="match_availability"
          onChange={handleChange}
          value="matching"
        />
      ) : (
        <>
          <div className={classes.icon}>
            <svg
              preserveAspectRatio="xMidYMid"
              viewBox="0 0 256 262"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                fill="#4285F4"
              />
              <path
                d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                fill="#34A853"
              />
              <path
                d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                fill="#FBBC05"
              />
              <path
                d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                fill="#EB4335"
              />
            </svg>
          </div>
          <Typography component="div" grey variant="caption1">
            <span className={classes.buttonContainer}>
              <Button
                classes={{ override: classes.button }}
                onClick={loginWithGoogle}
                variant="anchor"
              >
                <div className={classes.buttonContent}>
                  <FormattedMessage id="LoginWithGoogle.sign_in" />
                </div>
              </Button>
            </span>
            <span>&nbsp;</span>
            <FormattedMessage id="LoginWithGoogle.sign_in_details" />
          </Typography>
        </>
      )}
    </div>
  );
}

export default LoginWithGoogle;
