export default {
  /**
   * @returns {number}
   */
  booker() {
    return window.state.meta.booker;
  },

  /**
   * @returns {Boolean}
   */
  enterprise() {
    return window.state.meta.enterprise;
  },
};
