import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import AddAttendeePage from '../../components/AddAttendeePage';
import AppointmentSummaryExpansionPanel from '../../components/AppointmentSummaryExpansionPanel';
import StepCounter from '../../components/StepCounter';
import TrackPageView from '../../components/TrackPageView';
import Typography from '../../components/Typography';
import { PAGES } from '../../constants';
import { HeaderContext } from '../../contexts/HeaderContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import QuestionShape from '../../shapes/QuestionShape';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[200],
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
  },
  header: {
    background: theme.palette.white,
    padding: '1.25rem',
  },
  form: {
    scrollBehavior: 'smooth',
  },
  summary: {
    marginTop: '0.25rem',
  },
  title: {
    marginTop: '0.25rem',
  },
}));

const Details = ({
  addAdditionalAttendee,
  countries,
  country,
  currentStep,
  form,
  loading,
  selectedAttendee,
  previous,
  previousStep,
  questions,
  setSelectedAttendee,
  setUploads,
  stepsCount,
  validate,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const [, setHeader] = useContext(HeaderContext);
  const { callbackServiceHelp } = useContext(SettingsContext);
  const [{ bookingWalkIn }] = useContext(SelectionContext);

  /* TODO: Conditionally show the back button when this step is not first */
  useEffect(() => {
    const back = () => setSelectedAttendee(null);
    let title = 'Steps.details';

    if (selectedAttendee) {
      title = selectedAttendee.email
        ? 'DetailsForm.edit_attendee_title'
        : 'DetailsForm.add_attendee_title';
    }

    setHeader({
      action: selectedAttendee ? back : previous,
      title: <FormattedMessage id={title} />,
      previousStep,
    });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previous, selectedAttendee, setHeader]);

  const header = selectedAttendee || (
    <header className={classes.header}>
      <StepCounter currentStep={currentStep} stepsCount={stepsCount} />
      <Typography classes={{ root: classes.title }} component="h1" variant="h5">
        <FormattedMessage id="Details.form_header" />
      </Typography>
    </header>
  );

  if (loading) {
    return (
      <section
        aria-atomic="false"
        className={classes.root}
        data-testid="details-mobile"
        role="status"
      >
        {header}
      </section>
    );
  }

  if (selectedAttendee) {
    return (
      <AddAttendeePage
        addAdditionalAttendee={addAdditionalAttendee}
        countries={countries}
        country={country}
        questions={questions}
        selectedAttendee={selectedAttendee}
        setSelectedAttendee={setSelectedAttendee}
        setUploads={setUploads}
        validate={validate}
      />
    );
  }

  return (
    <section className={classes.root} data-testid="details-mobile">
      <TrackPageView identifier={PAGES.DETAILS} />
      {bookingWalkIn && callbackServiceHelp ? (
        <section className={classes.header}>
          <div dangerouslySetInnerHTML={{ __html: callbackServiceHelp }} />
        </section>
      ) : null}
      {header}
      <div className={classes.summary}>
        <AppointmentSummaryExpansionPanel />
      </div>
      <section className={classes.form}>{form}</section>
    </section>
  );
};

Details.propTypes = {
  addAdditionalAttendee: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  country: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  form: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  questions: PropTypes.arrayOf(QuestionShape),
  selectedAttendee: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      ),
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
  setSelectedAttendee: PropTypes.func.isRequired,
  setUploads: PropTypes.func.isRequired,
  stepsCount: PropTypes.number.isRequired,
  validate: PropTypes.func.isRequired,
};

Details.defaultProps = {
  loading: true,
  previousStep: null,
  questions: null,
  selectedAttendee: null,
};

export default Details;
