import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Dates from '../../shared/helpers/Dates';
import Item from '../helpers/Item';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  hours: {
    display: 'flex',
    marginBottom: '0.5rem',
  },
  hoursTopMarginNoLabel: {
    '&:first-of-type': {
      marginTop: '1.5rem',
    },
  },
  hoursTopMarginWithLabel: {
    '&:first-of-type': {
      marginTop: '0.5rem',
    },
  },
  timespan: {
    flexGrow: 1,
    width: '55%',
  },
  today: {
    fontWeight: theme.fontWeights.medium,
  },
  weekday: {
    flexGrow: 1,
    width: '45%',
  },
}));

const LocationHours = ({ hours, showTitle, titleVariant }) => {
  const today = Dates.today().format('day').toLowerCase();
  const classes = useStyles({ theme: useTheme() });

  if (Dates.isIsoLocale()) {
    const { sunday, ...others } = hours;

    hours = { ...others, sunday };
  }

  return (
    <>
      {showTitle ? (
        <Typography variant={titleVariant}>
          <FormattedMessage id="LocationHours.title" />
        </Typography>
      ) : null}
      {Item.map(hours, (weekday, index) => {
        const isToday = today === weekday;
        const { open, closed } = hours[weekday];
        const dayOfWeek = Dates.dayOfWeek(index);

        let timespan = <FormattedMessage id="LocationHours.closed" />;

        if (open && closed) {
          timespan = `${Dates.time(open, true)} - ${Dates.time(closed, true)}`;
        }

        return (
          <div
            className={classNames(
              classes.hours,
              isToday ? classes.today : null,
              titleVariant === 'label'
                ? classes.hoursTopMarginWithLabel
                : classes.hoursTopMarginNoLabel,
            )}
            data-testid={`weekday-${weekday}`}
            key={weekday}
          >
            <div className={classes.weekday}>
              <Typography variant={isToday ? 'subtitle' : 'caption1'}>
                {dayOfWeek}
              </Typography>
            </div>
            <div className={classes.timespan}>
              <Typography variant={isToday ? 'subtitle' : 'caption1'}>
                {timespan}
              </Typography>
            </div>
          </div>
        );
      })}
    </>
  );
};

LocationHours.propTypes = {
  hours: PropTypes.objectOf(
    PropTypes.shape({ closed: PropTypes.string, open: PropTypes.string }),
  ).isRequired,
  showTitle: PropTypes.bool,
  titleVariant: PropTypes.oneOf(['title', 'label']),
};

LocationHours.defaultProps = {
  showTitle: true,
  titleVariant: 'title',
};
export default LocationHours;
