import { VISIBILITIES } from '../../constants';
import Url from '../Url';

const SupportedLanguages = (client) => ({
  /**
   * @returns {Promise<any>}
   */
  all({
    location,
    locationCategory,
    method,
    region,
    service,
    settings,
    user,
    userCategory,
    visibility = VISIBILITIES.PUBLIC,
  } = {}) {
    const filters = {};

    if (location) {
      filters.location = location.id;
    }

    if (locationCategory && !settings?.preferred_location) {
      filters.location_category = locationCategory.id;
    }

    if (method) {
      filters.meeting_method = method;
    }

    if (region) {
      filters.region = region;
    }

    if (service) {
      filters.service = service.id;
    }

    if (user) {
      filters.user = user.id;
    }

    if (userCategory) {
      filters.user_category = userCategory.id;
    }

    if (visibility !== undefined) {
      filters.visibility = visibility;
    }

    if (settings?.invite_only_resources) {
      filters.invite_only_resources = settings.invite_only_resources;
    }

    return client.get(Url.url('/api/v2/open/supported-languages', filters));
  },
});

export default SupportedLanguages;
