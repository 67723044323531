import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '../Typography';

const useStyles = createUseStyles({
  root: {
    padding: '1.35rem 1.25rem 0.25rem',
    '&:last-child $content': {
      borderBottom: '0 none',
    },
  },
});

const ListHeader = ({ children }) => {
  const classes = useStyles();

  return (
    <li className={classes.root}>
      <Typography component="div" variant="caption1">
        {children}
      </Typography>
    </li>
  );
};

ListHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default ListHeader;
