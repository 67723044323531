import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from '../Typography';

const useStyles = createUseStyles((theme) => ({
  container: {
    height: '1.25rem',
    position: 'relative',
    width: '1.25rem',
  },
  input: {
    border: `0.125rem solid ${theme.palette.text.secondary}`,
    borderRadius: theme.borderRadius.full,
    height: '1.25rem',
    margin: 0,
    transition: theme.transitions.border,
    width: '1.25rem',
    '&:focus': {
      borderColor: theme.palette.info[400],
      boxShadow: theme.shadows.input,
      outline: 0,
    },
    '&:checked': {
      backgroundColor: theme.palette.info[400],
      borderColor: theme.palette.info[400],
      '& + $dot': {
        display: 'block',
      },
    },
    '&::-ms-check': {
      border: `0.125rem solid ${theme.palette.text.secondary}`,
      borderRadius: theme.borderRadius.full,
    },
  },
  dot: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.full,
    content: '""',
    display: 'none',
    height: '0.5rem',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '0.5rem',
  },
  label: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    paddingTop: '0.5rem',
    userSelect: 'none',
  },
  text: {
    marginLeft: '0.5rem',
    fontWeight: theme.fontWeights.normal,
  },
}));

const RadioInput = ({ defaultChecked, id, label, name, onChange, value }) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <label className={classes.label} htmlFor={`${id || name}-${value}`}>
      <div className={classes.container}>
        <input
          className={classes.input}
          defaultChecked={defaultChecked}
          id={`${id || name}-${value}`}
          name={name}
          onChange={onChange}
          type="radio"
          value={value}
        />
        <div className={classes.dot} />
      </div>
      <Typography classes={{ root: classes.text }} variant="label">
        {label}
      </Typography>
    </label>
  );
};

RadioInput.propTypes = {
  defaultChecked: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

RadioInput.defaultProps = {
  defaultChecked: false,
  id: '',
};

export default RadioInput;
