import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import SelectableListPage from '../../components/mobile/SelectableListPage';
import { SHORTCUTS, USER_PREFERENCE } from '../../constants';
import { SelectionContext } from '../../contexts/SelectionContext';
import Resources from '../../helpers/Resources';
import Shortcuts from '../../helpers/Shortcuts';

const Preferences = () => {
  const history = useHistory();

  const [{ user, userCategory }, setSelections] = useContext(SelectionContext);

  const selectPreference = ({ currentTarget: { dataset } }) => {
    const preference = dataset.id || USER_PREFERENCE.RANDOM;

    if (Shortcuts.exists(SHORTCUTS.USER)) {
      const selections = {
        user:
          preference === USER_PREFERENCE.SPECIFIC
            ? Resources.formatUser(Shortcuts.get(SHORTCUTS.USER))
            : null,
      };

      // We have to keep the preference as 'random' so that correct times page shows up
      if (preference === USER_PREFERENCE.SPECIFIC) {
        selections.userPreference = USER_PREFERENCE.RANDOM;
      }

      setSelections(selections);

      return;
    }

    setSelections({
      userCategory:
        preference === USER_PREFERENCE.SPECIFIC
          ? { id: Shortcuts.identify(SHORTCUTS.USER_CATEGORY) }
          : null,
    });
  };

  const { preferences = [] } = history.location.state;
  const preferred = {
    id:
      user || userCategory ? USER_PREFERENCE.SPECIFIC : USER_PREFERENCE.RANDOM,
  };

  const isChecked = (preference) => preference.id === preferred.id;

  return (
    <SelectableListPage
      isChecked={isChecked}
      items={preferences}
      onSelect={selectPreference}
      title="Pages.staff_preference"
    />
  );
};

export default Preferences;
