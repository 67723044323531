import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useIntl } from 'react-intl';
import LoadingState from '../components/LoadingState';
import { MEETING_METHODS, SHORTCUTS, STEPS } from '../constants';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Api from '../helpers/Api';
import {
  shouldSkipLocationStep,
  shouldSkipMethodsStep,
} from '../helpers/MeetingMethods';
import Resources from '../helpers/Resources';
import Shortcuts from '../helpers/Shortcuts';
import DesktopMeetingMethods from './desktop/MeetingMethods';
import Location from './Location';
import MobileMeetingMethods from './mobile/MeetingMethods';

const MeetingMethods = ({
  currentStep,
  next,
  previous,
  previousStep,
  stepsCount,
}) => {
  const intl = useIntl();
  const mode = useContext(ViewModeContext);
  const [locale] = useContext(LocaleContext);
  const { firstStep, includeLocationStep } = useContext(SettingsContext);
  const [
    {
      location,
      locationCategory,
      meetingMethod,
      service,
      settings,
      skip,
      user,
      userCategory,
    },
    setSelections,
  ] = useContext(SelectionContext);

  const [{ loading, methods }, setMethods] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    Shortcuts.exists(SHORTCUTS.MEETING_METHOD)
      ? {
          loading: false,
          methods: [{ id: Shortcuts.get(SHORTCUTS.MEETING_METHOD) }],
        }
      : {
          loading: true,
          methods: [],
        },
  );
  const isBack = useRef(false);
  const firstStepIsLocation = firstStep === STEPS.LOCATION;
  const onlyPhysical =
    methods.length === 1 && methods[0].id === MEETING_METHODS.AT_LOCATION;

  const showLocation = !shouldSkipLocationStep(
    meetingMethod,
    service,
    firstStep,
    includeLocationStep,
  );

  const handleClick = ({
    currentTarget: {
      dataset: { id },
    },
  }) => {
    const method = Number(id);
    const skipMethods = shouldSkipMethodsStep(methods);
    const skipLocation = shouldSkipLocationStep(
      method,
      service,
      firstStep,
      includeLocationStep,
    );

    const selections = {
      meetingMethod: method,
      skip: {
        ...skip,
        [STEPS.MEETING_METHODS]: skipMethods,
        [STEPS.LOCATION]: skipLocation,
      },
    };

    setSelections(selections);

    if (skipLocation) {
      next(selections);
    }
  };

  const onBack = () => {
    const selections = { location: null, meetingMethod: null };

    if (firstStepIsLocation || Shortcuts.exists(SHORTCUTS.LOCATION)) {
      delete selections.location;
    }

    setSelections(selections);
    previous(selections);
  };

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'Steps.meeting_methods' });
    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showLocation && !methods.length) {
      Api.locale(locale)
        .methods()
        .all({
          location:
            !settings?.preferred_location || firstStepIsLocation
              ? location
              : null,
          locationCategory,
          region: location ? null : Shortcuts.get(SHORTCUTS.REGION, null),
          service,
          settings,
          user: !settings?.preferred_staff ? user : null,
          userCategory,
        })
        .then((data) => {
          setMethods({ loading: false, methods: data });
        });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLocation]);

  if (showLocation) {
    const onBackFromLocation = () => {
      const selections = { location: null, meetingMethod: null };
      const hasMeetingMethodShortcut = Shortcuts.exists(
        SHORTCUTS.MEETING_METHOD,
      );
      const hasPreferredLocation = Shortcuts.get(
        SHORTCUTS.SETTINGS,
      )?.preferred_location;

      if (hasMeetingMethodShortcut) {
        delete selections.meetingMethod;
      }

      if (firstStepIsLocation) {
        delete selections.location;
      }

      if (hasPreferredLocation) {
        selections.location = Resources.formatLocation(
          Shortcuts.get(SHORTCUTS.LOCATION),
        );
      }

      isBack.current = true;
      setSelections(selections);

      if (skip[STEPS.MEETING_METHODS]) {
        previous(selections);
      }
    };

    let prevStep = STEPS.MEETING_METHODS;
    if (skip[STEPS.MEETING_METHODS]) {
      prevStep = Shortcuts.exists(SHORTCUTS.SERVICE) ? '' : prevStep;
    }

    return (
      <Location
        currentStep={currentStep}
        next={next}
        previous={onBackFromLocation}
        previousStep={prevStep}
        stepsCount={stepsCount}
      />
    );
  }

  if (
    !isBack.current &&
    ((!meetingMethod && onlyPhysical) ||
      Shortcuts.exists(SHORTCUTS.MEETING_METHOD))
  ) {
    const method = meetingMethod || MEETING_METHODS.AT_LOCATION;

    const skipMethods = shouldSkipMethodsStep(methods);
    const skipLocation = shouldSkipLocationStep(
      method,
      service,
      firstStep,
      includeLocationStep,
    );

    const selections = {
      skip: {
        ...skip,
        [STEPS.MEETING_METHODS]: skipMethods,
        [STEPS.LOCATION]: skipLocation,
      },
    };

    if (!meetingMethod) {
      selections.meetingMethod = method;
    }

    setSelections(selections);

    if (skipMethods && skipLocation) {
      next(selections);
    }
  }

  if (loading) {
    return <LoadingState data-testid="methods-loading" />;
  }

  const props = {
    currentStep,
    handleClick,
    methods,
    next,
    onBack,
    previousStep,
    stepsCount,
  };

  return mode === MOBILE ? (
    <MobileMeetingMethods {...props} />
  ) : (
    <DesktopMeetingMethods {...props} />
  );
};

MeetingMethods.propTypes = {
  currentStep: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  stepsCount: PropTypes.number.isRequired,
};

MeetingMethods.defaultProps = {
  previousStep: null,
};

export default MeetingMethods;
