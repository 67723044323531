import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import List from '../../components/List';
import LocationHeader from '../../components/LocationHeader';
import MeetingMethodInfoBanner from '../../components/MeetingMethodInfoBanner';
import Card from '../../components/mobile/Card';
import StepCounter from '../../components/StepCounter';
import TrackPageView from '../../components/TrackPageView';
import Typography from '../../components/Typography';
import { MEETING_METHOD_TITLES, PAGES } from '../../constants';
import { HeaderContext } from '../../contexts/HeaderContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { StepAbilityContext } from '../../contexts/StepAbilityContext';
import MeetingMethodsShape from '../../shapes/MeetingMethodsShape';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[200],
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    minHeight: '100%',
  },
  header: {
    background: theme.palette.white,
    padding: '1.25rem',
  },
  title: {
    marginTop: '0.25rem',
  },
}));

const MeetingMethods = ({
  currentStep,
  handleClick,
  methods,
  onBack,
  previousStep,
  stepsCount,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const { can } = useContext(StepAbilityContext);
  const [, setHeader] = useContext(HeaderContext);
  const [{ location }] = useContext(SelectionContext);

  useEffect(() => {
    setHeader({
      action: can.step.backwards ? onBack : null,
      title: <FormattedMessage id="Steps.meeting_methods" />,
      previousStep,
    });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [can.step.backwards, currentStep, onBack, setHeader]);

  return (
    <section className={classes.root} data-testid="mobile-meeting-methods">
      <TrackPageView identifier={PAGES.MEETING_METHOD} />
      <header aria-atomic="false" className={classes.header} role="alert">
        {location ? <LocationHeader location={location} /> : null}
        <StepCounter currentStep={currentStep} stepsCount={stepsCount} />
        <Typography
          classes={{ root: classes.title }}
          component="h1"
          variant="h5"
        >
          <FormattedMessage id="MeetingMethods.header" />
        </Typography>
      </header>
      <MeetingMethodInfoBanner methods={methods} />
      <List>
        {methods.map((method) => (
          <Card
            action={handleClick}
            data-testid={`meeting-method-card-${method.id}`}
            icon
            id={method.id}
            key={method.id}
            primary={
              <FormattedMessage id={MEETING_METHOD_TITLES[method.id].primary} />
            }
            secondary={
              <FormattedMessage
                id={MEETING_METHOD_TITLES[method.id].secondary}
              />
            }
          />
        ))}
      </List>
    </section>
  );
};

MeetingMethods.propTypes = {
  currentStep: PropTypes.number.isRequired,
  handleClick: PropTypes.func,
  methods: MeetingMethodsShape,
  onBack: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  stepsCount: PropTypes.number.isRequired,
};

MeetingMethods.defaultProps = {
  handleClick: () => {},
  methods: [],
  previousStep: null,
};

export default MeetingMethods;
