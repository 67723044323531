import { Button } from '@coconut-software/ui';
import { Place } from '@coconut-software/ui/dist/icons';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
  },
  icon: {
    '& svg:first-child': {
      transform: 'translateX(-4px)',
      verticalAlign: 'middle',
    },
  },
});

const ViewOnMapButton = ({ location, onClick }) => {
  const classes = useStyles();
  const mode = useContext(ViewModeContext);
  const isMobile = mode === MOBILE;

  if (location.physical && isMobile) {
    return (
      <span className={classes.root} data-testid="view-on-map-button">
        <Button
          color="primary"
          onClick={onClick}
          startAdornment={Place}
          variant="contained"
        >
          <FormattedMessage id="ViewOnMapButton.title" />
        </Button>
      </span>
    );
  }

  return null;
};

ViewOnMapButton.propTypes = {
  location: PropTypes.shape({ physical: PropTypes.bool }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ViewOnMapButton;
