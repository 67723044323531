import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { MEETING_METHODS } from '../../backend/constants';
import Dates from '../../shared/helpers/Dates';
import urlMode from '../../shared/helpers/Mode';
import EventBusyRounded from '../../shared/icons/EventBusyRounded';
import {
  APPOINTMENT_STATUSES,
  ATTENDEE_STATUSES,
  SHORTCUTS,
} from '../constants';
import { AppointmentContext } from '../contexts/AppointmentContext';
import { FeatureContext } from '../contexts/FeatureContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Api from '../helpers/Api';
import Open from '../helpers/api/Open';
import Resources from '../helpers/Resources';
import Shortcuts from '../helpers/Shortcuts';
import Button from './Button';
import CancelAppointmentModal from './CancelAppointmentModal';
import MeetingMethodModal from './MeetingMethodModal';
import RescheduleButton from './RescheduleButton';
import TooLateToCancelModal from './TooLateToCancelModal';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    padding: '1.5rem',
    marginTop: '0.5rem',
  },
  button: {
    marginTop: '0.75rem',
  },
  content: {
    ...theme.components.html.default,
    '&$desktop': {
      marginLeft: '2.375rem',
    },
  },
  desktop: {},
  icon: {
    color: theme.palette.primary[400],
    display: 'inline-flex',
    marginRight: '0.875rem',
  },
  noLongerAvailable: {
    marginTop: '1.5rem',
  },
  section: {
    marginTop: '1.5rem',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '1.25rem',
  },
}));

const ChangesToYourAppointment = ({ cancel }) => {
  const classes = useStyles({ theme: useTheme() });
  const OpenApi = Open.api();
  const intl = useIntl();
  const {
    builderEnabled,
    customCancellationMessage,
    customReschedulingMessage,
    timeToCancel: generalTimeToCancel,
    timeToReschedule: generalTimeToReschedule,
    includeLocationStep,
  } = useContext(SettingsContext);
  const [
    {
      attendee,
      date,
      location,
      locationCategory,
      meetingMethod,
      service,
      settings,
      user,
      userCategory,
    },
  ] = useContext(SelectionContext);
  const mode = useContext(ViewModeContext);
  const [locale] = useContext(LocaleContext);
  const { meetingMethods, appointmentBookingChannel } =
    useContext(FeatureContext);
  const [timezone] = useContext(TimezoneContext);
  const [appointment] = useContext(AppointmentContext);
  const timeToCancel =
    service.timeToCancel || service.timeToCancel === 0
      ? service.timeToCancel
      : generalTimeToCancel;
  const timeToReschedule =
    service.timeToReschedule || service.timeToReschedule === 0
      ? service.timeToReschedule
      : generalTimeToReschedule;

  const [
    {
      filteredAvailableMethods,
      canCancel,
      canReschedule,
      cancelled,
      formattedTimeToCancel,
      formattedTimeToReschedule,
      future,
      lateToCancel,
      lateToReschedule,
      meetingMethodEndpointCalled,
      meetingMethodOptions,
      meetingMethodLoading,
      openMeetingMethodModal,
      successfullyCancelled,
    },
    setState,
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    filteredAvailableMethods: [],
    canCancel: timeToCancel >= 0,
    canReschedule: timeToReschedule !== null && timeToReschedule >= 0,
    cancelled:
      appointment.status === APPOINTMENT_STATUSES.CANCELLED ||
      attendee.status === ATTENDEE_STATUSES.CANCELLED,
    formattedTimeToCancel: null,
    formattedTimeToReschedule: null,
    future: Dates.isFuture(date, timezone),
    lateToCancel: Dates.diffMinutes(date, Dates.now(timezone)) < timeToCancel,
    lateToReschedule:
      Dates.diffMinutes(date, Dates.now(timezone)) < timeToReschedule,
    meetingMethodEndpointCalled: false,
    meetingMethodOptions: [],
    meetingMethodLoading: true,
    openMeetingMethodModal: false,
    successfullyCancelled: false,
  });

  const [openCancelModal, setOpenCancelModal] = useState(false);

  const [{ form, loading }, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      form: null,
      loading: false,
    },
  );

  useEffect(() => {
    if (successfullyCancelled) {
      cancel();
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullyCancelled]);

  useEffect(() => {
    setState({
      cancelled:
        appointment.status === APPOINTMENT_STATUSES.CANCELLED ||
        attendee.status === ATTENDEE_STATUSES.CANCELLED,
    });
  }, [appointment.status, attendee.status]);

  useEffect(() => {
    setState({
      formattedTimeToCancel: Dates.formatMinutes(timeToCancel),
      formattedTimeToReschedule: Dates.formatMinutes(timeToReschedule),
    });
  }, [timeToCancel, timeToReschedule]);

  useEffect(() => {
    setState({
      canCancel: timeToCancel >= 0,
      canReschedule: timeToReschedule !== null && timeToReschedule >= 0,
      future: Dates.isFuture(date, timezone),
      lateToCancel: Dates.diffMinutes(date, Dates.now(timezone)) < timeToCancel,
      lateToReschedule:
        Dates.diffMinutes(date, Dates.now(timezone)) < timeToReschedule,
    });
  }, [date, timeToCancel, timeToReschedule, timezone]);

  const handleClickCancel = () => {
    setState({
      lateToCancel: Dates.diffMinutes(date, Dates.now(timezone)) < timeToCancel,
    });
    setOpenCancelModal(!openCancelModal);
  };

  const cancellationEnabled = canCancel && !cancelled;
  const cancellationDisabled = !cancellationEnabled;
  const cancellationExpired = !future || lateToCancel;

  const rescheduleEnabled =
    canReschedule && !cancelled && appointment.reschedulable;
  const rescheduleDisabled = !(
    rescheduleEnabled && (!user && !service.group ? builderEnabled : true)
  );
  const changeMeetingMethodDisabled = !rescheduleEnabled;
  const rescheduleExpired = !future || lateToReschedule;

  let availableMethods = [];

  if (!builderEnabled) {
    availableMethods = (service.meetingMethods || []).filter(
      (method) => method !== meetingMethod,
    );

    if (user) {
      availableMethods = availableMethods.filter((method) =>
        user.meetingMethods.includes(method),
      );
    }

    if (!location.physical) {
      availableMethods = availableMethods.filter(
        (method) => method !== MEETING_METHODS.AT_BUSINESS,
      );
    }

    if (
      location.physical &&
      !service.requireLocation &&
      includeLocationStep === false &&
      (meetingMethod === MEETING_METHODS.VIDEO_CALL ||
        meetingMethod === MEETING_METHODS.PHONE_CALL)
    ) {
      availableMethods = availableMethods.filter(
        (method) => method !== MEETING_METHODS.AT_BUSINESS,
      );
    }
  }

  const meetingMethodUpdatable = builderEnabled
    ? false
    : availableMethods.length > 0;

  useEffect(() => {
    if (cancellationEnabled && !cancellationExpired) {
      setForm({ loading: true });

      OpenApi.locale(locale)
        .forms()
        .cancellations()
        .get()
        .then(({ data: { data, included } }) => {
          const formData = Resources.formatForm(data, included);

          if (formData) {
            formData.forEach((question) => {
              question.options.push({
                id: '0',
                additional: true,
                value: 0,
                text: intl.formatMessage({ id: 'Service.uncategorized_title' }),
              });
            });
          }

          setForm({
            loading: false,
            form: formData,
          });
        });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lateToCancel, cancellationEnabled, locale]);

  useEffect(() => {
    if (
      openMeetingMethodModal &&
      meetingMethodUpdatable &&
      !meetingMethodEndpointCalled
    ) {
      Api.locale(locale)
        .methods()
        .all({
          location,
          locationCategory,
          region: location ? null : Shortcuts.get(SHORTCUTS.REGION, null),
          service,
          settings,
          timeRangeEnd: appointment.endRaw,
          timeRangeStart: appointment.startRaw,
          timeRangeTimezone: timezone,
          user,
          userCategory,
        })
        .then((data) => {
          data = data.map((method) => method.id);
          let methods = [];

          if (availableMethods.length > 0) {
            methods = availableMethods.filter((method) =>
              data.includes(method),
            );
          }

          setState({
            meetingMethodEndpointCalled: true,
            meetingMethodOptions: data,
            meetingMethodLoading: false,
            filteredAvailableMethods: methods,
          });
        })
        .catch(() => {
          setState({
            meetingMethodLoading: false,
          });
        });
    } else if (
      openMeetingMethodModal &&
      meetingMethodUpdatable &&
      meetingMethodEndpointCalled
    ) {
      let methods = [];

      if (availableMethods.length > 0) {
        methods = availableMethods.filter((method) =>
          meetingMethodOptions.includes(method),
        );
      }

      setState({
        filteredAvailableMethods: methods,
      });
    }
  }, [meetingMethodOptions, meetingMethodUpdatable, openMeetingMethodModal]);

  const validateReschedule = () => {
    const valid =
      Dates.isFuture(date, timezone) &&
      Dates.diffMinutes(date, Dates.now(timezone)) > timeToReschedule;
    setState({
      lateToReschedule: !valid,
    });

    return valid;
  };

  const setTooLate = () => setState({ lateToReschedule: true });

  const toggleCancelModal = () => setOpenCancelModal((prev) => !prev);

  const toggleMeetingMethodModal = () => {
    const pastTimeToReschedule = lateToReschedule || !validateReschedule();
    setState({
      openMeetingMethodModal: pastTimeToReschedule
        ? false
        : !openMeetingMethodModal,
    });
  };

  if (urlMode.isKiosk()) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography classes={{ root: classes.title }} component="h4" variant="h6">
        <span className={classes.icon}>
          <EventBusyRounded />
        </span>
        <FormattedMessage id="ChangesToYourAppointment.title" />
      </Typography>
      <div
        className={classNames(
          classes.content,
          mode !== MOBILE && classes.desktop,
        )}
      >
        <div>
          {appointmentBookingChannel ? (
            <Typography component="div" variant="label">
              <FormattedMessage id="ChangesToYourAppointment.reschedule_header" />
            </Typography>
          ) : null}
          {appointmentBookingChannel &&
          rescheduleDisabled &&
          !customReschedulingMessage ? (
            <Typography variant="subtitle">
              <FormattedMessage id="ChangesToYourAppointment.cannot_reschedule" />
            </Typography>
          ) : null}
          {appointmentBookingChannel &&
          rescheduleEnabled &&
          !customReschedulingMessage &&
          formattedTimeToReschedule !== null &&
          timeToReschedule > 0 ? (
            <Typography
              data-testid="default-reschedule-policy"
              variant="subtitle"
            >
              <FormattedMessage
                id="ChangesToYourAppointment.reschedule_policy_details"
                values={{
                  duration: (
                    <FormattedMessage
                      id={formattedTimeToReschedule.id}
                      values={{
                        duration: formattedTimeToReschedule.duration,
                      }}
                    />
                  ),
                }}
              />
            </Typography>
          ) : null}
          {appointmentBookingChannel &&
          rescheduleEnabled &&
          !customReschedulingMessage &&
          formattedTimeToReschedule !== null &&
          timeToReschedule === 0 ? (
            <Typography variant="subtitle">
              <FormattedMessage id="ChangesToYourAppointment.reschedule_policy_details_no_limit" />
            </Typography>
          ) : null}
          {appointmentBookingChannel && customReschedulingMessage ? (
            <Typography variant="subtitle">
              <span
                dangerouslySetInnerHTML={{ __html: customReschedulingMessage }}
              />
            </Typography>
          ) : null}
          {appointmentBookingChannel &&
          rescheduleEnabled &&
          !rescheduleExpired ? (
            <div className={classes.button}>
              <RescheduleButton validateReschedule={validateReschedule} />
            </div>
          ) : null}
          {appointmentBookingChannel &&
          rescheduleEnabled &&
          rescheduleExpired ? (
            <div className={classes.noLongerAvailable}>
              <Typography component="div" variant="caption1">
                <FormattedMessage id="ChangesToYourAppointment.reschedule_no_longer_available" />
              </Typography>
            </div>
          ) : null}
        </div>
        {
          // We are temporarily ignoring the destructuring-assignment rule explicitly.
          // There is a bug that was solved in a newer version of this plugin which
          // we will eventually be able to upgrade to once we can move off of
          // the current version of NodeJS in use.
          //
          // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
          //
          meetingMethods &&
          appointmentBookingChannel &&
          meetingMethodUpdatable &&
          !builderEnabled ? (
            <div className={classes.section}>
              <Typography component="div" variant="label">
                <FormattedMessage id="ChangesToYourAppointment.update_meeting_method" />
              </Typography>
              <Typography variant="subtitle">
                {changeMeetingMethodDisabled ? (
                  <FormattedMessage id="ChangesToYourAppointment.meeting_method_subtitle_locked" />
                ) : timeToReschedule === 0 || !formattedTimeToReschedule ? (
                  <FormattedMessage id="ChangesToYourAppointment.meeting_method_subtitle" />
                ) : (
                  <FormattedMessage
                    id="ChangesToYourAppointment.meeting_method_subtitle_limited"
                    values={{
                      duration: (
                        <FormattedMessage
                          id={formattedTimeToReschedule.id}
                          values={{
                            duration: formattedTimeToReschedule.duration,
                          }}
                        />
                      ),
                    }}
                  />
                )}
              </Typography>
              {changeMeetingMethodDisabled ? null : !rescheduleExpired ? (
                <div className={classes.button}>
                  <Button
                    fullWidth={false}
                    onClick={toggleMeetingMethodModal}
                    variant="smallOutlined"
                  >
                    <FormattedMessage id="ChangesToYourAppointment.update_meeting_method" />
                  </Button>
                </div>
              ) : (
                <div className={classes.noLongerAvailable}>
                  <Typography component="div" variant="caption1">
                    <FormattedMessage id="ChangesToYourAppointment.update_meeting_method_not_available" />
                  </Typography>
                </div>
              )}
              <MeetingMethodModal
                loading={meetingMethodLoading}
                meetingMethods={filteredAvailableMethods}
                open={openMeetingMethodModal}
                setTooLate={setTooLate}
                toggleModal={toggleMeetingMethodModal}
              />
            </div>
          ) : null
        }
        <div className={classes.section}>
          <Typography component="div" variant="label">
            <FormattedMessage id="ChangesToYourAppointment.cancellation_policy_header" />
          </Typography>
          {cancellationDisabled && !customCancellationMessage ? (
            <Typography variant="subtitle">
              <FormattedMessage id="ChangesToYourAppointment.cannot_cancel" />
            </Typography>
          ) : null}
          {cancellationEnabled &&
          !customCancellationMessage &&
          formattedTimeToCancel !== null &&
          timeToCancel > 0 ? (
            <Typography
              data-testid="default-cancellation-policy"
              variant="subtitle"
            >
              <FormattedMessage
                id="Manage.cancellation_policy_details"
                values={{
                  duration: (
                    <FormattedMessage
                      id={formattedTimeToCancel.id}
                      values={{ duration: formattedTimeToCancel.duration }}
                    />
                  ),
                }}
              />
            </Typography>
          ) : null}
          {cancellationEnabled &&
          !customCancellationMessage &&
          formattedTimeToCancel !== null &&
          timeToCancel === 0 ? (
            <Typography variant="subtitle">
              <FormattedMessage id="Manage.cancellation_policy_details_no_limit" />
            </Typography>
          ) : null}
          {customCancellationMessage ? (
            <Typography variant="subtitle">
              <span
                dangerouslySetInnerHTML={{ __html: customCancellationMessage }}
              />
            </Typography>
          ) : null}
          {cancellationEnabled && !cancellationExpired ? (
            <div className={classes.button} data-testid="cancel-button">
              <Button
                data-testid="manage-cancel-button"
                fullWidth={false}
                onClick={handleClickCancel}
                variant="smallOutlined"
              >
                <FormattedMessage id="Manage.cancel" />
              </Button>
            </div>
          ) : null}
          {cancellationEnabled && cancellationExpired ? (
            <div className={classes.noLongerAvailable}>
              <Typography component="div" variant="caption1">
                <FormattedMessage id="ChangesToYourAppointment.cancellation_no_longer_available" />
              </Typography>
            </div>
          ) : null}
        </div>
      </div>
      {cancellationEnabled && !cancellationExpired ? (
        <CancelAppointmentModal
          formLoading={loading}
          open={openCancelModal}
          questions={form}
          setState={setState}
          toggleModal={toggleCancelModal}
        />
      ) : null}
      {cancellationEnabled && lateToCancel ? (
        <TooLateToCancelModal
          open={openCancelModal}
          toggleModal={toggleCancelModal}
        />
      ) : null}
    </div>
  );
};

ChangesToYourAppointment.propTypes = {
  cancel: PropTypes.func.isRequired,
};

export default ChangesToYourAppointment;
