import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import CxSnippet from '../../../shared/components/CxSnippet';
import { FeatureDecisionContext } from '../../../shared/contexts/FeatureDecisionContext';
import Dates from '../../../shared/helpers/Dates';
import CircularProgress from '../../components/CircularProgress';
import DateTimeFilter from '../../components/DateTimeFilter';
import FindAvailableDate from '../../components/FindAvailableDate';
import Language from '../../components/icons/Language';
import LobbyBanner from '../../components/LobbyBanner';
import LocationHeader from '../../components/LocationHeader';
import LoginWithGoogle from '../../components/LoginWithGoogle';
import OtherLocationsTimeChunks from '../../components/OtherLocationsTimeChunks';
import SupportedLanguageFilter from '../../components/SupportedLanguageFilter';
import TimeChunks from '../../components/TimeChunks';
import TimezonesShownIn from '../../components/TimezonesShownIn';
import Typography from '../../components/Typography';
import WeeklyDatePicker from '../../components/WeeklyDatePicker';
import { USER_PREFERENCE } from '../../constants';
import { FeatureContext } from '../../contexts/FeatureContext';
import { HeaderContext } from '../../contexts/HeaderContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import { TimezoneContext } from '../../contexts/TimezoneContext';
import { UsersContext } from '../../contexts/UsersContext';
import { MOBILE } from '../../contexts/ViewModeContext';
import { formatMultipleUsers } from '../../helpers/AdditionalUsers';
import Item from '../../helpers/Item';
import Settings from '../../helpers/Settings';
import useFetchSlots from '../../hooks/useFetchSlots';
import Range from '../../prototypes/Range';
import SpacetimeShape from '../../shapes/SpacetimeShape';
import SupportedLocalePreferenceShape from '../../shapes/SupportedLocalePreferenceShape';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.white,
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  availabilities: {
    padding: '2.5rem 0',
  },
  header: {
    background: theme.palette.white,
    padding: '1.25rem',
  },
  title: {
    marginTop: '0.25rem',
  },
  date: {
    padding: '1.25rem 0 0 1.25rem',
  },
  filters: {
    borderBottom: `0.25rem solid ${theme.palette.neutral[100]}`,
    padding: '1.25rem 1.25rem 0.5rem',
  },
  findNextDateContainer: {
    margin: '1.25rem 1rem',
  },
  hidden: {
    display: 'none',
  },
  loadingMessage: {
    marginBottom: '1.5rem',
    marginTop: '1.5rem',
    textAlign: 'center',
  },
}));

const Times = ({
  earliestDate,
  error,
  exclusion,
  fetch,
  initialStartDate,
  loading,
  loadingMessage,
  preferences,
  previous,
  previousStep,
  rescheduling,
  selectDate,
  selected,
  selectTime,
  setInformation,
  slots,
  slotsApiIdRef,
  slotsLoading,
  subtitle,
  next,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });
  const [
    {
      additionalUsers,
      googleUser,
      location,
      locationCategory,
      meetingMethod,
      service,
      settings,
      shortcuts,
      user,
      userCategory,
      userPreference,
    },
  ] = useContext(SelectionContext);
  const [locale] = useContext(LocaleContext);
  const features = useContext(FeatureContext);
  const { supportedLanguages } = useContext(UsersContext);
  const [timezone] = useContext(TimezoneContext);
  const [, setHeader] = useContext(HeaderContext);
  const { showStaffAssigned, showStaffPicker } = useContext(SettingsContext);
  const { shouldUseNextAvailability, shouldUseNextAvailabilityNewView } =
    useContext(FeatureDecisionContext);
  const showStaffName =
    Settings.shouldShowStaffPicker({ showStaffPicker }, location) ||
    Boolean(showStaffAssigned);

  const initial = useRef(true);
  const windowStart = shortcuts?.settings?.booking_window_start;
  const windowEnd = shortcuts?.settings?.booking_window_end;
  const [{ range, skipNextFetchSlots }, setRange] = useReducer(
    (state, newState) => ({
      ...state,
      range: Range.override({
        range: newState.range,
        start: windowStart,
        end: windowEnd,
        endOverride: state.range.endOverride,
        startOverride: state.range.startOverride,
      }),
      skipNextFetchSlots: newState.skipNextFetchSlots,
    }),
    null,
    () => ({
      range: Range.override({
        range: Range.week({
          date:
            shouldUseNextAvailability &&
            shouldUseNextAvailabilityNewView &&
            rescheduling
              ? initialStartDate
              : selected,
        }),
        start: windowStart,
        end: windowEnd,
        startOverride: windowStart ? Dates.parse(windowStart) : null,
        endOverride: windowEnd ? Dates.parse(windowEnd) : null,
      }),
      skipNextFetchSlots: false,
    }),
  );

  useEffect(() => {
    if (initial.current) {
      initial.current = false;

      return;
    }

    setRange({ range: Range.week({ date: selected }) });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    setInformation({ error: null });
  }, [setInformation, user]);

  useEffect(() => {
    setHeader({
      action: previous,
      title: <FormattedMessage id="Steps.date_time" />,
      previousStep,
    });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previous]);

  useFetchSlots({
    additionalUsers,
    earliestDate,
    exclusion,
    features,
    fetch,
    googleUser,
    location,
    locationCategory,
    meetingMethod,
    merge: false,
    preferred: userPreference || { id: USER_PREFERENCE.RANDOM },
    range,
    service,
    setInformation,
    setRange,
    settings,
    skipNextFetchSlots,
    slotsApiIdRef,
    supportedLanguages,
    timezone,
    user,
    userCategory:
      userPreference?.id === USER_PREFERENCE.SPECIFIC || user
        ? null
        : userCategory,
  });

  const forward = () => {
    setInformation({ loading: true, error: null });
    setRange({ range: range.next() });
  };
  const backward = () => {
    setInformation({ loading: true, error: null });
    setRange({ range: range.previous() });
  };

  const resetRange = () => {
    // when choosing a specific staff, we want to reset the range to the initial start date
    // so the rest of the code can find the earliest date for next availability feature
    if (shouldUseNextAvailability && shouldUseNextAvailabilityNewView) {
      setRange({ range: Range.week({ date: initialStartDate }) });
    }
  };

  const pageHeader = (
    <Typography classes={{ root: classes.title }} component="h1" variant="h5">
      {user && showStaffName ? (
        <FormattedMessage
          id="DateTime.user_mobile_header"
          values={{
            name: formatMultipleUsers([user, ...additionalUsers]),
          }}
        />
      ) : (
        <FormattedMessage id="DateTime.mobile_header" />
      )}
    </Typography>
  );

  const header = (
    <div data-testid="lobby-banner">
      <LobbyBanner next={next} />
      {location ? (
        <div className="ml-4 mt-4 mb-0">
          <LocationHeader location={location} />
        </div>
      ) : null}
      <header aria-atomic="false" className={classes.header} role="alert">
        {subtitle}
        <CxSnippet fallback={pageHeader} targetId="meeting_details_header" />
      </header>
    </div>
  );

  const picker = (
    <WeeklyDatePicker
      end={range.end}
      loading={loading}
      onClickDate={selectDate}
      onClickNext={forward}
      onClickPrevious={backward}
      selected={selected}
      slots={slots}
      start={range.start}
    />
  );

  const findAvailableDate = (
    <FindAvailableDate
      earliestDate={earliestDate}
      loading={loading || slotsLoading}
      range={range}
      selectDate={selectDate}
      selectedDate={selected}
      setInformation={setInformation}
      setRange={setRange}
      slots={slots}
    />
  );

  const filters = (
    <div className={classes.filters}>
      <DateTimeFilter
        content={<TimezonesShownIn mode={MOBILE} />}
        icon={
          <Language
            altText={intl.formatMessage({ id: 'Svg.alt_text.timezone' })}
          />
        }
      />
      <SupportedLanguageFilter
        preferences={preferences}
        resetRange={resetRange}
      />
      <LoginWithGoogle />
    </div>
  );

  const chunks = Item.get(slots, selected.format('iso-short'), []);
  const isSameWeek = Dates.startOfWeek(selected).isSame(
    Dates.startOfWeek(range.start),
    'week',
  );

  return (
    <section className={classes.root} data-testid="date-time-mobile">
      {header}
      <section>
        {picker}
        {shouldUseNextAvailability && shouldUseNextAvailabilityNewView ? (
          <div className={classes.findNextDateContainer}>
            {findAvailableDate}
          </div>
        ) : null}
        {filters}
        <div
          className={classNames(
            (loading || chunks.length === 0) && classes.availabilities,
          )}
        >
          {loading ? (
            <>
              <CircularProgress />
              {loadingMessage ? (
                <div className={classes.loadingMessage}>
                  <Typography component="div" grey variant="regular">
                    <FormattedMessage id={loadingMessage} />
                  </Typography>
                </div>
              ) : null}
            </>
          ) : (
            <TimeChunks
              bordered
              different={!isSameWeek}
              error={error}
              group={service.group}
              selectTime={selectTime}
              slots={chunks}
            />
          )}
          <div className={loading ? classes.hidden : {}}>
            <OtherLocationsTimeChunks
              bordered
              locationHasSlots={!!chunks.length}
              selected={selected}
              selectTime={selectTime}
            />
          </div>
          {shouldUseNextAvailability && !shouldUseNextAvailabilityNewView ? (
            <div className="px-5 pb-5">{findAvailableDate}</div>
          ) : null}
        </div>
      </section>
    </section>
  );
};

Times.propTypes = {
  earliestDate: SpacetimeShape,
  exclusion: PropTypes.string,
  fetch: PropTypes.number,
  initialStartDate: SpacetimeShape,
  loading: PropTypes.bool,
  preferences: PropTypes.arrayOf(SupportedLocalePreferenceShape),
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string.isRequired,
  rescheduling: PropTypes.bool,
  selectDate: PropTypes.func.isRequired,
  selected: SpacetimeShape.isRequired,
  selectTime: PropTypes.func.isRequired,
  setInformation: PropTypes.func.isRequired,
  slots: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({ end: SpacetimeShape, start: SpacetimeShape }),
    ),
  ).isRequired,
  slotsApiIdRef: PropTypes.shape({ current: PropTypes.number }),
  slotsLoading: PropTypes.bool,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  next: PropTypes.func.isRequired,
};

Times.defaultProps = {
  earliestDate: null,
  exclusion: null,
  fetch: 0,
  initialStartDate: Dates.today(),
  loading: true,
  loadingMessage: null,
  preferences: [],
  rescheduling: false,
  slotsApiIdRef: {},
  slotsLoading: true,
};

export default Times;
