import PropTypes from 'prop-types';
import { MEETING_METHODS } from '../constants';

export default PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOf([
      MEETING_METHODS.AT_LOCATION,
      MEETING_METHODS.PHONE_CALL,
      MEETING_METHODS.VIDEO_CALL,
    ]),
  }),
);
