import { useLoadScript } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

const GoogleMapsApiContext = createContext({});

const useGoogleMapsApi = () => useContext(GoogleMapsApiContext);

const libraries = ['places'];

const GoogleMapsApiProvider = ({ apiKey, children, countries }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const autocompleteServiceRef = useRef(null);
  const placesServiceRef = useRef(null);
  const tokenRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (isLoaded) {
      placesServiceRef.current = new window.google.maps.places.PlacesService(
        mapRef.current,
      );
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  const getSuggestions = useCallback(
    (query, locale = 'en') =>
      new Promise((resolve, reject) => {
        if (tokenRef.current === null) {
          tokenRef.current =
            new window.google.maps.places.AutocompleteSessionToken();
        }

        const params = {
          componentRestrictions: { country: countries },
          input: query,
          language: locale,
          sessionToken: tokenRef.current,
          types: ['geocode'],
        };

        autocompleteServiceRef.current.getPlacePredictions(
          params,
          (result, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              return resolve(
                result.map((place) => ({
                  attributes: {
                    name: place.description,
                  },
                  id: place.place_id,
                  type: 'location-suggestions',
                })),
              );
            }

            return reject(result);
          },
        );
      }),
    [countries],
  );

  const getDetails = useCallback(
    (placeId) =>
      new Promise((resolve, reject) => {
        const params = {
          placeId,
          fields: ['formatted_address', 'geometry', 'place_id'],
          sessionToken: tokenRef.current,
        };

        tokenRef.current = null;

        placesServiceRef.current.getDetails(params, (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const {
              formatted_address: formattedAddress,
              geometry: { location },
            } = result;
            const latitude = location.lat();
            const longitude = location.lng();

            return resolve({
              formattedAddress,
              latitude,
              longitude,
            });
          }

          return reject(result);
        });
      }),
    [],
  );

  return (
    <GoogleMapsApiContext.Provider
      value={{ getDetails, getSuggestions, isLoaded }}
    >
      {children}
      <div ref={mapRef} style={{ height: '0', width: '0' }} />
    </GoogleMapsApiContext.Provider>
  );
};

GoogleMapsApiProvider.propTypes = {
  apiKey: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  countries: PropTypes.arrayOf(String).isRequired,
};

export { GoogleMapsApiProvider, useGoogleMapsApi };
