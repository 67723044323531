import { Typography, Select, TextInput } from '@coconut-software/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Dates from '../../../shared/helpers/Dates';
import useDateTime from '../../../shared/hooks/useDateTime';
import { FORM_QUESTION_TYPES, PAGES } from '../../constants';
import Item from '../../helpers/Item';
import DetailsFormValuesShape from '../../shapes/DetailsFormValuesShape';
import QuestionShape from '../../shapes/QuestionShape';
import SpacetimeShape from '../../shapes/SpacetimeShape';
import TrackPageView from '../TrackPageView';

const useStyles = createUseStyles((theme) => ({
  row: {
    '&:not(:last-child)': {
      marginBottom: '1.875rem',
    },
  },
  time: {
    fontWeight: theme.fontWeights.medium,
  },
}));

const CancelForm = ({
  date,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  questions,
  touched,
  values,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });
  const {
    formatters: { formatTime },
  } = useDateTime(true);

  const newDate = new Date();
  newDate.setHours(date.hour(), date.minute());

  const [cancelReasonId, setCancelReasonId] = useState(null);
  const [customReason, setCustomReason] = useState('');

  const handleCancellationReasonChange = (event, actions) => {
    setCancelReasonId(parseFloat(event.target.value));
    handleChange(event, actions);
  };

  const handleOtherReasonTextChange = (event, actions) => {
    setCustomReason(event.target.value);
    handleChange(event, actions);
  };

  return (
    <form className="pb-2" data-testid="cancel-form" onSubmit={handleSubmit}>
      <TrackPageView identifier={PAGES.CANCEL} />
      <section className="mb-5">
        <Typography variant="regular">
          <FormattedMessage
            id="CancelModal.message"
            values={{
              date: (
                <span className={classes.time}>
                  <FormattedMessage
                    id="CancelModal.appointment_date"
                    values={{
                      date: Dates.toWeekdayDateMonthYear(date),
                      time: formatTime(newDate),
                    }}
                  />
                </span>
              ),
            }}
          />
        </Typography>
      </section>

      {questions
        ? questions.map((question) => {
            const key = `questions.${question.id}`;
            const error =
              Item.has(errors, key) &&
              errors[key] &&
              Item.has(touched, 'questions') &&
              Item.has(touched.questions, question.id) &&
              touched.questions[question.id];

            Item.sort(question.options, 'additional', 'desc');

            const props = {
              ariaRequired: question.required,
              additionalName: `additional.${question.id}`,
              additionalValue: values.additional[question.id],
              errors: error ? errors[key] : [],
              label: question.body,
              name: key,
              onBlur: handleBlur,
              onChange: handleChange,
              optional: !question.required,
              value: values.questions[question.id],
            };

            return (
              <div
                className={classes.row}
                data-testid={`question-${question.id}`}
                key={question.id}
              >
                {question.type === FORM_QUESTION_TYPES.TEXT && (
                  <TextInput {...props} />
                )}

                {question.type === FORM_QUESTION_TYPES.MULTIPLE && (
                  <div id="cancel-select">
                    <Select
                      {...props}
                      error={errors[key]}
                      helperText={errors[key]}
                      onChange={handleCancellationReasonChange}
                    >
                      {question.options.map((option) => (
                        <Select.Option
                          key={option.value}
                          value={`${option.value}`}
                        >
                          {option.text}
                        </Select.Option>
                      ))}
                    </Select>
                    {cancelReasonId === 0 ? (
                      <div className="mt-5">
                        <TextInput
                          color="default"
                          defaultValue={customReason}
                          error={errors[props.additionalName]}
                          helperText={errors[props.additionalName]}
                          label={intl.formatMessage({
                            id: 'CancelModal.why_cancel_appointment',
                          })}
                          multiline
                          name={props.additionalName}
                          onChange={handleOtherReasonTextChange}
                          size="medium"
                          value={customReason}
                          variant="outlined"
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            );
          })
        : null}
    </form>
  );
};

CancelForm.propTypes = {
  date: SpacetimeShape.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(QuestionShape),
  toggleModal: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  values: DetailsFormValuesShape.isRequired,
  additionalName: PropTypes.string,
};

CancelForm.defaultProps = {
  additionalName: null,
  questions: null,
};

export default CancelForm;
