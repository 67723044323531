import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import Open from '../helpers/api/Open';
import { CountryContext } from './CountryContext';

const TimezonesContext = createContext({});

const TimezonesProvider = ({ children }) => {
  const Api = Open.api();

  const [country] = useContext(CountryContext);
  const [timezones, setTimezones] = useReducer(
    (state, newState) => newState,
    {},
  );

  useEffect(() => {
    Api.timezones()
      .get(country)
      .then(({ data: { data } }) => {
        const zones = data.reduce((object, zone) => {
          object[zone.id] = { ...zone.attributes, id: zone.id };

          return object;
        }, {});

        setTimezones(zones);
      });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <TimezonesContext.Provider value={timezones}>
      {children}
    </TimezonesContext.Provider>
  );
};

TimezonesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { TimezonesContext, TimezonesProvider };
