import PropTypes from 'prop-types';
import React from 'react';

const RadioButtonGroupContext = React.createContext({
  onChange: () => {},
  name: null,
  value: null,
});

const useRadioButtonGroup = () => React.useContext(RadioButtonGroupContext);

function RadioButtonGroupProvider({ children, name, onChange, value }) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <RadioButtonGroupContext.Provider
      value={{ onChange: handleChange, name, value }}
    >
      {children}
    </RadioButtonGroupContext.Provider>
  );
}

RadioButtonGroupProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export { RadioButtonGroupProvider, useRadioButtonGroup };
