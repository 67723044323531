import Api from './Api';

export default {
  handleConversion(bookingLocation) {
    const { rwg_token, location_id } = window.state.reserve_with_google;

    if (!rwg_token) {
      return;
    }

    Api.rwgConversionTracking().submit(
      rwg_token,
      parseInt(bookingLocation, 10) !== parseInt(location_id, 10),
    );
  },
};
