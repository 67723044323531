import PropTypes from 'prop-types';
import React from 'react';
import Phone from '../../shared/helpers/Phone';
import { CALLS_TO_ACTION } from '../constants';
import Button from './Button';

const CallToAction = ({ label, type, value }) => {
  const handleClick = () => {
    switch (type) {
      case CALLS_TO_ACTION.PHONE:
        window.open(`tel:${Phone.parse(value).number}`, '_self');
        break;
      case CALLS_TO_ACTION.EMAIL:
        window.open(`mailto:${value}`, '_self');
        break;
      case CALLS_TO_ACTION.LINK:
        window.open(value);
        break;
      default:
        break;
    }
  };

  return value ? (
    <Button fullWidth={false} onClick={handleClick} variant="smallOutlined">
      {label || value}
    </Button>
  ) : null;
};

CallToAction.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(Object.values(CALLS_TO_ACTION)).isRequired,
  value: PropTypes.string.isRequired,
};

CallToAction.defaultProps = {
  label: null,
};

export default CallToAction;
