import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import useClickAway from '../../hooks/useClickAway';
import Button from '../Button';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.lg,
    fontFamily: theme.fontFamilies.sansSerif,
    marginTop: '0.625rem',
    minWidth: '17.5rem',
    padding: '1rem 1.25rem',
    position: 'absolute',
    zIndex: 2,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '1.5rem',
  },
  button: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    '&:first-child': {
      marginRight: '0.75rem',
    },
  },
}));

const RescheduleConfirmation = ({
  anchor,
  confirmContent,
  handleClear,
  handleConfirm,
  loading,
  open,
}) => {
  const classes = useStyles({ theme: useTheme() });
  const element = useRef(null);
  const buttonRef = (element) => {
    if (element) {
      element.focus();
    }
  };

  useClickAway(element, handleClear);

  let positionStyle = {};

  if (anchor) {
    const { left, top, height, width } = anchor.getBoundingClientRect();

    positionStyle = {
      top: top - height,
      left: left + width,
      transform: 'translateX(-100%)',
    };
  }

  return open ? (
    <div className={classes.root} ref={element} style={positionStyle}>
      {confirmContent}
      <div className={classes.buttons}>
        <Button
          classes={{ override: classes.button }}
          fullWidth={false}
          inputRef={buttonRef}
          onClick={handleClear}
          type="button"
          variant="secondary"
        >
          <FormattedMessage id="Ui.clear" />
        </Button>
        <Button
          classes={{ override: classes.button }}
          data-testid="confirm-time-button"
          fullWidth={false}
          isLoading={loading}
          onClick={handleConfirm}
          type="button"
        >
          <FormattedMessage id="Ui.confirm" />
        </Button>
      </div>
    </div>
  ) : null;
};

RescheduleConfirmation.propTypes = {
  anchor: PropTypes.instanceOf(Element),
  confirmContent: PropTypes.element.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};

RescheduleConfirmation.defaultProps = {
  anchor: null,
};

export default RescheduleConfirmation;
