import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CookieNotice from './CookieNotice';

const CookieNoticeContainer = () => {
  const [dismissed, setDismissed] = useState(false);
  const [accepted, setAccepted] = useState(
    document.cookie
      .split(';')
      .filter((item) => item.trim().startsWith('cookieNotice=')).length > 0,
  );
  const [announcement, setAnnouncement] = useState(false);

  const setCookie = () => {
    document.cookie =
      'cookieNotice=accepted; SameSite=none; Secure; max-age=7776000'; // 7776000 seconds = 90 days
    setAccepted(true);
  };

  const handleAction = (func, e = null) => {
    func(e);
    setAnnouncement(true);
  };

  return (
    <>
      {!accepted && !dismissed && (
        <CookieNotice
          setCookie={() => handleAction(setCookie)}
          setDismissed={(e) => handleAction(setDismissed, e)}
        />
      )}
      <span aria-live="polite" className="sr-only">
        {announcement ? <FormattedMessage id="CookieNotice.closed" /> : null}
      </span>
    </>
  );
};

export default CookieNoticeContainer;
