import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Formats users into a comma separated list of names in the following form
 * 1 staff: John Smith
 * 2 staff: John Smith and Jane Doe
 * 3 staff: John Smith, Jane Doe, and Joe Somebody
 * 4 or more staff: John Smith, Jane Doe, and 2 more
 * @param {array} usersList
 * @returns {*}
 */
function formatMultipleUsers(usersList) {
  if (usersList.length === 1) {
    return usersList[0].name;
  }

  if (usersList.length === 2) {
    return (
      <FormattedMessage
        id="AdditionalUsers.two"
        values={{
          first: usersList[0].name,
          second: usersList[1].name,
        }}
      />
    );
  }

  if (usersList.length === 3) {
    return (
      <FormattedMessage
        id="AdditionalUsers.three"
        values={{
          first: usersList[0].name,
          second: usersList[1].name,
          third: usersList[2].name,
        }}
      />
    );
  }

  if (usersList.length > 3) {
    return (
      <FormattedMessage
        id="AdditionalUsers.four_plus"
        values={{
          first: usersList[0].name,
          second: usersList[1].name,
          number: usersList.length - 2,
        }}
      />
    );
  }

  return '';
}

export { formatMultipleUsers };
