import PropTypes from 'prop-types';
import React from 'react';
import { FormattedTimeParts } from 'react-intl';

const TimezoneLabel = ({ fallback, timezone, withName }) =>
  timezone ? (
    <>
      {withName ? `${timezone.name} (` : null}
      {Intl?.DateTimeFormat?.prototype?.formatToParts && timezone.name ? (
        <FormattedTimeParts
          timeZone={timezone.name}
          timeZoneName="short"
          value={new Date()}
        >
          {(parts) => {
            return (
              parts.find((part) => part.type === 'timeZoneName')?.value ||
              `${timezone.offset} ${timezone.abbreviation}`
            );
          }}
        </FormattedTimeParts>
      ) : (
        `${timezone.offset} ${timezone.abbreviation}`
      )}
      {withName ? ')' : null}
    </>
  ) : (
    fallback
  );

TimezoneLabel.propTypes = {
  fallback: PropTypes.string,
  timezone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  withName: PropTypes.bool,
};

TimezoneLabel.defaultProps = {
  fallback: null,
  timezone: null,
  withName: false,
};

export default TimezoneLabel;
