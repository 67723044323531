import Client from '../../shared/helpers/api/Client';
import Attendees from './api/Attendees';
import CheckIn from './api/CheckIn';
import Countries from './api/Countries';
import Feedback from './api/Feedback';
import LobbyAvailability from './api/LobbyAvailability';
import Locations from './api/Locations';
import MeetingMethods from './api/MeetingMethods';
import Regions from './api/Regions';
import ReserveWithGoogleConversion from './api/ReserveWithGoogleConversion';
import Services from './api/Services';
import SupportedLanguages from './api/SupportedLanguages';
import Timezone from './api/Timezone';
import Users from './api/Users';
import VerifiedFiles from './api/VerifiedFiles';
import WaitTimes from './api/WaitTimes';

const resources = {};
const client = Client();

export default {
  /**
   * @returns {{reschedule(): Promise<any>}}
   */
  attendees() {
    if (!resources.attendees) {
      resources.attendees = Attendees(client);
    }

    return resources.attendees;
  },

  /**
   * @returns {{find(): Promise<any>}}
   */
  checkIn() {
    if (!resources.checkIn) {
      resources.checkIn = CheckIn(client);
    }

    return resources.checkIn;
  },

  /**
   * @returns {{find(): Promise<any>}}
   */
  countries() {
    if (!resources.countries) {
      resources.countries = Countries(client);
    }

    return resources.countries;
  },

  /**
   * @returns {{submit(): Promise<any>}}
   */
  feedback() {
    if (!resources.feedback) {
      resources.feedback = Feedback(client);
    }

    return resources.feedback;
  },

  /**
   * @returns {{all(): Promise<any>}}
   */
  languages() {
    if (!resources.languages) {
      resources.languages = SupportedLanguages(client);
    }

    return resources.languages;
  },

  /**
   * @param {string} locale
   *
   * @returns {this}
   */
  locale(locale) {
    client.locale(locale);

    return this;
  },

  /**
   * @returns {{all(): Promise<any>, find(*, *=): Promise<any>, findByExternalId(*, *=):Promise<any>, queueable(*):
   *   Promise<any>}}
   */
  locations() {
    if (!resources.locations) {
      resources.locations = Locations(client);
    }

    return resources.locations;
  },

  /**
   * @returns {{all(): Promise<any>,update(): Promise<any>}}
   */
  methods() {
    if (!resources.methods) {
      resources.methods = MeetingMethods(client);
    }

    return resources.methods;
  },

  /**
   * @returns {{find(): Promise<any>}}
   */
  regions() {
    if (!resources.regions) {
      resources.regions = Regions(client);
    }

    return resources.regions;
  },

  /**
   * @returns {{all(): Promise<*>, find(*, *=): Promise<*>}}
   */
  services() {
    if (!resources.services) {
      resources.services = new Services(client);
    }

    return resources.services;
  },

  /**
   * @returns {{find(): Promise<any>}}
   */
  timezone() {
    if (!resources.timezone) {
      resources.timezone = new Timezone();
    }

    return resources.timezone;
  },

  /**
   * @returns {{find(*, *=): Promise<any>}}
   */
  users() {
    if (!resources.users) {
      resources.users = new Users(client);
    }

    return resources.users;
  },

  /**
   * @returns {{verify(*, *): Promise<*>}}
   */
  verifiedFiles() {
    if (!resources.verifiedFiles) {
      resources.verifiedFiles = new VerifiedFiles(client);
    }

    return resources.verifiedFiles;
  },

  /**
   * @returns {{all(): Promise<any>}}
   */
  waitTimes() {
    if (!resources.waitTimes) {
      resources.waitTimes = WaitTimes(client);
    }

    return resources.waitTimes;
  },

  /**
   * @returns {{all(): Promise<any>}}
   */
  lobbyAvailability() {
    if (!resources.lobbyAvailability) {
      resources.lobbyAvailability = LobbyAvailability(client);
    }

    return resources.lobbyAvailability;
  },

  rwgConversionTracking() {
    if (!resources.rwgConversionTracking) {
      resources.rwgConversionTracking = ReserveWithGoogleConversion(client);
    }

    return resources.rwgConversionTracking;
  },
};
