import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const UpChevron = ({ altText }) => {
  const intl = useIntl();

  return (
    <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.up_chevron' })}
      </title>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    </svg>
  );
};

UpChevron.propTypes = {
  altText: PropTypes.string,
};

UpChevron.defaultProps = {
  altText: '',
};

export default UpChevron;
