import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import Strings from '../../shared/helpers/Strings';
import { LANGUAGES } from '../constants';
import { FeatureContext } from '../contexts/FeatureContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { UsersContext } from '../contexts/UsersContext';
import CheckboxInput from './forms/CheckboxInput';
import Typography from './Typography';

const useStyles = createUseStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const SpecificUserLanguagePreferences = ({ languages, setPreferences }) => {
  const classes = useStyles();
  const { supportedLanguagesTranslations } = useContext(UsersContext);
  const { spokenLanguages } = useContext(FeatureContext);
  const [locale] = useContext(LocaleContext);
  const intl = useIntl();

  const [selected, setSelected] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    languages.reduce((object, language) => {
      object[language] = true;

      return object;
    }, {}),
  );

  useEffect(() => {
    setPreferences(selected);

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleChange = ({ currentTarget: { checked, name } }) => {
    setSelected({ [name]: checked });
  };

  return (
    <div className={classes.root}>
      <Typography id="lang-label" variant="subtitle">
        <FormattedMessage id="SpecificUserLanguagePreferences.title" />
      </Typography>
      <div
        aria-labelledby="lang-label"
        data-testid="language-checkboxes"
        role="group"
      >
        {languages.map((language) => {
          const fullLang = spokenLanguages
            ? Strings.handleCapitalization(
                supportedLanguagesTranslations[locale][language],
                intl.locale,
              )
            : intl.formatMessage({
                id: `Languages.${LANGUAGES[language].toLowerCase()}`,
              });

          return (
            <CheckboxInput
              defaultChecked={selected[language]}
              inline
              key={language}
              label={`${Strings.uppercaseFirst(language)} (${fullLang})`}
              name={language}
              onChange={handleChange}
              value={1}
            />
          );
        })}
      </div>
    </div>
  );
};

SpecificUserLanguagePreferences.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  setPreferences: PropTypes.func.isRequired,
};

export default SpecificUserLanguagePreferences;
