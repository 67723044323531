import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import CxSnippet from '../../../shared/components/CxSnippet';
import { FeatureDecisionContext } from '../../../shared/contexts/FeatureDecisionContext';
import Dates from '../../../shared/helpers/Dates';
import BackButton from '../../components/BackButton';
import CenterWrap from '../../components/CenterWrap';
import CircularProgress from '../../components/CircularProgress';
import DateTimeFilter from '../../components/DateTimeFilter';
import FindAvailableDate from '../../components/FindAvailableDate';
import Language from '../../components/icons/Language';
import LobbyBanner from '../../components/LobbyBanner';
import LoginWithGoogle from '../../components/LoginWithGoogle';
import MonthlyDatePicker from '../../components/MonthlyDatePicker';
import OtherLocationsTimeChunks from '../../components/OtherLocationsTimeChunks';
import SupportedLanguageFilter from '../../components/SupportedLanguageFilter';
import TimeChunks from '../../components/TimeChunks';
import TimezonesShownIn from '../../components/TimezonesShownIn';
import Typography from '../../components/Typography';
import { USER_PREFERENCE } from '../../constants';
import { FeatureContext } from '../../contexts/FeatureContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SnackContext } from '../../contexts/SnackContext';
import { TimezoneContext } from '../../contexts/TimezoneContext';
import { UsersContext } from '../../contexts/UsersContext';
import { DESKTOP } from '../../contexts/ViewModeContext';
import Item from '../../helpers/Item';
import Step from '../../helpers/Step';
import useFetchSlots from '../../hooks/useFetchSlots';
import Range from '../../prototypes/Range';
import SpacetimeShape from '../../shapes/SpacetimeShape';
import SupportedLocalePreferenceShape from '../../shapes/SupportedLocalePreferenceShape';

const useStyles = createUseStyles((theme) => ({
  header: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '4rem',
    padding: '1.125rem 0',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
  },
  picker: {
    borderRight: `1px solid ${theme.palette.neutral[200]}`,
    flexGrow: 1,
    maxWidth: '24rem',
    minWidth: '24rem',
    padding: '2rem',
    width: '24rem',
  },
  availabilities: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  availabilitiesContent: {
    padding: '2rem',
  },
  availabilityHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
  },
  findNextDateContainer: {
    marginTop: '1rem',
  },
  loadingMessage: {
    marginBottom: '1.5rem',
    marginTop: '1.5rem',
    textAlign: 'center',
  },
  overflow: {
    overflowY: 'auto',
  },
  times: {
    marginRight: '1.25rem',
  },
  hidden: {
    display: 'none',
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.25rem',
  },
}));

const RandomUserTime = ({
  earliestDate,
  error,
  exclusion,
  fetch,
  initialStartDate,
  loading,
  loadingMessage,
  mode,
  preferences,
  previous,
  previousStep,
  selectDate,
  selected,
  selectTime,
  setInformation,
  showBack,
  slots,
  slotsApiIdRef,
  next,
  slotsLoading,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const [
    {
      additionalUsers,
      googleUser,
      location,
      locationCategory,
      meetingMethod,
      service,
      settings,
      shortcuts,
      user,
      userCategory,
      userPreference,
    },
  ] = useContext(SelectionContext);
  const [timezone] = useContext(TimezoneContext);
  const features = useContext(FeatureContext);
  const { supportedLanguages } = useContext(UsersContext);
  const { shouldUseNextAvailability, shouldUseNextAvailabilityNewView } =
    useContext(FeatureDecisionContext);

  const [mounted, setMounted] = useState(false);
  const windowStart = shortcuts?.settings?.booking_window_start;
  const windowEnd = shortcuts?.settings?.booking_window_end;
  const [{ range, skipNextFetchSlots }, setRange] = useReducer(
    (state, newState) => ({
      ...state,
      range: Range.override({
        range: newState.range,
        start: windowStart,
        end: windowEnd,
        endOverride: state.range.endOverride,
        startOverride: state.range.startOverride,
      }),
      skipNextFetchSlots: newState.skipNextFetchSlots,
    }),
    null,
    () => ({
      range: Range.override({
        range: Range.month({
          date:
            shouldUseNextAvailability && shouldUseNextAvailabilityNewView
              ? initialStartDate
              : selected,
        }),
        start: windowStart,
        end: windowEnd,
        startOverride: windowStart ? Dates.parse(windowStart) : null,
        endOverride: windowEnd ? Dates.parse(windowEnd) : null,
      }),
      skipNextFetchSlots: false,
    }),
  );

  const [, setSnacks] = useContext(SnackContext);

  useEffect(() => {
    setMounted(true);
  }, []); // on mount, necessary to remove the flickering before fetching slots

  const addSnack = (error) => {
    console.error(error);

    setSnacks({
      handleUndo: () => {},
      snackMessage: (
        <Typography variant="subtitle" white>
          <FormattedMessage id="FetchSlots.error" />
        </Typography>
      ),
    });
  };

  useFetchSlots({
    additionalUsers,
    exclusion,
    features,
    fetch,
    googleUser,
    location,
    locationCategory,
    meetingMethod,
    preferred: userPreference || { id: USER_PREFERENCE.RANDOM },
    merge: false,
    range,
    service,
    setInformation,
    setRange,
    settings,
    skipNextFetchSlots,
    slotsApiIdRef,
    supportedLanguages,
    timezone,
    user,
    userCategory,
    shouldUseChunks: true,
    addSnack,
  });

  const forward = () => {
    setInformation({ loading: true });
    setRange({ range: range.next() });
  };
  const backward = () => {
    setInformation({ loading: true });
    setRange({ range: range.previous() });
  };

  const resetRange = () => {
    // when choosing a specific staff, we want to reset the range to the initial start date
    // so the rest of the code can find the earliest date for next availability feature
    if (shouldUseNextAvailability && shouldUseNextAvailabilityNewView) {
      setRange({ range: Range.month({ date: initialStartDate }) });
      setInformation({ earliestDate: null });
    }
  };

  const header = (
    <header className={classes.header} data-testid="date-time-header">
      {showBack ? (
        <BackButton
          previous={previous}
          text={<FormattedMessage id="Ui.back" />}
          title={Step.getBackString(intl, previousStep)}
        />
      ) : (
        <div />
      )}
      <Typography
        classes={{ root: classes.times }}
        component="span"
        variant="body1"
      >
        <FormattedMessage
          id="DateTime.times_available_on_date"
          values={{ date: Dates.toDateMonthYear(selected) }}
        />
      </Typography>
    </header>
  );

  const picker = (
    <MonthlyDatePicker
      end={range.end}
      isLoadingSlots={slotsLoading}
      loading={loading}
      onClickDate={selectDate}
      onClickNext={forward}
      onClickPrevious={backward}
      selected={selected}
      slots={slots}
      start={range.start}
    />
  );

  const findAvailableDate = (
    <FindAvailableDate
      earliestDate={earliestDate}
      error={error}
      loading={loading || slotsLoading}
      range={range}
      selectDate={selectDate}
      selectedDate={selected}
      setInformation={setInformation}
      setRange={setRange}
      slots={slots}
    />
  );

  const filters = (
    <div className={classes.filters}>
      <DateTimeFilter
        content={<TimezonesShownIn mode={DESKTOP} />}
        icon={
          <Language
            altText={intl.formatMessage({ id: 'Svg.alt_text.timezone' })}
          />
        }
      />
      <SupportedLanguageFilter
        preferences={preferences}
        resetRange={resetRange}
      />
      <LoginWithGoogle />
    </div>
  );

  const slotsToDisplay = Item.get(slots, selected.format('iso-short'), []);

  return (
    <CenterWrap data-testid="date-time-desktop" view={mode}>
      {header}
      <section className={classes.content}>
        <div className={classes.picker}>
          {picker}
          {shouldUseNextAvailability && shouldUseNextAvailabilityNewView ? (
            <div className={classes.findNextDateContainer}>
              {findAvailableDate}
            </div>
          ) : null}
        </div>
        <div className={classes.availabilities}>
          <LobbyBanner next={next} />
          <div
            className={classNames(
              classes.availabilitiesContent,
              classes.overflow,
            )}
          >
            <span role="status">
              <header className={classes.availabilityHeader}>
                <CxSnippet
                  fallback={
                    <Typography component="h2" variant="h5">
                      <FormattedMessage id="DateTime.select_time" />
                    </Typography>
                  }
                  targetId="meeting_details_header"
                />
              </header>
              {filters}
            </span>
            <span role="status">
              {loading || !mounted ? (
                <>
                  <CircularProgress />
                  {loadingMessage ? (
                    <div className={classes.loadingMessage}>
                      <Typography component="div" grey variant="regular">
                        <FormattedMessage id={loadingMessage} />
                      </Typography>
                    </div>
                  ) : null}
                </>
              ) : (
                <TimeChunks
                  error={error}
                  group={service.group}
                  selected={selected}
                  selectTime={selectTime}
                  slots={slotsToDisplay}
                />
              )}
            </span>
            <div
              className={loading || !mounted ? classes.hidden : {}}
              role="status"
            >
              <OtherLocationsTimeChunks
                locationHasSlots={!!slotsToDisplay.length}
                selected={selected}
                selectTime={selectTime}
              />
            </div>
            {shouldUseNextAvailability && !shouldUseNextAvailabilityNewView
              ? findAvailableDate
              : null}
          </div>
        </div>
      </section>
    </CenterWrap>
  );
};

RandomUserTime.propTypes = {
  earliestDate: SpacetimeShape,
  exclusion: PropTypes.string,
  fetch: PropTypes.number,
  initialStartDate: SpacetimeShape,
  loading: PropTypes.bool,
  mode: PropTypes.number.isRequired,
  preferences: PropTypes.arrayOf(SupportedLocalePreferenceShape),
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  selectDate: PropTypes.func.isRequired,
  selected: SpacetimeShape.isRequired,
  selectTime: PropTypes.func.isRequired,
  setInformation: PropTypes.func.isRequired,
  showBack: PropTypes.bool,
  slots: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({ end: SpacetimeShape, start: SpacetimeShape }),
    ),
  ).isRequired,
  slotsApiIdRef: PropTypes.shape({ current: PropTypes.number }),
  slotsLoading: PropTypes.bool,
  next: PropTypes.func,
};

RandomUserTime.defaultProps = {
  earliestDate: null,
  exclusion: null,
  fetch: 0,
  initialStartDate: Dates.today(),
  loading: true,
  loadingMessage: null,
  next: null,
  preferences: [],
  previousStep: null,
  showBack: false,
  slotsApiIdRef: {},
  slotsLoading: true,
};

export default RandomUserTime;
