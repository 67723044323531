import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  confirmed: {
    maxWidth: '17.5rem',
    margin: '0 auto 1.5rem',
    textAlign: 'center',
    '& > h3': {
      marginBottom: '1rem',
    },
    '&$desktop': {
      marginTop: '1rem',
    },
    '&$mobile': {
      marginTop: '0.25rem',
    },
  },
  desktop: {},
  mobile: {},
  success: {
    fill: theme.palette.success[400],
    height: '3.75rem',
    width: '3.75rem',
    // coco design-system sets SVG to display:block by default.
    display: 'inline-block',
  },
  spacing: {
    marginBottom: '1.5rem',
  },
}));

const ManageAppointmentDetailsHeader = ({ confirmation, rescheduled }) => {
  const classes = useStyles({ theme: useTheme() });
  const mode = useContext(ViewModeContext);

  return confirmation ? (
    <div
      className={classNames(
        classes.confirmed,
        mode === MOBILE ? classes.mobile : classes.desktop,
      )}
    >
      <svg
        className={classes.success}
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.88-11.71L10 14.17l-1.88-1.88c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7c.39-.39.39-1.02 0-1.41-.39-.39-1.03-.39-1.42 0z" />
      </svg>
      <Typography component="h3" variant="h5">
        <FormattedMessage id="ManageAppointmentDetails.done_title" />
      </Typography>
      <Typography component="p" variant="label">
        {rescheduled ? (
          <FormattedMessage id="ManageAppointmentDetails.successfully_rescheduled" />
        ) : (
          <FormattedMessage id="ManageAppointmentDetails.successfully_booked" />
        )}
      </Typography>
      <Typography component="p" variant="subtitle">
        <FormattedMessage id="ManageAppointmentDetails.details_below" />
      </Typography>
    </div>
  ) : (
    <div className={classes.spacing}>
      <Typography component="h3" variant="h5">
        <FormattedMessage id="ManageAppointmentDetails.your_appointment" />
      </Typography>
    </div>
  );
};

ManageAppointmentDetailsHeader.propTypes = {
  confirmation: PropTypes.bool.isRequired,
  rescheduled: PropTypes.bool,
};

ManageAppointmentDetailsHeader.defaultProps = {
  rescheduled: false,
};

export default ManageAppointmentDetailsHeader;
