import React from 'react';
import { useIntl } from 'react-intl';

const CoconutLogo = () => {
  const intl = useIntl();

  return (
    <svg
      alt="Coconut Software"
      fill="none"
      height="40"
      viewBox="0 40 1696 200"
      width="150"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{intl.formatMessage({ id: 'Svg.alt_text.coconut_logo' })}</title>
      <path
        d="M1695.5 180.3C1695.5 171.8 1689.1 165.4 1681.1 165.4L1666.6 166.5H1666.1C1650.1 166.5 1637.3 153.1 1637.3 137.1V68.8H1680C1684.3 68.8 1688 67.2 1690.7 64.5C1696.6 58.6 1696.6 49.6 1690.7 43.7C1688.1 41 1684.3 39.4 1680.6 39.4H1636.8V14.4C1636.8 6.4 1630.4 0 1622.4 0C1618.7 0 1615 1.6 1612.3 4.3C1609.6 6.9 1608 10.7 1608 14.4V136C1608 144 1609.6 151.4 1612.8 158.9C1618.7 172.8 1629.9 184 1643.8 189.9C1651.2 193.1 1658.7 194.7 1666.7 194.7H1681.1C1684.8 194.7 1688.5 193.1 1691.2 190.4C1693.9 187.8 1695.5 184 1695.5 180.3Z"
        fill="#212121"
      />
      <path
        clipRule="evenodd"
        d="M766.4 114.7C766.4 104 768.5 93.9 772.8 84.3C780.8 65.6 795.7 50.7 814.4 42.7C833.6 34.7 855.5 34.7 874.7 42.7C883.8 46.4 892.3 52.3 899.2 59.8C906.1 66.7 912 75.3 915.7 84.9C923.7 104.1 923.7 126 915.7 145.2C907.7 163.9 892.8 178.8 874.1 186.8C864.5 191.1 854.4 192.7 843.7 192.7C833.6 192.7 822.9 190.6 813.3 186.3C804.2 182.6 795.7 176.7 788.8 169.2C781.9 162.3 776 153.7 772.3 144.1C768.5 135.6 766.4 125.4 766.4 114.7ZM795.7 114.7C795.7 121.1 796.8 127.5 799.4 133.4C802.1 139.3 805.8 144.6 810.1 148.9C814.4 153.2 819.7 156.9 825.6 159.6C831.5 162.3 837.9 163.3 844.3 163.3C850.7 163.3 857.1 162.2 863 159.6C874.7 154.8 883.8 145.2 889.1 133.5C894.4 121.2 894.4 107.9 889.1 95.6C886.4 89.7 883.2 84.4 878.4 80.1C874.1 75.8 868.8 72.1 862.9 69.4C857 66.7 850.6 65.7 844.2 65.7C837.8 65.7 831.4 66.8 825.5 69.4C819.6 72.1 814.3 75.8 810 80.1C805.7 84.4 802 89.7 799.3 95.6C796.8 101.9 795.7 108.3 795.7 114.7Z"
        fill="#212121"
        fillRule="evenodd"
      />
      <path
        d="M730 149.4C735.9 144.1 745.5 144.6 750.8 150.5C755.6 155.8 756.1 164.4 750.8 170.2C743.3 177.7 734.3 183.5 724.7 187.3C715.6 191 705.5 192.6 695.3 192.6C685.1 192.6 674.5 190.5 664.9 186.2C646.2 178.2 631.3 163.3 623.3 144.6C615.3 125.4 615.3 103.5 623.8 84.3C631.8 65.6 646.7 50.7 665.4 42.7C675 38.4 685.1 36.3 695.8 36.8C705.9 36.8 715.5 38.9 725.2 42.7C734.8 46.4 743.9 52.3 750.8 59.8C753.5 62.5 755.1 66.2 755.1 69.9C755.1 73.6 753.5 77.4 750.8 80C744.9 85.9 735.9 85.9 730 80C725.7 75.7 720.4 72 714.5 69.3C702.2 64 688.9 64 676.6 69.3C670.7 72 665.4 75.8 661.1 80C656.8 84.3 653.1 89.6 650.4 95.5C645.1 107.8 645.1 121.1 650.4 133.4C655.2 145.1 664.3 154.2 676 159C688.3 164.3 701.6 164.3 713.9 159C720.5 156.9 725.8 153.7 730 149.4Z"
        fill="#212121"
      />
      <path
        d="M1069.9 150C1064 144.1 1055 144.1 1049.1 150C1044.4 154.2 1039.1 158 1035.1 160.3C1022.8 165.6 1009.5 165.6 997.2 160.3C985.5 155 975.9 145.9 971.1 134.2C968.5 128.3 967.4 121.9 967.4 115.5C967.4 109.1 968.4 102.7 971.1 96.8C973.8 90.9 977.5 85.6 981.8 81.3C986.1 77 991.4 73.3 997.3 70.6C1003.2 68 1009.6 66.9 1016 66.9C1022.4 66.9 1028.8 67.9 1034.7 70.6C1040.6 73.3 1045.9 77 1050.2 81.3C1056.1 87.2 1065.1 87.2 1071 81.3C1076.9 75.4 1076.9 66.4 1071 60.5C1064.1 53 1055 47.1 1045.4 43.4C1035.7 39.6 1026.1 37.5 1016 37.5C1005.3 37 995.2 39.1 985.6 43.4C976 47.1 967.4 53 960.5 59.9C953.6 66.8 947.7 75.3 944 84.4C939.7 94 937.6 104.7 937.6 114.8C937.6 125.5 939.2 135.6 943.5 145.2C947.2 154.8 953.1 163.4 960 170.3C966.9 177.2 975.4 183.1 984.5 186.8C994.1 191.1 1004.8 193.2 1014.9 193.2C1025.1 193.2 1035.2 191.6 1044.3 187.9C1053.9 184.1 1062.4 178.3 1069.9 170.8C1075.8 164.9 1075.8 155.9 1069.9 150Z"
        fill="#212121"
      />
      <path
        clipRule="evenodd"
        d="M1085.4 115.3C1085.4 105.2 1087.5 94.5 1091.8 84.9C1099.8 66.2 1114.7 51.3 1133.4 43.3C1152.6 35.3 1174.5 35.3 1193.7 43.3C1212.4 51.3 1227.3 66.2 1235.3 84.9C1239.6 94.5 1241.2 104.6 1241.2 115.3C1241.2 125.4 1239.1 136.1 1234.8 145.7C1226.8 164.4 1211.9 179.3 1193.2 187.3C1183.6 191.6 1173.5 193.2 1162.8 193.2C1152.7 193.2 1142 191.1 1132.4 186.8C1113.7 178.8 1098.8 163.9 1090.8 145.2C1087.1 136.1 1084.9 125.9 1085.4 115.3ZM1114.2 115.3C1114.2 121.7 1115.3 128.1 1117.9 134C1120.5 139.9 1124.3 145.2 1128.6 149.5C1132.9 154.3 1138.2 157.5 1144.1 160.2C1156.4 165 1169.7 165 1182 160.2C1187.9 157.5 1193.2 153.8 1197.5 149.5C1202.3 145.2 1205.5 139.9 1208.2 134C1213 121.7 1213 108.4 1208.2 96.1C1205.5 90.2 1201.8 84.9 1197.5 80.6C1193.2 76.3 1187.9 72.6 1182 69.9C1176.1 67.2 1169.7 66.2 1163.3 66.2C1156.9 66.2 1150.5 67.3 1144.6 69.9C1138.7 72.5 1133.4 75.8 1129.1 80.6C1124.8 84.9 1121.1 90.2 1118.4 96.1C1115.4 102.5 1114.2 108.9 1114.2 115.3Z"
        fill="#212121"
        fillRule="evenodd"
      />
      <path
        d="M1399.7 194.8C1407.7 194.2 1414.1 187.3 1413.6 179.3L1415.1 116.9C1415.1 106.8 1413.5 96.1 1409.2 86.5C1401.2 67.3 1385.7 52.9 1367 44.9C1357.4 40.6 1346.7 38.5 1336.6 38.5C1325.9 38.5 1315.8 40.1 1306.2 44.4C1296.6 48.1 1288 54 1281.1 60.9C1274.2 67.8 1268.3 76.3 1264.6 85.4C1260.3 95 1258.2 105.7 1258.2 115.8V179.3C1258.2 187.8 1264.6 194.2 1272.6 194.2C1276.3 194.2 1280 192.6 1282.7 189.9C1285.4 187.3 1287 183.5 1287 179.8V116.3C1287 109.9 1288 103.5 1290.7 97.6C1293.4 91.7 1297.1 86.4 1301.4 82.1C1305.7 77.8 1311 74.1 1316.9 71.4C1322.8 68.8 1329.2 67.7 1335.6 67.7C1342 67.7 1348.4 68.7 1354.3 71.4C1360.2 74.1 1365.5 77.3 1369.8 82.1C1374.1 86.4 1377.9 91.7 1380.5 97.6C1383.1 103.5 1384.2 109.9 1384.2 116.3V180.9C1384.8 188.9 1391.7 195.3 1399.7 194.8Z"
        fill="#212121"
      />
      <path
        d="M1588.6 116.3C1588.6 126.4 1586.5 137.1 1582.7 146.7C1579 155.8 1573.1 164.3 1566.2 171.2C1559.3 178.1 1550.7 184 1541.1 188.3C1531.5 192.6 1521.4 194.2 1510.7 194.2C1500.6 194.2 1489.9 192.1 1480.3 187.8C1461.6 179.8 1446.7 164.9 1438.7 146.2C1434.4 136.6 1432.8 126.5 1432.8 115.8V53.4C1432.8 49.7 1434.4 45.9 1437.1 43.3C1443 37.4 1452 37.4 1457.9 43.3C1460.6 46 1462.2 49.7 1462.2 53.4V116.4C1462.2 122.8 1463.3 129.2 1465.9 135.1C1468.6 141 1472.3 146.3 1476.6 150.6C1480.9 155.4 1486.2 158.6 1492.1 161.3C1498 164 1504.4 165 1510.8 165C1517.2 165 1523.6 163.9 1529.5 161.3C1535.4 158.6 1540.7 154.9 1545 150.6C1549.3 146.3 1553 141 1555.7 135.1C1558.4 129.2 1559.4 122.8 1559.4 116.4V53.4C1559.4 45.4 1565.8 39 1573.8 39C1581.8 39 1588.2 45.4 1588.2 53.4L1588.6 116.3Z"
        fill="#212121"
      />
      <path
        d="M707.5 327.9C708 340.4 697.2 350 676 350C657.8 350 648.9 344.7 638.6 335.9L643.6 330.2C652.4 339 664.2 343.4 676 342.7C691.4 342.7 700.3 337.6 700.3 328.7C700.3 319.8 691 316.9 673.8 316.9C654.2 316.9 640.1 312.1 640 297.8C639.9 284.6 655.4 277.2 672.4 277.2C684.9 277.2 695.5 281.7 704.9 290.3L699.6 295.5C692.2 288.9 682.6 284.5 673 284.5C661.2 284.5 647.4 288.6 647.3 297.6C647.2 307.2 660.1 309.5 675.3 309.5C698.7 309.5 707 315.5 707.5 327.9Z"
        fill="#212121"
      />
      <path
        clipRule="evenodd"
        d="M787 276.4C762.7 276.4 748 290.4 748 313.2C748 336 762.7 350 787 350C811.3 350 826 336 826 313.2C826 290.4 811.3 276.4 787 276.4ZM787.8 283.8C806.2 283.8 818.7 294.8 818.7 313.2C818.7 332.3 806.2 342.6 787.8 342.6C769.4 342.6 755.4 331.6 755.4 313.2C755.4 294.8 769.4 283.8 787.8 283.8Z"
        fill="#212121"
        fillRule="evenodd"
      />
      <path
        d="M879 286V310.3H924.6V317.6H879V347.8H871.6V278.6H932.7V286H879Z"
        fill="#212121"
      />
      <path
        d="M1007.7 286H1037.9L1037.8 278.6H969.4V286H1000.3V347.8H1007.7V286Z"
        fill="#212121"
      />
      <path
        d="M1179.9 278.6L1156.4 347.8H1148.3L1127.7 288.9L1106.4 347.8H1098.3L1074.8 278.6H1083.6L1102.7 338.2L1123.3 278.6H1131.4L1152 338.2L1171.1 278.6H1179.9Z"
        fill="#212121"
      />
      <path
        clipRule="evenodd"
        d="M1223.4 329.4H1266.8L1276.4 347.8H1285.2L1249.9 278.6H1241.1L1205.8 347.8H1214.6L1223.4 329.4ZM1245.4 286L1263.1 322H1227.4L1245.4 286Z"
        fill="#212121"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M1385.1 347.8L1363.8 321.3H1333.6V347.8H1326.2V278.6H1368.9C1382.9 278.6 1393.2 286.7 1393.2 299.9C1393.2 310.9 1384.4 320.5 1373.3 321.2L1394.5 347.7H1385.1V347.8ZM1368.2 314C1378.5 314 1385.1 309.6 1385.1 300C1385.1 290.4 1378.5 286 1368.2 286H1333.6V314H1368.2Z"
        fill="#212121"
        fillRule="evenodd"
      />
      <path
        d="M1500.6 347.8V340.4H1446.8V316.9H1491.8V309.5H1446.8V286.7H1499.9V279.3H1439.4V347.8H1500.6Z"
        fill="#212121"
      />
      <path
        d="M290.2 148.9C224.3 214.3 119.4 185.5 85.4999 173.9C81.8999 172.7 78.6999 176.4 80.3999 179.8C87.0999 193.3 100.8 216.3 119 233.7C157.1 272.4 209.2 293.9 263.9 293.9H264.5C376.2 293.9 467.4 204.5 469.5 92.7C469.5 90.5 467.9 88.6001 465.7 88.4C446.4 86.1001 359.2 80.7 290.2 148.9Z"
        fill="#212121"
      />
      <path
        d="M501 82.7C500.9 75 507.1 68.8 514.7 68.8C522.2 68.8 528.2 74.9 528.4 82.5V85.7C528.4 231.5 410.2 349.7 264.4 349.7C118.6 349.7 0.399902 231.5 0.399902 85.7V82.5C0.499902 74.9 6.5999 68.8 14.0999 68.8C21.6999 68.8 27.8999 75.1 27.7999 82.7V85.7C27.7999 216.3 133.7 322.3 264.4 322.3C395.1 322.3 501 216.4 501 85.7V82.7Z"
        fill="#212121"
      />
    </svg>
  );
};

export default CoconutLogo;
