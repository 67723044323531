import PropTypes from 'prop-types';
import React from 'react';

const Checkmark = ({ altText, classes, ariaHidden }) => {
  // NOTE: iOS voiceover (not mac OS) will NOT read this unless an aria label exists.
  let ariaLabel = {};
  if (altText) {
    ariaLabel = { 'aria-label': altText };
  }
  return (
    <svg
      aria-hidden={ariaHidden ? 'true' : 'false'}
      {...ariaLabel}
      className={classes.root}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {altText ? <title>{altText}</title> : null}
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
    </svg>
  );
};

Checkmark.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  altText: PropTypes.string,
  ariaHidden: PropTypes.bool,
};

Checkmark.defaultProps = {
  altText: '',
  ariaHidden: true,
  classes: {
    root: '',
  },
};

export default Checkmark;
