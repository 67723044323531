import PropTypes from 'prop-types';
import React, { createContext, useEffect, useRef, useState } from 'react';

const SnackContext = createContext(false);

const SnackProvider = ({ children }) => {
  const [snacks, setSnacks] = useState([]);

  const snacksRef = useRef(snacks);
  const timerRef = useRef(0);

  const addToSnacks = ({ handleUndo: undo, snackMessage }) => {
    const handleUndo = () => {
      undo();
      clearTimeout(timerRef.current);

      const newSnacks = [...snacksRef.current];
      newSnacks.shift();
      setSnacks(newSnacks);
    };

    setSnacks([...snacks, { handleUndo, snackMessage }]);
  };

  const processQueue = () => {
    timerRef.current = setTimeout(() => {
      const newSnacks = [...snacksRef.current];
      newSnacks.shift();
      setSnacks(newSnacks);
    }, 4000);
  };

  useEffect(() => {
    if (snacksRef.current.length > snacks.length) {
      processQueue();
    } else if (snacksRef.current.length === 0 && snacks.length > 0) {
      processQueue();
    }

    snacksRef.current = snacks;
  }, [snacks]);

  return (
    <SnackContext.Provider value={[snacks, addToSnacks]}>
      {children}
    </SnackContext.Provider>
  );
};

SnackProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { SnackContext, SnackProvider };
