import { Alert, Checkbox, Dialog, Typography } from '@coconut-software/ui';
import { useForm } from '@coconut-software/ui/dist/hooks';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import Button from '../../components/Button';
import { REQUIRED_VALUES } from '../../constants';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import Api from '../../helpers/Api';

const Summary = ({ appointment, checkIn, contact }) => {
  const intl = useIntl();
  const { register } = useForm();
  const checkboxRefs = useRef({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { clientFields } = useContext(SettingsContext);
  const showPhone =
    clientFields &&
    (clientFields.phone !== REQUIRED_VALUES.NA ||
      clientFields.mobile_phone !== REQUIRED_VALUES.NA ||
      clientFields.work_phone !== REQUIRED_VALUES.NA);
  const [
    {
      location: { id: locationId },
    },
  ] = useContext(SelectionContext);

  const registerCheckbox = (checkbox) => {
    if (checkbox?.name) {
      checkboxRefs.current[checkbox.name] = checkbox;
    }

    register(checkbox);
  };

  const handleSubmit = () => {
    setError(false);
    if (!checkboxRefs.current['sms_updates']?.checked) {
      setOpenDialog(true);
    } else {
      handleCheckIn();
    }
  };

  const handleCheckIn = () => {
    setLoading(true);

    Api.checkIn()
      .update({
        appointment: appointment,
        location: locationId,
        optIn: checkboxRefs.current['sms_updates']?.checked,
        phone: contact.type === 'phone' ? contact.value : null,
      })
      .then(() => {
        checkIn({ optIn: checkboxRefs.current['sms_updates']?.checked });
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Typography component="h2" variant="h5">
        <FormattedMessage id="CheckIn.summary.title" />
      </Typography>
      <div className="mt-3 mb-6">
        <Typography component="p" variant="body2">
          <FormattedMessage id="CheckIn.summary.subtitle" />
        </Typography>
      </div>
      <div className="rounded p-4 mb-6 border border-gray-300 border-solid">
        <div>
          <div className="text-black-45">
            <Typography color="inherit" component="p" variant="body2">
              <FormattedMessage id="AppointmentDetails.date_time" />
            </Typography>
          </div>
          <Typography component="p" variant="subtitle1">
            <FormattedDate
              day="numeric"
              month="long"
              value={appointment.start}
              year="numeric"
            />
          </Typography>
          <Typography component="p" variant="body2">
            <>
              <span>
                <FormattedDate
                  hour="numeric"
                  minute="numeric"
                  value={appointment.start}
                />
              </span>
              <span>&nbsp;&ndash;&nbsp;</span>
              <span>
                <FormattedDate
                  hour="numeric"
                  minute="numeric"
                  timeZoneName="short"
                  value={appointment.end}
                />
              </span>
            </>
          </Typography>
        </div>
        <div className="mt-6">
          <div className="text-black-45">
            <Typography color="inherit" component="p" variant="body2">
              <FormattedMessage id="AppointmentDetails.user" />
            </Typography>
          </div>
          <Typography component="p" variant="subtitle1">
            {appointment.user}
          </Typography>
        </div>
      </div>
      {showPhone ? (
        <div className="mb-6" style={{ marginLeft: '-11px' }}>
          <Checkbox.Input
            color="primary"
            defaultChecked={appointment.optIn}
            disabled={loading}
            inputRef={registerCheckbox}
            name="sms_updates"
            size="medium"
          >
            <span className="flex flex-col">
              <Typography variant="subtitle2">
                <FormattedMessage id="CheckIn.summary.sms_updates" />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage
                  id={'CheckIn.summary.sms_updates.message'}
                  values={{ phone: appointment.phone }}
                />
              </Typography>
            </span>
          </Checkbox.Input>
        </div>
      ) : null}
      {error ? (
        <div className="mb-6 w-full">
          <Alert severity="error" variant="minimal">
            <Alert.Content>
              <Alert.Description>
                <Typography component="p" variant="subtitle">
                  {intl.formatMessage({ id: 'CheckIn.summary.fetch_failed' })}
                </Typography>
              </Alert.Description>
            </Alert.Content>
          </Alert>
        </div>
      ) : null}
      <Button
        aria-label={intl.formatMessage({ id: 'CheckIn.check_in.aria_label' })}
        disabled={loading}
        isLoading={loading}
        onClick={handleSubmit}
        type="submit"
      >
        <FormattedMessage id="CheckIn.check_in" />
      </Button>
      <Dialog
        onDismiss={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
      >
        <Dialog.Header>
          <FormattedMessage id="CheckIn.summary.sms_updates.dialog" />
        </Dialog.Header>
        <Dialog.Content>
          <Typography variant="body1">
            <FormattedMessage
              id={'CheckIn.summary.sms_updates.message'}
              values={{ phone: appointment.phone }}
            />
          </Typography>
        </Dialog.Content>
        <div className="flex flex-col gap-3 p-6">
          <Button
            color="primary"
            onClick={() => {
              checkboxRefs.current['sms_updates'].checked = true;
              setOpenDialog(false);
              handleCheckIn();
            }}
            size="small"
            variant="primary"
          >
            <FormattedMessage id="CheckIn.summary.sms_updates.dialog.accept" />
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setOpenDialog(false);
              handleCheckIn();
            }}
            size="small"
            variant="text"
          >
            <div className="p-3">
              <FormattedMessage id="CheckIn.summary.sms_updates.dialog.deny" />
            </div>
          </Button>
        </div>
      </Dialog>
    </>
  );
};

Summary.propTypes = {
  checkIn: PropTypes.func.isRequired,
};

export default Summary;
