import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const NearMe = ({ altText, classes }) => {
  const intl = useIntl();

  return (
    <svg
      aria-hidden="true"
      className={classes.root}
      height="24"
      style={{ fill: 'currentColor' }}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.near_me' })}
      </title>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M21 3L3 10.53v.98l6.84 2.65L12.48 21h.98L21 3z" />
    </svg>
  );
};

NearMe.propTypes = {
  altText: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
};

NearMe.defaultProps = {
  altText: '',
  classes: {
    root: '',
  },
};

export default NearMe;
