import { useRef } from 'react';

const useToggleTranslations = (locale, setLocale) => {
  const prevLocale = useRef(locale);

  const toggleTranslation = () => {
    setLocale(locale === 'dev' ? prevLocale.current : 'dev');
    prevLocale.current = locale;
  };
  window.toggleTranslation = toggleTranslation;
};

export default useToggleTranslations;
