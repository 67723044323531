import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { useRadioButtonGroup } from '../../contexts/RadioButtonGroupContext';

const useStyles = createUseStyles((theme) => ({
  root: {
    alignItems: 'center',
    borderRadius: theme.borderRadius.default,
    boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.2)',
    color: theme.palette.black,
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    margin: '0 0.5rem',
    padding: '1rem 0',
    transition: 'box-shadow 150ms',
    willChange: 'box-shadow',
    '&:first-of-type': {
      marginLeft: '0',
    },
    '&:last-of-type': {
      marginRight: '0',
    },
  },
  checked: {
    boxShadow: `inset 0 0 0 2px ${theme.palette.secondary[400]}`,
    color: theme.palette.secondary[400],
  },
  focus: {
    boxShadow: `inset 0 0 0 4px ${theme.palette.secondary[400]}`,
  },
}));

function RadioButton({ children, id, value, ariaLabel }) {
  const intl = useIntl();
  const theme = useTheme();
  const group = useRadioButtonGroup();

  const [focused, setFocused] = useState(false);

  const classes = useStyles({ theme });
  const checked = group.value === value;

  const handleBlur = () => setFocused(false);
  const handleFocus = () => setFocused(true);

  return (
    <label
      className={clsx(
        classes.root,
        checked ? classes.checked : null,
        focused ? classes.focus : null,
      )}
      htmlFor={id}
    >
      <input
        aria-label={
          ariaLabel ? ariaLabel : intl.formatMessage({ id: 'RadioButton' })
        }
        checked={checked}
        className="absolute opacity-0"
        id={id}
        name={group.name}
        onBlur={handleBlur}
        onChange={group.onChange}
        onFocus={handleFocus}
        type="radio"
        value={value}
      />
      {children}
    </label>
  );
}

RadioButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  value: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
  ariaLabel: '',
};

export default RadioButton;
