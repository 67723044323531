import classNames from 'classnames';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { MEETING_METHODS, VIRTUAL_MEETING_METHODS } from '../constants';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import MeetingMethodsShape from '../shapes/MeetingMethodsShape';
import Alert from './Alert';

const messages = {
  [MEETING_METHODS.PHONE_CALL]: 'MeetingMethodInfoBanner.only_phone_available',
  [MEETING_METHODS.VIDEO_CALL]: 'MeetingMethodInfoBanner.only_video_available',
};

const useStyles = createUseStyles({
  root: {
    marginBottom: '1.25rem',
  },
  mobile: {
    marginBottom: 0,
    marginTop: '0.25rem',
  },
});

const MeetingMethodInfoBanner = ({ methods }) => {
  const classes = useStyles();
  const mode = useContext(ViewModeContext);

  return (
    methods.length === 1 &&
    VIRTUAL_MEETING_METHODS.includes(methods[0].id) && (
      <div
        className={classNames(classes.root, mode === MOBILE && classes.mobile)}
        data-testid="meeting-method-info-banner"
      >
        <Alert
          message={<FormattedMessage id={messages[methods[0].id]} />}
          variant="info"
        />
      </div>
    )
  );
};

MeetingMethodInfoBanner.propTypes = {
  methods: MeetingMethodsShape,
};

export default MeetingMethodInfoBanner;
