import { Button, Dialog, Typography } from '@coconut-software/ui';
import { Attendee, Notifications, Response } from 'coconut-open-api-js';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Dates from '../../shared/helpers/Dates';
import { APPOINTMENT_STATUSES, FORM_QUESTION_TYPES, PAGES } from '../constants';
import { AppointmentContext } from '../contexts/AppointmentContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import Open from '../helpers/api/Open';
import Item from '../helpers/Item';
import Tracker from '../helpers/Tracker';
import Validator from '../helpers/Validator';
import QuestionShape from '../shapes/QuestionShape';
import CircularProgress from './CircularProgress';
import CancelForm from './forms/CancelForm';

const CancelAppointmentModal = ({
  formLoading,
  open,
  questions,
  setState,
  toggleModal,
}) => {
  const Api = Open.api();

  const [timezone] = useContext(TimezoneContext);
  const [{ attendee, date }] = useContext(SelectionContext);
  const [appointment, setAppointment] = useContext(AppointmentContext);
  const [selections] = useContext(SelectionContext);
  const { firstStep } = useContext(SettingsContext);

  const confirmation = location.pathname.includes('/confirmation');

  const [loading, setLoading] = useState(false);

  const prepareResponses = (values) => {
    const responses = [];

    Item.each(values.questions, (id) => {
      const value = values.questions[id];

      if (!value) {
        return;
      }

      const question = questions.find((item) => item.id === id);

      if (question.type === FORM_QUESTION_TYPES.MULTIPLE) {
        const selectedOption = question.options.find(
          (option) => value === String(option.value),
        );
        const hasAdditionalInput = selectedOption && selectedOption.additional;

        const newResponse = new Response()
          .for(id)
          .selected(value)
          .is((hasAdditionalInput && values.additional[id]) || null);
        if (newResponse.attributes.option === '0') {
          newResponse.attributes.option = null;
        }

        responses.push(newResponse);

        return;
      }

      responses.push(new Response().for(id).is(value));
    });

    return responses;
  };

  const formatAttendee = (attendeeId, values) => {
    const attendee = new Attendee().as(attendeeId);

    if (values.questions) {
      attendee.responses(prepareResponses(values));
    }

    return attendee;
  };

  const handleSubmit = (values) => {
    if (!Dates.isFuture(date, timezone)) {
      setState({
        future: false,
        open: false,
      });

      return;
    }

    const state = {
      open: false,
    };

    setLoading(true);

    const attendeeId = attendee.id;
    const person = formatAttendee(attendeeId, values);

    Api.appointments()
      .with(person)
      .notify(Notifications.ALL)
      .cancel(appointment.id, attendeeId, appointment.confirmCode)
      .then(() => {
        state.cancelled = true;
        state.successfullyCancelled = true;

        setAppointment({ status: APPOINTMENT_STATUSES.CANCELLED });
        setState(state);

        Tracker.view(
          confirmation ? PAGES.CANCEL_CONFIRMATION : PAGES.MANAGE,
          Tracker.getTrackingData(
            PAGES.CANCEL_CONFIRMATION,
            firstStep,
            selections,
          ),
        );

        window.scrollTo(0, 0);
      })
      .catch(() => {
        state.canCancel = false;

        setState(state);
      });
  };

  const validate = (values) => Validator.cancel(values, questions);

  const initialValues = questions
    ? questions.reduce(
        (object, question) => {
          object.questions[question.id] = '';

          const hasAdditional =
            question.options.length &&
            question.options.some((option) => option.additional);

          if (hasAdditional) {
            object.additional[question.id] = '';
          }

          return object;
        },
        { additional: {}, questions: {} },
      )
    : {};

  return (
    <Dialog dismissable onDismiss={toggleModal} open={open}>
      <Dialog.Header>
        <Typography component="h2" id="CancelModal" variant="h6">
          <FormattedMessage id="CancelModal.title" />
        </Typography>
      </Dialog.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={(props) => (
          <>
            <Dialog.Content>
              {formLoading ? (
                <CircularProgress />
              ) : (
                <CancelForm
                  {...props}
                  date={date}
                  loading={loading}
                  questions={questions}
                  toggleModal={toggleModal}
                  values={initialValues}
                />
              )}
            </Dialog.Content>
            {!formLoading ? (
              <Dialog.Actions>
                <Button
                  color="primary"
                  onClick={toggleModal}
                  size="medium"
                  variant="outlined"
                >
                  <FormattedMessage id="CancelModal.keep" />
                </Button>
                <Button
                  color="primary"
                  disabled={
                    loading ||
                    (props.errors && Object.keys(props.errors).length > 0)
                  }
                  onClick={props.submitForm}
                  size="medium"
                  variant="contained"
                >
                  <FormattedMessage id="CancelModal.cancel_appointment" />
                </Button>
              </Dialog.Actions>
            ) : null}
          </>
        )}
        validate={validate}
      />
    </Dialog>
  );
};

CancelAppointmentModal.propTypes = {
  formLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(QuestionShape),
  setState: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

CancelAppointmentModal.defaultProps = {
  questions: [],
};

export default CancelAppointmentModal;
