import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';
import Api from '../helpers/Api';

const DetectedTimezoneContext = createContext(null);

const shouldNotIgnorePath = () => window.location.pathname !== '/manage';

const STATUSES = {
  INIT: 'INIT',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
};

const reducer = (state, newState) => ({ ...state, ...newState });

const DetectedTimezoneProvider = ({ children }) => {
  const { timeZone: detected } = Intl.DateTimeFormat().resolvedOptions();
  const initialState = { status: STATUSES.INIT, timezone: detected };
  const [{ status, timezone }, setState] = useReducer(reducer, initialState);

  useEffect(() => {
    if ((!timezone || timezone === 'UTC') && shouldNotIgnorePath()) {
      setState({ status: STATUSES.FETCHING });

      Api.timezone()
        .find()
        .then(({ timezone }) => {
          setState({ status: STATUSES.FETCHED, timezone });
        })
        .catch(() => {
          setState({ status: STATUSES.FETCHED });
        });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DetectedTimezoneContext.Provider value={[timezone, status]}>
      {children}
    </DetectedTimezoneContext.Provider>
  );
};

DetectedTimezoneProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { DetectedTimezoneContext, DetectedTimezoneProvider, STATUSES };
