import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import Item from '../helpers/Item';

const StepAbilityContext = createContext([{}, () => {}]);

const StepAbilityProvider = ({ children, previous, steps }) => {
  const previousStep = steps.find(
    (step) => step.id === Item.get(previous, 'id'),
  );
  const canStepBackwards = !Item.get(previousStep, 'locked', false);

  const abilities = {
    can: {
      step: {
        backwards: canStepBackwards,
      },
    },
  };

  return (
    <StepAbilityContext.Provider value={abilities}>
      {children}
    </StepAbilityContext.Provider>
  );
};

StepAbilityProvider.propTypes = {
  children: PropTypes.element.isRequired,
  previous: PropTypes.shape({
    id: PropTypes.string,
  }),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      locked: PropTypes.bool,
    }),
  ).isRequired,
};

StepAbilityProvider.defaultProps = {
  previous: undefined,
};

export { StepAbilityContext, StepAbilityProvider };
