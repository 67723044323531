import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { SHORTCUTS, USER_PREFERENCE } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import Resources from '../helpers/Resources';
import Shortcuts from '../helpers/Shortcuts';
import ListboxPicker from './ListboxPicker';
import SelectableListItem from './SelectableListItem';

const preferences = [
  {
    id: USER_PREFERENCE.SPECIFIC,
    title: 'UserPreference.assigned_title',
  },
  {
    id: USER_PREFERENCE.RANDOM,
    title: 'UserPreference.anyone',
  },
];

const PreferencePicker = ({ resetRange }) => {
  const history = useHistory();

  const [{ user, userCategory }, setSelections] = useContext(SelectionContext);

  const preferred =
    user || userCategory ? USER_PREFERENCE.SPECIFIC : USER_PREFERENCE.RANDOM;

  const selectPreference = (preference) => {
    if (Shortcuts.exists(SHORTCUTS.USER)) {
      const selections = {
        user:
          preference === USER_PREFERENCE.SPECIFIC
            ? Resources.formatUser(Shortcuts.get(SHORTCUTS.USER))
            : null,
      };

      // We have to keep the preference as 'random' so that correct times page shows up
      if (preference === USER_PREFERENCE.SPECIFIC) {
        selections.userPreference = USER_PREFERENCE.RANDOM;
      }

      setSelections(selections);

      resetRange();

      return;
    }

    setSelections({
      userCategory:
        preference === USER_PREFERENCE.SPECIFIC
          ? { id: Shortcuts.identify(SHORTCUTS.USER_CATEGORY) }
          : null,
    });

    resetRange();
  };

  const showPreferencePage = () => {
    history.push('/user-preferences', { preferences });
  };

  return (
    <ListboxPicker
      label={
        preferred === USER_PREFERENCE.SPECIFIC ? (
          <FormattedMessage id="SupportedLanguagePreferencePicker.assigned_staff" />
        ) : (
          <FormattedMessage id="SupportedLanguagePreferencePicker.anyone" />
        )
      }
      mobileOnClick={showPreferencePage}
      onChange={selectPreference}
      options={preferences.map((preference) => (
        <SelectableListItem
          checked={preference.id === preferred}
          key={preference.id}
          title={<FormattedMessage id={preference.title} />}
          value={preference.id}
        />
      ))}
      value={preferred}
    />
  );
};

PreferencePicker.propTypes = {
  resetRange: PropTypes.func.isRequired,
};

export default PreferencePicker;
