import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import NoResults from '../../../shared/icons/NoResults';
import BackButton from '../../components/BackButton';
import CenterWrap from '../../components/CenterWrap';
import MultiPageServiceLayout from '../../components/desktop/MultiPageServiceLayout';
import SinglePageServiceLayout from '../../components/desktop/SinglePageServiceLayout';
import EmptyState from '../../components/EmptyState';
import Typography from '../../components/Typography';
import { SelectionContext } from '../../contexts/SelectionContext';
import { ServiceSearchProvider } from '../../contexts/ServiceSearchContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import { StepAbilityContext } from '../../contexts/StepAbilityContext';
import Step from '../../helpers/Step';

const useStyles = createUseStyles((theme) => ({
  emptyPage: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '1.875rem 2.5rem',
  },
  emptyPageContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  header: {
    background: theme.palette.white,
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '4rem',
    padding: '1.125rem 0',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  title: {
    color: theme.palette.black,
    fontSize: theme.textSizes.xl,
    fontWeight: theme.fontWeights.medium,
    marginBottom: '1.875rem',
  },
  widthShift: {
    transform: 'translateY(-10%)',
  },
}));

const Service = ({
  categories,
  category,
  expanded,
  firstCategory,
  loading,
  mode,
  previous,
  previousStep,
  selectCategory,
  selectService,
  services,
  toggleDescription,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const { can } = useContext(StepAbilityContext);
  const { firstStep, serviceStepLayout } = useContext(SettingsContext);
  const [{ attendee }, setSelections] = useContext(SelectionContext);

  if (loading) {
    return (
      <CenterWrap
        data-testid="empty-state"
        narrow={!!serviceStepLayout}
        view={mode}
      />
    );
  }

  if (!services.length) {
    return (
      <CenterWrap data-testid="empty-state" view={mode}>
        <div className={classes.emptyPage}>
          <Typography className={classes.title} component="h2" variant="title">
            <FormattedMessage
              id={
                firstStep === 'service' && attendee?.firstName
                  ? 'Service.select_title_prefill'
                  : 'Service.select_title'
              }
              values={{ name: attendee?.firstName, break: ' ' }}
            />
          </Typography>
          <div className={classes.emptyPageContent}>
            <EmptyState
              classes={{ overrides: classes.widthShift }}
              icon={<NoResults />}
              title={<FormattedMessage id="Service.no_services" />}
            />
          </div>
        </div>
      </CenterWrap>
    );
  }

  const onBack = () => {
    const selections = { service: null };

    setSelections(selections);
    previous(selections);
  };

  const props = {
    categories,
    category,
    classes,
    expanded,
    firstCategory,
    selectCategory,
    selectService,
    services,
    toggleDescription,
  };

  return (
    <CenterWrap
      data-testid="desktop-service"
      narrow={!!serviceStepLayout}
      view={mode}
    >
      {can.step.backwards && firstStep === 'location' ? (
        <header className={classes.header}>
          <BackButton
            previous={onBack}
            text={<FormattedMessage id="Ui.back" />}
            title={Step.getBackString(intl, previousStep)}
          />
        </header>
      ) : null}
      {serviceStepLayout ? (
        <ServiceSearchProvider services={services}>
          <SinglePageServiceLayout {...props} />
        </ServiceSearchProvider>
      ) : (
        <MultiPageServiceLayout {...props} />
      )}
    </CenterWrap>
  );
};

Service.propTypes = {
  category: PropTypes.shape({ id: PropTypes.number }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstCategory: PropTypes.shape({ id: PropTypes.string }),
  loading: PropTypes.bool,
  mode: PropTypes.number.isRequired,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  selectCategory: PropTypes.func,
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
  toggleDescription: PropTypes.func,
};

Service.defaultProps = {
  categories: [],
  loading: true,
  previousStep: null,
  services: [],
};

export default Service;
