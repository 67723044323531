import React, { Fragment, useContext, useEffect, useReducer } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import SearchableInput from '../components/forms/SearchableInput';
import Checkmark from '../components/icons/Checkmark';
import List from '../components/List';
import MobileListItem from '../components/mobile/ListItem';
import { CountriesContext } from '../contexts/CountriesContext';
import { CountryContext } from '../contexts/CountryContext';
import { HeaderContext } from '../contexts/HeaderContext';
import Item from '../helpers/Item';
import HistoryShape from '../shapes/HistoryShape';

const useStyles = createUseStyles((theme) => ({
  header: {
    background: theme.palette.white,
    boxShadow: theme.shadows.xs,
    padding: '1rem 1.25rem',
    zIndex: 900,
  },
  icon: {
    alignSelf: 'center',
    fill: theme.palette.neutral[400],
  },
  section: {
    overflow: 'hidden',
    overflowY: 'auto',
  },
  separator: {
    marginTop: '0.25rem',
  },
}));

const searchFieldName = 'searchable-countries';

const Timezones = ({ history }) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const countries = useContext(CountriesContext);
  const [, setHeader] = useContext(HeaderContext);
  const [country, setCountry] = useContext(CountryContext);
  const [filter, setFilter] = useReducer((state, newState) => newState, '');

  useEffect(() => {
    setHeader({
      action: history.goBack,
      title: <FormattedMessage id="Pages.country" />,
    });
  }, [history.goBack, setHeader]);

  const filterTimezones = ({ currentTarget: { value } }) => {
    setFilter(value.toLowerCase().trim());
  };

  const selectTimezone = ({ currentTarget: { dataset } }) => {
    setCountry(dataset.id);
  };

  const checkmark = (
    <Checkmark
      altText={intl.formatMessage({ id: 'Svg.alt_text.country_selected' })}
      classes={{ root: classes.icon }}
    />
  );

  return (
    <>
      <header className={classes.header}>
        <SearchableInput name={searchFieldName} onChange={filterTimezones} />
      </header>
      <section className={classes.section}>
        <div className={classes.separator}>
          <List id={`searchable-input-${searchFieldName}`}>
            {Item.map(countries, (key) => {
              // We are temporarily ignoring the destructuring-assignment rule explicitly.
              // There is a bug that was solved in a newer version of this plugin which
              // we will eventually be able to upgrade to once we can move off of
              // the current version of NodeJS in use.
              //
              // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
              //
              // eslint-disable-next-line react/destructuring-assignment
              const name = countries[key];
              const found = name.toLowerCase().includes(filter);

              if (found) {
                const adornment =
                  // We are temporarily ignoring the destructuring-assignment rule explicitly.
                  // There is a bug that was solved in a newer version of this plugin which
                  // we will eventually be able to upgrade to once we can move off of
                  // the current version of NodeJS in use.
                  //
                  // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
                  //
                  // eslint-disable-next-line react/destructuring-assignment
                  name === countries[country] ? checkmark : null;

                return (
                  <MobileListItem
                    action={selectTimezone}
                    adornment={adornment}
                    id={key}
                    key={name}
                    primary={name}
                  />
                );
              }

              return null;
            })}
          </List>
        </div>
      </section>
    </>
  );
};

Timezones.propTypes = {
  history: HistoryShape.isRequired,
};

export default Timezones;
