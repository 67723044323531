import Dates from '../../shared/helpers/Dates';

export default {
  /**
   * @returns {boolean}
   */
  open(allHours) {
    const hours = allHours[Dates.dayName()];

    if (!hours.open || !hours.closed) {
      return false;
    }

    if (Dates.time(hours.closed).isBefore(Dates.time(hours.open))) {
      throw new Error('Closed hours cannot be before open');
    }

    const now = Dates.now();
    const open = Dates.time(hours.open);
    const closed = Dates.time(hours.closed);

    if (now.isBefore(open) || now.isAfter(closed)) {
      return false;
    }

    return true;
  },
};
