import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import Dates from '../../shared/helpers/Dates';
import useDateTime from '../../shared/hooks/useDateTime';
import { STEPS } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Button from './Button';
import Modal from './Modal';
import Typography from './Typography';

const useStyles = createUseStyles({
  primary: {
    marginLeft: '0.5rem',
  },
});

const BookingValidationErrorModal = ({
  errorMessage,
  handleClose,
  open,
  step,
}) => {
  const classes = useStyles();
  const {
    formatters: { formatTime },
  } = useDateTime(true);
  const mode = useContext(ViewModeContext);
  const [{ date, location }] = useContext(SelectionContext);

  const messages = (stepTitle, date, location) => {
    const newTime = new Date();
    newTime.setHours(date.hour(), date.minute());

    switch (stepTitle) {
      case STEPS.SERVICE:
        return (
          <FormattedMessage
            id="BookingValidationErrorModal.service_unavailable_description"
            values={{ phone: location.phone }}
          />
        );
      case STEPS.LOCATION:
        return (
          <FormattedMessage id="BookingValidationErrorModal.location_unavailable_description" />
        );
      case STEPS.TIMES:
        return (
          <FormattedMessage
            id="BookingValidationErrorModal.times_unavailable_description"
            values={{
              time: (
                <Typography variant="label">{formatTime(newTime)}</Typography>
              ),
              date: (
                <Typography variant="label">
                  {Dates.toDateMonthYear(date)}
                </Typography>
              ),
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      buttons={
        <>
          <Button
            fullWidth={false}
            onClick={handleClose}
            type="button"
            variant={step ? 'secondary' : 'primary'}
          >
            <FormattedMessage id={step ? 'Ui.cancel' : 'Ui.ok'} />
          </Button>
          {step ? (
            <Button
              classes={mode === MOBILE ? '' : { override: classes.primary }}
              data-testid="to-step-button"
              fullWidth={false}
              onClick={step.action}
              type="button"
            >
              <FormattedMessage
                id={`BookingValidationErrorModal.${step.title}_button`}
              />
            </Button>
          ) : null}
        </>
      }
      header={
        <FormattedMessage
          id={
            step
              ? `BookingValidationErrorModal.${step.title}_unavailable_title`
              : 'BookingValidationErrorModal.default_error_title'
          }
        />
      }
      isOpen={open}
      onRequestClose={handleClose}
      testId="validation-error-modal"
    >
      <Typography component="p" variant="caption1">
        {(step && messages(step.title, date, location)) || (
          <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
        )}
      </Typography>
    </Modal>
  );
};

BookingValidationErrorModal.propTypes = {
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  step: PropTypes.shape({
    title: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }),
};

BookingValidationErrorModal.defaultProps = {
  errorMessage: null,
  step: null,
};

export default BookingValidationErrorModal;
