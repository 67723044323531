import Dates from '../../shared/helpers/Dates';
import Item from './Item';

export default {
  /**
   * @param {array} slots
   * @param {array} dates
   *
   * @returns {boolean}
   */
  available(slots, dates) {
    const days = [];

    dates.forEach((times) => {
      times.forEach((date) => {
        days.push(date.format('iso-short'));
      });
    });

    for (let i = 0; i < days.length; i++) {
      if (slots[days[i]]) {
        return true;
      }
    }

    return false;
  },

  /**
   * @param {array} slots
   *
   * @returns {object}
   */
  combine(slots) {
    return slots.reduce((object, { attributes }) => {
      const date = Dates.parse(attributes.start).format();

      object[date] = object[date] || [];
      object[date] = [...object[date], this.formatSlot(attributes)];

      return object;
    }, {});
  },

  /**
   * @param {object} slot
   *
   * @returns {object}
   */
  formatSlot(attributes) {
    const { locations, staff: users } = attributes;
    const end = Dates.parse(attributes.end);
    const start = Dates.parse(attributes.start);

    return {
      end,
      start,
      users: users.map((user) => user.id),
      count: attributes.attendee_count,
      limit: attributes.attendee_limit,
      appointment: attributes.appointment,
      locations: locations ? locations.map((location) => location.id) : [],
    };
  },

  /**
   * @param {array} data array of slots
   * @param {object} startDate window start date
   * @param {object} endDate window end date
   *
   * @returns {object} array of slots filtered between the start and date dates
   */
  filterWithinRange(data, startDate, endDate) {
    return data.filter((slotInfo) => {
      return Dates.isBetween(
        startDate,
        endDate,
        Dates.parse(slotInfo.attributes.start),
      );
    });
  },

  /**
   * @param {object} data object dict of days and the list of day slots
   *
   * @returns {object} object of data excluding filled group slots
   */
  filterFilledGroupAppointmentsArray(data) {
    return data.filter(
      (slot) =>
        !slot.attributes.attendee_limit ||
        slot.attributes.attendee_limit - slot.attributes.attendee_count > 0,
    );
  },

  /**
   * @param {object} range
   * @param {object} rangeStartDate window start date
   *
   * @returns {object} the later start date
   */
  startDate(range, rangeStartDate) {
    return range.startOverride &&
      range.startOverride.epoch >= rangeStartDate.epoch
      ? range.startOverride
      : rangeStartDate;
  },

  /**
   * @param {object} range
   * @param {object} rangeEndDate window end date
   *
   * @returns {object} the earlier end date
   */
  endDate(range, rangeEndDate) {
    return range.endOverride && rangeEndDate.isAfter(range.endOverride)
      ? range.endOverride
      : rangeEndDate.endOf('day');
  },

  /**
   * @param {object} data array of slots
   * @param {object} startDate target start date
   *
   * @returns {object} the desired date to be selected
   */
  getSelectedSlot(data, startDate) {
    const available = Item.first(data) || null;

    if (available) {
      return Dates.parse(available.attributes.start);
    } else {
      return startDate.epoch < Dates.today().epoch ? Dates.today() : startDate;
    }
  },
};
