import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { SettingsContext } from '../../contexts/SettingsContext';
import Strings from '../../helpers/Strings';
import AccountCircle from '../icons/AccountCircle';
import Typography from '../Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    cursor: 'pointer',
    margin: 0,
    padding: '0 1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    width: '100%',
    '&:focus-visible': {
      outline: `2px solid ${theme.palette.secondary[400]}`,
      outlineOffset: '-2px',
    },
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem 0',
    textAlign: 'left',
    width: '100%',
  },
  primary: {
    display: 'block',
    maxWidth: '31.25rem',
  },
  withMargin: {
    marginBottom: '0.25rem',
  },
  title: {
    alignItems: 'flex-start',
    display: 'flex',
  },
  image: {
    width: '2.5rem',
    height: '2.5rem',
    minWidth: '2.5rem',
    minHeight: '2.5rem',
    marginRight: '1rem',
    fill: theme.palette.neutral[200],
    borderRadius: '50%',
  },
}));

const ListItem = ({
  action,
  adornment,
  badges,
  id,
  imageUrl,
  primary,
  secondary,
  ariaLabel,
}) => {
  const classes = useStyles({ theme: useTheme() });
  const { showStaffPhotos } = useContext(SettingsContext);

  const [imageError, setImageError] = useState(false);

  const getAccountImage = () => {
    const accountCircle = <AccountCircle className={classes.image} />;
    const image = imageError ? (
      accountCircle
    ) : (
      <img
        alt="profile"
        className={classes.image}
        onError={() => setImageError(true)}
        src={imageUrl}
      />
    );

    return !imageUrl ? accountCircle : image;
  };

  return (
    <li>
      <div
        aria-label={ariaLabel}
        className={classes.root}
        data-id={id}
        onClick={action}
        onKeyPress={action}
        role="button"
        tabIndex={0}
      >
        {showStaffPhotos ? getAccountImage() : null}
        <div className={classes.content}>
          <div>
            <div className={classes.title}>
              <Typography
                classes={{
                  root: classNames(
                    classes.primary,
                    secondary && classes.withMargin,
                  ),
                }}
                variant="title"
              >
                {primary}
              </Typography>
            </div>
            {secondary ? (
              <Typography variant="caption1">{secondary}</Typography>
            ) : null}
            {badges ? (
              <div className="flex flex-wrap py-1">
                {badges
                  ? badges.map((badge) => {
                      return (
                        <div
                          className="mr-1 mb-0.5 rounded bg-black-10 p-1 text-xs"
                          key={badge.label}
                        >
                          {Strings.uppercaseFirst(badge.label)}
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : null}
          </div>
          {adornment ? adornment : null}
        </div>
      </div>
    </li>
  );
};

ListItem.propTypes = {
  action: PropTypes.func.isRequired,
  adornment: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      tooltip: PropTypes.element,
    }),
  ),
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ariaLabel: PropTypes.string,
};

ListItem.defaultProps = {
  adornment: null,
  ariaLabel: 'List Item',
  badges: null,
  imageUrl: null,
  secondary: null,
};

export default ListItem;
