import Item from './Item';

export default {
  convertRemToPixels(rem) {
    return (
      rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
  },

  scrollIntoViewWithOffset(element, parent, offset) {
    if (
      !(element instanceof HTMLElement) ||
      !(parent instanceof HTMLElement || parent === window)
    ) {
      return;
    }

    const boundingRect = element.getBoundingClientRect();

    let scrollTop = boundingRect.top;
    let noOffsetZone;

    if (parent === window) {
      scrollTop += window.pageYOffset;
      noOffsetZone = document.body.scrollHeight - parent.innerHeight / 2;
    } else {
      scrollTop =
        scrollTop + parent.scrollTop - parent.getBoundingClientRect().top;
      noOffsetZone = parent.scrollHeight - parent.clientHeight / 2;
    }

    const elementBottom = scrollTop + boundingRect.height;

    if (elementBottom < noOffsetZone) {
      scrollTop -= offset;
    }

    // make sure the scrollTo method exists on the element
    if (typeof parent.scrollTo === 'function') {
      parent.scrollTo({ top: scrollTop, behavior: 'smooth' });
    } else {
      parent.scrollTop = scrollTop;
    }
  },

  scrollToError({ errors, formId, isSubmitting, isValidating }) {
    if (isSubmitting && Item.length(errors) && !isValidating) {
      const key = Object.keys(errors)[0];
      const selector = `#${formId} [name="${key}"],#${formId} [name="${key}[0]"]`;
      const element = document.querySelector(selector);

      if (element) {
        if (key === 'duplicate') {
          element.scrollIntoView({ block: 'center' });
        } else {
          element.focus();
        }
      }
    }
  },
};
