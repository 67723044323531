import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const CheckmarkCircle = ({ altText }) => {
  const intl = useIntl();

  return (
    <svg
      fill="inherit"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.checkmark' })}
      </title>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  );
};

CheckmarkCircle.propTypes = {
  altText: PropTypes.string,
};

CheckmarkCircle.defaultProps = {
  altText: '',
};

export default CheckmarkCircle;
