import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { readableForegroundBackground } from '../helpers/Colors';

const VendorColorContext = createContext({});

const useVendorColors = () => useContext(VendorColorContext);

const VendorColorProvider = ({ children }) => {
  const [colors, setColors] = useState({
    background: null,
    foreground: null,
  });

  useEffect(() => {
    if (window.state?.settings?.use_custom_icon_color) {
      const { background, foreground } = readableForegroundBackground(
        window.state?.theme?.custom_icon_colour,
      );
      setColors({ background, foreground });
    }
  }, []);

  return (
    <VendorColorContext.Provider value={colors}>
      {children}
    </VendorColorContext.Provider>
  );
};

VendorColorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { VendorColorContext, VendorColorProvider, useVendorColors };
