import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { DESKTOP, ViewModeContext } from '../contexts/ViewModeContext';
import Clear from './icons/Clear';
import Edit from './icons/Edit';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem 0',
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: '2.5rem',
  },
  button: {
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    borderRadius: theme.borderRadius.full,
    cursor: 'pointer',
    display: 'inline-flex',
    margin: 0,
    padding: 0,
    '& svg': {
      fill: theme.palette.neutral[400],
      height: theme.textSizes.base,
      width: theme.textSizes.base,
    },
    '&[disabled]': {
      cursor: 'default',
      cursorEvents: 'none',
      opacity: 0,
    },
    '&:focus': {
      boxShadow: theme.shadows.input,
      '& svg': {
        fill: theme.palette.black,
      },
    },
    '&:not(:first-of-type)': {
      marginLeft: '0.5rem',
    },
  },
  right: {
    display: 'flex',
  },
}));

const AttendeeListItem = ({ attendee, deleteAttendee, editAttendee }) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const mode = useContext(ViewModeContext);

  const handleDelete = () => deleteAttendee(attendee);
  const handleEdit = () => editAttendee(attendee);

  return (
    <li className={classes.root}>
      <Typography component="h3" data-personal variant="label">
        {`${attendee.firstName} ${attendee.lastName}`}
      </Typography>
      <div className={classes.right}>
        {mode === DESKTOP && (
          <Typography data-personal variant="caption1">
            {attendee.email}
          </Typography>
        )}
        <span className={classes.actions}>
          <button
            aria-label={`${intl.formatMessage({
              id: 'AttendeeListItem.edit',
            })} (${attendee.firstName} ${attendee.lastName})`}
            className={classes.button}
            data-testid="delete-attendee"
            onClick={handleEdit}
            type="button"
          >
            <Edit />
          </button>
          <button
            aria-label={`${intl.formatMessage({
              id: 'AttendeeListItem.delete',
            })} (${attendee.firstName} ${attendee.lastName})`}
            className={classes.button}
            data-testid="delete-attendee"
            onClick={handleDelete}
            type="button"
          >
            <Clear />
          </button>
        </span>
      </div>
    </li>
  );
};

AttendeeListItem.propTypes = {
  attendee: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  deleteAttendee: PropTypes.func.isRequired,
  editAttendee: PropTypes.func.isRequired,
};

export default AttendeeListItem;
