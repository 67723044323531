export default {
  getCurrent() {
    return document.documentElement.getAttribute('lang');
  },

  setCurrent(language) {
    document.documentElement.setAttribute('lang', language);

    // Update reporting data object to reflect updated language
    if (window.reportingData) {
      window.reportingData.language = language;
    }
  },
};
