import Shortcuts from '../helpers/Shortcuts';

const { parse, stringify } = JSON;
const items = {};
const arrayStore = {
  removeItem: (key) => {
    delete items[key];
  },
  getItem: (key) => items[key] || null,
  setItem: (key, value) => {
    items[key] = value;
  },
};
let integrationPartner;
let store;

if (window.state?.shortcuts) {
  integrationPartner = Shortcuts.get('partner');
}

if (integrationPartner) {
  store = arrayStore;
} else {
  try {
    store = sessionStorage;
  } catch (e) {
    store = arrayStore;
  }
}

export default {
  /**
   * @param {string} key
   *
   * @returns {undefined}
   */
  clear(key) {
    store.removeItem(key);
  },

  clearPermanent(key) {
    localStorage.removeItem(key);
  },

  /**
   * @param {string} key
   * @param {*} fallback
   *
   * @returns {*}
   */
  get(key, fallback = null) {
    return store.getItem(key) !== null ? parse(store.getItem(key)) : fallback;
  },

  getPermanent(key, fallback = null) {
    const item = localStorage.getItem(key);

    return item !== null ? JSON.parse(item) : fallback;
  },

  /**
   * @param {string} key
   * @param {*} value
   *
   * @returns {undefined}
   */
  set(key, value) {
    store.setItem(key, stringify(value));
  },

  setPermanent(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
};
