export const STEPS = {
  CHECK_IN: 'check-in',
  SERVICE: 'service',
  LOCATION: 'location',
  MEETING_METHODS: 'meeting-methods',
  TIMES: 'times',
  DETAILS: 'details',
};

export const PAGE_TITLES = {
  [STEPS.SERVICE]: 'Service.select_title',
  [STEPS.MEETING_METHODS]: 'MeetingMethods.header',
  [STEPS.LOCATION]: 'LocationList.title',
  [STEPS.TIMES]: 'DateTime.select_time',
  [STEPS.DETAILS]: 'Details.form_header',
  staff: 'UserPreference.header',
  manage: 'Manage.appointment_details',
};

export const SHORTCUTS = {
  ADDITIONAL_USERS: 'additional_staff',
  CATEGORY: 'category',
  CITY: 'city',
  COUNTRY: 'country',
  DETECTED_COUNTRY: 'detected_country',
  INVITATION: 'invitation',
  LANGUAGE: 'language',
  LOCATION: 'location',
  LOCATION_CATEGORY: 'location_category',
  MEETING_METHOD: 'meeting_method',
  QUEUE: 'queue',
  REGION: 'region',
  SERVICE: 'service',
  SETTINGS: 'settings',
  TIMESLOT: 'timeslot',
  TIMEZONE: 'timezone',
  USER: 'user',
  USER_CATEGORY: 'user_category',
  WITHIN: 'within',
};

export const QUESTION_TYPES = {
  TEXT: 1,
  SELECT: 2,
  CHECKBOX: 3,
  RADIO: 4,
  TEXTAREA: 5,
  UPLOAD: 6,
};

export const FORM_QUESTION_TYPES = {
  MULTIPLE: 1,
  TEXT: 2,
};

export const REQUIRED_VALUES = {
  NA: 'na',
  OPTIONAL: 'optional',
  REQUIRED: 'required',
  REQUIRED_CHECKBOX: 'required_checkbox',
};

export const MAP_OVERLAY = {
  WIDTH: 380,
  LEFT_MARGIN: 20,
};

export const HEADER_HEIGHT = {
  DESKTOP: '4.5rem',
  MOBILE: '4rem',
};

export const SCROLL_OFFSET = '4.625rem';

export const APPOINTMENT_STATUSES = {
  CONFIRMED: 1,
  CANCELLED: 4,
};

export const ATTENDEE_STATUSES = {
  CONFIRMED: 1,
  CANCELLED: 4,
};

export const UNCATEGORIZED = '0';

export const DEFAULT_COUNTRY = 'US';
export const POPULAR_COUNTRIES = ['CA', 'US'];

export const COUNTRIES_WITH_REGIONS = ['CA', 'US'];

export const ENGLISH = 'en';
export const SPANISH = 'es';
export const FRENCH = 'fr';
export const KOREAN = 'ko';
export const POLISH = 'pl';
export const PORTUGUESE = 'pt';
export const RUSSIAN = 'ru';
export const CHINESE = 'zh';

export const LOCALES = ['en', 'fr', 'es', 'ko', 'pl', 'pt', 'ru', 'zh', 'dev'];

export const LANGUAGES = {
  [ENGLISH]: 'english',
  [SPANISH]: 'spanish',
  [FRENCH]: 'french',
  [KOREAN]: 'korean',
  [POLISH]: 'polish',
  [PORTUGUESE]: 'portuguese',
  [RUSSIAN]: 'russian',
  [CHINESE]: 'chinese',
};

export const USER_PREFERENCE = {
  RANDOM: 'random',
  SPECIFIC: 'specific',
};

export const USER_PREFERENCE_OPTIONS = [
  {
    id: USER_PREFERENCE.RANDOM,
    subtitle: 'UserPreference.random_subtitle',
    testId: 'user-preference-random-option',
    title: 'UserPreference.random_title',
  },
  {
    id: USER_PREFERENCE.SPECIFIC,
    subtitle: 'UserPreference.specific_subtitle',
    testId: 'user-preference-specific-option',
    title: 'UserPreference.specific_title',
  },
];

export const MEETING_METHODS = {
  AT_LOCATION: 1,
  PHONE_CALL: 2,
  VIDEO_CALL: 3,
  OFF_SITE: 4,
};

export const METHODS = [
  { id: MEETING_METHODS.AT_LOCATION },
  { id: MEETING_METHODS.PHONE_CALL },
  { id: MEETING_METHODS.VIDEO_CALL },
];

export const MEETING_METHOD_NAMES = {
  [MEETING_METHODS.AT_LOCATION]: 'In Person',
  [MEETING_METHODS.PHONE_CALL]: 'Phone',
  [MEETING_METHODS.VIDEO_CALL]: 'Video',
  [MEETING_METHODS.OFF_SITE]: 'Off Site',
};

export const VIRTUAL_MEETING_METHODS = [
  MEETING_METHODS.PHONE_CALL,
  MEETING_METHODS.VIDEO_CALL,
];

export const MEETING_METHOD_TITLES = {
  [MEETING_METHODS.AT_LOCATION]: {
    primary: 'MeetingMethods.at_location_primary',
    secondary: 'MeetingMethods.at_location_secondary',
  },
  [MEETING_METHODS.PHONE_CALL]: {
    primary: 'MeetingMethods.phone_call_primary',
    secondary: 'MeetingMethods.phone_call_secondary',
  },
  [MEETING_METHODS.VIDEO_CALL]: {
    primary: 'MeetingMethods.video_call_primary',
    secondary: 'MeetingMethods.video_call_secondary',
  },
  [MEETING_METHODS.OFF_SITE]: {
    primary: 'MeetingMethods.off_site_primary',
    secondary: 'MeetingMethods.off_site_secondary',
  },
};

export const VISIBILITIES = {
  PUBLIC: 0,
  INVITE_ONLY: 2,
};

export const CALLS_TO_ACTION = {
  PHONE: 0,
  EMAIL: 1,
  LINK: 2,
};

export const RESOURCE_CLIENT_VIEW = 'client_view';

export const LANDING_PAGES = {
  LOCATION: '/where',
  CHOICES: '/how',
  CHECK_IN: '/check-in',
};

export const ORIGINS = {
  RESERVE_WITH_GOOGLE: 10,
  KIOSK: 14,
  TV: 15,
  DIGITAL_BANKING_PLATFORM: 17,
  BANNO: 19,
};

export const SERVICE_SEARCH_THRESHOLD = 20;

export const SHOW_STAFF_PICKER_VALUES = {
  NEVER: 0,
  ALL_LOCATIONS: 1,
  PHYSICAL_LOCATIONS: 2,
  VIRTUAL_LOCATIONS: 3,
};

export const GOOGLE_CLIENT_STORAGE_KEY = 'google-client';

export const PAGES = {
  ADD_ATTENDEE: '/add-attendee',
  CANCEL: '/cancel',
  CANCEL_CONFIRMATION: '/cancel-confirmation',
  CATEGORY: '/category',
  CHECK_IN: '/check-in',
  CHANGE_MEETING_METHOD: '/change-meeting-method',
  CONFIRMATION: '/confirmation',
  EDIT_ATTENDEE: '/edit-attendee',
  DETAILS: '/details',
  FASTER_ROUTE: '/faster-route',
  FASTER_ROUTE_LINK: '/faster-route-link',
  HOW: '/how',
  LOCATION: '/location',
  MANAGE: '/manage',
  MEETING_METHOD: '/meeting-method',
  RESCHEDULE: '/reschedule',
  SERVICE: '/service',
  SUMMARY: '/summary',
  TIMES: '/times',
  USER: '/staff',
  USER_PREFERENCE: '/staff-preference',
  USER_TIMES: '/staff-times',
  VIEW_AVAILABLE_TIMES_AT_OTHER_LOCATIONS:
    '/view-available-times-at-other-locations',
  WHERE: '/where',
};

export const STAFF_SELECTIONS = {
  RANDOM: 'random',
  MOST_AVAILABLE: 'most_available',
  NEAREST_AVAILABLE: 'nearest_available',
};

export const TAG_MANAGERS = {
  GOOGLE_TAG_MANAGER: 1,
  TEALIUM_TAG_MANAGER: 2,
  ADOBE_TAG_MANAGER: 3,
};

export const HIDE_BOOK_ANOTHER_APPOINTMENT_LINK = 1;
