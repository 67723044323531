import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { HEADER_HEIGHT } from '../constants';
import CircularProgress from './CircularProgress';

const useStyles = createUseStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    verticalAlign: 'middle',
    minHeight: `calc(100vh - ${HEADER_HEIGHT.MOBILE})`,
  },
  override: {},
});

const LoadingState = ({ classes: injectedClasses, ...props }) => {
  const classes = {
    ...useStyles(),
    ...injectedClasses,
  };

  return (
    <div
      aria-busy="true"
      aria-describedby="progressbar"
      className={classNames(classes.root, classes.override)}
      role="status"
      {...props}
    >
      <CircularProgress />
    </div>
  );
};

LoadingState.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

LoadingState.defaultProps = {
  classes: {},
};

export default LoadingState;
