import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { useFeatureDecisions } from '../../shared/contexts/FeatureDecisionContext';
import mode from '../../shared/helpers/Mode';
import synonymReplacement from '../../shared/helpers/SynonymReplacement';
import { FeatureContext } from '../contexts/FeatureContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import messages from '../locales';
import { Anchor } from './Anchor';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  statement: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    marginTop: '1rem',
    paddingTop: '1rem',
    '& a': {
      color: theme.palette.secondary[400],
      borderBottom: `1px dotted ${theme.palette.secondary[400]}`,
      textDecoration: 'none',
      target: '_blank',
      '&:focus': {
        outline: `2px ${theme.palette.secondary[500]} solid`,
        '& $text': {
          color: theme.palette.secondary[500],
        },
      },
      '&:hover': {
        borderBottomColor: theme.palette.secondary[500],
        color: theme.palette.secondary[500],
      },
    },
    '& ol li': {
      listStyle: 'decimal',
      listStylePosition: 'inside',
    },
    '& ul li': {
      listStyle: 'circle',
      listStylePosition: 'inside',
    },
  },
  kioskStatement: {
    fontSize: '0.875rem',
  },
}));

const Policies = () => {
  const classes = useStyles({ theme: useTheme() });

  const {
    privacyPolicyUrl,
    termsOfServiceUrl,
    preBookingStatement: preBooking,
  } = useContext(SettingsContext);
  const { fullPrivateLabel } = useContext(FeatureContext);
  const [{ bookingWalkIn }] = useContext(SelectionContext);
  const { shouldUseJoinTheLineFromCV } = useFeatureDecisions();

  const vendorName = window.state.vendor_name;
  const [locale] = useContext(LocaleContext);
  const translations = synonymReplacement(
    messages[locale],
    window.state.synonyms[locale],
  );
  const preBookingStatement = { __html: preBooking };
  const values = {
    privacy: (
      <Anchor
        data-testid="coconut-privacy"
        external
        href={translations['DetailsForm.coconut_privacy_url']}
      >
        <FormattedMessage id="DetailsForm.privacy" />
      </Anchor>
    ),
    terms: (
      <Anchor
        data-testid="coconut-terms"
        external
        href={translations['DetailsForm.coconut_terms_url']}
      >
        <FormattedMessage id="DetailsForm.terms_of_use" />
      </Anchor>
    ),
    vendor: vendorName,
    vendorPrivacy: (
      <Anchor data-testid="vendor-privacy" external href={privacyPolicyUrl}>
        <FormattedMessage id="DetailsForm.privacy" />
      </Anchor>
    ),
    vendorTerms: (
      <Anchor data-testid="vendor-terms" external href={termsOfServiceUrl}>
        <FormattedMessage id="DetailsForm.terms_of_use" />
      </Anchor>
    ),
    action:
      bookingWalkIn && shouldUseJoinTheLineFromCV ? (
        <FormattedMessage id="DetailsForm.walkin_policy_action" />
      ) : (
        <FormattedMessage id="DetailsForm.appointment_policy_action" />
      ),
  };

  const getId = () => {
    if (privacyPolicyUrl && termsOfServiceUrl && !fullPrivateLabel) {
      return 'DetailsForm.agree_tos_with_vendor';
    }

    if (privacyPolicyUrl && !termsOfServiceUrl && !fullPrivateLabel) {
      return 'DetailsForm.agree_tos_with_privacy';
    }

    if (!privacyPolicyUrl && termsOfServiceUrl && !fullPrivateLabel) {
      return 'DetailsForm.agree_tos_with_tos';
    }

    if (fullPrivateLabel && privacyPolicyUrl && !termsOfServiceUrl) {
      return 'DetailsForm.agree_tos_with_vendor_full_privacy_policy';
    }

    if (fullPrivateLabel && !privacyPolicyUrl && termsOfServiceUrl) {
      return 'DetailsForm.agree_tos_with_vendor_full_privacy_tos';
    }

    if (fullPrivateLabel && privacyPolicyUrl && termsOfServiceUrl) {
      return 'DetailsForm.agree_tos_with_vendor_full_privacy';
    }

    return 'DetailsForm.agree_tos';
  };

  return (
    <div data-testid="compliance-policies" role="contentinfo">
      {!mode.isKiosk() ? (
        <Typography component="p" variant="subtitle">
          <FormattedMessage id={getId()} values={values} />
        </Typography>
      ) : null}
      {preBooking ? (
        <Typography variant="body2">
          <div
            className={
              mode.isKiosk() ? classes.kioskStatement : classes.statement
            }
            dangerouslySetInnerHTML={preBookingStatement}
            data-testid="pre-booking-statement"
          />
        </Typography>
      ) : null}
    </div>
  );
};

export default Policies;
