import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { FeatureDecisionContext } from '../../../shared/contexts/FeatureDecisionContext';
import Dates from '../../../shared/helpers/Dates';
import Button from '../../components/Button';
import CircularProgress from '../../components/CircularProgress';
import SearchableInput from '../../components/forms/SearchableInput';
import List from '../../components/List';
import MobileListItem from '../../components/mobile/ListItem';
import SpecificUserLanguagePreferences from '../../components/SpecificUserLanguagePreferences';
import Typography from '../../components/Typography';
import { LANGUAGES, SHORTCUTS } from '../../constants';
import { FeatureContext } from '../../contexts/FeatureContext';
import { HeaderContext } from '../../contexts/HeaderContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import { UsersContext } from '../../contexts/UsersContext';
import Item from '../../helpers/Item';
import Shortcuts from '../../helpers/Shortcuts';
import SpacetimeShape from '../../shapes/SpacetimeShape';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[200],
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  header: {
    padding: '1.25rem',
  },
  loading: {
    padding: '4rem 2rem',
  },
  preferences: {
    padding: '0.75rem 1.25rem',
  },
  section: {
    background: theme.palette.white,
    marginBottom: '0.25rem',
  },
  content: {
    background: theme.palette.white,
    flexGrow: 1,
  },
  seeAllStaff: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1.5rem',
  },
  select: {
    borderRadius: theme.borderRadius.default,
    color: theme.palette.secondary[400],
    padding: '0.5rem 0.75rem',
  },
  title: {
    marginTop: '0.25rem',
  },
}));

const searchFieldName = 'searchable-users';

const Users = ({
  initialStartDate,
  loading,
  previous,
  previousStep,
  setInformation,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const [locale] = useContext(LocaleContext);
  const [, setHeader] = useContext(HeaderContext);
  const { showStaffLanguages } = useContext(SettingsContext);
  const {
    users,
    supportedLanguages,
    getCategoryUsers,
    getUsers,
    setState,
    supportedLanguagesTranslations,
  } = useContext(UsersContext);
  const { spokenLanguages } = useContext(FeatureContext);
  const [query, setQuery] = useReducer((state, newState) => newState, '');
  const [{ settings, shortcuts, userCategory }, setSelections] =
    useContext(SelectionContext);
  const { shouldUseNextAvailability, shouldUseNextAvailabilityNewView } =
    useContext(FeatureDecisionContext);
  const [preferences, setPreferences] = useReducer(
    (_state, newState) => newState,
    {},
  );
  const hasPreferredUser = settings?.preferred_staff;

  const [waitingForUsers, setWaiting] = useState(true);

  useEffect(() => {
    if (userCategory && users === null) {
      if (!waitingForUsers) {
        setWaiting(true);
      }

      getCategoryUsers().then((result) => {
        setWaiting(result);
      });
    } else {
      setWaiting(false);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, userCategory, users]);

  // when not using shortcuts, fetch users for normal booking flow
  useEffect(() => {
    if (
      (shortcuts?.length === 0 || !Shortcuts.exists(SHORTCUTS.USER_CATEGORY)) &&
      !users
    ) {
      if (!waitingForUsers) {
        setWaiting(true);
      }

      getUsers().then((result) => {
        setWaiting(result);
      });
    } else {
      setWaiting(false);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, users]);

  useEffect(() => {
    if (
      !Shortcuts.exists(
        SHORTCUTS.USER || Shortcuts.exists(SHORTCUTS.USER_CATEGORY),
      ) &&
      users?.length === 0
    ) {
      if (!waitingForUsers) {
        setWaiting(true);
      }

      getUsers().then((result) => {
        setWaiting(result);
      });
    } else {
      setWaiting(false);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, users]);

  useEffect(() => {
    setHeader({
      action: previous,
      title: <FormattedMessage id="Steps.staff" />,
      previousStep,
    });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previous, setHeader]);

  const changeLocalePreferences = (preferences) => {
    setPreferences(preferences);
  };

  const filterUsers = () => {
    const options = Item.map(preferences, (preference) =>
      preferences[preference] ? preference : null,
    ).filter(Boolean);

    const filtered =
      supportedLanguages?.length > 1 && options.length > 0
        ? users?.filter((user) =>
            spokenLanguages
              ? user.spokenLanguages.some((locale) => options.includes(locale))
              : user.supportedLocales.some((locale) =>
                  options.includes(locale),
                ),
          )
        : users;

    if (query.length < 2) {
      return filtered;
    }

    return filtered.filter((item) => item.name.toLowerCase().includes(query));
  };

  const handleSearch = ({ currentTarget: { value } }) => {
    setQuery(value.toLowerCase());
  };

  const setUser = ({
    currentTarget: {
      dataset: { id },
    },
  }) => {
    setSelections({ user: users?.find((item) => item.id === id) });
    setInformation({
      loading: true,
      earliestDate: null,
      ...(shouldUseNextAvailability &&
        shouldUseNextAvailabilityNewView && { selected: initialStartDate }),
    });
  };

  const showAllUsers = () => {
    if (loading) {
      return;
    }

    if (users?.length && !loading) {
      setState({ supportedLanguages: null, users: [] });
      setSelections({
        settings: { ...settings, preferred_staff: null },
        userCategory: null,
      });
    }
  };

  const header = (
    <header className={classNames(classes.section, classes.header)}>
      <SearchableInput name={searchFieldName} onChange={handleSearch} />
    </header>
  );

  const getAriaLabel = (item) => {
    const locales = spokenLanguages
      ? item.spokenLanguages
      : item.supportedLocales;
    const ariaLabelLanguages = [];

    locales.forEach((abbr) => {
      const language = spokenLanguages
        ? supportedLanguagesTranslations[locale][abbr]
        : intl.formatMessage({
            id: `Languages.${LANGUAGES[abbr].toLowerCase()}`,
          });

      ariaLabelLanguages.push(language);
    });

    // Outputs similar to {name} speaks {English, Dutch, French}
    return intl.formatMessage(
      { id: 'DateTime.locale_badge_label' },
      {
        name: item.name,
        language: ariaLabelLanguages.join(', '),
      },
    );
  };

  const getBadges = (item) => {
    if (!supportedLanguages) {
      return null;
    }

    const locales = spokenLanguages
      ? item.spokenLanguages.filter((lang) => supportedLanguages.includes(lang))
      : item.supportedLocales;

    return locales.map((abbr) => {
      const language = spokenLanguages ? (
        supportedLanguagesTranslations[locale][abbr]
      ) : (
        <FormattedMessage id={`Languages.${LANGUAGES[abbr].toLowerCase()}`} />
      );

      return {
        label: abbr,
        tooltip: (
          <FormattedMessage
            id="DateTime.locale_badge_tooltip"
            values={{
              language,
            }}
          />
        ),
      };
    });
  };

  const content = (
    <section className={classes.content}>
      <List id={`searchable-input-${searchFieldName}`}>
        {filterUsers()?.map((item) => (
          <MobileListItem
            action={setUser}
            adornment={
              <div className={classNames('adornment', classes.select)}>
                <Typography variant="button">
                  <FormattedMessage id="Ui.select" />
                </Typography>
              </div>
            }
            ariaLabel={getAriaLabel(item)}
            badges={showStaffLanguages ? getBadges(item) : null}
            id={item.id}
            imageUrl={item.profilePhotoUrl}
            key={item.id}
            primary={item.name}
            secondary={item.jobTitle}
          />
        ))}
      </List>
      {hasPreferredUser ? (
        <div className={classes.seeAllStaff}>
          <Button
            fullWidth={false}
            onClick={showAllUsers}
            variant="smallOutlined"
          >
            <FormattedMessage id="UserPreference.see_all_staff" />
          </Button>
        </div>
      ) : null}
    </section>
  );

  return (
    <section className={classes.root} data-testid="specific-date-time-mobile">
      {waitingForUsers ? (
        <div className={classNames(classes.content, classes.loading)}>
          <CircularProgress />
        </div>
      ) : users?.length ? (
        <>
          {header}
          {supportedLanguages?.length > 1 && (
            <section
              className={classNames(classes.section, classes.preferences)}
            >
              <SpecificUserLanguagePreferences
                languages={supportedLanguages}
                setPreferences={changeLocalePreferences}
              />
            </section>
          )}
          {content}
        </>
      ) : null}
    </section>
  );
};

Users.propTypes = {
  initialStartDate: SpacetimeShape,
  loading: PropTypes.bool,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string.isRequired,
  setInformation: PropTypes.func.isRequired,
};

Users.defaultProps = {
  initialStartDate: Dates.today(),
  loading: true,
};

export default Users;
