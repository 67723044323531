import React, { Fragment, useContext, useEffect, useReducer } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Badge from '../components/Badge';
import SearchableInput from '../components/forms/SearchableInput';
import Checkmark from '../components/icons/Checkmark';
import List from '../components/List';
import MobileCard from '../components/mobile/Card';
import MobileListItem from '../components/mobile/ListItem';
import TimezoneLabel from '../components/TimezoneLabel';
import { HeaderContext } from '../contexts/HeaderContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { TimezonesContext } from '../contexts/TimezonesContext';
import Item from '../helpers/Item';
import HistoryShape from '../shapes/HistoryShape';

const searchFieldName = 'searchable-timezones';

const useStyles = createUseStyles((theme) => ({
  badge: {
    alignSelf: 'center',
    whiteSpace: 'nowrap',
  },
  header: {
    background: theme.palette.white,
    boxShadow: theme.shadows.xs,
    padding: '1rem 1.25rem',
    zIndex: 900,
  },
  icon: {
    alignSelf: 'center',
    fill: theme.palette.neutral[400],
  },
  section: {
    overflow: 'hidden',
    overflowY: 'auto',
  },
  separator: {
    marginTop: '0.25rem',
  },
  ariaSelected: {
    position: 'absolute',
    left: '-99999px',
    height: '1px',
    width: '1px',
    overflow: 'hidden',
  },
}));

const Timezones = ({ history }) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const timezones = useContext(TimezonesContext);
  const [, setHeader] = useContext(HeaderContext);
  const [timezone, setTimezone] = useContext(TimezoneContext);
  const [filter, setFilter] = useReducer((state, newState) => newState, '');
  const isNotIOS = !/iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    setHeader({
      action: history.goBack,
      title: <FormattedMessage id="Pages.timezones" />,
    });
  }, [history.goBack, setHeader]);

  const filterTimezones = ({ currentTarget: { value } }) => {
    setFilter(value.toLowerCase().trim());
  };

  const selectTimezone = ({ currentTarget: { dataset } }) => {
    setTimezone(dataset.id);
  };

  // We are temporarily ignoring the destructuring-assignment rule explicitly.
  // There is a bug that was solved in a newer version of this plugin which
  // we will eventually be able to upgrade to once we can move off of
  // the current version of NodeJS in use.
  //
  // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
  //
  // eslint-disable-next-line react/destructuring-assignment
  const zone = timezones[timezone];
  const checkmark = (
    <Checkmark
      altText={intl.formatMessage({ id: 'Svg.alt_text.timezone_selected' })}
      ariaHidden={false}
      classes={{ root: classes.icon }}
    />
  );
  const badge = (
    <div className={classes.badge}>
      <Badge component="div" variant="subtitle">
        {intl.formatMessage({ id: 'Ui.current_selection' })}
      </Badge>
    </div>
  );

  return (
    <>
      <header className={classes.header}>
        <SearchableInput name={searchFieldName} onChange={filterTimezones} />
      </header>
      <section className={classes.section}>
        {zone ? (
          <List>
            <MobileCard
              adornment={badge}
              id="current-timezone-selection"
              primary={zone.name}
              secondary={<TimezoneLabel timezone={zone} />}
            />
            {isNotIOS ? (
              <li aria-live="polite" className={classes.ariaSelected}>
                {`${zone.name} ${intl.formatMessage({ id: 'Ui.selected' })}`}
              </li>
            ) : null}
          </List>
        ) : null}
        <div className={classes.separator}>
          <List id={`searchable-input-${searchFieldName}`}>
            {Item.map(timezones, (key) => {
              // We are temporarily ignoring the destructuring-assignment rule explicitly.
              // There is a bug that was solved in a newer version of this plugin which
              // we will eventually be able to upgrade to once we can move off of
              // the current version of NodeJS in use.
              //
              // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
              //
              // eslint-disable-next-line react/destructuring-assignment
              const tz = timezones[key];
              const found =
                tz.name.toLowerCase().includes(filter) ||
                tz.abbreviation.toLowerCase().includes(filter);

              if (found) {
                const adornment =
                  zone && tz.name === zone.name ? checkmark : null;

                return (
                  <MobileListItem
                    action={selectTimezone}
                    adornment={adornment}
                    id={tz.name}
                    key={tz.name}
                    primary={tz.name}
                    secondary={<TimezoneLabel timezone={tz} />}
                  />
                );
              }

              return null;
            })}
          </List>
        </div>
      </section>
    </>
  );
};

Timezones.propTypes = {
  history: HistoryShape.isRequired,
};

export default Timezones;
