import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import GeneralError from '../../components/GeneralError';
import LoadingState from '../../components/LoadingState';
import ManageAppointmentDetails from '../../components/ManageAppointmentDetails';
import { HEADER_HEIGHT } from '../../constants';
import { AppointmentContext } from '../../contexts/AppointmentContext';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[200],
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    minHeight: `calc(100vh - ${HEADER_HEIGHT.MOBILE})`,
    margin: '0 0.5rem',
  },
  transparent: {
    background: 'transparent',
    paddingTop: '2rem',
  },
}));

const ManageAppointment = ({ confirmation, loading }) => {
  const classes = useStyles({ theme: useTheme() });

  const [appointment] = useContext(AppointmentContext);

  if (loading) {
    return <LoadingState />;
  }

  if (!appointment.id && appointment.reloading) {
    return null;
  }

  if (!appointment.id) {
    return <GeneralError />;
  }

  return (
    <div
      className={classNames(classes.root, classes.transparent)}
      data-testid="manage-mobile"
    >
      <ManageAppointmentDetails confirmation={confirmation} />
    </div>
  );
};

ManageAppointment.propTypes = {
  confirmation: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ManageAppointment;
