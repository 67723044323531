import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Item from '../helpers/Item';
import DropDownArrow from './icons/DropDownArrow';
import SelectableListItem from './SelectableListItem';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  anchor: {
    alignItems: 'center',
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    padding: '0.5rem 0.75rem',
    textDecoration: 'none',
    '&:focus': {
      background: theme.palette.neutral[100],
    },
    '&:hover': {
      background: theme.palette.neutral[100],
    },
  },
  hidden: {
    display: 'none',
  },
  listText: {
    width: '14rem',
  },
  mobileListText: {
    width: '10rem',
  },
  mobilePopperContent: {
    position: 'absolute',
    right: ({ isLeftAligned }) => (isLeftAligned ? '' : '0'),
    left: ({ isLeftAligned }) => (isLeftAligned ? '0' : ''),
    top: '100%',
    width: '14rem !important',
    zIndex: 2,
  },
  mobileRoot: {
    paddingRight: 0,
    marginRight: '1rem',
  },
  open: {
    display: 'block',
  },
  popperContent: {
    boxShadow: theme.shadows.hover,
    background: theme.palette.white,
    borderRadius: '0.25rem',
    fontFamily: theme.fontFamilies.sansSerif,
    position: 'absolute',
    right: ({ isLeftAligned }) => (isLeftAligned ? '' : '0'),
    left: ({ isLeftAligned }) => (isLeftAligned ? '0' : ''),
    top: '100%',
    width: '18rem',
    zIndex: 2,
  },
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    width: '7rem',
    borderRadius: theme.borderRadius.default,
    fontFamily: theme.fontFamilies.sansSerif,
    fontSize: theme.textSizes.base,
  },
}));

const CategoryPicker = ({
  categories,
  category,
  isLeftAligned,
  selectCategory,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme(), isLeftAligned });
  const mode = useContext(ViewModeContext);
  const isMobile = mode === MOBILE;

  return (
    <Listbox
      as="div"
      data-testid="category-picker"
      onChange={selectCategory}
      value={category ? category.id : null}
    >
      {({ open }) => (
        <div
          className={classNames(classes.root, isMobile && classes.mobileRoot)}
        >
          <Listbox.Button>
            <div className={classes.anchor}>
              <Typography component="p" primary variant="caption1">
                <FormattedMessage id="Service.category_title" />
              </Typography>
              <DropDownArrow
                altText={intl.formatMessage({
                  id: 'Svg.alt_text.select_category',
                })}
              />
            </div>
          </Listbox.Button>
          <Listbox.Options>
            <div
              className={classNames(
                open ? classes.open : classes.hidden,
                classes.popperContent,
                isMobile && classes.mobilePopperContent,
              )}
            >
              <SelectableListItem
                checked={category === null}
                title={<FormattedMessage id="Service.all_categories_title" />}
              />
              {Item.map(categories, (item) => (
                <SelectableListItem
                  checked={category ? item.id === category.id : null}
                  key={item.id}
                  title={
                    <div
                      className={
                        isMobile ? classes.mobileListText : classes.listText
                      }
                    >
                      <Typography
                        classes={{ root: classes.name }}
                        variant="subtitle"
                      >
                        {item.name}
                      </Typography>
                    </div>
                  }
                  value={item.id}
                />
              ))}
            </div>
          </Listbox.Options>
        </div>
      )}
    </Listbox>
  );
};

CategoryPicker.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  category: PropTypes.shape({ id: PropTypes.string }),
  isLeftAligned: PropTypes.bool,
  selectCategory: PropTypes.func.isRequired,
};

CategoryPicker.defaultProps = {
  categories: [],
  category: null,
  isLeftAligned: false,
};

export default CategoryPicker;
