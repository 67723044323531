import React from 'react';

const Timer = (props) => (
  <svg
    {...props}
    data-name="24px (2)"
    height="15.998"
    id="_24px_2_"
    viewBox="0 0 15.998 15.998"
    width="15.998"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0,0H16V16H0Z" data-name="Path 577" fill="none" id="Path_577" />
    <path
      d="M11,1H7V2.333h4ZM8.333,9.666H9.666v-4H8.333Zm5.353-4.406.947-.947a7.365,7.365,0,0,0-.94-.94l-.947.947a6,6,0,1,0,.94.94ZM9,13.665A4.666,4.666,0,1,1,13.665,9,4.663,4.663,0,0,1,9,13.665Z"
      data-name="Path 578"
      fill="#616161"
      id="Path_578"
      transform="translate(-1 -0.333)"
    />
  </svg>
);

export default Timer;
