import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { debounce } from 'throttle-debounce';
import { useGoogleMapsApi } from '../../shared/contexts/GoogleMapsApiContext';
import LocationList from '../components/LocationList';
import LocationListCard from '../components/LocationListCard';
import MapContainer from '../components/MapContainer';
import { SHORTCUTS, STEPS, PAGES } from '../constants';
import { LocaleContext } from '../contexts/LocaleContext';
import { PositionContext } from '../contexts/PositionContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { DESKTOP, TABLET, ViewModeContext } from '../contexts/ViewModeContext';
import Api from '../helpers/Api';
import useLocalQueue from '../helpers/LocalQueue';
import Math from '../helpers/Math';
import Resources from '../helpers/Resources';
import Shortcuts from '../helpers/Shortcuts';
import Tracker from '../helpers/Tracker';
import { LIST_CAPACITY, RECENT_LOCATIONS_KEY } from './Details';

const useStyles = createUseStyles((theme) => ({
  root: {
    height: '100%',
    position: 'relative',
  },
  mobile: {
    padding: 0,
    background: theme.palette.white,
  },
  mobileSelected: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    position: 'absolute',
    bottom: '2.5rem',
    left: '1rem',
    right: '1rem',
  },
  search: {
    background: theme.palette.white,
    border: '0 none',
    borderRadius: theme.borderRadius.rounded,
    boxShadow: theme.shadows.default,
    color: theme.palette.primary[400],
    left: '50%',
    padding: '0.375rem 1rem',
    position: 'absolute',
    top: '1.25rem',
    transform: 'translateX(100%)',
    '&:focus': {
      boxShadow: theme.shadows.input,
    },
  },
  searchMobile: {
    top: '11rem',
    transform: 'translateX(-50%)',
  },
}));

const Location = ({ lockStep, next, previous, previousStep }) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });
  const mode = useContext(ViewModeContext);
  const [locale] = useContext(LocaleContext);
  const { getPosition } = useContext(PositionContext);
  const [selections, setSelections] = useContext(SelectionContext);
  const { firstStep } = useContext(SettingsContext);
  const { coordinates, onFetched, valid } = useContext(PositionContext);
  const [, setTimezone, useDetectedTimezone] = useContext(TimezoneContext);
  const { locationSearchRadius } = useContext(SettingsContext);
  const [suggestions, setSuggestions] = useReducer(
    (state, newState) => newState,
    [],
  );
  const [
    {
      loading,
      locations,
      locationIds,
      regionMissing,
      shouldFitBounds,
      visibleLocations,
      visibleLocationIds,
    },
    setLocations,
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loading: true,
    locations: null,
    locationIds: [],
    regionMissing: false,
    shouldFitBounds: true,
    visibleLocations: [],
    visibleLocationIds: [],
  });
  const [regions, setRegions] = useReducer((state, newState) => newState, []);
  const [chosen, setChosen] = useReducer((state, newState) => newState, {});
  const [focused, setFocused] = useState({});
  const [idle, setIdle] = useState(false);
  const [mapCoordinates, setMapCoordinates] = useState(null);
  const [{ detailsOpen, showMap }, setMapState] = useState({
    detailsOpen: false,
    showMap: false,
  });
  const [searching, setSearching] = useState(false);
  const [shouldRedraw, setShouldRedraw] = useState(false);
  const [showPositionError, setShowPositionError] = useState(false);
  const features = window.state.features;

  const { all: getRecentLocationIds } = useLocalQueue(
    RECENT_LOCATIONS_KEY,
    LIST_CAPACITY,
  );
  const [recentLocationIds] = useState(getRecentLocationIds());

  const { getDetails, getSuggestions } = useGoogleMapsApi();

  const initial = useRef(true);
  const searchResults = useRef(false);
  const searchInputRef = useRef(null);
  const allowOtherLocations = selections.settings?.preferred_location;

  const category = allowOtherLocations
    ? null
    : Shortcuts.get(SHORTCUTS.LOCATION_CATEGORY);
  const desktopOrTablet = mode === DESKTOP || mode === TABLET;
  const setMarkers = () => {};
  const toggleMap = (initDetailsOpen = false) => {
    setMapState((prevState) => ({
      detailsOpen: initDetailsOpen === true,
      showMap: !prevState.showMap,
    }));
  };
  const getUpdatedLocationState = ({
    locations,
    regionMissing = false,
    shouldFilterVisible = false,
    shouldFitBounds = true,
  }) => {
    const newVisibleLocations = shouldFilterVisible
      ? locations?.filter(
          (location) =>
            location.physical === 0 ||
            mapCoordinates.bounds.contains(location.coordinates),
        )
      : locations;

    let state = {
      locations,
      regionMissing,
      shouldFitBounds,
      visibleLocations: newVisibleLocations,
    };

    const newVisibleLocationIds = newVisibleLocations.map(
      (location) => location.id,
    );

    const sameIds =
      newVisibleLocationIds.length === visibleLocationIds.length &&
      newVisibleLocationIds.every(
        (id, index) => id === visibleLocationIds[index],
      );
    if (!sameIds) {
      state = {
        ...state,
        locationIds: shouldFilterVisible
          ? locations?.map((location) => location.id)
          : newVisibleLocationIds,
        visibleLocationIds: newVisibleLocationIds,
      };
    }

    return state;
  };
  const fetchLocations = ({ region = null, initialLoad = false } = {}) => {
    let details;
    const selectedRegion = region || selections.region;

    if (selectedRegion) {
      if (selectedRegion.country) {
        details = {
          country: selectedRegion.id,
          regionless: true,
        };
      } else {
        details = Shortcuts.details({ region: selectedRegion });
      }
    }

    setLocations({ loading: true });

    const getSortKey = () => {
      if (features?.allowCustomSortOrder) {
        return valid ? ['distance', 'sort_order'] : ['sort_order', 'name'];
      }

      return valid ? ['distance', 'name'] : ['name'];
    };

    Api.locale(locale)
      .locations()
      .all({
        filters: {
          assigned: true,
          category,
          coordinates: valid ? coordinates : null,
          details,
          meetingMethod: selections.meetingMethod,
          service: selections.service,
          serviceCategory: Shortcuts.get(SHORTCUTS.CATEGORY),
          settings: selections.settings,
          user: selections.user,
          userCategory: selections.userCategory,
        },
        sortBy: getSortKey(),
      })
      .then((data) => {
        if (initialLoad && desktopOrTablet) {
          resetCount();
        }

        if (data.length) {
          const locations = data.map(Resources.formatV3Location);

          setLocations({
            ...getUpdatedLocationState({
              locations,
            }),
            loading: false,
          });
        }
      });
  };
  const fetchRecentLocations = ({ initialLoad = false } = {}) => {
    Api.locale(locale)
      .locations()
      .in({
        filters: {
          assigned: true,
          category,
          id: recentLocationIds,
          meetingMethod: selections.meetingMethod,
          service: selections.service,
          settings: selections.settings,
          user: selections.user,
          userCategory: selections.userCategory,
        },
        sortBy: features?.allowCustomSortOrder
          ? ['sort_order', 'name']
          : ['name'],
      })
      .then((data) => {
        if (initialLoad && desktopOrTablet) {
          resetCount();
        }

        if (data.length) {
          const locations = data.map(Resources.formatV3Location);

          setLocations({
            ...getUpdatedLocationState({
              locations,
            }),
            loading: false,
          });
        } else {
          // don't show recent locations if no results returned
          setSelections({ showRecentLocations: false });
        }
      });
  };
  const clearSearchBar = () => {
    searchResults.current = false;

    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  };

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'Steps.location' });
    Tracker.view(
      PAGES.LOCATION,
      Tracker.getTrackingData(PAGES.LOCATION, firstStep, selections),
    );
    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chosen.id !== undefined) {
      setShowPositionError(false);
    }
  }, [chosen.id]);

  useEffect(() => {
    if (regionMissing && locations?.length) {
      const region = regions.find(
        (region) => region.id === locations[0].region,
      );

      setLocations({ regionMissing: false });
      setSelections({ region });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, regionMissing]);

  useEffect(() => {
    // show recent locations list on load if there are any in storage
    if (
      recentLocationIds.length > 0 &&
      selections.showRecentLocations &&
      !searching &&
      !locations
    ) {
      fetchRecentLocations();
    } else if (
      // fetch locations when region is selected
      selections.region &&
      !selections.showRecentLocations &&
      !locations &&
      !searching
    ) {
      fetchLocations({ region: selections.region });
      // hide show all locations button
      setSelections({ showAllLocations: false });
    } else if (
      // fetch locations after region selected and no recent locations
      selections.region &&
      !locations &&
      !searching &&
      !recentLocationIds.length > 0
    ) {
      fetchLocations();
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, searching, selections.region, selections.showRecentLocations]);

  useEffect(() => {
    if (regions.length === 0) {
      if (initial.current) {
        initial.current = false;
      } else {
        return;
      }

      Api.locale(locale)
        .locations()
        .regions({
          category,
          method: selections.meetingMethod,
          service: selections.service,
          settings: selections.settings,
          user: selections.user,
          userCategory: selections.userCategory,
          serviceCategory: Shortcuts.get(SHORTCUTS.CATEGORY),
        })
        .then((data) => {
          if (data.length === 1) {
            setSelections({ region: data[0] });
          } else {
            setLocations({ loading: false });
          }

          setRegions(data);
        });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, locations, regions, selections.region, selections.service]);

  useEffect(() => {
    if (!selections.region && !selections.location) {
      clearSearchBar();

      setLocations({
        locations: null,
        locationIds: [],
        regionMissing: false,
        shouldFitBounds: true,
        visibleLocations: [],
        visibleLocationIds: [],
      });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, selections.region]);

  useEffect(() => {
    if (valid) {
      setSearching(false);
      clearSearchBar();

      setLocations({ loading: true });

      Api.locale(locale)
        .locations()
        .all({
          filters: {
            assigned: true,
            category,
            coordinates,
            details: Shortcuts.details(),
            meetingMethod: selections.meetingMethod,
            radius: Shortcuts.get(SHORTCUTS.WITHIN, locationSearchRadius),
            service: selections.service,
            settings: selections.settings,
            user: selections.user,
            userCategory: selections.userCategory,
          },
          sortBy: features?.allowCustomSortOrder
            ? ['distance', 'sort_order']
            : ['distance', 'name'],
        })
        .then((data) => {
          if (data.length === 0) {
            setSearching(true);
            setLocations({
              ...getUpdatedLocationState({ locations: data }),
              loading: false,
            });
            onFetched();
          } else {
            const locations = data.map(Resources.formatV3Location);

            setShouldRedraw(true); // trigger redraw after clustering
            setLocations({
              ...getUpdatedLocationState({ locations, regionMissing: true }),
              loading: false,
            });
            onFetched();

            setTimeout(() => {
              setChosen(locations[0]);
            }, 150);
          }
        });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, valid]);

  useEffect(() => {
    if (locations?.length && mapCoordinates && (desktopOrTablet || showMap)) {
      const state = getUpdatedLocationState({
        locations,
        shouldFilterVisible: true,
        shouldFitBounds: false,
      });

      if (
        chosen &&
        !state.visibleLocations.find((location) => location.id === chosen.id)
      ) {
        setChosen({});
      }

      setLocations(state);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapCoordinates]);

  // In order to introduce linting to all JS projects without introducing
  // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
  //
  // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetLocations = useCallback(
    debounce(500, (value) => {
      if (value === '' && searchResults.current) {
        searchResults.current = false;

        if (selections.region) {
          fetchLocations();
        }
      }
    }),
    [],
  );

  // In order to introduce linting to all JS projects without introducing
  // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
  //
  // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSuggestions = useCallback(
    debounce(500, (value) => {
      setShowPositionError(false);
      if (value === '') {
        setSuggestions([]);
      } else if (value) {
        getSuggestions(value, locale)
          .then((data) => {
            setSuggestions(data);
          })
          .catch(() => {
            //
          });
      }
    }),
    [getSuggestions, locale],
  );

  const redraw = (clusterer) => {
    if (shouldRedraw) {
      setShouldRedraw(false);
      clusterer.redraw();
    }
  };

  const selectSuggestion = ({ currentTarget: { dataset } }) => {
    const { identifier } = dataset;

    setChosen({});
    setSuggestions([]);
    setSearching(false);

    if (identifier === 'near-me') {
      getPosition();
    } else {
      searchResults.current = true;

      getDetails(identifier).then(({ latitude, longitude }) => {
        Api.locale(locale)
          .locations()
          .all({
            filters: {
              assigned: true,
              category,
              coordinates: {
                latitude,
                longitude,
              },
              details: Shortcuts.details(),
              meetingMethod: selections.meetingMethod,
              radius: Shortcuts.get(SHORTCUTS.WITHIN, locationSearchRadius),
              service: selections.service,
              settings: selections.settings,
              user: selections.user,
              userCategory: selections.userCategory,
            },
            sortBy: features?.allowCustomSortOrder
              ? ['distance', 'sort_order']
              : ['distance', 'name'],
          })
          .then((data) => {
            setSearching(true);

            const locations = data.map(Resources.formatV3Location);
            setLocations({
              ...getUpdatedLocationState({ locations, regionMissing: true }),
              loading: false,
            });
          });
      });
    }
  };

  const selectLocation = (location, lockCurrentStep = false) => {
    if (lockCurrentStep && lockStep) {
      location.locked = true;
      lockStep(STEPS.LOCATION);
    }

    const selections = { location, preferredLocation: true };

    setShouldRedraw(true); // trigger redraw after clustering
    setSelections(selections);

    if (location.physical) {
      setTimezone(location.timezone);
    } else {
      // We are explicilty ignoring the react-hooks/rules-of-hooks as this is
      // a false positive based on the naming of the function being called.
      //
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useDetectedTimezone();
    }

    next(selections);
  };

  const selectRegion = (region) => {
    setLocations({ locations: null });
    setSelections({ region });
  };

  const viewLocationOnMap = (location) => {
    setChosen(location);
    toggleMap(true);
  };

  const handleClickMarker = (location) => {
    setChosen(location);
    setFocused({});
    setMapState((prevState) => ({ ...prevState, detailsOpen: false }));
  };

  const addNearMeOption = () => {
    setSuggestions([
      {
        id: 'near-me',
        type: 'location-suggestions',
        attributes: {
          name: intl.formatMessage({ id: 'Ui.near_me' }),
        },
      },
    ]);
  };

  // When viewing the map on a mobile view, the first time you switch
  // to the map, it fires a fitBounds which causes the idle event
  // to fire early. Similarly, on desktop the idle event fires
  // upon load. To compensate for that, we are using this
  // counting mechanism to determine if we've actually
  // idled after the initial load.
  //
  // On desktop, when there is a single region available or region shortcut
  // is provided, we need to skip the next idle event after that to make sure
  // the map acts the same way as on initial load.
  let count = 0;
  const resetCount = useCallback(() => {
    // The way this function works is counter-intuitive to how
    // React works. This should be refactored to either use
    // a ref, or something completely different.
    //
    // TODO: Reconsider how this is implemented and possibly change it.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    count = 0;
  }, []);
  const onIdle = useCallback((coordinates) => {
    if (desktopOrTablet) {
      if (count < 1) {
        count++;
        return;
      }

      setIdle(true);
      setMapCoordinates(coordinates);
    } else {
      if (count < 2) {
        count++;
        return;
      }

      setIdle(true);
      setMapCoordinates(coordinates);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayAllLocations = () => {
    initial.current = true;
    if (recentLocationIds.length > 0) {
      // need to wipe out locations state instead now with new Locations.js method
      // so it knows to refetch locations
      setLocations({ locations: null });
      setSelections({
        region: selections.region,
        showAllLocations: true,
        showRecentLocations: false,
      });
    }

    setRegions([]);
    setSelections({ region: null, showAllLocations: true });
  };

  const searchThisArea = () => {
    const [distance] = Math.distance(
      mapCoordinates.center,
      mapCoordinates.corner,
    );

    setChosen({});
    setSearching(false);
    clearSearchBar();

    Api.locale(locale)
      .locations()
      .all({
        filters: {
          assigned: true,
          category,
          coordinates: mapCoordinates.center,
          details: Shortcuts.details(),
          meetingMethod: selections.meetingMethod,
          radius: distance,
          service: selections.service,
          serviceCategory: Shortcuts.get(SHORTCUTS.CATEGORY),
          settings: selections.settings,
          user: selections.user,
          userCategory: selections.userCategory,
        },
        sortBy: features?.allowCustomSortOrder
          ? ['distance', 'sort_order']
          : ['distance', 'name'],
      })
      .then((data) => {
        setShowPositionError(false);
        setShouldRedraw(true); // trigger redraw after clustering
        setSearching(true);
        setIdle(false);

        setLocations({
          ...getUpdatedLocationState({
            locations: data.map(Resources.formatV3Location),
            regionMissing: true,
            shouldFilterVisible: true,
            shouldFitBounds: false,
          }),
          loading: false,
        });
      });
  };

  return (
    <div
      className={classNames(
        classes.root,
        desktopOrTablet ? '' : classes.mobile,
      )}
    >
      <MapContainer
        focused={focused}
        locationIds={locationIds}
        locations={locations}
        onClickMarker={handleClickMarker}
        onCreateMarker={setMarkers}
        onIdle={onIdle}
        redraw={redraw}
        selected={chosen}
        setFocused={setFocused}
        shouldCluster={locations?.length > 0}
        shouldFitBounds={shouldFitBounds}
        showMap={showMap}
      />
      <LocationList
        addNearMeOption={addNearMeOption}
        chosen={chosen}
        displayAllLocations={displayAllLocations}
        fetchSuggestions={fetchSuggestions}
        focused={focused}
        locationIds={visibleLocationIds}
        locations={visibleLocations}
        locationsLoading={loading}
        next={next}
        previous={previous}
        previousStep={previousStep}
        recentlyBookedLocations={recentLocationIds}
        regions={regions}
        resetLocations={resetLocations}
        searching={searching}
        searchInputRef={searchInputRef}
        selectLocation={selectLocation}
        selectRegion={selectRegion}
        selectSuggestion={selectSuggestion}
        setChosen={setChosen}
        setFocused={setFocused}
        setSearching={setSearching}
        setShowPositionError={setShowPositionError}
        setSuggestions={setSuggestions}
        showMap={showMap}
        showPositionError={showPositionError}
        suggestions={suggestions}
        toggleMap={toggleMap}
        viewOnMap={viewLocationOnMap}
      />
      {(idle && desktopOrTablet) || (idle && !desktopOrTablet && showMap) ? (
        <button
          className={classNames(
            classes.search,
            !desktopOrTablet && classes.searchMobile,
          )}
          onClick={searchThisArea}
          type="button"
        >
          <FormattedMessage id="Location.search_area" />
        </button>
      ) : null}
      {chosen.id !== undefined && !desktopOrTablet && showMap ? (
        <div className={classes.mobileSelected}>
          <LocationListCard
            initOpen={detailsOpen}
            isMapView
            key={chosen.id}
            location={chosen}
            selectLocation={selectLocation}
            setFocused={setFocused}
          />
        </div>
      ) : null}
    </div>
  );
};

Location.propTypes = {
  lockStep: PropTypes.func,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
};

Location.defaultProps = {
  lockStep: null,
  previousStep: null,
};

export default Location;
