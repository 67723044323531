import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Dates from '../../shared/helpers/Dates';
import BackChevron from '../../shared/icons/BackChevron';
import ForwardChevron from '../../shared/icons/ForwardChevron';
import Item from '../helpers/Item';
import Slots from '../helpers/Slots';
import SpacetimeShape from '../shapes/SpacetimeShape';
import CircularProgress from './CircularProgress';
import NoTimesAvailable from './NoTimesAvailable';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  button: {
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    borderRadius: theme.borderRadius.full,
    cursor: 'pointer',
    display: 'flex',
    margin: 0,
    padding: 0,
    '& svg': {
      fill: theme.palette.neutral[400],
    },
    '&[disabled]': {
      cursor: 'default',
      cursorEvents: 'none',
      opacity: 0,
    },
    '&:focus': {
      boxShadow: theme.shadows.input,
      '& svg': {
        fill: theme.palette.black,
      },
    },
  },
  dates: {
    borderCollapse: 'separate',
    borderSpacing: '0.35em',
    position: 'relative',
    tableLayout: 'fixed',
    width: '100%',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '0.5rem',
  },
  item: {
    display: 'table-cell',
    fontWeight: theme.fontWeights.normal,
    textAlign: 'center',
  },
  clickable: {
    appearance: 'none',
    background: 'transparent',
    border: '1px solid transparent',
    borderRadius: theme.borderRadius.full,
    cursor: 'pointer',
    display: 'block',
    fontWeight: theme.fontWeights.normal,
    height: '2.5em',
    margin: 0,
    padding: 0,
    width: '2.5em',
  },
  selectable: {
    '&:focus, &:hover': {
      background: theme.palette.secondary[400],
      border: `1px solid ${theme.palette.secondary[400]}`,
      color: theme.palette.white,
    },
  },
  active: {
    color: theme.palette.secondary[400],
  },
  inactive: {
    color: theme.palette.neutral[350],
    '&$circle': {
      color: theme.palette.white,
    },
  },
  circle: {
    borderColor: theme.palette.neutral[200],
    borderRadius: theme.borderRadius.full,
  },
  row: {
    display: 'table-row',
  },
  today: {
    position: 'relative',
    '&:before': {
      background: theme.palette.secondary[400],
      borderRadius: theme.borderRadius.full,
      content: '""',
      display: 'block',
      height: '0.25rem',
      left: '50%',
      position: 'absolute',
      top: '85%',
      transform: 'translate(-50%,-50%)',
      width: '0.25rem',
    },
    '&:hover': {
      '&:before': {
        background: theme.palette.white,
      },
    },
    '&$selected:before': {
      background: theme.palette.white,
    },
  },
  selected: {
    background: theme.palette.secondary[400],
    borderColor: theme.palette.secondary[400],
    '&$active': {
      color: theme.palette.white,
    },
  },
}));

const MonthlyDatePicker = ({
  end,
  loading,
  onClickDate,
  onClickNext,
  onClickPrevious,
  selected,
  slots,
  start,
  isLoadingSlots,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const FOCUS = 'focusButton';
  const today = Dates.today();
  const weekdays = Dates.weekdays();
  const month = Dates.toMonth(start);
  const dates = Dates.calendarize(start, end);
  const disabled = today.isSame(start, 'month');
  const handleClick = ({ currentTarget: { dataset } }) => {
    onClickDate(Dates.parse(dataset.day));
  };
  const notAvailable = Slots.available(slots, dates) === false;

  useEffect(() => {
    document.getElementsByClassName(FOCUS)[0]?.focus();
  }, [selected]);

  return (
    <section
      aria-label={Dates.toMonthYear(start)}
      className={classes.root}
      data-testid="monthly-date-picker"
    >
      <header className={classes.header}>
        <button
          aria-disabled={disabled}
          aria-hidden={disabled}
          aria-label={intl.formatMessage({
            id: 'MonthlyDatePicker.previous_month',
          })}
          className={classes.button}
          data-testid="back-button"
          disabled={disabled}
          onClick={onClickPrevious}
          title={intl.formatMessage({ id: 'MonthlyDatePicker.previous_month' })}
          type="button"
        >
          <BackChevron
            altText={intl.formatMessage({ id: 'Svg.alt_text.previous_month' })}
          />
        </button>
        <Typography component="div" role="status" variant="title">
          <div className="flex flex-row items-center ml-7 ">
            {Dates.toMonthYear(start)}
            <div className={`${!isLoadingSlots ? 'invisible' : ''} ml-4`}>
              <CircularProgress size={15} />
            </div>
          </div>
        </Typography>
        <button
          aria-label={intl.formatMessage({
            id: 'MonthlyDatePicker.next_month',
          })}
          className={classes.button}
          data-testid="forward-button"
          onClick={onClickNext}
          title={intl.formatMessage({ id: 'MonthlyDatePicker.next_month' })}
          type="button"
        >
          <ForwardChevron
            altText={intl.formatMessage({ id: 'Svg.alt_text.next_month' })}
          />
        </button>
      </header>
      <section className={classes.dates}>
        {notAvailable && !loading ? (
          <NoTimesAvailable month={month} onClick={onClickNext} rounded />
        ) : null}
        <div className={classes.row}>
          {weekdays.map((weekday) => (
            <Typography
              aria-label={Dates.dayOfWeek(weekdays.indexOf(weekday))}
              classes={{ root: classes.item }}
              component="div"
              key={weekday}
              title={Dates.dayOfWeek(weekdays.indexOf(weekday))}
              variant="caption1"
            >
              {weekday}
            </Typography>
          ))}
        </div>
        {dates.map((week) => (
          <div className={classes.row} key={week[0].format()}>
            {week.map((day) => {
              const date = day.format();
              const isToday = day.isSame(today, 'day');
              const isSelected = day.isSame(selected, 'day');
              const isSameMonth = day.isSame(start, 'month');
              const active =
                Item.has(slots, date) && Item.length(slots[date]) > 0;
              const optionalProps = {};
              if (isToday) {
                optionalProps['aria-current'] = 'date';
              }
              if (isSelected) {
                optionalProps['aria-pressed'] = 'true';
              } else {
                optionalProps['aria-pressed'] = 'false';
              }

              return (
                <label
                  className={classes.item}
                  htmlFor={date}
                  key={`${date}-${active}-${isSameMonth}-${isSelected}-${isToday}`}
                >
                  {isSameMonth ? (
                    <Typography
                      aria-disabled={!isSameMonth}
                      aria-label={`${day.dayName()} ${date}`}
                      classes={{
                        root: classNames(
                          classes.clickable,
                          isToday && classes.today,
                          isSameMonth && classes.selectable,
                          active && isSameMonth && classes.circle,
                          active ? classes.active : classes.inactive,
                          isSelected &&
                            isSameMonth &&
                            classNames(classes.selected, classes.circle),
                          isSelected && isSameMonth && FOCUS,
                        ),
                      }}
                      component="button"
                      data-day={day.format()}
                      disabled={!isSameMonth}
                      id={date}
                      // The aria-label including y/m helps give context to non-sighted users
                      // as what's seen by sighted users (ex. 14) makes sense with visual context
                      onClick={handleClick}
                      variant="title"
                      {...optionalProps}
                    >
                      {day.format('date')}
                    </Typography>
                  ) : null}
                </label>
              );
            })}
          </div>
        ))}
      </section>
    </section>
  );
};

MonthlyDatePicker.propTypes = {
  end: SpacetimeShape.isRequired,
  loading: PropTypes.bool,
  onClickDate: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  selected: SpacetimeShape.isRequired,
  slots: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({ end: SpacetimeShape, start: SpacetimeShape }),
    ),
  ).isRequired,
  start: SpacetimeShape.isRequired,
};

MonthlyDatePicker.defaultProps = {
  loading: false,
};

export default MonthlyDatePicker;
