import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { RadioButtonGroupProvider } from '../../contexts/RadioButtonGroupContext';
import Typography from '../Typography';

const useStyles = createUseStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
}));

function RadioButtonGroup({ children, label, name, onChange, value }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <fieldset className={classes.root}>
      {label ? (
        <legend>
          <Typography variant="label">{label}</Typography>
        </legend>
      ) : null}
      <RadioButtonGroupProvider name={name} onChange={onChange} value={value}>
        {children}
      </RadioButtonGroupProvider>
    </fieldset>
  );
}

RadioButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

RadioButtonGroup.defaultProps = {
  label: '',
};

export default RadioButtonGroup;
