const { isArray } = Array;
const { hasOwnProperty } = Object.prototype;

export default {
  /**
   * @param {object|array} item
   * @param {function} callback
   *
   * @returns {undefined}
   */
  each(item, callback) {
    if (isArray(item)) {
      item.forEach(callback);

      return;
    }

    Object.keys(item).forEach(callback);
  },

  /**
   * Recursively removes null or undefined properties from an object
   *
   * @param {Object} item
   * @returns {Object}
   */
  clean(item) {
    return Object.keys(item)
      .filter(
        (key) =>
          item[key] !== null && item[key] !== undefined && item[key] !== '',
      )
      .reduce(
        (obj, key) =>
          typeof item[key] === 'object'
            ? Object.assign(obj, { [key]: this.clean(item[key]) })
            : Object.assign(obj, { [key]: item[key] }),
        {},
      );
  },

  /**
   * Deep copy an object.
   *
   * @param {Object} obj
   *
   * @return {Object}
   */
  copy(obj) {
    return JSON.parse(JSON.stringify(obj));
  },

  defined(item, property) {
    return this.has(item, property) && item[property];
  },

  /**
   * Determine if an object is empty.
   *
   * @param {Object} obj
   *
   * @return {Boolean}
   */
  empty(obj) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  },

  /**
   * Performs a deep comparison between two values to determine if they are equivalent.
   *
   * @param {Object} a
   * @param {Object} b
   *
   * @return {Boolean}
   */
  equal(a, b) {
    if (a === b) {
      return true;
    }

    if (a instanceof Date && b instanceof Date) {
      return a.getTime() === b.getTime();
    }

    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
      return a === b;
    }

    if (a === null || a === undefined || b === null || b === undefined) {
      return false;
    }

    if (a.prototype !== b.prototype) {
      return false;
    }

    let keys = Object.keys(a);

    if (keys.length !== Object.keys(b).length) {
      return false;
    }

    return keys.every((k) => this.equal(a[k], b[k]));
  },

  /**
   * Determine if an object is filled.
   *
   * @param {Object} obj
   *
   * @return {Boolean}
   */
  filled(obj) {
    return obj && !this.empty(obj);
  },

  /**
   * @param {object|array} item
   * @param {*} callback
   *
   * @returns {*[]}
   */
  filter(item = {}, callback) {
    if (isArray(item)) {
      return item.filter(callback);
    }

    return Object.values(item).filter(callback);
  },

  first(item, fallback = null) {
    if (item && this.length(item) > 0) {
      return isArray(item) ? item[0] : item[Object.keys(item).shift()];
    }

    return fallback;
  },

  get(item, property, fallback = null) {
    return this.has(item, property) ? item[property] : fallback;
  },

  has(item = {}, property) {
    return hasOwnProperty.call(item, property);
  },

  length(item = {}) {
    return Object.keys(item).length;
  },

  map(item, callback) {
    return Object.keys(item).reduce((result, key) => {
      result[key] = callback(item[key]);

      return result;
    }, {});
  },

  /**
   * @param {object|array} item
   * @param {function} callback
   *
   * @returns {array}
   */
  mapToArray(item, callback) {
    if (isArray(item)) {
      return item.map(callback);
    }

    return Object.keys(item).map(callback);
  },

  /**
   * Returns tuple of elements that pass the given truth test and those that do not.
   *
   * @param {object|array} item
   * @param {function} callback
   *
   * @returns {array}
   */
  partition(item, callback) {
    const reducer = (accumulator, e) => {
      accumulator[callback(e) ? 0 : 1].push(e);

      return accumulator;
    };

    if (isArray(item)) {
      return item.reduce(reducer, [[], []]);
    }

    return Object.values(item).reduce(reducer, [[], []]);
  },

  /**
   * @param {Array<Number>} amounts
   * @param {Number} total
   *
   * @returns {number}
   */
  percentage(amounts, total) {
    return (amounts.reduce((a, b) => a + b, 0) / total) * 100;
  },

  /**
   * Retrieve a random element from an item. This function only supports
   * numerically indexed arrays at the moment. Support for objects
   * will need to be added if required.
   *
   * @param {array} items
   *
   * @returns {*}
   */
  random(items) {
    return items[Math.floor(Math.random() * items.length)];
  },

  /**
   * Resolve a nested object property, returning the specified fallback if the
   * property cannot be resolved.
   *
   * @param {object} item
   * @param {array|string} path
   * @param {*} fallback
   * @param {string} separator
   *
   * @returns {*|undefined}
   */
  resolve(item, path, fallback = undefined, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);

    const value = properties.reduce(
      (prev, curr) => (prev ? prev[curr] : undefined),
      item,
    );

    return value !== undefined ? value : fallback;
  },
};
