import * as Sentry from '@sentry/browser';

export default {
  exception(error, extras = {}, externalScope = null) {
    let sentryEventId = null;

    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setExtras(extras);
        sentryEventId = Sentry.captureException(error, externalScope);
      });
    }

    return sentryEventId;
  },
  message(message, extras = {}, externalScope = null) {
    let sentryEventId = null;

    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setExtras(extras);
        sentryEventId = Sentry.captureMessage(message, externalScope);
      });
    }

    return sentryEventId;
  },
};
