import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import TagManager from '../../shared/helpers/TagManager';
import DetailsForm from '../components/forms/DetailsForm';
import {
  DEFAULT_COUNTRY,
  MEETING_METHODS,
  QUESTION_TYPES,
  REQUIRED_VALUES,
  SHORTCUTS,
  VIRTUAL_MEETING_METHODS,
} from '../constants';
import { BookingContext } from '../contexts/BookingContext';
import { CountriesContext } from '../contexts/CountriesContext';
import { CountryContext } from '../contexts/CountryContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Open from '../helpers/api/Open';
import Forms from '../helpers/Forms';
import Item from '../helpers/Item';
import useLocalQueue from '../helpers/LocalQueue';
import Shortcuts from '../helpers/Shortcuts';
import Validator from '../helpers/Validator';
import DesktopDetails from './desktop/Details';
import MobileDetails from './mobile/Details';

export const LIST_CAPACITY = 20;
export const RECENT_LOCATIONS_KEY = 'recently-booked-location';

const Details = ({ currentStep, previous, previousStep, stepsCount }) => {
  const Api = Open.api();

  const intl = useIntl();
  const mode = useContext(ViewModeContext);
  const [locale] = useContext(LocaleContext);
  const countries = useContext(CountriesContext);
  const [country, , detectedCountry] = useContext(CountryContext);
  const { clientFields, walkinQuestions, callbackContactOptions } =
    useContext(SettingsContext);
  const { submitBooking } = useContext(BookingContext);
  const [selections, setSelections] = useContext(SelectionContext);

  const [{ loading, questions }, setInformation] = useReducer(
    (_state, newState) => newState,
    {
      loading: true,
      questions: [],
    },
  );

  const [{ formattedCountries }, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      formattedCountries: [],
    },
  );

  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState(null);

  const { enqueue: addRecentLocation } = useLocalQueue(
    RECENT_LOCATIONS_KEY,
    LIST_CAPACITY,
  );

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'Steps.details' });

    const url = window.location.pathname;
    const vendor = window.state.meta;

    TagManager.trackVirtualPage(url, TagManager.pageNames.FORM, vendor, locale);

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldSubmit && selections.attendee) {
      setShouldSubmit(false);

      submitBooking();
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSubmit, selections.attendee]);

  useEffect(() => {
    if (
      clientFields &&
      clientFields.location_information !== REQUIRED_VALUES.NA
    ) {
      setState({
        formattedCountries: Item.map(countries, (code) => ({
          value: code,
          // We are temporarily ignoring the destructuring-assignment rule explicitly.
          // There is a bug that was solved in a newer version of this plugin which
          // we will eventually be able to upgrade to once we can move off of
          // the current version of NodeJS in use.
          //
          // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
          //
          // eslint-disable-next-line react/destructuring-assignment
          text: countries[code],
        })),
      });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFields, countries]);

  useEffect(() => {
    Api.locale(locale)
      .questions()
      .when(selections.service, (api) => api.for(selections.service.id))
      .sortBy('sort_order')
      .get()
      .then(({ data: { data } }) => {
        let questionData = data.map((question) => ({
          id: question.id,
          ...question.attributes,
        }));

        if (selections.bookingWalkIn && !walkinQuestions) {
          questionData = [];
        }

        setInformation({
          loading: false,
          questions: questionData,
        });
      });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, selections.service]);

  const attendee = selections.attendee || {};

  const removeUnansweredCheckboxes = (values) => {
    const checkboxes = questions
      .filter((question) => question.type === QUESTION_TYPES.CHECKBOX)
      .map((question) => question.id);

    const valuesClone = JSON.parse(JSON.stringify(values));

    Item.each(checkboxes, (key) => {
      if (
        Item.filter(valuesClone.questions[key], (value) => value).length === 0
      ) {
        delete valuesClone.questions[key];
      }
    });

    return valuesClone;
  };

  const handleSubmit = (values) => {
    values = removeUnansweredCheckboxes(values);

    setSelections({
      attendee: Item.clean(values),
      unvalidatedAttendee: null,
    });

    setShouldSubmit(true);

    if (selections.location?.id) {
      addRecentLocation(selections.location.id);
    }
  };

  const addAdditionalAttendee = (values) => {
    const { index } = selectedAttendee;
    const { attendees } = selections;

    values = removeUnansweredCheckboxes(values);

    if (index >= 0) {
      setSelections({
        attendees: [
          ...attendees.slice(0, index),
          values,
          ...attendees.slice(index + 1),
        ],
      });
    } else {
      setSelections({ attendees: [...attendees, values] });
    }

    setSelectedAttendee(null);
  };

  const setUploads = (addedUploadedFiles, removedUploadedFiles) => {
    const { uploads } = selections;

    let updatedUploadedFiles = [];

    if (uploads !== null) {
      updatedUploadedFiles = uploads.filter(
        (uploadedFile) =>
          !removedUploadedFiles.some(({ key }) => uploadedFile.key === key),
      );
    }

    setSelections({
      uploads: [...updatedUploadedFiles, ...addedUploadedFiles],
    });
  };

  const filterClientFields = (clientFields, meetingMethod, primary = true) => {
    const filtered = {};

    Item.each(clientFields, (field) => {
      filtered[field] =
        clientFields[field] === REQUIRED_VALUES.REQUIRED
          ? REQUIRED_VALUES.REQUIRED
          : 'present';
    });

    filtered.mobile_phone =
      meetingMethod === MEETING_METHODS.PHONE_CALL
        ? REQUIRED_VALUES.REQUIRED
        : filtered.mobile_phone;

    if (!primary) {
      filtered.notes = undefined;
    }

    return filtered;
  };

  const validate = (values) => {
    const errors = Validator.details(
      values,
      filterClientFields(
        selections.bookingWalkIn ? callbackContactOptions : clientFields,
        selections.meetingMethod,
        false,
      ),
      questions,
    );

    const { attendee, attendees } = selections;

    const duplicate = Validator.duplicate(
      values,
      attendee,
      attendees,
      selectedAttendee !== null,
      selectedAttendee ? selectedAttendee.index : null,
    );

    return { ...duplicate, ...errors };
  };

  const validatePrimary = (values) => {
    setSelections({ unvalidatedAttendee: values });

    const errors = Validator.details(
      values,
      filterClientFields(
        selections.bookingWalkIn ? callbackContactOptions : clientFields,
        selections.meetingMethod,
      ),
      questions,
    );

    const { attendee, attendees } = selections;

    const duplicate = Validator.duplicate(
      values,
      attendee,
      attendees,
      selectedAttendee !== null,
      selectedAttendee ? selectedAttendee.index : null,
    );

    return { ...duplicate, ...errors };
  };

  const attendeeProps = {
    addAdditionalAttendee,
    countries: formattedCountries,
    country: country || detectedCountry,
    questions,
    setSelectedAttendee,
    setUploads,
  };

  const { unvalidatedAttendee } = selections;
  const unvalidatedAttendeeQuestions =
    unvalidatedAttendee && unvalidatedAttendee.questions;

  const form = (
    <Formik
      initialValues={{
        ...Forms.attendeeInitialValues(
          unvalidatedAttendee || attendee,
          questions,
        ),
        country: country || detectedCountry || DEFAULT_COUNTRY,
      }}
      onSubmit={handleSubmit}
      render={(props) => {
        const { values } = props;

        if (values.receiveSms) {
          values.receiveSms = Boolean(values.receiveSms);
        }

        if (clientFields.sms_notifications === REQUIRED_VALUES.REQUIRED) {
          values.receiveSms = true;
        }

        if (values.homePhone === undefined) {
          values.homePhone = '';
        }

        if (values.cellPhone === undefined) {
          values.cellPhone = '';
        }

        return (
          <DetailsForm
            {...props}
            {...attendeeProps}
            attendeeAnswers={unvalidatedAttendeeQuestions || attendee.questions}
          />
        );
      }}
      validate={validatePrimary}
      validateOnChange={false}
    />
  );

  const onBack = () => {
    const selection = {};

    if (
      selections.meetingMethod &&
      VIRTUAL_MEETING_METHODS.includes(selections.meetingMethod) &&
      !Shortcuts.exists(SHORTCUTS.LOCATION) &&
      !selections.preferredLocation &&
      selections.location !== null
    ) {
      selection.location = null;
    }

    // When we go back from the details page we should set
    // the engagement back to appointment
    if (selections.bookingWalkIn) {
      selection.bookingWalkIn = false;
    }

    if (Object.keys(selection).length > 0) {
      setSelections(selection);
      previous(selection);
    } else {
      previous();
    }
  };

  const props = {
    currentStep,
    form,
    loading,
    selectedAttendee,
    previous: onBack,
    previousStep,
    validate,
  };

  return mode === MOBILE ? (
    <MobileDetails {...props} {...attendeeProps} stepsCount={stepsCount} />
  ) : (
    <DesktopDetails {...props} {...attendeeProps} mode={mode} />
  );
};

Details.propTypes = {
  currentStep: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  stepsCount: PropTypes.number.isRequired,
};

Details.defaultProps = {
  previousStep: null,
};

export default Details;
