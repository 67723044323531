import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { SelectionContext } from '../contexts/SelectionContext';
import { SnackContext } from '../contexts/SnackContext';
import AttendeeListItem from './AttendeeListItem';
import Typography from './Typography';

const useStyles = createUseStyles({
  root: {
    marginBottom: '1.25rem',
  },
});

const AttendeeList = ({ setSelectedAttendee }) => {
  const classes = useStyles();

  const [{ attendees }, setSelections] = useContext(SelectionContext);
  const [, setSnacks] = useContext(SnackContext);
  const attendeesRef = useRef(attendees);

  const findIndex = (attendee) =>
    attendees.findIndex(
      (item) =>
        item.firstName === attendee.firstName &&
        item.lastName === attendee.lastName &&
        item.email === attendee.email,
    );

  const restoreAttendee = (attendee) => {
    setSelections({ attendees: [...attendeesRef.current, attendee] });
    attendeesRef.current = [...attendeesRef.current, attendee];
    setSnacks({
      handleUndo: () => {},
      snackMessage: null,
    });
  };

  const deleteAttendee = (attendee) => {
    const index = findIndex(attendee);
    const updatedAttendees = [
      ...attendees.slice(0, index),
      ...attendees.slice(index + 1),
    ];

    setSelections({ attendees: updatedAttendees });
    attendeesRef.current = updatedAttendees;
    setSnacks({
      handleUndo: () => restoreAttendee(attendee),
      snackMessage: (
        <Typography variant="subtitle" white>
          <FormattedMessage
            id="AttendeeListItem.deleted"
            values={{ name: `${attendee.firstName} ${attendee.lastName}` }}
          />
        </Typography>
      ),
    });
  };

  const editAttendee = (attendee) => {
    const index = findIndex(attendee);

    setSelectedAttendee({ ...attendee, index });
  };

  return (
    <ul className={classes.root}>
      {attendees.map((attendee) => {
        const { firstName, lastName, email } = attendee;

        return (
          <AttendeeListItem
            attendee={attendee}
            deleteAttendee={deleteAttendee}
            editAttendee={editAttendee}
            key={`${firstName}-${lastName}-${email}`}
          />
        );
      })}
    </ul>
  );
};

AttendeeList.propTypes = {
  setSelectedAttendee: PropTypes.func.isRequired,
};

export default AttendeeList;
