import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from './Button';
import Modal from './Modal';
import Typography from './Typography';

const TooLateToCancelModal = ({ open, toggleModal }) => (
  <Modal
    buttons={
      <Button fullWidth={false} onClick={toggleModal} type="button">
        <FormattedMessage id="Ui.ok" />
      </Button>
    }
    header={<FormattedMessage id="CancelModal.title" />}
    isOpen={open}
    onRequestClose={toggleModal}
  >
    <Typography component="p" variant="regular">
      <FormattedMessage id="TooLateToCancelModal.message" />
    </Typography>
  </Modal>
);

TooLateToCancelModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default TooLateToCancelModal;
