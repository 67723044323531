import { Typography } from '@coconut-software/ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import mode from '../../../shared/helpers/Mode';
import CheckmarkOutlined from '../../components/icons/CheckmarkOutlined';

const Confirmation = ({ optIn = false }) => {
  mode.handleKioskConfirmation(null, () => {}, true, location?.lang);

  return (
    <>
      <div className="mt-9 mx-6 mb-4 text-center text-green-500">
        <CheckmarkOutlined classes="w-20 m-auto" />
      </div>
      <div className="text-center mb-4">
        <Typography component="h2" variant="h5">
          <FormattedMessage id="CheckIn.confirmation.title" />
        </Typography>
      </div>
      <div className="text-center">
        <Typography component="h3" variant="body1">
          {optIn ? (
            <FormattedMessage id="CheckIn.confirmation.subtitle_notifiable" />
          ) : (
            <FormattedMessage id="CheckIn.confirmation.subtitle" />
          )}
        </Typography>
      </div>
    </>
  );
};

export default Confirmation;
