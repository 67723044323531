import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { HEADER_HEIGHT } from '../constants';
import { SettingsContext } from '../contexts/SettingsContext';
import { ViewModeContext, MOBILE } from '../contexts/ViewModeContext';
import CenterWrap from './CenterWrap';

const useStyles = createUseStyles((theme) => ({
  root: {
    height: `calc(100vh - ${HEADER_HEIGHT.DESKTOP})`,
    display: 'flex',
  },
  customRoot: {
    height: `calc(100vh - ${HEADER_HEIGHT.MOBILE})`,
  },
  main: {
    flexGrow: 1,
  },
  container: {
    background: theme.palette.white,
    height: '100%',
  },
  content: {
    flexGrow: 1,
    padding: '1.5rem',
  },
}));

const Landing = ({ children }) => {
  const classes = useStyles({ theme: useTheme() });

  const mode = useContext(ViewModeContext);
  const { availableLanguages } = useContext(SettingsContext);
  const customStyle = mode === MOBILE && availableLanguages.length > 1;

  return (
    <section
      className={classNames(classes.root, customStyle && classes.customRoot)}
    >
      <main className={classes.main}>
        {mode === MOBILE ? (
          <section className={classes.container}>
            <div className={classes.content}>{children}</div>
          </section>
        ) : (
          <CenterWrap justified narrow view={mode}>
            <div className={classes.content}>{children}</div>
          </CenterWrap>
        )}
      </main>
    </section>
  );
};

Landing.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Landing;
