import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, withTheme } from 'react-jss';
import CircularProgress from './CircularProgress';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    appearance: 'none',
    borderRadius: theme.borderRadius.sm,
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'block',
    margin: 0,
    minWidth: '4rem',
    padding: '0.75rem',
    transition: theme.transitions.border,
    '&[disabled]': {
      borderColor: theme.palette.neutral[200],
      background: theme.palette.neutral[200],
      color: theme.palette.neutral[400],
      cursor: 'not-allowed',
      '&:hover': {
        borderColor: theme.palette.neutral[200],
        background: theme.palette.neutral[200],
        color: theme.palette.neutral[400],
      },
      '&:focus': {
        borderColor: theme.palette.neutral[200],
        background: theme.palette.neutral[200],
        color: theme.palette.neutral[400],
      },
    },
  },
  buttonText: {
    color: 'inherit',
    fontWeight: theme.fontWeights.medium,
    textTransform: 'uppercase',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  fullWidth: {
    width: '100%',
  },
  outlined: {
    background: 'transparent',
    border: `1px solid ${theme.palette.secondary[400]}`,
    borderRadius: theme.borderRadius.default,
    '&:hover': {
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      boxShadow: theme.shadows.input,
      outline: 0,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:active': {
      background: `${theme.palette.secondary[600]} !important`,
      borderColor: `${theme.palette.secondary[600]} !important`,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&[disabled] $buttonText': {
      color: theme.palette.neutral[400],
    },
    '& $buttonText': {
      color: theme.palette.secondary[400],
      fontWeight: theme.fontWeights.normal,
      textTransform: 'none',
    },
  },
  primary: {
    background: theme.palette.secondary[400],
    border: 'none',
    borderRadius: theme.borderRadius.default,
    color: theme.palette.white,
    '&:hover': {
      background: theme.palette.secondary[500],
    },
    '&:focus': {
      boxShadow: theme.shadows.input,
      outlineColor: theme.palette.secondary[600],
    },
    '&:active': {
      background: theme.palette.secondary[600],
    },
  },
  secondary: {
    background: 'none',
    border: 'none',
    color: theme.palette.secondary[400],
    '&:hover': {
      color: theme.palette.white,
      background: theme.palette.secondary[500],
    },
    '&:focus': {
      boxShadow: theme.shadows.input,
    },
    '&:active': {
      background: theme.palette.secondary[600],
    },
  },
  simpleOutline: {
    background: theme.palette.white,
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.borderRadius.default,
    '&:hover': {
      background: theme.palette.neutral[100],
    },
    '&:focus': {
      background: theme.palette.neutral[100],
    },
    '&:active': {
      background: theme.palette.neutral[200],
    },
  },
  shadow: {
    boxShadow: theme.shadows.md,
  },
  floating: {
    background: theme.palette.white,
    border: 'none',
    boxShadow: theme.shadows.default,
    borderRadius: theme.borderRadius.default,
    '&:hover': {
      background: theme.palette.neutral[100],
    },
    '&:focus': {
      background: theme.palette.neutral[100],
    },
    '&:active': {
      background: theme.palette.neutral[200],
    },
  },
  smallOutlined: {
    background: 'transparent',
    border: `1px solid ${theme.palette.secondary[400]}`,
    borderRadius: theme.borderRadius.default,
    padding: '0.5rem 0.75rem',
    '&:hover': {
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      boxShadow: theme.shadows.input,
      outline: 0,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:active': {
      background: `${theme.palette.secondary[600]} !important`,
      borderColor: `${theme.palette.secondary[600]} !important`,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&[disabled] $buttonText': {
      color: theme.palette.neutral[400],
    },
    '& $buttonText': {
      color: theme.palette.secondary[400],
      fontWeight: theme.fontWeights.normal,
      textTransform: 'none',
    },
  },
  smallOutlinedChip: {
    background: 'transparent',
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: '1.25rem',
    padding: '0.5rem 0.75rem',
    '&:hover': {
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      color: theme.palette.white,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:focus': {
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      outline: 0,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&[disabled] $buttonText': {
      color: theme.palette.neutral[400],
    },
    '& $buttonText': {
      color: theme.palette.secondary[400],
      fontWeight: theme.fontWeights.normal,
      textTransform: 'none',
    },
  },
  smallChip: {
    background: 'transparent',
    border: `1px solid ${theme.palette.neutral[250]}`,
    borderRadius: '1.25rem',
    color: theme.palette.secondary[400],
    padding: '0.5rem 0.75rem',
    '&:hover': {
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:focus': {
      '& $buttonText': {
        color: theme.palette.white,
      },
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      outline: 0,
    },
    '&:active': {
      background: theme.palette.secondary[400],
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&[disabled] $buttonText': {
      color: theme.palette.neutral[400],
    },
    '& $buttonText': {
      color: theme.palette.secondary[400],
      fontWeight: theme.fontWeights.normal,
      textTransform: 'none',
    },
  },
  tertiary: {
    background: 'transparent',
    border: `1px solid ${theme.palette.secondary[400]}`,
    borderRadius: theme.borderRadius.default,
    '&:hover': {
      background: theme.palette.secondary[400],
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:focus': {
      boxShadow: theme.shadows.input,
      background: theme.palette.secondary[400],
      border: 'none',
      borderRadius: theme.borderRadius.default,
      color: theme.palette.white,
      '& $buttonText': {
        color: theme.palette.white,
      },
    },
    '&:active': {
      background: theme.palette.secondary[600],
    },
    '&:disabled': {
      background: 'transparent',
      color: theme.palette.neutral[300],
      cursor: 'not-allowed',
    },
    '& $buttonText': {
      color: theme.palette.secondary[400],
    },
  },
  text: {
    background: 'none',
    border: 'none',
    color: theme.palette.secondary[400],
    minWidth: 0,
    padding: 0,
  },
  anchor: {
    background: 'none',
    border: 'none',
    borderBottom: `1px dotted ${theme.palette.secondary[400]}`,
    color: theme.palette.secondary[400],
    display: 'inline-block',
    minWidth: 0,
    padding: 0,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '& $buttonText': {
      textTransform: 'none',
    },
    '&:focus': {
      borderBottomColor: theme.palette.secondary[500],
      outline: 0,
      color: theme.palette.secondary[500],
    },
    '&:hover': {
      borderBottomColor: theme.palette.secondary[500],
      color: theme.palette.secondary[500],
    },
  },
}));

const Button = ({
  ariaLabel,
  children,
  classes: injectedClasses,
  disabled,
  fullWidth,
  isLoading,
  onClick,
  inputRef,
  shadow,
  textVariant,
  type,
  theme,
  variant,
  ...other
}) => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      aria-label={ariaLabel}
      className={classNames(
        classes.root,
        injectedClasses.override,
        classes[variant],
        fullWidth && classes.fullWidth,
        shadow ? classes.shadow : '',
      )}
      disabled={disabled}
      onClick={onClick}
      ref={inputRef}
      type={
        type === 'reset' ? 'reset' : type === 'submit' ? 'submit' : 'button'
      }
      {...other}
    >
      {isLoading ? (
        <CircularProgress
          color={disabled ? theme.palette.neutral[400] : theme.palette.white}
          size={20}
        />
      ) : (
        <Typography
          classes={{ root: classes.buttonText }}
          variant={textVariant}
        >
          {children}
        </Typography>
      )}
    </button>
  );
};

Button.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  classes: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string),
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  inputRef: PropTypes.oneOf([PropTypes.object, PropTypes.func]),
  shadow: PropTypes.bool,
  textVariant: PropTypes.oneOf(['subtitle', 'caption1']),
  theme: PropTypes.shape({
    palette: PropTypes.shape({ white: PropTypes.string }),
  }).isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf([
    'anchor',
    'floating',
    'outlined',
    'primary',
    'secondary',
    'simpleOutline',
    'smallOutlined',
    'smallOutlinedChip',
    'smallChip',
    'tertiary',
    'text',
  ]),
};

Button.defaultProps = {
  ariaLabel: null,
  classes: {},
  disabled: false,
  fullWidth: true,
  inputRef: undefined,
  isLoading: false,
  onClick: null,
  shadow: false,
  textVariant: 'caption1',
  type: 'button',
  variant: 'primary',
};

export default withTheme(Button);
