import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import BackArrow from './icons/BackArrow';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    alignItems: 'center',
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: theme.fontWeights.medium,
    margin: 0,
    marginLeft: '1.25rem',
    padding: 0,
    '& svg': {
      fill: theme.palette.neutral[400],
      marginBottom: '0.125rem',
      marginRight: '0.75rem',
    },
    '&:focus': {
      outline: 0,
      boxShadow: theme.shadows.input,
      borderColor: theme.palette.secondary[500],
      borderBottomColor: theme.palette.secondary[500],
      color: theme.palette.secondary[500],
      fontWeight: theme.fontWeights.boldWeight,
      '& $caption': {
        color: theme.palette.black,
      },
      '& svg': {
        fill: theme.palette.black,
      },
    },
  },
  caption: {
    textTransform: 'uppercase',
  },
}));

const BackButton = ({ previous, text, title }) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <button
      className={classes.root}
      data-testid="back-button"
      onClick={previous}
      title={title}
      type="button"
    >
      <BackArrow altText={title} ariaHidden />
      <Typography
        classes={{ root: classes.caption }}
        id="back"
        variant="caption1"
      >
        {text}
      </Typography>
    </button>
  );
};

BackButton.propTypes = {
  previous: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  title: PropTypes.string,
};

BackButton.defaultProps = {
  title: '',
};

export default BackButton;
