import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  SelectionContext,
  EMPTY_SELECTION,
  SELECTION_STORAGE_KEY,
} from '../../frontend/contexts/SelectionContext';
import mode from '../helpers/Mode';
import Storage from '../helpers/Storage';

const ResetContext = createContext({});
const LISTENERS = [
  'click',
  'mousemove',
  'keyup',
  'touchstart',
  'scroll',
  'touchmove',
  'change',
];

const ResetProvider = ({ children, timeOut }) => {
  const [selections] = useContext(SelectionContext);
  const [redirect, setRedirect] = useState(null);
  const locale = Storage.get(SELECTION_STORAGE_KEY)?.location?.lang;

  const reset = () =>
    mode.resetKiosk(
      selections,
      () => Storage.set(SELECTION_STORAGE_KEY, EMPTY_SELECTION),
      true,
      locale,
    );

  const handleReceiveInput = () => {
    if (redirect) {
      clearTimeout(redirect);
    }
    setRedirect(setTimeout(reset, timeOut));
  };

  useEffect(() => {
    if (mode.isKiosk()) {
      if (!redirect) {
        setRedirect(setTimeout(reset, timeOut));
      }

      LISTENERS.forEach((evt) =>
        document.body.removeEventListener(evt, handleReceiveInput),
      );
      LISTENERS.forEach((evt) =>
        document.body.addEventListener(evt, handleReceiveInput),
      );
    }

    return () => {
      if (mode.isKiosk()) {
        if (redirect) {
          clearTimeout(redirect);
        }
        LISTENERS.forEach((evt) =>
          document.body.removeEventListener(evt, handleReceiveInput),
        );
      }
    };
    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  return <ResetContext.Provider value={{}}>{children}</ResetContext.Provider>;
};

ResetProvider.propTypes = {
  children: PropTypes.element.isRequired,
  timeOut: PropTypes.number,
};

ResetProvider.defaultProps = {
  timeOut: 30000, // 30 seconds
};

export { ResetContext, ResetProvider };
