import axios from 'axios';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocale as useBackendLocale } from '../../backend/contexts/LocaleContext';
import { useLocale as useCallbackLocale } from '../../callback-service/contexts/LocaleContext';
import { useLocale as useFrontendLocale } from '../../frontend/contexts/LocaleContext';

const CxSnippetContext = createContext({});
const useCxSnippets = () => useContext(CxSnippetContext);

const CxSnippetProvider = ({ children }) => {
  const [snippets, setSnippets] = useState(
    window.state?.cx_snippets || window.initialState?.cx_snippets || [],
  );

  const [backendLocale] = useBackendLocale();
  const [frontendLocale] = useFrontendLocale();
  const { callbackLocale } = useCallbackLocale();

  const [localeHasLoaded, setLocaleHasLoaded] = useState(false);
  const [currentLocale, setCurrentLocale] = useState(null);

  useEffect(() => {
    setCurrentLocale(backendLocale);
  }, [backendLocale]);

  useEffect(() => {
    setCurrentLocale(frontendLocale);
  }, [frontendLocale]);

  useEffect(() => {
    setCurrentLocale(callbackLocale);
  }, [callbackLocale]);

  useEffect(() => {
    if (!currentLocale) {
      return;
    }

    if (!localeHasLoaded) {
      setLocaleHasLoaded(true);
      return;
    }

    axios
      .get('/cx/snippets/api/v1/cx_snippets', {
        headers: {
          'Accept-Language': currentLocale,
        },
      })
      .then((response) => {
        setSnippets(response.data);
      });
  }, [currentLocale, localeHasLoaded]);

  return (
    <CxSnippetContext.Provider value={snippets}>
      {children}
    </CxSnippetContext.Provider>
  );
};

CxSnippetProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { CxSnippetContext, CxSnippetProvider, useCxSnippets };
