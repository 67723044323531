import classNames from 'classnames';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { SelectionContext } from '../contexts/SelectionContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import InfoOutlined from './icons/InfoOutlined';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    padding: '1.5rem',
    marginBottom: '0.5rem',
  },
  content: {
    ...theme.components.html.default,
    '&$desktop': {
      marginLeft: '2.375rem',
    },
  },
  desktop: {},
  icon: {
    color: theme.palette.primary[400],
    display: 'inline-flex',
    marginRight: '0.875rem',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '1.25rem',
  },
}));

const ServiceInstructions = () => {
  const classes = useStyles({ theme: useTheme() });

  const mode = useContext(ViewModeContext);
  const [{ service }] = useContext(SelectionContext);

  if (service.instructions) {
    return (
      <div className={classes.root}>
        <Typography
          classes={{ root: classes.title }}
          component="h4"
          variant="h6"
        >
          <span className={classes.icon}>
            <InfoOutlined />
          </span>
          <FormattedMessage id="ServiceInstructions.title" />
        </Typography>
        <div
          className={classNames(
            classes.content,
            mode !== MOBILE && classes.desktop,
          )}
          dangerouslySetInnerHTML={{ __html: service.instructions }}
        />
      </div>
    );
  }

  return null;
};

export default ServiceInstructions;
