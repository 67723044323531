import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { AppointmentContext } from '../contexts/AppointmentContext';
import HistoryShape from '../shapes/HistoryShape';
import Button from './Button';

const RescheduleButton = ({ history, validateReschedule }) => {
  const [, setAppointment] = useContext(AppointmentContext);
  const handleReschedule = () => {
    if (!validateReschedule()) {
      return;
    }

    setAppointment({ rescheduled: false });
    history.push(`/reschedule${window.location.search}`);
  };

  return (
    <Button
      data-testid="reschedule-button"
      fullWidth={false}
      onClick={handleReschedule}
      variant="smallOutlined"
    >
      <FormattedMessage id="ChangesToYourAppointment.reschedule_appointment" />
    </Button>
  );
};

RescheduleButton.propTypes = {
  history: HistoryShape.isRequired,
  validateReschedule: PropTypes.func.isRequired,
};

export default withRouter(RescheduleButton);
