import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';

const SIZE = 44;
const THICKNESS = 3;

const useStyles = createUseStyles((theme) => ({
  root: {
    animation: 'progress-circular-rotate 1.4s linear infinite',
    animationName: '$progress-circular-rotate',
    color: theme.palette.secondary[400],
  },
  centered: {
    margin: 'auto',
  },
  circle: {
    animation: 'progress-circular-dash 1.4s ease-in-out infinite',
    animationName: '$progress-circular-dash',
    stroke: 'currentColor',
    strokeDasharray: '80px, 200px',
    strokeDashoffset: '0px',
  },
  '@keyframes progress-circular-rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes progress-circular-dash': {
    '0%': {
      strokeDasharray: '1px, 200px',
      strokeDashoffset: '0px',
    },
    '50%': {
      strokeDasharray: '100px, 200px',
      strokeDashoffset: '-15px',
    },
    '100%': {
      strokeDasharray: '100px, 200px',
      strokeDashoffset: '-125px',
    },
  },
}));

const CircularProgress = ({ centered, color, size }) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const style = { width: size, height: size };

  if (color) {
    style.color = color;
  }

  return (
    <div
      aria-busy="true"
      aria-label={intl.formatMessage({ id: 'CircularProgress.loading' })}
      aria-live="polite"
      className={classNames(classes.root, centered && classes.centered)}
      id="progressbar"
      role="progressbar"
      style={style}
    >
      <svg viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
        <circle
          className={classes.circle}
          cx={SIZE}
          cy={SIZE}
          fill="none"
          r={(SIZE - THICKNESS) / 2}
          strokeWidth={THICKNESS}
        />
      </svg>
    </div>
  );
};

CircularProgress.propTypes = {
  centered: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CircularProgress.defaultProps = {
  centered: true,
  color: null,
  size: 60,
};

export default CircularProgress;
