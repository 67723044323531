import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import CenterWrap from '../../components/CenterWrap';
import GeneralError from '../../components/GeneralError';
import LoadingState from '../../components/LoadingState';
import ManageAppointmentDetails from '../../components/ManageAppointmentDetails';
import { AppointmentContext } from '../../contexts/AppointmentContext';
import { ViewModeContext } from '../../contexts/ViewModeContext';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[200],
    maxHeight: '100%',
    flexGrow: 1,
  },
  transparent: {
    background: 'transparent',
  },
}));

const ManageAppointment = ({ confirmation, loading }) => {
  const classes = useStyles({ theme: useTheme() });
  const mode = useContext(ViewModeContext);
  const [appointment] = useContext(AppointmentContext);

  if (loading) {
    return <LoadingState />;
  }

  if (!appointment.id && appointment.reloading) {
    return null;
  }

  if (!appointment.id) {
    return <GeneralError />;
  }

  return (
    <CenterWrap flat justified narrow view={mode}>
      <div
        className={classNames(classes.root, classes.transparent)}
        data-testid="manage-desktop"
      >
        <ManageAppointmentDetails confirmation={confirmation} />
      </div>
    </CenterWrap>
  );
};

ManageAppointment.propTypes = {
  confirmation: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ManageAppointment;
