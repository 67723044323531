import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { RECOMMENDED_IFRAME_HEIGHT } from '../../shared/constants';
import { useGoogleMapsApi } from '../../shared/contexts/GoogleMapsApiContext';
import Detective from '../../shared/helpers/Detective';
import { HEADER_HEIGHT, MAP_OVERLAY } from '../constants';
import { PositionContext } from '../contexts/PositionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Item from '../helpers/Item';
import Map from '../helpers/Map';
import Typography from './Typography';
import WaitTime from './WaitTime';

const useStyles = createUseStyles((theme) => ({
  waitTime: {
    paddingTop: '0.125rem',
  },
  appFont: {
    fontFamily: theme.fontFamilies.sansSerif,
  },
}));

let markers = {};

const customStyles = [
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#4F5961' }],
  },
  {
    featureType: 'poi.business.food_and_drink',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#855400' }],
  },
];

const options = {
  center: {
    // Rugby, North Dakota, yee haw (centre of North America)
    lat: 48.365691,
    lng: -99.99659,
  },
  zoom: 4,
  disableDefaultUI: true,
  styles: customStyles,
};

const LEFT_DESKTOP_OFFSET = MAP_OVERLAY.WIDTH + MAP_OVERLAY.LEFT_MARGIN;
const PAN_DESKTOP_BY = -LEFT_DESKTOP_OFFSET / 2;

const DESKTOP_BOUNDS_PADDING = {
  bottom: 50,
  left: 50 + MAP_OVERLAY.WIDTH + MAP_OVERLAY.LEFT_MARGIN,
  right: 50,
  top: 50,
};

const MapContainer = ({
  focused,
  locations,
  locationIds,
  onClickMarker,
  onCreateMarker,
  onIdle,
  redraw,
  selected,
  setFocused,
  shouldCluster,
  shouldFitBounds,
  showMap,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const mode = useContext(ViewModeContext);
  const { availableLanguages, showWaitTime } = useContext(SettingsContext);
  const { coordinates, valid } = useContext(PositionContext);

  const colour = theme.palette.secondary[400];
  const useDefaultPin = window?.state?.theme?.useDefaultPin || false;
  const [mapRef, setMapRef] = useReducer((state, newState) => newState, null);

  const anchor = useRef(null);
  const markerClustererRef = useRef(null);

  const { isLoaded } = useGoogleMapsApi();

  const shouldShowMap = showMap || mode !== MOBILE;

  const places = locations?.filter(
    (location) => location.within && location.physical,
  );

  const addMarker = (id, marker) => {
    markers = { ...markers, [id]: marker };
    onCreateMarker(markers);
  };

  const panTo = (map, coordinates) => {
    map.panTo(Map.coordinates(coordinates));

    if (mode !== MOBILE) {
      map.panBy(PAN_DESKTOP_BY, 0);
    }
  };

  const fitBounds = (map, bounds) => {
    if (mode !== MOBILE) {
      map.fitBounds(bounds, DESKTOP_BOUNDS_PADDING);
    } else {
      map.fitBounds(bounds);
    }
  };

  const fitPlacesBounds = (map) => {
    if (places?.length > 1) {
      const bounds = Map.bounds();

      places?.forEach((place) => {
        bounds.extend(Map.coordinates(place.coordinates));
      });

      fitBounds(map, bounds);
    } else if (places?.length === 1) {
      map.setZoom(15);
      panTo(map, places[0].coordinates);
    }

    if (selected?.coordinates) {
      panTo(map, selected.coordinates);
    }
  };

  const onLoad = useCallback((map) => {
    setMapRef(map);
    fitPlacesBounds(map);
    map.addListener('idle', () => {
      const bounds = map.getBounds();
      const center = bounds.getCenter();
      const corner = bounds.getNorthEast();

      onMouseOut();

      onIdle({
        bounds,
        center: {
          latitude: center.lat(),
          longitude: center.lng(),
        },
        corner: {
          latitude: corner.lat(),
          longitude: corner.lng(),
        },
      });
    });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (places?.length === 1) {
    options.center = places[0].coordinates;
  }

  useEffect(() => {
    if (mapRef && shouldShowMap && shouldFitBounds) {
      fitPlacesBounds(mapRef);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, mode, showMap]); // fit bounds after every fetch, even if locations are the same

  useEffect(() => {
    if (mapRef && shouldShowMap && selected?.coordinates) {
      panTo(mapRef, selected.coordinates);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  // when markers change check if we should trigger a redraw
  // automatic rendering when adding marker to existing cluster is disabled since it was very slow
  useEffect(() => {
    const clusterer = markerClustererRef?.current?.state?.markerClusterer;

    if (clusterer) {
      redraw(clusterer);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  const onMouseOut = () => {
    anchor.current = null;

    if (Item.filled(focused)) {
      setFocused({});
    }
  };

  const renderMarkers = (clusterer = null) =>
    places?.map((place) => {
      const chosen = place.id === selected.id || place.id === focused.id;

      const onClick = () => {
        onClickMarker(place);
      };
      const onLoad = (marker) => addMarker(place.id, marker);
      const onMouseOver = () => {
        const marker = markers[place.id];
        anchor.current = { marker, id: place.id };
        setFocused(place);
      };
      let icon;
      if (useDefaultPin) {
        icon = chosen ? Map.defaultIcon() : null;
      } else {
        icon = Map.icon(chosen ? Map.LARGE : Map.SMALL, colour);
      }

      return (
        <Marker
          clusterer={clusterer}
          icon={icon}
          key={place.id}
          noClustererRedraw
          onClick={onClick}
          onLoad={onLoad}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          position={place.coordinates}
        />
      );
    });

  const showPicker = availableLanguages?.length > 1;
  const headerHeight =
    mode === MOBILE && showPicker
      ? HEADER_HEIGHT.MOBILE
      : HEADER_HEIGHT.DESKTOP;
  const minHeight = Detective.inIFrame()
    ? `${RECOMMENDED_IFRAME_HEIGHT - headerHeight}px`
    : 0;
  const mapHeight =
    mode === MOBILE
      ? `calc(${window.innerHeight}px - ${headerHeight})`
      : '100%';

  const renderedMarkers = useMemo(() => {
    if (shouldCluster) {
      return (
        <MarkerClusterer
          onClick={(cluster) => {
            fitBounds(mapRef, cluster.getBounds());
          }}
          ref={markerClustererRef}
          zoomOnClick={false}
        >
          {(clusterer) => renderMarkers(clusterer)}
        </MarkerClusterer>
      );
    }

    return renderMarkers();

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selected.id,
    focused.id,
    focused.waitTime,
    locationIds,
    mapRef,
    shouldCluster,
  ]);

  return isLoaded ? (
    <GoogleMap
      clickableIcons={false}
      mapContainerStyle={{
        minHeight,
        height: mapHeight,
        display: shouldShowMap ? 'block' : 'none',
      }}
      onLoad={onLoad}
      options={options}
    >
      {valid ? (
        <Marker
          icon={Map.position()}
          position={Map.coordinates({
            lat: coordinates.latitude,
            lng: coordinates.longitude,
          })}
        />
      ) : null}
      {renderedMarkers}
      {focused.name && anchor.current?.id === focused.id ? (
        <InfoWindow anchor={anchor.current.marker}>
          <div className={classes.appFont}>
            <Typography variant="label">{focused.name}</Typography>
            {showWaitTime ? (
              <div className={classes.waitTime}>
                <WaitTime
                  hoverOver
                  location={focused}
                  setLocation={setFocused}
                />
              </div>
            ) : null}
          </div>
        </InfoWindow>
      ) : null}
    </GoogleMap>
  ) : (
    <div data-testid="location-map" />
  );
};

MapContainer.propTypes = {
  focused: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({ physical: PropTypes.number, within: PropTypes.bool }),
  ),
  locationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickMarker: PropTypes.func,
  onCreateMarker: PropTypes.func,
  onIdle: PropTypes.func,
  redraw: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    coordinates: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    id: PropTypes.number,
  }),
  setFocused: PropTypes.func.isRequired,
  shouldCluster: PropTypes.bool,
  shouldFitBounds: PropTypes.bool,
  showMap: PropTypes.bool.isRequired,
};

MapContainer.defaultProps = {
  locations: [] || null,
  onClickMarker: () => {},
  onCreateMarker: () => {},
  onIdle: () => {},
  selected: null,
  shouldCluster: true,
  shouldFitBounds: true,
};

export default MapContainer;
