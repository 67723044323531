import { SHOW_STAFF_PICKER_VALUES } from '../constants';

export default {
  /**
   * @param {object} settings
   * @param {object|null} location
   *
   * @returns {boolean}
   */
  shouldShowStaffPicker(settings, location) {
    const { showStaffPicker } = settings;

    return Boolean(
      showStaffPicker > 0 &&
        (showStaffPicker === SHOW_STAFF_PICKER_VALUES.ALL_LOCATIONS ||
          (location?.physical &&
            showStaffPicker === SHOW_STAFF_PICKER_VALUES.PHYSICAL_LOCATIONS) ||
          (!location?.physical &&
            showStaffPicker === SHOW_STAFF_PICKER_VALUES.VIRTUAL_LOCATIONS)),
    );
  },
};
