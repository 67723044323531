import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '../Typography';

const useStyles = createUseStyles({
  root: {
    marginTop: '0.375rem',
  },
});

const HelpText = ({ value }) => {
  const classes = useStyles();

  return (
    value && (
      <Typography classes={{ root: classes.root }} component="p" variant="help">
        {value}
      </Typography>
    )
  );
};

HelpText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

HelpText.defaultProps = {
  value: null,
};

export default HelpText;
