import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import mode from '../../shared/helpers/Mode';
import { SHORTCUTS } from '../constants';
import {
  EMPTY_APPOINTMENT,
  AppointmentContext,
} from '../contexts/AppointmentContext';
import { FeatureContext } from '../contexts/FeatureContext';
import { LocaleContext } from '../contexts/LocaleContext';
import {
  EMPTY_SELECTION,
  SelectionContext,
} from '../contexts/SelectionContext';
import Shortcuts from '../helpers/Shortcuts';
import Url from '../helpers/Url';
import { Anchor } from './Anchor';
import Button from './Button';

const BookAnotherAppointmentButton = ({ anchor, variant }) => {
  const [locale] = useContext(LocaleContext);
  const { locationLocking } = useContext(FeatureContext);
  const [{ search }, setSelections] = useContext(SelectionContext);
  const [appointment, setAppointment] = useContext(AppointmentContext);

  const bookAnother = (event) => {
    event.preventDefault();

    setAppointment({ ...EMPTY_APPOINTMENT, reloading: true });
    setSelections(EMPTY_SELECTION);

    if (appointment.bookAnotherLink) {
      window.location.href = appointment.bookAnotherLink;

      return;
    }

    const params = Url.params(search);

    if (locationLocking) {
      params.location = appointment.location;
    }

    const query = Object.keys(params)
      .filter((key) => key !== SHORTCUTS.QUEUE)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    const bookingShortcutPathname = Shortcuts.get('pathname');
    if (bookingShortcutPathname !== null && bookingShortcutPathname !== '') {
      const href = `/s/${bookingShortcutPathname}`;
      window.location.href = href + (query ? `?${query}` : '');

      return;
    }

    window.location.href = query ? `/?${query}` : '/';
  };

  const text = mode.isKiosk() ? 'Manage.restart' : 'Manage.book_another';
  const onClick = mode.isKiosk()
    ? () => mode.resetKiosk({}, null, true, locale)
    : bookAnother;

  return anchor ? (
    <Anchor href="#" onClick={onClick} type="noUnderline">
      <FormattedMessage id={text} />
    </Anchor>
  ) : (
    <Button onClick={onClick} variant={variant}>
      <FormattedMessage id={text} />
    </Button>
  );
};

BookAnotherAppointmentButton.propTypes = {
  anchor: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'outlined', 'sentenceText']),
};

BookAnotherAppointmentButton.defaultProps = {
  anchor: false,
  variant: 'primary',
};

export default withRouter(BookAnotherAppointmentButton);
