import en from './en';
import es from './es';
import fr from './fr';
import ko from './ko';
import pl from './pl';
import pt from './pt';
import ru from './ru';
import zh from './zh';

export default {
  en,
  es,
  fr,
  ko,
  pl,
  pt,
  ru,
  zh,
};
