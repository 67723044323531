import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import Dates from '../../shared/helpers/Dates';
import useToggleTranslations from '../../shared/hooks/useToggleTranslations';
import { SHORTCUTS, LOCALES } from '../constants';
import Languages from '../helpers/Languages';
import Shortcuts from '../helpers/Shortcuts';
import Storage from '../helpers/Storage';
import Validator from '../helpers/Validator';

const LocaleContext = createContext(['en', () => 'en']);

const useLocale = () => useContext(LocaleContext);

const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useReducer((state, newState) => {
    const locale = newState?.toLowerCase();

    if (LOCALES.includes(locale)) {
      Storage.set(SHORTCUTS.LANGUAGE, locale);

      return locale;
    }

    return state;
  }, Storage.get(SHORTCUTS.LANGUAGE, Shortcuts.get(SHORTCUTS.LANGUAGE, Languages.getCurrent())));

  useToggleTranslations(locale, setLocale);
  Dates.locale(locale);
  Validator.locale(locale);

  return (
    <LocaleContext.Provider value={[locale, setLocale]}>
      {children}
    </LocaleContext.Provider>
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { LocaleContext, LocaleProvider, useLocale };
