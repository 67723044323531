import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';
import Dates from '../../shared/helpers/Dates';
import Storage from '../helpers/Storage';

const AppointmentContext = createContext([{}, () => {}]);

export const APPOINTMENT_STORAGE_KEY = 'appointment-state';

export const EMPTY_APPOINTMENT = {
  id: null,
  bookingShortcut: null,
  bookAnotherLink: null,
  confirmCode: null,
  startRaw: null,
  status: null,
  end: null,
  endRaw: null,
  fromReschedule: null,
  rescheduled: null,
  reschedulable: false,
  reloading: false,
  location: null,
  meetingLink: null,
  dialIn: null,
};

const getInitialState = (initial) => {
  return Storage.get(APPOINTMENT_STORAGE_KEY, initial);
};

const AppointmentProvider = ({ children }) => {
  const [appointment, setAppointment] = useReducer(
    (state, newState) => {
      const data = { ...state, ...newState };

      if (newState.end !== state.end) {
        data.end = newState.end ? Dates.parse(newState.end) : state.end;
      }

      return data;
    },
    EMPTY_APPOINTMENT,
    getInitialState,
  );

  useEffect(() => {
    Storage.set(APPOINTMENT_STORAGE_KEY, appointment);
  }, [appointment]);

  return (
    <AppointmentContext.Provider value={[appointment, setAppointment]}>
      {children}
    </AppointmentContext.Provider>
  );
};

AppointmentProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { AppointmentContext, AppointmentProvider };
