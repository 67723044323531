import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import DesktopCard from './desktop/Card';
import List from './List';
import ServiceHeader from './ServiceHeader';
import ToggleText from './ToggleText';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    display: 'flex',
    height: 'calc(100% - 1.25rem)',
    marginBottom: '1.25rem',
    marginLeft: '2.5rem',
    marginRight: '2.5rem',
  },
  button: {
    alignItems: 'center',
    appearance: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
    width: '100%',
  },
  icon: {
    marginLeft: '3rem',
    '& svg:first-child': {
      fill: theme.palette.neutral[300],
    },
  },
  info: {
    textAlign: 'left',
  },
  service: {
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.borderRadius.default,
    marginBottom: '1.25rem',
    padding: '0.8rem 1.25rem',
  },
  services: {
    flexGrow: 1,
    padding: '1.875rem 2.5rem',
    overflowY: 'auto',
  },
  title: {
    marginBottom: '1.875rem',
  },
}));

const ServiceList = ({
  expanded,
  selectService,
  selectedCategory,
  services,
  toggleDescription,
}) => {
  const classes = useStyles({ theme: useTheme() });
  const { firstStep } = useContext(SettingsContext);
  const [{ attendee }] = useContext(SelectionContext);

  return (
    <section className={classes.services}>
      <section className={classes.title}>
        <Typography component="h2" variant="h5">
          <FormattedMessage
            id={
              firstStep === 'service' && attendee?.firstName
                ? 'Service.select_title_prefill'
                : 'Service.select_title'
            }
            values={{ name: attendee?.firstName, break: ' ' }}
          />
        </Typography>
      </section>
      <List>
        {services
          .filter((service) =>
            selectedCategory
              ? service.categories.includes(selectedCategory.id)
              : true,
          )
          .map((service) => (
            <DesktopCard
              action={() => selectService(service, selectedCategory)}
              header={
                <ServiceHeader
                  duration={service.duration}
                  group={service.group}
                  maximum={service.maxDuration}
                  minimum={service.minDuration}
                />
              }
              icon
              id={service.id}
              key={service.id}
              primary={service.name}
              secondary={
                service.description !== null ? (
                  <ToggleText
                    model={service}
                    setVariant={() => toggleDescription(service.id)}
                    variant={expanded}
                  />
                ) : null
              }
            />
          ))}
      </List>
    </section>
  );
};

ServiceList.propTypes = {
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  selectService: PropTypes.func.isRequired,
  selectedCategory: PropTypes.shape({ id: PropTypes.string }),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
  toggleDescription: PropTypes.func.isRequired,
};

export default ServiceList;
