import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import Dates from '../../shared/helpers/Dates';
import BackChevron from '../../shared/icons/BackChevron';
import ForwardChevron from '../../shared/icons/ForwardChevron';
import Item from '../helpers/Item';
import Slots from '../helpers/Slots';
import SpacetimeShape from '../shapes/SpacetimeShape';
import NoTimesAvailable from './NoTimesAvailable';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[100],
    padding: '1.25rem',
    position: 'relative',
    zIndex: 1,
  },
  borders: {
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    padding: '1.25rem 0',
    margin: '0 1.25rem',
  },
  bordersForNextAvailabilityNewView: {
    borderBottom: 'none',
  },
  button: {
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    borderRadius: theme.borderRadius.full,
    cursor: 'pointer',
    display: 'inline-flex',
    margin: 0,
    padding: 0,
    '& svg': {
      fill: theme.palette.neutral[400],
    },
    '&[disabled]': {
      cursor: 'default',
      cursorEvents: 'none',
      opacity: 0,
    },
    '&:focus': {
      boxShadow: theme.shadows.wrap,
      '& svg': {
        fill: theme.palette.black,
      },
    },
    '&:not(:first-of-type)': {
      marginLeft: '0.5rem',
    },
  },
  dates: {
    display: 'table',
    marginBottom: '1rem',
    tableLayout: 'fixed',
    width: '100%',
  },
  row: {
    display: 'table-row',
  },
  dateRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1.25rem',
    justifyContent: 'space-between',
  },
  item: {
    display: 'table-cell',
    fontWeight: theme.fontWeights.normal,
    padding: '0.5rem 0',
    textAlign: 'center',
  },
  clickable: {
    appearance: 'none',
    background: 'transparent',
    border: '1px solid transparent',
    borderRadius: theme.borderRadius.full,
    cursor: 'pointer',
    display: 'block',
    height: '2.5em',
    margin: '0 auto',
    padding: 0,
    width: '2.5em',
  },
  selectable: {
    '&:focus, &:hover': {
      background: theme.palette.secondary[400],
      border: `1px solid ${theme.palette.secondary[400]}`,
      color: theme.palette.white,
    },
  },
  active: {
    color: theme.palette.secondary[400],
  },
  inactive: {
    color: theme.palette.neutral[300],
  },
  circle: {
    border: `1px solid ${theme.palette.neutral[250]}`,
    borderRadius: theme.borderRadius.full,
  },
  today: {
    position: 'relative',
    '&::before': {
      background: theme.palette.secondary[400],
      borderRadius: theme.borderRadius.full,
      content: '""',
      height: '0.25rem',
      left: '50%',
      position: 'absolute',
      top: '85%',
      transform: 'translate(-50%,-50%)',
      width: '0.25rem',
    },
    '&:hover': {
      '&:before': {
        background: theme.palette.white,
      },
    },
  },
  selected: {
    background: theme.palette.secondary[400],
    borderColor: theme.palette.secondary[400],
    color: theme.palette.white,
    '&::before': {
      background: theme.palette.white,
    },
  },
  month: {
    fontWeight: theme.fontWeights.normal,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 0.5rem',
  },
  specific: {
    marginTop: '0.875rem',
  },
}));

const WeeklyDatePicker = ({
  borders,
  chosen,
  end,
  loading,
  onClickDate,
  onClickNext,
  onClickPrevious,
  selected,
  slots,
  specific,
  start,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });
  const { shouldUseNextAvailability, shouldUseNextAvailabilityNewView } =
    useContext(FeatureDecisionContext);

  const FOCUS = 'focusButton';
  const today = Dates.today();
  const weekdays = Dates.weekdays();
  const dates = Dates.calendarize(start, end);
  const handleClick = ({ currentTarget: { dataset } }) => {
    onClickDate(Dates.parse(dataset.day));
  };
  const notAvailable = Slots.available(slots, dates) === false;
  const isSameWeekAsToday = Dates.startOfWeek(today).isSame(
    Dates.startOfWeek(start),
    'week',
  );

  const navigationBar = () => {
    return (
      <>
        <Typography
          classes={{ root: classes.month }}
          component="p"
          variant="title"
        >
          {Dates.toMonthYear(start)}
        </Typography>
        <div>
          <button
            aria-disabled={isSameWeekAsToday}
            aria-hidden={isSameWeekAsToday}
            aria-label={intl.formatMessage({
              id: 'WeeklyDatePicker.previous_week',
            })}
            className={classes.button}
            data-testid="back-button"
            disabled={isSameWeekAsToday}
            onClick={onClickPrevious}
            type="button"
          >
            <BackChevron
              altText={intl.formatMessage({
                id: 'Svg.alt_text.previous_week',
              })}
            />
          </button>
          <button
            aria-label={intl.formatMessage({
              id: 'WeeklyDatePicker.next_week',
            })}
            className={classes.button}
            data-testid="forward-button"
            onClick={onClickNext}
            type="button"
          >
            <ForwardChevron
              altText={intl.formatMessage({
                id: 'Svg.alt_text.next_week',
              })}
            />
          </button>
        </div>
      </>
    );
  };

  useEffect(() => {
    document.getElementsByClassName(FOCUS)[0]?.focus();
  }, [selected]);

  return (
    <>
      <section
        aria-label={Dates.toMonthYear(start)}
        className={classNames(
          classes.root,
          borders && classes.borders,
          borders &&
            shouldUseNextAvailability &&
            shouldUseNextAvailabilityNewView
            ? classes.bordersForNextAvailabilityNewView
            : '',
        )}
        data-testid="weekly-date-picker"
      >
        {shouldUseNextAvailability && shouldUseNextAvailabilityNewView ? (
          <div className={classes.dateRow}>{navigationBar()}</div>
        ) : null}
        <section className={classes.dates}>
          <div className={classes.row}>
            {weekdays.map((weekday) => (
              <Typography
                aria-label={Dates.dayOfWeek(weekdays.indexOf(weekday))}
                classes={{ root: classes.item }}
                component="div"
                key={weekday}
                title={Dates.dayOfWeek(weekdays.indexOf(weekday))}
                variant="small"
              >
                {weekday.substr(0, 1).toUpperCase()}
              </Typography>
            ))}
          </div>
          {dates.map((week) => (
            <div className={classes.row} key={week[0].format()}>
              {week.map((day) => {
                const date = day.format();
                const isToday = day.isSame(today, 'day');
                const isSelected = day.isSame(selected, 'day');
                const active =
                  Item.has(slots, date) && Item.length(slots[date]) > 0;

                const optionalProps = {};
                if (isToday) {
                  optionalProps['aria-current'] = 'date';
                }
                if (isSelected) {
                  optionalProps['aria-pressed'] = 'true';
                } else {
                  optionalProps['aria-pressed'] = 'false';
                }

                return (
                  // eslint-disable-next-line jsx-a11y/label-has-for
                  <label
                    className={classes.item}
                    htmlFor={day}
                    key={`${date}-${active}-${isSelected}-${isToday}`}
                  >
                    <Typography
                      aria-disabled={!active}
                      aria-label={`${day.dayName()} ${date}`}
                      classes={{
                        root: classNames(
                          classes.clickable,
                          classes.selectable,
                          isToday ? classes.today : null,
                          active
                            ? classNames(classes.active, classes.circle)
                            : classes.inactive,
                          isSelected
                            ? classNames(classes.selected, classes.circle)
                            : null,
                          isSelected ? FOCUS : null,
                        ),
                      }}
                      component="button"
                      data-day={day.format()}
                      data-testid="weekly-date-picker-day"
                      id={date}
                      onClick={handleClick}
                      variant="title"
                      {...optionalProps}
                    >
                      {day.format('date')}
                    </Typography>
                  </label>
                );
              })}
            </div>
          ))}
        </section>
        {shouldUseNextAvailability &&
        shouldUseNextAvailabilityNewView ? null : (
          <footer className={classes.footer}>{navigationBar()}</footer>
        )}
        {notAvailable &&
        specific &&
        chosen &&
        !loading &&
        !shouldUseNextAvailability ? (
          <div className={classes.specific}>
            <NoTimesAvailable onClick={onClickNext} rounded shadow />
          </div>
        ) : null}
      </section>
      {notAvailable && !specific && !loading && !shouldUseNextAvailability ? (
        <NoTimesAvailable onClick={onClickNext} />
      ) : null}
    </>
  );
};

WeeklyDatePicker.propTypes = {
  borders: PropTypes.bool,
  chosen: PropTypes.bool,
  end: SpacetimeShape.isRequired,
  loading: PropTypes.bool,
  onClickDate: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  selected: SpacetimeShape.isRequired,
  slots: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({ end: SpacetimeShape, start: SpacetimeShape }),
    ),
  ).isRequired,
  specific: PropTypes.bool,
  start: SpacetimeShape.isRequired,
};

WeeklyDatePicker.defaultProps = {
  borders: false,
  chosen: false,
  loading: false,
  specific: false,
};

export default WeeklyDatePicker;
