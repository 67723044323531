import PropTypes from 'prop-types';
import React, { useContext, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { withRouter } from 'react-router-dom';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { TimezonesContext } from '../contexts/TimezonesContext';
import { DESKTOP, MOBILE } from '../contexts/ViewModeContext';
import HistoryShape from '../shapes/HistoryShape';
import Button from './Button';
import CountryTimezoneModal from './CountryTimezoneModal';
import TimezoneLabel from './TimezoneLabel';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  button: {
    '&:focus': {
      outline: 0,
      boxShadow: theme.shadows.input,
      borderColor: theme.palette.secondary[500],
    },
  },
  buttonContainer: {
    display: 'inline-flex',
  },
  buttonContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '1rem',
    marginLeft: '0.75rem',
    width: '1rem',
  },
  icon: {
    fill: 'currentColor',
  },
  label: {
    display: 'inline-block',
    marginBottom: '0.5rem',
    marginRight: '1rem',
  },
}));

const TimezonesShownIn = ({ history, mode }) => {
  const styles = useStyles();
  const timezones = useContext(TimezonesContext);
  const [timezone] = useContext(TimezoneContext);
  const [showModal, setShowModal] = useReducer(
    (_state, newState) => newState,
    false,
  );

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleTimezoneList = (e) => {
    e.preventDefault();

    if (mode === DESKTOP) {
      setShowModal(true);
    }

    if (mode === MOBILE) {
      history.push('/country-timezones');
    }
  };

  return (
    <>
      <Typography component="div" grey variant="caption1">
        <FormattedMessage id="TimezonesShownIn.label" />
      </Typography>
      <span>&nbsp;</span>
      <span className={styles.buttonContainer}>
        <Button
          classes={{ override: styles.button }}
          onClick={toggleTimezoneList}
          variant="anchor"
        >
          <div className={styles.buttonContent}>
            <TimezoneLabel
              fallback={timezone}
              // We are temporarily ignoring the destructuring-assignment rule explicitly.
              // There is a bug that was solved in a newer version of this plugin which
              // we will eventually be able to upgrade to once we can move off of
              // the current version of NodeJS in use.
              //
              // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
              //
              // eslint-disable-next-line react/destructuring-assignment
              timezone={timezones[timezone]}
              withName
            />
          </div>
        </Button>
      </span>
      {mode === DESKTOP && showModal ? (
        <CountryTimezoneModal close={closeModal} open={showModal} />
      ) : null}
    </>
  );
};

TimezonesShownIn.propTypes = {
  history: HistoryShape.isRequired,
  mode: PropTypes.oneOf([DESKTOP, MOBILE]),
};

TimezonesShownIn.defaultProps = {
  mode: DESKTOP,
};

export default withRouter(TimezonesShownIn);
