import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sort_order: PropTypes.number,
  type: PropTypes.number,
});
