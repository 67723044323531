import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import Api from '../helpers/Api';
import { LocaleContext } from './LocaleContext';

const CountriesContext = createContext({});

const CountriesProvider = ({ children }) => {
  const [locale] = useContext(LocaleContext);
  const [countries, setCountries] = useReducer(
    (state, newState) => newState,
    {},
  );

  useEffect(() => {
    Api.locale(locale)
      .countries()
      .all()
      .then(({ data }) => {
        const currentCountries = data.reduce((object, country) => {
          object[country.attributes.code] = country.attributes.name;

          return object;
        }, {});

        setCountries(currentCountries);
      });
  }, [locale]);

  return (
    <CountriesContext.Provider value={countries}>
      {children}
    </CountriesContext.Provider>
  );
};

CountriesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { CountriesContext, CountriesProvider };
