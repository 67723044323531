import axios from 'axios';

export const API_ROUTE = '/api/v2/open/queue-appointments';

export default {
  patch(id, attributes) {
    const data = {
      id,
      attributes,
      type: 'queue-appointments',
    };

    return axios.patch(`/api/v2/open/queue-appointments/${id}`, { data });
  },
};
