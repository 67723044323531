import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const BackChevron = ({ altText }) => {
  const intl = useIntl();

  return (
    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.back_chevron' })}
      </title>
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

BackChevron.propTypes = {
  altText: PropTypes.string,
};

BackChevron.defaultProps = {
  altText: '',
};

export default BackChevron;
