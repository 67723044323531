import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import TrackPageView from '../../components/TrackPageView';
import { SHORTCUTS, STEPS, USER_PREFERENCE, PAGES } from '../../constants';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import Settings from '../../helpers/Settings';
import Shortcuts from '../../helpers/Shortcuts';
import Step from '../../helpers/Step';
import RandomUserTime from './RandomUserTime';
import SpecificUserTime from './SpecificUserTime';

const Time = ({ canStepBackwards, previousStep, ...props }) => {
  const { builderEnabled, firstStep, showStaffPicker } =
    useContext(SettingsContext);
  const [{ location, settings, skip, userPreference }] =
    useContext(SelectionContext);

  const showTime =
    !(
      !builderEnabled &&
      Settings.shouldShowStaffPicker({ showStaffPicker }, location)
    ) ||
    (Shortcuts.exists(SHORTCUTS.USER) && !settings.preferred_staff) ||
    (userPreference &&
      userPreference.id !== USER_PREFERENCE.SPECIFIC &&
      !settings.preferred_staff);

  const canGoToPrevStep = Step.canGoToPrevStep({
    step: STEPS.TIMES,
    previousStep,
    firstStep,
    skip,
    canStepBackwards,
  });

  return showTime ? (
    <>
      <TrackPageView identifier={PAGES.TIMES} />
      <RandomUserTime showBack={canGoToPrevStep} {...props} />
    </>
  ) : (
    <>
      <TrackPageView identifier={PAGES.USER_TIMES} />
      <SpecificUserTime showBack={canGoToPrevStep} {...props} />
    </>
  );
};

Time.propTypes = {
  canStepBackwards: PropTypes.bool.isRequired,
  previousStep: PropTypes.string,
};

Time.defaultProps = {
  previousStep: null,
};

export default Time;
