const Regions = (client) => ({
  /**
   * @returns {Promise<any>}
   */
  find(country) {
    return client.get(`/api/v2/open/regions/${country}`);
  },
});

export default Regions;
