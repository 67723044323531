import classNames from 'classnames';
import type { MouseEventHandler, FC } from 'react';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import type { ThemeType } from './../helpers/Theme';
import Typography from './Typography';

const useStyles = createUseStyles((theme: ThemeType) => ({
  button: {
    border: `1px solid ${theme.palette.secondary[400]} !important`,
    borderRadius: theme.borderRadius.default,
    display: 'inline-block',
    padding: '0.5rem 0.75rem',
    '&:hover': {
      background: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      '& $text': {
        color: theme.palette.white,
      },
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary[400],
      borderColor: theme.palette.secondary[400],
      boxShadow: theme.shadows.input,
      outline: 0,
      '& $text': {
        color: theme.palette.white,
      },
    },
    '&:active': {
      background: `${theme.palette.secondary[600]} !important`,
      borderColor: `${theme.palette.secondary[600]} !important`,
      '& $text': {
        color: theme.palette.white,
      },
    },
  },
  default: {
    borderBottom: `1px dotted ${theme.palette.secondary[400]}`,
    textDecoration: 'none',
    padding: '0 0.25rem',
    borderRadius: '4px',
    '&:focus-visible': {
      borderBottomColor: 'transparent',
      outline: `2px ${theme.palette.secondary[500]} solid`,
      '& $text': {
        color: theme.palette.secondary[500],
      },
    },
    '&:hover': {
      borderBottomColor: theme.palette.secondary[500],
      '& $text': {
        color: theme.palette.secondary[500],
      },
    },
  },
  text: {
    color: theme.palette.secondary[400],
  },
  area: {
    marginBottom: '0.25rem',
    borderRadius: theme.borderRadius.default,
    display: 'inline-block',
    padding: '0.5rem 0.75rem',
    '&:focus': {
      outline: `2px solid ${theme.palette.secondary[400]} !important`,
      borderBottom: 'none',
    },
  },
  noUnderline: {
    textDecoration: 'none',
    padding: '4px',
    borderRadius: '4px',
    '&:focus': {
      outline: `2px ${theme.palette.secondary[500]} solid`,
      '& $text': {
        color: theme.palette.secondary[500],
      },
    },
    '&:hover': {
      '& $text': {
        color: theme.palette.secondary[500],
      },
    },
    '& $text': {
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
}));

type AnchorType = 'area' | 'button' | 'default' | 'noUnderline';

interface AnchorProps {
  href: string;
  external: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  type?: AnchorType;
  variant?: string;
  preventDefault?: boolean;
}

export const Anchor: FC<AnchorProps> = ({
  children,
  external = false,
  href,
  onClick,
  type = 'default',
  variant = 'body1',
  preventDefault = false,
  ...props
}) => {
  const classes = useStyles({ theme: useTheme() });

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (preventDefault) {
      event.preventDefault();
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <a
      className={classNames(classes[type])}
      href={href}
      onClick={handleClick}
      rel={external ? 'noreferrer' : undefined}
      target={external ? '_blank' : '_parent'}
      {...props}
    >
      <Typography classes={{ root: classes.text }} variant={variant}>
        {children}
      </Typography>
    </a>
  );
};
