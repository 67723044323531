import Item from './Item';

export default {
  /**
   * @param {string} identifier
   * @param {*} fallback
   *
   * @returns {*}
   */
  get(identifier, fallback = null) {
    const { utm_parameters: utmParameters } = window.state;

    return Item.get(utmParameters, identifier, fallback);
  },
};
