const Timezone = () => ({
  /**
   * @returns {Promise<any>}
   */
  find() {
    return fetch('http://ip-api.com/json?fields=timezone').then((response) => {
      if (response.ok) {
        return response.json();
      }

      return new Promise((_resolve, reject) => reject(response));
    });
  },
});

export default Timezone;
