export default {
  engagements: {
    SELECT_LOCATION: {
      category: 'BookingData',
      action: 'Click',
      name: 'SelectLocation',
      uuid: '9ff27fb6-7ab6-11eb-a49b-97ed78e737b2',
    },
    BOOK_QUEUE_APPOINTMENT: {
      category: 'Booking',
      action: 'Click',
      name: 'BookQueueAppointment',
      uuid: 'ee4327a6-7ab6-11eb-8faf-afb6f0557017',
    },
  },

  pageNames: {
    LOCATION: 'online-queue location',
    FORM: 'online-queue form',
    CONFIRMATION: 'online-queue confirmation',
    UNAVAILABLE: 'online-queue unavailable',
  },

  trackVirtualPage(url, pageName, vendor, locale) {
    const googleTagManagerJson = vendor?.gtm_configuration
      ? JSON.parse(vendor.gtm_configuration)
      : null;

    if (typeof window.dataLayer !== 'undefined' && googleTagManagerJson) {
      window.dataLayer.push({
        event: 'VirtualPageview',
        vpvURL: url,
        vpvName: pageName,
        ...googleTagManagerJson,
        language: locale,
      });
    }
  },

  trackEngagement(vendor, engagement, value) {
    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: engagement.name,
        eventId: engagement.uuid,
        category: engagement.category,
        action: engagement.action,
        value: value,
      });
    }
  },
};
