import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { HEADER_HEIGHT } from '../constants';
import { DESKTOP, TABLET, ViewModeContext } from '../contexts/ViewModeContext';

const useStyles = createUseStyles((theme) => {
  const header = 4.625;
  const spaceForLogo = 1.875;
  const headerWithMargin = header + 1.25;
  const headerWithMarginAndLogo = headerWithMargin + spaceForLogo;

  return {
    root: {
      borderRadius: theme.borderRadius.default,
      boxShadow: theme.shadows.overlay,
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '1.25rem',
      position: 'absolute',
    },
    background: {
      background: theme.palette.white,
    },
    mobileBackgroundHeight: {
      minHeight: `calc(100vh - ${HEADER_HEIGHT.MOBILE})`,
      position: 'unset',
    },
    desktop: {
      height: `calc(100vh - ${headerWithMarginAndLogo}rem)`,
      top: '1.25rem',
    },
    mobile: {
      boxShadow: 'none',
      marginLeft: 0,
      top: 0,
      width: '100%',
    },
  };
});

const MapOverlay = ({ children, transparent }) => {
  const classes = useStyles({ theme: useTheme() });

  const mode = useContext(ViewModeContext);
  const desktopOrTablet = mode === DESKTOP || mode === TABLET;

  return (
    <div
      className={classnames(
        classes.root,
        desktopOrTablet ? classes.desktop : classes.mobile,
        transparent ? '' : classes.background,
        !desktopOrTablet && !transparent ? classes.mobileBackgroundHeight : '',
      )}
    >
      {children}
    </div>
  );
};

MapOverlay.propTypes = {
  children: PropTypes.element.isRequired,
  transparent: PropTypes.bool.isRequired,
};

export default MapOverlay;
