import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  override: {},
  subtitle: {
    display: 'block',
    marginTop: '0.25rem',
  },
  title: {
    fontWeight: theme.fontWeights.medium,
  },
  tertiary: {
    color: theme.palette.neutral[400],
  },
}));

const BreadcrumbSelection = ({
  title,
  subtitle,
  tertiary,
  classes: injectedClasses,
}) => {
  const classes = { ...useStyles({ theme: useTheme() }), ...injectedClasses };

  return (
    <div className={classes.override}>
      {title ? (
        <Typography classes={{ root: classes.title }} variant="body2">
          {title}
        </Typography>
      ) : null}
      {subtitle ? (
        <Typography classes={{ root: classes.subtitle }} variant="body2">
          {subtitle}
        </Typography>
      ) : null}
      {tertiary ? (
        <Typography classes={{ root: classes.tertiary }} variant="body2">
          {tertiary}
        </Typography>
      ) : null}
    </div>
  );
};

BreadcrumbSelection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tertiary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

BreadcrumbSelection.defaultProps = {
  classes: {},
  subtitle: null,
  tertiary: null,
  title: null,
};

export default BreadcrumbSelection;
