import PropTypes from 'prop-types';
import React from 'react';

const AccountCircle = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" fill="black" fillOpacity="0.1" r="20" />
    <path
      d="M20 20C22.21 20 24 18.21 24 16C24 13.79 22.21 12 20 12C17.79 12 16 13.79 16 16C16 18.21 17.79 20 20 20ZM20 22C17.33 22 12 23.34 12 26V28H28V26C28 23.34 22.67 22 20 22Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
);

AccountCircle.propTypes = {
  className: PropTypes.string,
};

AccountCircle.defaultProps = {
  className: '',
};

export default AccountCircle;
