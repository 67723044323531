import React, { Fragment, useContext, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import CxSnippet from '../../shared/components/CxSnippet';
import ErrorBoundary from '../../shared/components/ErrorBoundary';
import { FeatureDecisionProvider } from '../../shared/contexts/FeatureDecisionContext';
import mode from '../../shared/helpers/Mode';
import synonymReplacement from '../../shared/helpers/SynonymReplacement';
import { SHORTCUTS, LANDING_PAGES } from '../constants';
import { FeatureContext } from '../contexts/FeatureContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { StepContext } from '../contexts/StepContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import Shortcuts from '../helpers/Shortcuts';
import Url from '../helpers/Url';
import messages from '../locales';
import CheckIn from '../pages/CheckIn';
import Country from '../pages/Country';
import CountryTimezones from '../pages/CountryTimezones';
import Disabled from '../pages/Disabled';
import ErrorPage from '../pages/ErrorPage';
import Feedback from '../pages/Feedback';
import LandingChoices from '../pages/LandingChoices';
import LocationIdentifier from '../pages/LocationIdentifier';
import ManageAppointment from '../pages/ManageAppointment';
import Languages from '../pages/mobile/Languages';
import Preferences from '../pages/mobile/Preferences';
import Reschedule from '../pages/Reschedule';
import ShortcutExpired from '../pages/ShortcutExpired';
import Timezones from '../pages/Timezones';
import BookingSteps from './BookingSteps';
import CookieNoticeContainer from './CookieNoticeContainer';
import Header from './Header';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[100],
    display: 'flex',
    flexDirection: 'column',
    fontFamily: theme.fontFamilies.sansSerif,
    fontSmoothing: 'initial',
    minHeight: '100vh',
  },
}));

const App = () => {
  const classes = useStyles({ theme: useTheme() });

  const history = useHistory();
  const location = useLocation();
  const features = useContext(FeatureContext);
  const [[step]] = useContext(StepContext);
  const [locale] = useContext(LocaleContext);
  const [timezone] = useContext(TimezoneContext);
  const { fullPrivateLabel } = useContext(FeatureContext);
  const [{ location: selectedLocation }] = useContext(SelectionContext);
  const translations = synonymReplacement(
    messages[locale],
    window.state.synonyms[locale],
  );
  const { lobby, appointmentBookingChannel } = features;
  const isLobbyOnly = lobby && !appointmentBookingChannel;

  const params = Url.params(history.location.search);
  const queue = Number(params?.queue) === 1;

  useEffect(
    () =>
      history.listen(() => {
        window.scrollTo(0, 0);
      }),
    [history],
  );

  /**
   * Conditional routes cannot be grouped within a fragement inside a switch,
   * and switch cannot be nested inside another switch. This is a limitation of react-router.
   * So the only solution is to conditionally render each route one by one.
   *
   * https://github.com/remix-run/react-router/issues/5785
   * https://github.com/remix-run/react-router/issues/6199
   */
  return (
    <div className={classes.root}>
      <IntlProvider locale={locale} messages={translations} timeZone={timezone}>
        <FeatureDecisionProvider>
          <ErrorBoundary fallback={ErrorPage}>
            <>
              {fullPrivateLabel || mode.isKiosk() ? null : (
                <CookieNoticeContainer />
              )}
              <Header />
              <Switch>
                <Route component={ManageAppointment} path="/confirmation" />
                <Route component={ManageAppointment} path="/manage" />
                <Route component={Disabled} path="/disabled" />
                <Route path={LANDING_PAGES.LOCATION}>
                  {Shortcuts.exists(SHORTCUTS.LOCATION) ? (
                    <Redirect
                      to={{ ...location, pathname: LANDING_PAGES.CHOICES }}
                    />
                  ) : (
                    <LocationIdentifier />
                  )}
                </Route>
                <Route path={LANDING_PAGES.CHOICES}>
                  {!selectedLocation ? (
                    <Redirect
                      to={{ ...location, pathname: LANDING_PAGES.LOCATION }}
                    />
                  ) : (
                    <LandingChoices />
                  )}
                </Route>
                <Route path={LANDING_PAGES.CHECK_IN}>
                  {!selectedLocation ? (
                    <Redirect
                      to={{ ...location, pathname: LANDING_PAGES.LOCATION }}
                    />
                  ) : (
                    <CheckIn />
                  )}
                </Route>
                <Route component={Feedback} path="/give-feedback" />
                {!isLobbyOnly ? (
                  <Route component={Reschedule} path="/reschedule" />
                ) : null}
                {!isLobbyOnly ? (
                  <Route component={ShortcutExpired} path="/shortcut-expired" />
                ) : null}
                {!isLobbyOnly ? (
                  <Route component={Timezones} path="/timezones" />
                ) : null}
                {!isLobbyOnly ? (
                  <Route component={Country} path="/country" />
                ) : null}
                {!isLobbyOnly ? (
                  <Route
                    component={CountryTimezones}
                    path="/country-timezones"
                  />
                ) : null}
                {!isLobbyOnly ? (
                  <Route
                    component={
                      location.state?.preferences ? Languages : BookingSteps
                    }
                    path="/language-preferences"
                  />
                ) : null}
                {!isLobbyOnly ? (
                  <Route
                    component={
                      location.state?.preferences ? Preferences : BookingSteps
                    }
                    path="/user-preferences"
                  />
                ) : null}

                {!isLobbyOnly ? (
                  <Route component={BookingSteps} path="/:step" />
                ) : null}
                {!isLobbyOnly ? (
                  <Route exact path="/">
                    <Redirect
                      to={{
                        ...location,
                        pathname: queue
                          ? Shortcuts.exists(SHORTCUTS.LOCATION)
                            ? LANDING_PAGES.CHOICES
                            : LANDING_PAGES.LOCATION
                          : step.id,
                      }}
                    />
                  </Route>
                ) : null}
              </Switch>
              <CxSnippet targetId="footer" />
            </>
          </ErrorBoundary>
        </FeatureDecisionProvider>
      </IntlProvider>
    </div>
  );
};

export default App;
