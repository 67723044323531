import { useEffect, useRef } from 'react';

const defaultEvents = ['mousedown', 'touchstart'];

const useClickAway = (reference, onClickAway, events = defaultEvents) => {
  const savedCallback = useRef(onClickAway);

  useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);

  useEffect(() => {
    const handler = (event) => {
      const { current: el } = reference;

      if (el && !el.contains(event.target)) {
        savedCallback.current(event);
      }
    };

    events.forEach((event) => {
      document.addEventListener(event, handler);
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handler);
      });
    };
  }, [events, reference]);
};

export default useClickAway;
