import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { DEFAULT_COUNTRY } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import Forms from '../helpers/Forms';
import Item from '../helpers/Item';
import QuestionShape from '../shapes/QuestionShape';
import AdditionalDetailsForm from './forms/mobile/AdditionalDetailsForm';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[200],
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
  },
  form: {
    scrollBehavior: 'smooth',
  },
}));

const AddAttendeePage = ({
  addAdditionalAttendee,
  countries,
  country,
  questions,
  selectedAttendee,
  setUploads,
  validate,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const rootRef = useRef();
  const [{ attendee }] = useContext(SelectionContext);

  useEffect(() => {
    rootRef.current.scrollIntoView();
  }, []);

  const handleSubmit = (values) => addAdditionalAttendee(values);

  // TODO: possibly use the main attendee's country as default
  return (
    <section
      className={classes.root}
      data-testid="details-mobile"
      ref={rootRef}
    >
      <section className={classes.form}>
        <Formik
          initialValues={{
            ...Forms.attendeeInitialValues(selectedAttendee, questions),
            country: Item.get(
              selectedAttendee,
              'country',
              (attendee && attendee.country) || DEFAULT_COUNTRY,
            ),
          }}
          onSubmit={handleSubmit}
          render={(props) => (
            <AdditionalDetailsForm
              {...props}
              countries={countries}
              country={country}
              questions={questions}
              selectedAttendee={selectedAttendee}
              setUploads={setUploads}
            />
          )}
          validate={validate}
          validateOnChange={false}
        />
      </section>
    </section>
  );
};

AddAttendeePage.propTypes = {
  addAdditionalAttendee: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  country: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(QuestionShape),
  selectedAttendee: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      ),
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
  setUploads: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

AddAttendeePage.defaultProps = {
  questions: null,
  selectedAttendee: null,
};

export default AddAttendeePage;
