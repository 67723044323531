import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import ForwardChevron from '../../shared/icons/ForwardChevron';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: 'transparent',
    border: '0 none',
    cursor: 'pointer',
    padding: '0',
    margin: '0',
    textAlign: 'left',
    width: '100%',
    '&:focus': {
      outline: `2px solid ${theme.palette.neutral[350]}`,
      outlineOffset: '-2px',
    },
  },
  button: {
    display: 'flex',
    marginRight: '0.5rem',
  },
  content: {
    flexGrow: 1,
    paddingRight: '1rem',
  },
  icon: {
    fill: theme.palette.text.secondary,
  },
  inner: {
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
    display: 'flex',
    marginLeft: '1rem',
    padding: '1rem 0',
    width: 'calc(100% - 1rem)',
  },
}));

const RegionListCard = ({ ariaLabel, region, selectRegion, setSearching }) => {
  const classes = useStyles({ theme: useTheme() });

  const onClick = () => {
    setSearching(false);
    selectRegion(region);
  };

  return (
    <button
      aria-label={ariaLabel}
      className={classes.root}
      onClick={onClick}
      type="button"
    >
      <div className={classes.inner}>
        <div className={classes.content}>
          <Typography component="h2" variant="title">
            {region.name}
          </Typography>
        </div>
        <span className={classes.button}>
          <ForwardChevron className={classes.icon} />
        </span>
      </div>
    </button>
  );
};

RegionListCard.propTypes = {
  ariaLabel: PropTypes.string,
  region: PropTypes.shape({ name: PropTypes.string }).isRequired,
  selectRegion: PropTypes.func.isRequired,
  setSearching: PropTypes.func.isRequired,
};

RegionListCard.defaultProps = {
  ariaLabel: null,
};

export default RegionListCard;
