import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { useVendor } from '../../backend/contexts/VendorContext';

const FeatureDecisionContext = createContext({});

const useFeatureDecisions = () => useContext(FeatureDecisionContext);

const FeatureDecisionProvider = ({ children }) => {
  const { vendor } = useVendor();

  // in /backend, the features come from redux in the vendor object.
  // in /frontend, they come off window
  const features = vendor?.featureDecisions || window.state?.featureDecisions;

  return (
    <FeatureDecisionContext.Provider value={features}>
      {children}
    </FeatureDecisionContext.Provider>
  );
};

FeatureDecisionProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { FeatureDecisionContext, FeatureDecisionProvider, useFeatureDecisions };
