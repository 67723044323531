import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import AppointmentDetailsCard from '../../components/AppointmentDetailsCard';
import ManageAppointmentDetailsHeader from '../../components/ManageAppointmentDetailsHeader';
import ModernFeedback from '../../components/ModernFeedback';
import WhatNow from '../../components/WhatNow';
import { FeatureContext } from '../../contexts/FeatureContext';

const useStyles = createUseStyles({
  root: {
    maxHeight: '100%',
    flexGrow: 1,
    background: 'transparent',
    margin: '0 0.5rem',
    paddingTop: '2rem',
  },
});

const Feedback = () => {
  const classes = useStyles();
  const { appointmentBookingChannel } = useContext(FeatureContext);

  return (
    <section>
      <main>
        <div className={classes.root}>
          <ManageAppointmentDetailsHeader
            confirmation={false}
            rescheduled={false}
          />
          <AppointmentDetailsCard />
          <ModernFeedback />
          {appointmentBookingChannel ? <WhatNow /> : null}
        </div>
      </main>
    </section>
  );
};

export default Feedback;
