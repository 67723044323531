export default {
  /**
   * @returns {boolean}
   */
  shouldSetEarliestDate(earliestDate, newEarliestDate, areSlotsLoading) {
    // if the earliest date is already set or if we don't want to reset it back to null, we block the update.
    if (earliestDate && newEarliestDate !== null) {
      return false;
    }

    // if slots are still loading in chunks, we'll have to wait until the last update, so we block.
    if (areSlotsLoading) {
      return false;
    }

    // if we get here then we should tell them to set the earliest date.
    return true;
  },

  shouldUseNewSelectedDate(newEarliestDate, newSlots) {
    // we want to reset the earliest date when its null so do that.
    if (newEarliestDate === null) {
      return false;
    }

    // if we have slots then tell them we need to use the selected date.
    if (newSlots && Object.keys(newSlots).length > 0) {
      return true;
    }

    // if we get here then we just want to reset back to null.
    return false;
  },
};
