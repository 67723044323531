import Storage from './Storage';

const LIMIT_KEY = 'group-limit';

export const UNLIMITED = 'unlimited';

export default {
  /**
   * @returns {undefined}
   */
  clear() {
    Storage.clear(LIMIT_KEY);
  },

  /**
   * @returns {*}
   */
  get() {
    const limit = Storage.get(LIMIT_KEY);

    return limit === UNLIMITED ? UNLIMITED : Number(limit);
  },

  /**
   * @param {*} limit
   *
   * @returns {undefined}
   */
  set(limit = undefined) {
    Storage.set(LIMIT_KEY, limit === undefined ? UNLIMITED : limit);
  },
};
