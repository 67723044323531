import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import SelectableListPage from '../../components/mobile/SelectableListPage';
import { SHORTCUTS, USER_PREFERENCE } from '../../constants';
import { SelectionContext } from '../../contexts/SelectionContext';
import Resources from '../../helpers/Resources';
import Shortcuts from '../../helpers/Shortcuts';

const Languages = () => {
  const history = useHistory();

  const [{ user, userPreference }, setSelections] =
    useContext(SelectionContext);

  const selectPreference = ({ currentTarget: { dataset } }) => {
    const preference = dataset.id || USER_PREFERENCE.RANDOM;

    // We have to keep the preference as 'random' so that correct times page shows up
    setSelections({
      user:
        preference === USER_PREFERENCE.SPECIFIC
          ? Resources.formatUser(Shortcuts.get(SHORTCUTS.USER))
          : null,
      userPreference: {
        id:
          preference === USER_PREFERENCE.SPECIFIC
            ? USER_PREFERENCE.RANDOM
            : preference,
      },
    });
  };

  const { preferences = [] } = history.location.state;
  const preferred =
    Shortcuts.exists(SHORTCUTS.USER) && user
      ? { id: USER_PREFERENCE.SPECIFIC }
      : userPreference;

  const isChecked = (preference) => preference.id === preferred?.id;
  return (
    <SelectableListPage
      isChecked={isChecked}
      items={preferences}
      onSelect={selectPreference}
      title="Pages.supported_language_preference"
    />
  );
};

export default Languages;
