import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';

const defaultState = {
  error: null,
  latitude: null,
  loading: false,
  longitude: null,
};

const reducer = (state, newState) => ({ ...state, ...newState });

const PositionContext = createContext({
  coordinates: {
    latitude: null,
    longitude: null,
  },
  error: null,
  getPosition: () => {},
  loading: false,
  onFetched: () => {},
  valid: false,
});

const PositionProvider = ({ children }) => {
  const [state, setState] = useReducer(reducer, defaultState);
  const coordinates = {
    latitude: state.latitude,
    longitude: state.longitude,
  };
  const valid = coordinates.latitude && coordinates.longitude && !state.error;

  const getPosition = () => {
    if (valid) {
      // This "valid" block might look a little odd, but what is happening here,
      // is we are "faking" a lookup for the coordinates without sacrificing
      // the useEffect based infrastructure already in place. Instead of
      //  using another piece of state or some odd workaround to
      //  trigger the fetch for locations near the user again,
      //  we simply "flash" the state in order to make it
      //  look like it has changed, even though its
      //  receiving the same values again.
      setState({ loading: true, latitude: null, longitude: null });

      setTimeout(() => {
        setState({
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        });
      }, 100);

      setTimeout(onFetched, 500);
    } else {
      setState({ loading: true });

      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
  };

  const onFetched = () => {
    setState({ loading: false });
  };

  const onSuccess = (position) => {
    setState({
      error: null,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  };

  const onError = (error) => {
    setState({
      error: error.code,
      loading: false,
      latitude: null,
      longitude: null,
    });
  };

  return (
    <PositionContext.Provider
      value={{
        coordinates,
        error: state.error,
        getPosition,
        loading: state.loading,
        onFetched,
        valid,
      }}
    >
      {children}
    </PositionContext.Provider>
  );
};

PositionProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { PositionContext, PositionProvider };
