const Users = (client) => ({
  /**
   * @returns {Promise<any>}
   */
  find({ user, settings }) {
    return client.get(
      `/api/v2/open/users/${user}${
        settings?.invite_only_resources
          ? '?filter[invite_only_resources]=1'
          : ''
      }`,
    );
  },

  /**
   * @param users
   * @returns {*}
   */
  get(users) {
    const ids = users.join(',');
    return client.get(`/api/v2/open/users?filter[user]=${ids}`);
  },
});

export default Users;
