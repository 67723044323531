import { Dialog, Backdrops, Constraints } from '@coconut-software/ui';
import PropTypes from 'prop-types';
import React from 'react';
import ModalContent from './ModalContent';

const Modal = ({
  buttons,
  children,
  header,
  isOpen,
  modalContent,
  onRequestClose,
  testId,
  width,
}) => {
  return (
    <Dialog
      backdrop={Backdrops.Dark}
      constraint={width === 'large' ? Constraints.Padded : Constraints.Default}
      onDismiss={onRequestClose}
      open={isOpen}
    >
      {modalContent || (
        <ModalContent buttons={buttons} header={header} testId={testId}>
          {children}
        </ModalContent>
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  buttons: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  header: PropTypes.element,
  isOpen: PropTypes.bool,
  modalContent: PropTypes.element,
  onRequestClose: PropTypes.func,
  testId: PropTypes.string,
  width: PropTypes.oneOf(['large', 'default']),
};

Modal.defaultProps = {
  buttons: null,
  children: null,
  header: null,
  isOpen: false,
  modalContent: null,
  onRequestClose: () => {},
  testId: null,
  width: 'default',
};

export default Modal;
