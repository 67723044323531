import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import useToggleTranslations from '../../shared/hooks/useToggleTranslations';
import { useLocation } from './LocationContext';
import { useService } from './ServiceContext';

const LocaleContext = createContext({
  locale: 'en',
  locales: ['en'],
  setLocale: () => {},
});
LocaleContext.displayName = 'LocaleContext';

const useLocale = () => React.useContext(LocaleContext);

const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState(
    document.documentElement.getAttribute('lang'),
  );
  const [locales] = useState(window.state.locales || []);
  const { changeLang: changeLocationLang } = useLocation();
  const { changeLang: changeServiceLang } = useService();
  useToggleTranslations(locale, setLocale);

  const handleLocale = (loc) => {
    const url = new URL(window.location);
    url.searchParams.set('lang', loc);
    window.history.pushState({ path: url.href }, '', url.href);
    setLocale(loc);
    changeLocationLang(loc);
    changeServiceLang(loc);
  };

  return (
    <LocaleContext.Provider
      value={{ locale, locales, setLocale: handleLocale }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { LocaleContext, LocaleProvider, useLocale };
