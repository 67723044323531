import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import Dates from '../../shared/helpers/Dates';
import { SelectionContext } from '../contexts/SelectionContext';
import Api from '../helpers/Api';
import Formatter from '../helpers/Formatter';
import Locations from '../helpers/Locations';
import Timer from './icons/Timer';
import Typography from './Typography';

const useStyles = createUseStyles({
  hoverOver: {
    display: 'flex',
    '& > span': {
      paddingLeft: '0.3125rem',
      paddingTop: '0.0625rem',
    },
  },
});

const WaitTime = ({ hoverOver, location, setLocation }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [selections] = useContext(SelectionContext);
  const [loading, setLoading] = useState(location.waitTime.loading);
  const shouldLoad = Object.keys(location.waitTime).length <= 1;
  const { wait = null } = location.waitTime;
  const hasWait = wait !== null;

  useEffect(() => {
    let isMounted = true;

    if (shouldLoad && !loading && selections.service) {
      location.waitTime.loading = true;
      setLoading(true);

      Api.waitTimes()
        .find(selections.service.id, [location.id])
        .then(({ data: waitTimes }) => {
          const { expected_start: expectedStart } = waitTimes[0].attributes;
          location.waitTime = {
            loading: false,
            serviceId: waitTimes[0].id,
            wait:
              Locations.open(location.hours) && expectedStart
                ? Dates.diffMinutes(Dates.parse(expectedStart), Dates.now())
                : null,
          };

          if (isMounted) {
            setLoading(false);
            setLocation({ ...location });
          }
        });
    }

    return () => {
      isMounted = false;
    };

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selections.service) {
    return null;
  }

  const message = shouldLoad ? (
    <FormattedMessage id="WaitTime.loading" />
  ) : hasWait ? (
    <FormattedMessage
      id="WaitTime.wait"
      values={{ wait: Formatter.duration(wait, intl) }}
    />
  ) : (
    <FormattedMessage id="WaitTime.no_walkins_currently" />
  );

  return (
    <div>
      {hoverOver ? (
        <div className={classes.hoverOver} data-testid="wait-time-component">
          <Timer data-testid="timer" />
          <Typography data-testid="wait-time-text">{message}</Typography>
        </div>
      ) : (
        <div data-testid="wait-time-component">
          <Typography data-testid="wait-time-text" variant="subtitle">
            {message}
          </Typography>
        </div>
      )}
    </div>
  );
};

WaitTime.propTypes = {
  hoverOver: PropTypes.bool,
  location: PropTypes.shape({
    waitTime: PropTypes.shape({
      loading: PropTypes.bool,
      wait: PropTypes.number,
    }),
  }).isRequired,
  setLocation: PropTypes.func.isRequired,
};

WaitTime.defaultProps = {
  hoverOver: false,
};

export default WaitTime;
