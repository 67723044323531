import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router';
import { HeaderContext } from '../../contexts/HeaderContext';
import Checkmark from '../icons/Checkmark';
import List from '../List';
import Typography from '../Typography';

const useStyles = createUseStyles((theme) => ({
  section: {
    overflow: 'hidden',
    overflowY: 'auto',
  },
  separator: {
    marginTop: '0.25rem',
  },
  button: {
    backgroundColor: theme.palette.white,
    '&:last-child $content': {
      borderBottom: '0 none',
    },
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    textAlign: 'left',
    '& svg': {
      fill: theme.palette.neutral[400],
      height: '1.5rem',
      marginRight: '0.5rem',
    },
    alignItems: 'center',
    appearance: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    margin: 0,
    padding: '0.4rem 1.25rem',
    width: '100%',
    '&:hover': {
      background: theme.palette.neutral[100],
    },
    '&:focus': {
      background: theme.palette.neutral[100],
    },
  },
  title: {
    cursor: 'pointer',
    padding: '0.5rem',
  },
  hidden: {
    opacity: 0,
  },
}));

const SelectableListPage = ({ isChecked, items, onSelect, title }) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();

  const [, setHeader] = useContext(HeaderContext);

  useEffect(() => {
    setHeader({
      action: history.goBack,
      title: <FormattedMessage id={title} />,
    });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.goBack, setHeader]);

  return (
    <section className={classes.section}>
      <div className={classes.separator}>
        <List>
          {items.map((item) => (
            <li key={item.id}>
              <button
                className={classes.button}
                data-id={item.id}
                onClick={onSelect}
                type="button"
              >
                <Checkmark
                  altText={
                    isChecked(item)
                      ? intl.formatMessage({ id: 'Svg.alt_text.item_selected' })
                      : null
                  }
                  classes={{ root: isChecked(item) ? '' : classes.hidden }}
                />
                <Typography classes={{ root: classes.title }} variant="title">
                  <FormattedMessage
                    id={item.title}
                    values={item.values || {}}
                  />
                </Typography>
              </button>
            </li>
          ))}
        </List>
      </div>
    </section>
  );
};

SelectableListPage.propTypes = {
  isChecked: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      values: PropTypes.objectOf(PropTypes.string),
    }),
  ).isRequired,
};

export default SelectableListPage;
