import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import List from './List';
import ServiceDetails from './ServiceDetails';
import SinglePageCard from './SinglePageCard';
import Typography from './Typography';

const useStyles = createUseStyles({
  mobileServices: {
    flexGrow: 1,
    paddingTop: '1rem',
  },
  services: {
    flexGrow: 1,
    padding: '1rem 2rem',
  },
  title: {
    marginBottom: '0.5rem',
  },
});

const SinglePageCategory = ({
  category,
  inListItem,
  isMobile,
  selectService,
  services,
}) => {
  const classes = useStyles();
  const categoryServices = services.filter((service) =>
    category ? service.categories.includes(category.id) : true,
  );

  const content = categoryServices.length ? (
    <section
      className={classNames(
        isMobile ? classes.mobileServices : classes.services,
      )}
    >
      <div className={classes.title}>
        {category ? (
          <Typography
            classes={{ root: classes.name }}
            component="h3"
            variant="h6"
          >
            {category.name}
          </Typography>
        ) : null}
      </div>
      <List>
        {categoryServices.map((service) => {
          return (
            <SinglePageCard
              action={() => selectService(service, category)}
              details={
                <ServiceDetails
                  description={service.description}
                  duration={service.duration}
                  group={service.group}
                  id={service.id}
                  maximum={service.maxDuration}
                  minimum={service.minDuration}
                />
              }
              icon
              id={service.id}
              isMobile={isMobile}
              key={service.id}
              primary={service.name}
            />
          );
        })}
      </List>
    </section>
  ) : null;

  return content && inListItem ? <li>{content}</li> : content;
};

SinglePageCategory.propTypes = {
  category: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  inListItem: PropTypes.bool,
  isMobile: PropTypes.bool,
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
};

SinglePageCategory.defaultProps = {
  category: null,
  inListItem: false,
  isMobile: false,
  services: [],
};

export default SinglePageCategory;
