import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { PAGES } from '../constants';
import { AppointmentContext } from '../contexts/AppointmentContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import Tracker from '../helpers/Tracker';

const PARAMETERLESS = [PAGES.CANCEL, PAGES.MANAGE];

const TrackPageView = ({ identifier }) => {
  const { firstStep } = useContext(SettingsContext);
  const [selections] = useContext(SelectionContext);
  const [appointment] = useContext(AppointmentContext);
  const [locale] = useContext(LocaleContext);

  useEffect(() => {
    const query =
      PARAMETERLESS.includes(identifier) ||
      window.location.search.includes('email')
        ? ''
        : window.location.search;
    Tracker.view(
      identifier + query,
      Tracker.getTrackingData(identifier, firstStep, {
        ...selections,
        confirmCode: appointment.confirmCode,
        locale: locale,
      }),
    );
  }, [identifier]);

  return null;
};

TrackPageView.propTypes = {
  identifier: PropTypes.string.isRequired,
};

export default TrackPageView;
