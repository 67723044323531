import { MEETING_METHODS, METHODS, SHORTCUTS, STEPS } from '../constants';
import Shortcuts from '../helpers/Shortcuts';

function shouldSkipLocationStep(
  meetingMethod,
  service,
  firstStep,
  includeLocationStep,
) {
  const firstStepIsLocation = firstStep === STEPS.LOCATION;
  const otherPhysicalLocationSelectable =
    meetingMethod === MEETING_METHODS.AT_LOCATION &&
    Shortcuts.get(SHORTCUTS.SETTINGS)?.preferred_location;
  const skipLocation =
    firstStepIsLocation ||
    (Shortcuts.exists(SHORTCUTS.LOCATION) && !otherPhysicalLocationSelectable);
  // if no service is selected yet, we cannot determine that we need to skip location step
  const requireLocationForVirtual = service
    ? includeLocationStep || service.requireLocation
    : true;
  const isVirtualMeeting =
    meetingMethod === MEETING_METHODS.PHONE_CALL ||
    meetingMethod === MEETING_METHODS.VIDEO_CALL;
  const shouldIncludeLocation =
    meetingMethod === MEETING_METHODS.AT_LOCATION ||
    (requireLocationForVirtual && isVirtualMeeting);

  return skipLocation || !shouldIncludeLocation;
}

function shouldSkipMethodsStep(methods) {
  const onlyPhysical =
    methods.length === 1 && methods[0].id === MEETING_METHODS.AT_LOCATION;

  return Shortcuts.exists(SHORTCUTS.MEETING_METHOD) || onlyPhysical;
}

function shouldSkipMethodsAndLocationStep(
  meetingMethod,
  methods,
  service,
  firstStep,
  includeLocationStep,
) {
  return (
    shouldSkipLocationStep(
      meetingMethod,
      service,
      firstStep,
      includeLocationStep,
    ) && shouldSkipMethodsStep(methods)
  );
}

function filterMethods(service, user) {
  let meetingMethods;

  if (Shortcuts.exists(SHORTCUTS.MEETING_METHOD)) {
    meetingMethods = [{ id: Shortcuts.get(SHORTCUTS.MEETING_METHOD) }];
  } else {
    meetingMethods = service
      ? METHODS.filter((method) => service.meetingMethods.includes(method.id))
      : METHODS;

    if (user) {
      meetingMethods = meetingMethods.filter((method) =>
        user.meetingMethods.includes(method.id),
      );
    }
  }

  return meetingMethods;
}

export {
  filterMethods,
  shouldSkipLocationStep,
  shouldSkipMethodsStep,
  shouldSkipMethodsAndLocationStep,
};
