import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { v4 as uuidv4 } from 'uuid';
import { QUESTION_TYPES } from '../../constants';
import Api from '../../helpers/Api';
import Math from '../../helpers/Math';
import CircularProgress from '../CircularProgress';
import CheckmarkCircle from '../icons/CheckmarkCircle';
import Clear from '../icons/Clear';
import CloudUpload from '../icons/CloudUpload';
import Typography from '../Typography';
import InputErrors from './InputErrors';

const useStyles = createUseStyles((theme) => ({
  button: {
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    borderRadius: theme.borderRadius.full,
    cursor: 'pointer',
    display: 'inline-flex',
    margin: 0,
    padding: 0,
    '& svg': {
      fill: theme.palette.neutral[400],
      height: theme.textSizes.base,
      width: theme.textSizes.base,
    },
    '&[disabled]': {
      cursor: 'default',
      cursorEvents: 'none',
      opacity: 0,
    },
    '&:focus': {
      boxShadow: theme.shadows.input,
      '& svg': {
        fill: theme.palette.black,
      },
    },
    '&:not(:first-of-type)': {
      marginLeft: '0.5rem',
    },
  },
  checkmarkIcon: {
    fill: theme.palette.success[400],
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  dropzone: {
    alignItems: 'center',
    border: `0.1rem dashed ${theme.palette.neutral[200]}`,
    borderRadius: '0.2rem',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '8rem',
    justifyContent: 'center',
  },
  fileData: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileName: {
    color: theme.palette.neutral[400],
    fontSize: theme.textSizes.sm,
  },
  fileRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& div:last-child': {
      flexGrow: 100,
      textAlign: 'right',
    },
    gap: '1.75rem',
    marginTop: '1rem',
  },
  fileSize: {
    textAlign: 'left !important',
  },
  files: {
    margin: '1rem',
  },
  helpText: {
    cursor: 'pointer',
    display: 'inline-block',
    fontWeight: theme.fontWeights.normal,
    marginBottom: '0.5rem',
  },
  icon: {
    fill: theme.palette.neutral[200],
  },
  instructions: {
    color: theme.palette.neutral[400],
    fontSize: theme.textSizes.sm,
    '& > span': {
      color: theme.palette.primary[400],
    },
  },
  label: {
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0.5rem',
  },
  optional: {
    marginLeft: '0.25rem',
  },
  statusIcon: {
    minWidth: '1.5rem',
  },
}));

const VERIFICATION_STATUS = {
  PENDING: null,
  FAILED: 0,
  PASSED: 1,
};

const UploadInput = ({
  errors,
  helpText,
  label,
  name,
  optional,
  setUploads,
  setValue,
  uploads,
  value,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });
  const uploadedFiles = useRef([]);
  const [uploadedFilesStatuses, setUploadedFilesStatuses] = useState({});

  const verifyFile = (key, file) => {
    uploadedFilesStatuses[key] = VERIFICATION_STATUS.PENDING;
    setUploadedFilesStatuses({ ...uploadedFilesStatuses });

    Api.verifiedFiles()
      .verify(key, file)
      .then((data) => {
        if (Object.prototype.hasOwnProperty.call(uploadedFilesStatuses, key)) {
          uploadedFilesStatuses[data.key] = data.passed
            ? VERIFICATION_STATUS.PASSED
            : VERIFICATION_STATUS.FAILED;

          setUploadedFilesStatuses({ ...uploadedFilesStatuses });
        }
      });
  };

  const removeFile = (key) => {
    const previousUploadedFiles = uploadedFiles.current;
    uploadedFiles.current = uploadedFiles.current.filter(
      (uploadedFile) => uploadedFile.key !== key,
    );
    setUploads(uploadedFiles.current, previousUploadedFiles);

    const fileIds = uploadedFiles.current.map(
      (uploadedFile) => uploadedFile.key,
    );

    const value =
      fileIds.length > 0
        ? { type: QUESTION_TYPES.UPLOAD, files: fileIds }
        : null;
    setValue({ target: { name, value } });

    delete uploadedFilesStatuses[key];
    setUploadedFilesStatuses({ ...uploadedFilesStatuses });
  };

  const onDrop = (acceptedFiles) => {
    const previousUploadedFiles = uploadedFiles.current;
    const newUploadedFiles = [];
    const fileIds = [];

    // accept only one file for the file upload
    if (acceptedFiles.length > 0) {
      const fileId = uuidv4();
      fileIds.push(fileId);

      const file = acceptedFiles[0];

      newUploadedFiles.push({
        key: fileId,
        file,
      });

      uploadedFiles.current = newUploadedFiles;

      verifyFile(fileId, file);
    }

    // push the selection up to the context
    setValue({
      target: { name, value: { type: QUESTION_TYPES.UPLOAD, files: fileIds } },
    });
    setUploads(newUploadedFiles, previousUploadedFiles);

    // clean up the statuses for the keys that are being removed
    previousUploadedFiles.forEach(({ key }) => {
      delete uploadedFilesStatuses[key];
    });
    setUploadedFilesStatuses({ ...uploadedFilesStatuses });
  };

  useEffect(() => {
    // nothing to reload
    if (!value || !value.files.length) {
      return;
    }

    // restore the uploaded files
    if (uploads.length > 0) {
      // clear upload selections if the page was refreshed
      // since the uploaded files are no longer valid
      if (!uploads[0].file.name) {
        setValue({ target: { name, value: null } });
        setUploads([], uploads);

        return;
      }

      uploadedFiles.current = uploads.filter((uploadedFile) =>
        value.files.some((key) => key === uploadedFile.key),
      );
    }

    // restore the statuses
    value.files.forEach((key) => {
      uploadedFilesStatuses[key] = VERIFICATION_STATUS.PASSED;
    });
    setUploadedFilesStatuses({ ...uploadedFilesStatuses });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.container}>
            <div className={classNames(classes.label)}>
              <Typography variant="label">{label}</Typography>
              {optional ? (
                <Typography
                  classes={{ root: classes.optional }}
                  variant="body2"
                >
                  <FormattedMessage id="Form.optional" />
                </Typography>
              ) : null}
            </div>
            {helpText ? (
              <div className={classNames(classes.helpText)}>
                <Typography
                  classes={{
                    root: classNames(classes.helpText),
                  }}
                  variant="label"
                >
                  <span dangerouslySetInnerHTML={{ __html: helpText }} />
                </Typography>
              </div>
            ) : null}
            <div className={classNames(classes.dropzone)}>
              <input {...getInputProps()} />
              <span className={classes.icon}>
                <CloudUpload />
              </span>
              <Typography className={classes.instructions} variant="body2">
                <FormattedMessage
                  id="Dropzone.instructions"
                  values={{ span: (chunks) => <span>{chunks}</span> }}
                />
              </Typography>
            </div>
          </div>
        )}
      </Dropzone>
      {errors.length > 0 ? (
        <InputErrors errors={errors} id={`error-${name}`} />
      ) : null}
      {uploadedFiles.current.length > 0 ? (
        <div className={classNames(classes.files)}>
          <ul>
            {uploadedFiles.current.map(({ key, file }) => (
              <li key={file.name}>
                <div className={classes.fileRow}>
                  <div className={classes.statusIcon}>
                    {uploadedFilesStatuses[key] ===
                    VERIFICATION_STATUS.PENDING ? (
                      <CircularProgress centered={false} size="1.25rem" />
                    ) : (
                      <div className={classes.checkmarkIcon}>
                        <CheckmarkCircle />
                      </div>
                    )}
                  </div>
                  <div className={classes.fileData}>
                    <div className={classes.fileName}>
                      <Typography variant="body2">{file.name}</Typography>
                    </div>
                    <div className={classes.fileSize}>
                      <Typography variant="body1">
                        {Math.convertBytes(file.size)}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <button
                      aria-label={intl.formatMessage({ id: 'Dropzone.delete' })}
                      className={classes.button}
                      onClick={() => removeFile(key)}
                      type="button"
                    >
                      <Clear />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};

UploadInput.propTypes = {
  ariaRequired: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  ),
  helpText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  setUploads: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  uploads: PropTypes.arrayOf(PropTypes.instanceOf(File)),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

UploadInput.defaultProps = {
  ariaRequired: false,
  errors: [],
  helpText: null,
  id: '',
  optional: false,
  uploads: [],
  value: null,
};

export default UploadInput;
