import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import Dates from '../../shared/helpers/Dates';
import { CALLS_TO_ACTION } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import Item from '../helpers/Item';
import SpacetimeShape from '../shapes/SpacetimeShape';
import CallToAction from './CallToAction';
import EmptyState from './EmptyState';
import NoAvailableTimes from './icons/NoAvailableTimes';
import TimeChunk from './TimeChunk';

const useStyles = createUseStyles({
  action: {
    marginRight: '0.375rem',
    marginLeft: '0.375rem',
    marginTop: '1.5rem',
  },
  actionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  emptyState: {
    flexGrow: 0,
    padding: '0rem 1.25rem',
  },
  title: {
    textAlign: 'center',
  },
});

const TimeChunks = ({
  error,
  bordered,
  different,
  group,
  selectTime,
  selected,
  slots,
  specificUser,
}) => {
  const classes = useStyles();

  const {
    callsToAction: actions,
    customContact,
    customContactAdditionalMessage,
    customContactHeading,
    showLocationPhoneCallToAction,
  } = useContext(SettingsContext);

  const [{ location, user }] = useContext(SelectionContext);
  const callsToAction = [...(actions || [])];
  const HeaderDefault = error ? (
    <FormattedMessage id={error.messageTitleKey} values={error.messageValues} />
  ) : selected ? (
    <FormattedMessage
      id="TimeChunks.no_available_times_on"
      values={{ date: Dates.toDateMonthYear(selected) }}
    />
  ) : (
    <FormattedMessage id="TimeChunks.no_available_times" />
  );
  const emptyStateHeader = customContact ? customContactHeading : HeaderDefault;
  const emptyStateMessage = customContact ? (
    customContactAdditionalMessage
  ) : error ? (
    <FormattedMessage
      id={error.messageSubtitleKey}
      values={error.messageValues}
    />
  ) : (
    <FormattedMessage id="TimeChunks.select_another_day" />
  );
  const hasSelectedSpecificUser = specificUser && user === null;

  if (
    customContact &&
    showLocationPhoneCallToAction &&
    location &&
    Item.has(location, 'phone')
  ) {
    callsToAction.unshift({
      id: 0,
      type: CALLS_TO_ACTION.PHONE,
      value: location.phone,
    });
  }

  const chunks = {
    morning: {
      id: 'TimeChunks.morning',
      slots: [],
    },
    afternoon: {
      id: 'TimeChunks.afternoon',
      limit: 12,
      slots: [],
    },
    evening: {
      id: 'TimeChunks.evening',
      limit: 18,
      slots: [],
    },
  };

  slots.forEach((slot) => {
    const hour = slot.start.unixFmt('HH');

    if (hour < chunks.afternoon.limit) {
      chunks.morning.slots.push(slot);
    } else if (hour >= chunks.afternoon.limit && hour < chunks.evening.limit) {
      chunks.afternoon.slots.push(slot);
    } else {
      chunks.evening.slots.push(slot);
    }
  });

  return (
    <span>
      <span>
        {slots.length === 0 || error ? (
          <EmptyState
            action={
              !hasSelectedSpecificUser && customContact ? (
                <div className={classes.actionsContainer}>
                  {callsToAction.map(({ id, label, type, value }) => (
                    <div className={classes.action} key={id}>
                      <CallToAction label={label} type={type} value={value} />
                    </div>
                  ))}
                </div>
              ) : null
            }
            classes={{
              overrides: classNames(classes.emptyState, classes.title),
            }}
            icon={<NoAvailableTimes />}
            subtitle={specificUser && !user ? null : emptyStateMessage}
            title={
              specificUser && !user ? (
                <FormattedMessage id="TimeChunks.select_user" />
              ) : (
                emptyStateHeader
              )
            }
          />
        ) : null}
      </span>
      {group ? (
        <TimeChunk
          bordered={bordered}
          different={different}
          group
          handleClickTime={selectTime}
          label={null}
          slots={slots}
          specificUser={specificUser}
        />
      ) : null}
      {!group && slots.length !== 0 ? (
        <>
          <TimeChunk
            bordered={bordered}
            different={different}
            handleClickTime={selectTime}
            id="morning"
            key={chunks.morning.id}
            label={<FormattedMessage id={chunks.morning.id} />}
            slots={chunks.morning.slots}
            specificUser={specificUser}
          />
          <TimeChunk
            bordered={bordered}
            different={different}
            handleClickTime={selectTime}
            id="afternoon"
            key={chunks.afternoon.id}
            label={<FormattedMessage id={chunks.afternoon.id} />}
            slots={chunks.afternoon.slots}
            specificUser={specificUser}
          />
          <TimeChunk
            bordered={bordered}
            different={different}
            handleClickTime={selectTime}
            id="evening"
            key={chunks.evening.id}
            label={<FormattedMessage id={chunks.evening.id} />}
            slots={chunks.evening.slots}
            specificUser={specificUser}
          />
        </>
      ) : null}
    </span>
  );
};

TimeChunks.propTypes = {
  bordered: PropTypes.bool,
  different: PropTypes.bool,
  group: PropTypes.bool,
  selectTime: PropTypes.func.isRequired,
  slots: PropTypes.arrayOf(
    PropTypes.shape({
      end: SpacetimeShape,
      start: SpacetimeShape,
    }),
  ).isRequired,
  specificUser: PropTypes.bool,
  selected: SpacetimeShape,
};

TimeChunks.defaultProps = {
  bordered: false,
  different: false,
  group: false,
  selected: null,
  specificUser: false,
};

export default TimeChunks;
