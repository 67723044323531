/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import InputOptionShape from '../../shapes/InputOptionShape';
import Typography from '../Typography';
import HelpText from './HelpText';
import InputErrors from './InputErrors';
import RadioInput from './RadioInput';
import TextInput from './TextInput';

const useStyles = createUseStyles({
  additional: {
    marginTop: '0.5rem',
  },
  label: {
    cursor: 'pointer',
  },
  optional: {
    marginLeft: '0.25rem',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
});

const RadioGroup = ({
  additionalName,
  ariaRequired,
  defaultChecked,
  errors,
  helpText,
  id,
  label,
  name,
  onChange,
  optional,
  options,
  value,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <fieldset aria-required={ariaRequired}>
      <legend>
        <Typography classes={{ root: classes.label }} variant="label">
          {label}
        </Typography>
        {optional ? (
          <Typography classes={{ root: classes.optional }} variant="body2">
            <FormattedMessage id="Form.optional" />
          </Typography>
        ) : null}
        <span className={classes.visuallyHidden}>{errors}</span>
      </legend>
      {options.map((option) => (
        <Fragment key={`${option.text}-${option.value}`}>
          <RadioInput
            defaultChecked={option.value.toString() === defaultChecked}
            id={id || name}
            label={option.text}
            name={name}
            onChange={onChange}
            value={option.value}
          />
          {option.additional && value === String(option.value) ? (
            <div className={classes.additional}>
              <TextInput
                hideLabel
                key={`${option.text}-${option.value}-additional`}
                label={intl.formatMessage({ id: 'Input.additional_input' })}
                name={additionalName}
                onChange={onChange}
              />
            </div>
          ) : null}
        </Fragment>
      ))}
      {errors.length > 0 ? (
        <InputErrors errors={errors} />
      ) : (
        <HelpText value={helpText} />
      )}
    </fieldset>
  );
};

RadioGroup.propTypes = {
  additionalName: PropTypes.string,
  ariaRequired: PropTypes.bool,
  defaultChecked: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  helpText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  options: PropTypes.arrayOf(InputOptionShape),
  value: PropTypes.string.isRequired,
};

RadioGroup.defaultProps = {
  additionalName: '',
  ariaRequired: false,
  defaultChecked: '',
  errors: [],
  helpText: null,
  id: '',
  options: [],
};

export default RadioGroup;
