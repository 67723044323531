import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AppointmentContext } from '../contexts/AppointmentContext';
import { SelectionContext } from '../contexts/SelectionContext';
import Api from '../helpers/Api';
import Validator from '../helpers/Validator';
import MeetingMethodForm from './forms/MeetingMethodForm';
import Modal from './Modal';

const MeetingMethodModal = ({
  meetingMethods,
  loading,
  open,
  toggleModal,
  setTooLate,
}) => {
  const [appointment, setAppointment] = useContext(AppointmentContext);
  const [, setSelections] = useContext(SelectionContext);

  const handleSubmit = (values) => {
    if (values.meetingMethod) {
      Api.methods()
        .update({
          code: appointment.confirmCode,
          id: appointment.id,
          method: values.meetingMethod,
        })
        .then((response) => {
          setAppointment({
            meetingLink: response.data.attributes.meeting_link,
          });
          setSelections({ meetingMethod: Number(values.meetingMethod) });
          toggleModal();
        })
        .catch(() => {
          toggleModal();
          setTooLate();
        });
    }
  };

  const validate = (values) => Validator.meetingMethod(values);

  return (
    <Modal
      isOpen={open}
      label="MeetingMethodModal"
      modalContent={
        <Formik
          initialValues={{ meetingMethod: '' }}
          onSubmit={handleSubmit}
          render={(props) => (
            <MeetingMethodForm
              {...props}
              loading={loading}
              meetingMethods={meetingMethods}
              toggleModal={toggleModal}
            />
          )}
          validate={validate}
        />
      }
      onRequestClose={toggleModal}
    />
  );
};

MeetingMethodModal.propTypes = {
  loading: PropTypes.func.isRequired,
  meetingMethods: PropTypes.arrayOf(PropTypes.number).isRequired,
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  setTooLate: PropTypes.func.isRequired,
};

export default MeetingMethodModal;
