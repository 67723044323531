import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { SnackContext } from '../contexts/SnackContext';
import { MOBILE } from '../contexts/ViewModeContext';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.black,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.lg,
    color: theme.palette.white,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
    position: 'fixed',
    zIndex: 1000,
  },
  desktopRoot: {
    bottom: '1.5rem',
    left: '1.5rem',
    right: 'auto',
    '& > span:last-child': {
      // undo button only on Desktop
      marginLeft: '10rem',
    },
  },
  mobileRoot: {
    margin: '1.5rem',
    top: 0,
    width: 'calc(100% - 3rem)',
  },
  undo: {
    cursor: 'pointer',
  },
}));

const Snackbar = ({ mode }) => {
  const classes = useStyles({ theme: useTheme() });
  const [snacks] = useContext(SnackContext);

  if (!snacks.length) {
    return <div />;
  }

  return (
    <div
      className={classNames(
        classes.root,
        mode === MOBILE ? classes.mobileRoot : classes.desktopRoot,
      )}
    >
      {snacks[0].snackMessage}
      <Typography
        classes={{ root: classes.undo }}
        onClick={snacks[0].handleUndo}
        primary
        variant="button"
      >
        <FormattedMessage id="Ui.undo" />
      </Typography>
    </div>
  );
};

Snackbar.propTypes = {
  mode: PropTypes.number.isRequired,
};

export default Snackbar;
