import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from './Button';

const LocationPhoneCallButton = ({ location }) => {
  if (location.phone) {
    const openPhoneCall = () => {
      window.open(`tel:${location.phone}`, '_self');
    };

    return (
      <Button
        data-testid="phone-location-button"
        fullWidth={false}
        onClick={openPhoneCall}
        variant="smallOutlined"
      >
        <FormattedMessage
          id="LocationPhoneCallButton.text"
          values={{ number: location.phone }}
        />
      </Button>
    );
  }

  return null;
};

LocationPhoneCallButton.propTypes = {
  location: PropTypes.shape({
    phone: PropTypes.string,
  }).isRequired,
};

export default LocationPhoneCallButton;
