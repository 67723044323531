/* eslint-disable react/jsx-curly-newline */
import { Divider } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { MAX_FIND_AVAILABILITY_SEARCH_RANGE_MINUTES } from '../../shared/constants';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import Dates from '../../shared/helpers/Dates';
import { USER_PREFERENCE } from '../constants';
import { FeatureContext } from '../contexts/FeatureContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { UsersContext } from '../contexts/UsersContext';
import { useFindAvailabilityMethods } from '../hooks/useFetchSlots';
import SpacetimeShape from '../shapes/SpacetimeShape';
import Button from './Button';

const useStyles = createUseStyles(() => ({
  availabilityContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    maxWidth: '24rem',
  },
  availabilityContainerNewView: {
    gap: '1rem',
    maxWidth: '45rem',
  },
  availabilityLoader: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'fit-content',
  },
  availabilityButtonWidth: {
    flex: '50%',
  },
  dividerSpacing: {
    margin: '1.25rem 0',
  },
}));

const FindAvailableDate = ({
  earliestDate,
  error,
  loading,
  range,
  selectDate,
  selectedDate,
  setInformation,
  setRange,
  slots,
}) => {
  const [
    {
      additionalUsers,
      googleUser,
      location,
      locationCategory,
      meetingMethod,
      service,
      settings,
      user,
      userCategory,
      userPreference,
    },
  ] = useContext(SelectionContext);
  const [timezone] = useContext(TimezoneContext);
  const features = useContext(FeatureContext);
  const { supportedLanguages } = useContext(UsersContext);
  const { shouldUseNextAvailabilityNewView } = useContext(
    FeatureDecisionContext,
  );
  const { fetchNextAvailableSlots } = useFindAvailabilityMethods({
    additionalUsers,
    error,
    features,
    fetch,
    googleUser,
    location,
    locationCategory,
    meetingMethod,
    preferred: userPreference || { id: USER_PREFERENCE.RANDOM },
    merge: false,
    range,
    service,
    setInformation,
    setRange,
    settings,
    shouldUseChunks: true,
    supportedLanguages,
    timezone,
    user,
    userCategory:
      userPreference?.id === USER_PREFERENCE.SPECIFIC || user
        ? null
        : userCategory,
  });

  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const { timeInAdvance } = useContext(SettingsContext);

  const timeInAdvanceMinutes =
    timeInAdvance && timeInAdvance < MAX_FIND_AVAILABILITY_SEARCH_RANGE_MINUTES
      ? timeInAdvance
      : MAX_FIND_AVAILABILITY_SEARCH_RANGE_MINUTES;

  async function findNextDate(startDate = null) {
    if (!startDate) {
      const days = Object.keys(slots).sort(
        (date1, date2) => new Date(date1) - new Date(date2),
      );
      const nextIndex = days.findIndex(
        (day) => Dates.toDateString(selectedDate) < day,
      );

      if (nextIndex >= 0) {
        selectDate(Dates.parse(days[nextIndex]));
        window.scrollTo(0, 0);
        return;
      }
    }

    setInformation({
      loadingMessage: startDate
        ? 'FindAvailableDate.finding_first_time'
        : 'FindAvailableDate.finding_next_time',
    });

    await fetchNextAvailableSlots(
      startDate ? startDate : selectedDate.clone().add(1, 'day'),
      timeInAdvanceMinutes,
      range.unit,
    );

    window.scrollTo(0, 0);
  }

  async function findFirstDate() {
    // if the earliest day is already loaded, just jump to it.
    if (shouldUseNextAvailabilityNewView) {
      if (
        earliestDate &&
        selectedDate &&
        earliestDate.isSame(selectedDate, range.unit)
      ) {
        selectDate(earliestDate);

        return;
      }
    }

    findNextDate(Dates.today());
  }

  const nextAvailabilityButton = () => {
    if (
      shouldUseNextAvailabilityNewView &&
      Object.keys(slots).length === 0 &&
      Dates.today().isSame(selectedDate, 'day')
    ) {
      return null;
    }

    const labelId = shouldUseNextAvailabilityNewView
      ? 'FindAvailableDate.next_available'
      : 'FindAvailableDate.next_available_date';

    return (
      <Button
        aria-label={intl.formatMessage({ id: labelId })}
        disabled={loading}
        onClick={() => findNextDate(null)}
        size="medium"
        type="button"
        variant="outlined"
      >
        <FormattedMessage id={labelId} />
      </Button>
    );
  };

  const firstAvailabilityButton = () => {
    if (
      shouldUseNextAvailabilityNewView &&
      Dates.toDateString(selectedDate) === Dates.toDateString(earliestDate)
    ) {
      return null;
    }

    const labelId = shouldUseNextAvailabilityNewView
      ? 'FindAvailableDate.earliest_available'
      : 'FindAvailableDate.first_available_date';

    return (
      <Button
        aria-label={intl.formatMessage({ id: labelId })}
        disabled={loading}
        onClick={findFirstDate}
        size="medium"
        type="button"
        variant="outlined"
      >
        <FormattedMessage id={labelId} />
      </Button>
    );
  };

  return (
    <>
      {!shouldUseNextAvailabilityNewView ? (
        <div className={classes.dividerSpacing}>
          <Divider className={classes.divider} />
        </div>
      ) : null}
      <div
        className={classNames(
          classes.availabilityContainer,
          shouldUseNextAvailabilityNewView
            ? classes.availabilityContainerNewView
            : '',
        )}
      >
        {firstAvailabilityButton()}
        {nextAvailabilityButton()}
      </div>
    </>
  );
};

FindAvailableDate.propTypes = {
  earliestDate: SpacetimeShape,
  loading: PropTypes.bool,
  selectDate: PropTypes.func.isRequired,
  setInformation: PropTypes.func.isRequired,
  setRange: PropTypes.func.isRequired,
  selectedDate: SpacetimeShape.isRequired,
  slots: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({ end: SpacetimeShape, start: SpacetimeShape }),
    ),
  ).isRequired,
};

FindAvailableDate.defaultProps = {
  earliestDate: null,
  error: null,
  loading: true,
};

export default FindAvailableDate;
