import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const PhoneCall = ({ altText, classes }) => {
  const intl = useIntl();

  return (
    <svg
      className={classes.root}
      height="19.997"
      width="19.997"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.phone' })}
      </title>
      <path d="M0 0h20v20H0z" fill="none" />
      <path
        d="M5.45 4.166a10.259 10.259 0 00.375 2.158l-1 1a12.353 12.353 0 01-.633-3.158H5.45m8.216 10.015a10.626 10.626 0 002.166.375V15.8a12.854 12.854 0 01-3.166-.625l1-.992M6.25 2.5H3.333a.836.836 0 00-.833.833A14.164 14.164 0 0016.665 17.5a.836.836 0 00.835-.835v-2.908a.836.836 0 00-.833-.833 9.5 9.5 0 01-2.975-.475.7.7 0 00-.258-.042.853.853 0 00-.592.242l-1.833 1.833a12.622 12.622 0 01-5.493-5.491l1.833-1.833a.836.836 0 00.208-.85 9.466 9.466 0 01-.475-2.975.836.836 0 00-.832-.833z"
        fill="#616161"
      />
    </svg>
  );
};

PhoneCall.propTypes = {
  altText: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};

PhoneCall.defaultProps = {
  altText: '',
  classes: {
    root: '',
  },
};

export default PhoneCall;
