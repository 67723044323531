export default function synonymReplacement(translations, synonyms) {
  if (!synonyms || synonyms.length === 0) {
    return translations;
  }

  const translationsWithSynonyms = { ...translations };
  for (const key of Object.keys(translationsWithSynonyms)) {
    for (let i = 0; i < synonyms.length; i++) {
      const exclusions = synonyms[i].exclusions ? synonyms[i].exclusions : '';
      const suffix = exclusions ? `(?!(${exclusions}))` : '';
      const prefix = exclusions ? `(?<!(${exclusions}))` : '';

      const replacement = synonyms[i].replacement;
      const subject = synonyms[i].subject;

      const pattern = new RegExp(
        prefix + // Negative-lookbehind using a bar separated list of exclusions
          `${subject}` + // The text to match on
          suffix + // Negative-lookahead using a bar separated list of exclusions
          '(?![\\w\\s]*[}])', // Exclude matches wrapped in curly brackets (used for variables in js translations)
        'g', // Global flag. Replace all matches found in the string.
      );

      translationsWithSynonyms[key] = translationsWithSynonyms[key].replace(
        pattern,
        replacement,
      );
    }
  }

  return translationsWithSynonyms;
}
