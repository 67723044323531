import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const DropDownArrow = ({ altText }) => {
  const intl = useIntl();

  return (
    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.drop_down_arrow' })}
      </title>
      <path d="M7 10l5 5 5-5z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

DropDownArrow.propTypes = {
  altText: PropTypes.string,
};

DropDownArrow.defaultProps = {
  altText: '',
};

export default DropDownArrow;
