const LobbyAvailability = (client) => ({
  /**
   * @param location
   * @param service
   * @param meetingMethod
   * @param language
   * @param user
   *
   * @returns {Promise<any>}
   */
  all({ location, service, meetingMethod, language, includeWaitTime }) {
    const params = {};

    if (location) {
      params.location = parseInt(location, 10);
    }

    if (service) {
      params.service = parseInt(service, 10);
    }

    if (meetingMethod) {
      params.meeting_method = meetingMethod;
    }

    if (language) {
      params.language = language;
    }

    if (includeWaitTime) {
      params.include_wait_time = includeWaitTime;
    }

    const query = Object.keys(params)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      )
      .join('&');

    return client.get(
      `/api/v3/open/lobby-availability${query ? `?${query}` : ''}`,
    );
  },
});

export default LobbyAvailability;
