import classNames from 'classnames';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import GeneralError from '../components/GeneralError';
import LoadingState from '../components/LoadingState';
import { HEADER_HEIGHT } from '../constants';
import { SettingsContext } from '../contexts/SettingsContext';
import { DESKTOP, ViewModeContext } from '../contexts/ViewModeContext';

const useStyles = createUseStyles({
  root: {
    height: `calc(100% - ${HEADER_HEIGHT.MOBILE})`,
  },
  desktop: {
    height: `calc(100% - ${HEADER_HEIGHT.DESKTOP})`,
  },
});

const Disabled = () => {
  const classes = useStyles();
  const mode = useContext(ViewModeContext);
  const { disabledDescription, disabledTitle, loading } =
    useContext(SettingsContext);

  let icon = true;
  let title = <FormattedMessage id="NotAvailable.title" />;
  let subtitle = <FormattedMessage id="NotAvailable.subtitle" />;

  if (disabledDescription || disabledTitle) {
    icon = false;
    title = disabledTitle;
    subtitle = disabledDescription;
  }

  return (
    <main
      className={classNames(classes.root, mode === DESKTOP && classes.desktop)}
    >
      {loading ? (
        <LoadingState />
      ) : (
        <GeneralError
          bookAnother={false}
          icon={icon}
          subtitle={subtitle}
          title={title}
        />
      )}
    </main>
  );
};

export default Disabled;
