import Phone from '../../shared/helpers/Phone';
import { DEFAULT_COUNTRY, QUESTION_TYPES } from '../constants';
import Item from './Item';

export default {
  attendeeInitialValues(attendee, questions) {
    return {
      address: Item.get(attendee, 'address', ''),
      cellPhone: Item.get(attendee, 'cellPhone', ''),
      city: Item.get(attendee, 'city', ''),
      country: Item.get(attendee, 'country', DEFAULT_COUNTRY),
      email: Item.get(attendee, 'email', ''),
      externalId: Item.get(attendee, 'externalId', ''),
      firstName: Item.get(attendee, 'firstName', ''),
      homePhone: Item.get(attendee, 'homePhone', ''),
      lastName: Item.get(attendee, 'lastName', ''),
      receiveSms: Item.get(attendee, 'receiveSms', false),
      notes: Item.get(attendee, 'notes', ''),
      postalCode: Item.get(attendee, 'postalCode', ''),
      province: Item.get(attendee, 'province', ''),
      questions: Item.get(
        attendee,
        'questions',
        questions.reduce((object, question) => {
          object[question.id] = '';

          if (question.type === QUESTION_TYPES.CHECKBOX) {
            object[question.id] = question.options.reduce((obj, option) => {
              obj[option.value] = false;

              return obj;
            }, {});
          }

          return object;
        }, {}),
      ),
      workPhone: Item.get(attendee, 'workPhone', ''),
    };
  },

  // Sets the attendee details form values directly.
  // This will cause validation to be run on the most up-to date form
  // when autofill is used and changing/blurring inputs is not possible.
  syncAutofill(form, values) {
    Array.from(form.elements).forEach((element) => {
      if (element.name.includes('Phone')) {
        if (values[element.name]) {
          return;
        }

        const phone = Phone.parse(element.value, {
          country: values.country,
          format: false,
        });

        values[element.name] = phone.number;
      } else if (
        element.name &&
        element.type !== 'checkbox' &&
        element.type !== 'radio'
      ) {
        // Form field entries may be nested so set the most inner key's value only
        const keys = element.name.split('.');
        const lastKey = keys.pop();
        keys.reduce((fields, key) => fields[key], values)[lastKey] =
          element.value;
      }
    });
  },
};
