import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { HEADER_HEIGHT, SCROLL_OFFSET, SHORTCUTS, STEPS } from '../constants';
import { HeaderContext } from '../contexts/HeaderContext';
import { PositionContext } from '../contexts/PositionContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { StepAbilityContext } from '../contexts/StepAbilityContext';
import {
  DESKTOP,
  TABLET,
  TABLET_MIN_WIDTH,
  ViewModeContext,
} from '../contexts/ViewModeContext';
import Item from '../helpers/Item';
import useLocalQueue from '../helpers/LocalQueue';
import Shortcuts from '../helpers/Shortcuts';
import Step from '../helpers/Step';
import Ui from '../helpers/Ui';
import { LIST_CAPACITY, RECENT_LOCATIONS_KEY } from '../steps/Details';
import BackButton from './BackButton';
import Button from './Button';
import CircularProgress from './CircularProgress';
import SearchableInput from './forms/SearchableInput';
import NearMe from './icons/NearMe';
import LoadingState from './LoadingState';
import LocationListCard from './LocationListCard';
import LocationSuggestion from './LocationSuggestion';
import MapOverlay from './MapOverlay';
import NoLocationPreferenceButton from './NoLocationPreferenceButton';
import NoLocationsAlert from './NoLocationsAlert';
import PositionErrorAlert from './PositionErrorAlert';
import RegionListCard from './RegionListCard';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    borderRadius: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    width: '25rem',
  },
  desktop: {
    height: 'calc(100vh - 7.75rem)',
  },
  desktopList: {
    overflowY: 'auto',
  },
  mobileMapError: {
    boxShadow: theme.shadows.default,
    position: 'fixed',
    bottom: '2.5rem',
    left: '1rem',
    right: '1rem',
  },
  header: {
    background: theme.palette.white,
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '4rem',
    padding: '1.125rem 0',
    width: '100%',
  },
  hide: {
    display: 'none',
  },
  horizontalGrow: {
    display: 'flex',
    flexGrow: 1,
  },
  listContainer: {
    background: theme.palette.white,
    borderTop: '1px solid #E0E0E0',
  },
  listSearch: {
    background: theme.palette.white,
  },
  loader: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  loadingStateContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'center',
    marginBottom: '5rem',
    textAlign: 'center',
  },
  loadingState: {
    height: '4.375rem',
    marginBottom: '1rem',
    maxHeight: '4.375rem',
    minHeight: '4.375rem',
  },
  mapTitleContainer: {
    paddingBottom: '1rem !important',
  },
  mobile: {
    width: '100%',
  },
  mobileButton: {
    alignItems: 'center',
    background: theme.palette.white,
    display: 'flex',
    height: '3.25rem',
    justifyContent: 'center',
    marginLeft: '1rem',
    width: '3.25rem',
    '& > span': {
      lineHeight: '0',
    },
  },
  nearMeContainer: {
    padding: '1.125rem 1rem 0',
  },
  nearMeContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      marginRight: '0.5rem',
    },
  },
  search: {
    flexGrow: 1,
    '& > ul': {
      zIndex: 949,
    },
  },
  searchContainer: {
    display: 'flex',
    padding: '1.5rem 1rem 1rem',
  },
  focusedLocation: {
    outline: `2px solid ${theme.palette.neutral[350]}`,
    outlineOffset: '-2px',
  },
  selectedLocation: {
    background: '#e3f2fd',
  },
  subheader: {
    color: '#616161',
    fontSize: '1rem',
    padding: '1.5rem 0 0 0',
  },
  title: {
    display: 'block',
    marginBottom: '0.25rem',
  },
  titleContainer: {
    alignItems: 'center',
    background: theme.palette.white,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem 1rem 0',
  },
  verticalGrow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  seeAllLocations: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1.5rem',
  },
  loading: {
    marginTop: '1rem',
    overflowY: 'hidden',
  },
  [`@media screen and (min-width:${TABLET_MIN_WIDTH}px)`]: {
    nearMeContainer: {
      paddingTop: '.875rem',
    },
    searchContainer: {
      paddingTop: '1rem',
    },
    titleContainer: {
      paddingTop: '1rem',
    },
  },
}));

const SeeAllLocationsButton = ({ classes, onClick, show }) => {
  return show ? (
    <div className={classes.seeAllLocations}>
      <Button fullWidth={false} onClick={onClick} variant="smallOutlined">
        <FormattedMessage id="LocationDetails.see_all_locations" />
      </Button>
    </div>
  ) : null;
};

SeeAllLocationsButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

SeeAllLocationsButton.defaultProps = {
  show: null,
};

const LocationList = ({
  addNearMeOption,
  chosen,
  displayAllLocations,
  fetchSuggestions,
  focused,
  locations,
  locationsLoading,
  locationIds,
  next,
  previous,
  previousStep,
  regions,
  resetLocations,
  searching,
  searchInputRef,
  selectLocation,
  selectRegion,
  selectSuggestion,
  setChosen,
  setFocused,
  setSearching,
  setShowPositionError,
  setSuggestions,
  showMap,
  showPositionError,
  suggestions,
  toggleMap,
  viewOnMap,
}) => {
  const classes = useStyles({ theme: useTheme() });
  const intl = useIntl();

  const mode = useContext(ViewModeContext);
  const { can } = useContext(StepAbilityContext);
  const [, setHeader] = useContext(HeaderContext);
  const { firstStep } = useContext(SettingsContext);
  const { error, getPosition, loading } = useContext(PositionContext);
  const [
    {
      locationCategory,
      meetingMethod,
      region,
      settings,
      showAllLocations,
      showRecentLocations,
      skip,
      attendee,
    },
    setSelections,
  ] = useContext(SelectionContext);
  const selectedLocation = useRef(null);
  const focusedLocation = useRef(null);

  const desktopOrTablet = mode === DESKTOP || mode === TABLET;
  const viewingMobileMap = !desktopOrTablet && showMap;

  const singleRegion = regions.length === 1;
  const regionChosen = region && !Shortcuts.exists(SHORTCUTS.REGION);

  const { all: getRecentLocationIds } = useLocalQueue(
    RECENT_LOCATIONS_KEY,
    LIST_CAPACITY,
  );
  const recentLocations = Array.isArray(getRecentLocationIds())
    ? getRecentLocationIds()
    : [getRecentLocationIds()];
  const hasRecentLocations = recentLocations.length > 0;
  const validLocations = locations.some((location) =>
    recentLocations.includes(location.id),
  );
  const locationInputSelector = intl.formatMessage({ id: 'Steps.location' });

  const goBack = () => {
    if (regionChosen && singleRegion && showRecentLocations) {
      const selection = { region: null };

      setSelections(selection);
      previous(selection);
    } else if (regionChosen && singleRegion && !hasRecentLocations) {
      const selection = { region: null };

      setSelections(selection);
      previous(selection);
    } else if (
      !validLocations &&
      singleRegion &&
      regionChosen &&
      meetingMethod &&
      !showRecentLocations
    ) {
      previous();
    } else if (
      singleRegion &&
      regionChosen &&
      hasRecentLocations &&
      !showRecentLocations
    ) {
      setSelections({ showRecentLocations: true });
    } else if (regionChosen && !showRecentLocations) {
      setSearching(false);
      setChosen({});
      setSelections({ location: null, region: null });

      if (!desktopOrTablet && showMap) {
        toggleMap();
      }
      // selecting a service not offered in location category
    } else if (locationCategory && locations?.length === 0) {
      previous();
    } else if (!singleRegion && regionChosen && !hasRecentLocations) {
      setSelections({ region: null });
    } else if (!singleRegion && regionChosen && hasRecentLocations) {
      setSelections({
        meetingMethod: null,
        region: null,
        showRecentLocations: true,
      });
    } else if (
      hasRecentLocations &&
      meetingMethod &&
      !showRecentLocations &&
      !regionChosen
    ) {
      setSelections({ showRecentLocations: true });
    } else {
      previous();
    }
  };

  const canGoToPrevStep = Step.canGoToPrevStep({
    step: STEPS.LOCATION,
    previousStep,
    firstStep,
    skip,
    canStepBackwards: can.step.backwards,
  });
  const backAction =
    canGoToPrevStep || (regionChosen && !singleRegion) || hasRecentLocations
      ? goBack
      : null;

  useEffect(() => {
    if (!error && !suggestions.length) {
      addNearMeOption();
    }

    if (error) {
      setShowPositionError(true);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!desktopOrTablet) {
      setHeader({
        action: backAction,
        title: <FormattedMessage id="Steps.location" />,
        previousStep,
      });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    can.step.backwards,
    firstStep,
    previousStep,
    skip,
    setHeader,
    region,
    regions,
    showMap,
    showRecentLocations,
  ]);

  useEffect(() => {
    if (selectedLocation.current) {
      const prevElement = document.querySelector(
        `[data-id="${selectedLocation.current}"]`,
      );
      if (prevElement) {
        prevElement.classList.remove(classes.selectedLocation);
      }
    }
    if (Item.has(chosen, 'id')) {
      if (desktopOrTablet) {
        const element = document.querySelector(`[data-id="${chosen.id}"]`);

        if (element) {
          element.classList.add(classes.selectedLocation);
          selectedLocation.current = chosen.id;
        }
      }
    } else {
      selectedLocation.current = null;
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosen.id]); // only adjust classes, as scrolling should be done on list change

  useEffect(() => {
    if (Item.has(chosen, 'id')) {
      if (desktopOrTablet) {
        const element = document.querySelector(`[data-id="${chosen.id}"]`);
        const parent = document.querySelector('[id="location-list"]');

        if (element) {
          const offsetPx = Ui.convertRemToPixels(parseFloat(SCROLL_OFFSET));

          Ui.scrollIntoViewWithOffset(element, parent, offsetPx);
        }
      }
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationIds]); // location list will be filtered after a new location is selected

  useEffect(() => {
    if (desktopOrTablet) {
      if (focusedLocation.current) {
        focusedLocation.current.classList.remove(classes.focusedLocation);
      }

      if (Item.has(focused, 'id')) {
        const element = document.querySelector(`[data-id="${focused.id}"]`);

        if (element) {
          element.classList.add(classes.focusedLocation);
          focusedLocation.current = element;
        }
      } else {
        focusedLocation.current = null;
      }
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  useEffect(() => {
    if (!desktopOrTablet && !showMap) {
      if (Item.has(chosen, 'id')) {
        const element = document.querySelector(`[data-id="${chosen.id}"]`);

        if (element) {
          const offsetPx =
            Ui.convertRemToPixels(parseFloat(SCROLL_OFFSET)) +
            Ui.convertRemToPixels(parseFloat(HEADER_HEIGHT.MOBILE));

          Ui.scrollIntoViewWithOffset(element, window, offsetPx);
          element.classList.add(classes.selectedLocation);
          selectedLocation.current = chosen.id;
        }
      }
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMap]);

  const onClickSuggestion = (e) => {
    const input = document.getElementById(locationInputSelector);

    input.value = e.target.innerText;
    selectSuggestion(e);
  };

  const onBlur = ({ currentTarget: { value } }) => {
    resetLocations(value);
  };

  const onChangeSearch = ({ currentTarget: { value } }) => {
    fetchSuggestions(value);
  };

  const dismissError = () => {
    setShowPositionError(false);
  };

  const onNearMe = () => {
    setSuggestions([]);
    setShowPositionError(true);
    getPosition();
  };

  const locationList = useMemo(
    () =>
      locations.map((location) => (
        <LocationListCard
          key={location.id}
          location={location}
          selectLocation={selectLocation}
          setFocused={setFocused}
          viewOnMap={viewOnMap}
        />
      )),

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationIds, locations],
  );

  const recentLocationList = useMemo(
    () =>
      locations.map((location) => (
        <LocationListCard
          key={location.id}
          location={location}
          selectLocation={selectLocation}
          setFocused={setFocused}
          viewOnMap={viewOnMap}
        />
      )),

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationIds, locations],
  );

  return (
    <MapOverlay transparent={viewingMobileMap}>
      <>
        <div
          className={classNames(
            classes.root,
            !desktopOrTablet ? classes.mobile : classes.desktop,
          )}
        >
          {desktopOrTablet && backAction ? (
            <header className={classes.header}>
              <BackButton
                previous={backAction}
                text={<FormattedMessage id="Ui.back" />}
                title={Step.getBackString(intl, previousStep)}
              />
            </header>
          ) : null}
          {loading ? (
            <div className={classes.loader}>
              <div className={classes.titleContainer}>
                <Typography
                  classes={{ root: classes.title }}
                  component="h2"
                  variant="h5"
                >
                  <FormattedMessage
                    id={
                      firstStep === 'location' && attendee?.firstName
                        ? 'Location.select_location_prefill'
                        : 'Location.select_location'
                    }
                    values={{ name: attendee?.firstName }}
                  />
                </Typography>
              </div>
              <div className={classes.loadingStateContainer}>
                <LoadingState classes={{ override: classes.loadingState }} />
                <Typography component="p" variant="subtitle">
                  <FormattedMessage id="LocationList.finding_locations" />
                </Typography>
              </div>
            </div>
          ) : (
            <>
              <div>
                <div
                  aria-atomic="false"
                  className={classNames(
                    classes.titleContainer,
                    viewingMobileMap ? classes.mapTitleContainer : null,
                  )}
                  role="alert"
                >
                  <Typography
                    classes={{ root: classes.title }}
                    component="h1"
                    variant="h5"
                  >
                    <FormattedMessage
                      id={
                        firstStep === 'location' && attendee?.firstName
                          ? 'Location.select_location_prefill'
                          : 'Location.select_location'
                      }
                      values={{ name: attendee?.firstName }}
                    />
                  </Typography>
                  <NoLocationPreferenceButton layout="small" next={next} />
                </div>
                {viewingMobileMap ? null : (
                  <div className={classes.nearMeContainer}>
                    {error && showPositionError ? (
                      <PositionErrorAlert dismissError={dismissError} />
                    ) : (
                      <Button onClick={onNearMe} variant="smallOutlined">
                        <span className={classes.nearMeContent}>
                          <NearMe />
                          <FormattedMessage id="LocationList.near_me" />
                        </span>
                      </Button>
                    )}
                  </div>
                )}
                <div
                  className={classNames(
                    classes.searchContainer,
                    !viewingMobileMap ? classes.listSearch : '',
                  )}
                >
                  <div className={classes.verticalGrow}>
                    <div className={classes.horizontalGrow}>
                      <div className={classes.verticalGrow}>
                        <SearchableInput
                          className={classes.search}
                          expandOnFocus
                          inputRef={searchInputRef}
                          name={locationInputSelector}
                          onBlur={onBlur}
                          onChange={onChangeSearch}
                          onClick={onClickSuggestion}
                          onFocus={error ? undefined : addNearMeOption}
                          optionLabels={suggestions.map(
                            (suggestion) => suggestion.attributes.name,
                          )}
                          options={suggestions.map((suggestion) => (
                            <LocationSuggestion
                              identifier={suggestion.id}
                              key={suggestion.id}
                              suggestion={suggestion}
                            />
                          ))}
                          scheme={viewingMobileMap ? 'light' : 'dark'}
                          shadow={viewingMobileMap}
                        />
                        {showRecentLocations && hasRecentLocations ? (
                          <Typography
                            classes={{ root: classes.subheader }}
                            component="span"
                            variant="body2"
                          >
                            <FormattedMessage id="Location.previously_booked" />
                          </Typography>
                        ) : null}
                      </div>
                      {!desktopOrTablet ? (
                        <Button
                          aria-label={
                            showMap
                              ? intl.formatMessage({
                                  id: 'LocationList.toggle_list',
                                })
                              : intl.formatMessage({
                                  id: 'LocationList.toggle_map',
                                })
                          }
                          classes={{ override: classes.mobileButton }}
                          fullWidth={false}
                          onClick={toggleMap}
                          shadow={viewingMobileMap}
                          variant={showMap ? 'floating' : 'simpleOutline'}
                        >
                          {showMap ? (
                            <svg
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 13H5V11H3V13ZM3 17H5V15H3V17ZM3 9H5V7H3V9ZM7 13H21V11H7V13ZM7 17H21V15H7V17ZM7 7V9H21V7H7Z"
                                fill="black"
                                fillOpacity="0.6"
                              />
                            </svg>
                          ) : (
                            <svg
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                clipRule="evenodd"
                                d="M9.65488 3.22999L14.6549 4.97999C14.8849 5.05999 15.1249 5.04999 15.3449 4.96999L18.2749 3.82999C19.5849 3.31999 20.9949 4.27999 20.9949 5.68999V17.54C20.9949 18.4 20.4449 19.17 19.6349 19.44L15.6449 20.78C15.2249 20.92 14.7649 20.92 14.3449 20.77L9.34488 19.02C9.12488 18.94 8.87488 18.94 8.65488 19.03L5.72488 20.17C4.41488 20.68 3.00488 19.72 3.00488 18.31V6.45999C3.00488 5.59999 3.55488 4.83999 4.36488 4.55999L8.35488 3.21999C8.77488 3.07999 9.23488 3.07999 9.65488 3.22999ZM9.00488 16.78L15.0049 18.89V7.21999L9.00488 5.10999V16.78Z"
                                fill="black"
                                fillOpacity="0.6"
                                fillRule="evenodd"
                              />
                            </svg>
                          )}
                        </Button>
                      ) : null}
                    </div>
                    {!region &&
                    searching &&
                    locations.length === 0 &&
                    !viewingMobileMap ? (
                      <NoLocationsAlert dense />
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                className={classNames({
                  [classes.listContainer]: true,
                  [classes.hide]: viewingMobileMap,
                  [classes.desktopList]: desktopOrTablet,
                })}
                id="location-list"
              >
                {locationsLoading ? (
                  <div className={classes.loading}>
                    <CircularProgress />
                  </div>
                ) : region || (showRecentLocations && hasRecentLocations) ? (
                  locations.length > 0 ? (
                    locationList
                  ) : validLocations && showRecentLocations ? (
                    recentLocationList
                  ) : searching ? (
                    <NoLocationsAlert />
                  ) : null
                ) : (
                  regions.map((region) => (
                    <RegionListCard
                      ariaLabel={intl.formatMessage(
                        { id: 'Navigation.aria_label.card' },
                        { navItem: region.name },
                      )}
                      key={`${region.id}-${region.country ? 1 : 0}`}
                      region={region}
                      selectRegion={selectRegion}
                      setSearching={setSearching}
                    />
                  ))
                )}
              </div>
              <SeeAllLocationsButton
                classes={classes}
                onClick={displayAllLocations}
                show={
                  (showRecentLocations && hasRecentLocations) ||
                  (Shortcuts.get(SHORTCUTS.LOCATION_CATEGORY) &&
                    settings.preferred_location &&
                    !locationsLoading &&
                    !showAllLocations)
                }
              />
            </>
          )}
        </div>
        {viewingMobileMap && searching && locations.length === 0 ? (
          <NoLocationsAlert mobile />
        ) : null}
        {viewingMobileMap && error && showPositionError ? (
          <div className={classes.mobileMapError}>
            <PositionErrorAlert dismissError={dismissError} white />
          </div>
        ) : null}
      </>
    </MapOverlay>
  );
};

LocationList.propTypes = {
  addNearMeOption: PropTypes.func.isRequired,
  chosen: PropTypes.shape({ id: PropTypes.number }).isRequired,
  displayAllLocations: PropTypes.func.isRequired,
  focused: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  fetchSuggestions: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  locationsLoading: PropTypes.bool.isRequired,
  locationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.bool,
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  resetLocations: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  searchInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  setChosen: PropTypes.func.isRequired,
  setSearching: PropTypes.func.isRequired,
  setShowPositionError: PropTypes.func.isRequired,
  setSuggestions: PropTypes.func.isRequired,
  selectLocation: PropTypes.func.isRequired,
  selectRegion: PropTypes.func.isRequired,
  selectSuggestion: PropTypes.func.isRequired,
  setFocused: PropTypes.func.isRequired,
  showMap: PropTypes.bool.isRequired,
  showPositionError: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
  toggleMap: PropTypes.func.isRequired,
  viewOnMap: PropTypes.func.isRequired,
};

LocationList.defaultProps = {
  previousStep: null,
};

export default LocationList;
