const ReserveWithGoogleConversion = (client) => ({
  submit(rwg_token, merchant_changed) {
    return client.post('/api/v3/open/rwg-track-conversion', {
      rwg_token,
      merchant_changed,
    });
  },
});

export default ReserveWithGoogleConversion;
