import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingState from '../components/LoadingState';
import {
  AppointmentContext,
  EMPTY_APPOINTMENT,
} from '../contexts/AppointmentContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Open from '../helpers/api/Open';
import Item from '../helpers/Item';
import Resources from '../helpers/Resources';
import Url from '../helpers/Url';
import DesktopFeedback from './desktop/Feedback';
import MobileFeedback from './mobile/Feedback';

const Feedback = () => {
  const Api = Open.api();

  const location = useLocation();
  const viewMode = useContext(ViewModeContext);
  const [timezone, setTimezone] = useContext(TimezoneContext);
  const [, setSelections] = useContext(SelectionContext);
  const [, setAppointment] = useContext(AppointmentContext);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    const { code, client } = Url.params(location.search);

    Api.appointments()
      .matching({ code, id: client })
      .get()
      .then(({ data: { data, included } }) => {
        if (data.length) {
          const {
            id,
            attributes: {
              client_end: end,
              meeting_link: meetingLink,
              meeting_method: meetingMethod,
              reschedulable,
              start,
              status,
            },
            relationships: {
              location: requestLocation,
              user: requestUser,
              additionalUsers: { data: addUsers },
            },
          } = Item.first(data);

          const userId = requestUser?.data?.id;

          const users = Item.included(included, 'users', []);
          const loc = Item.first(Item.included(included, 'locations'));
          const service = Item.first(Item.included(included, 'services'));
          const user = users.find((user) => user.id === userId);
          const attendee = Item.first(Item.included(included, 'attendees'));
          const additionalUserIds = addUsers
            ? addUsers.map((addUser) => addUser.id)
            : [];
          const additionalUsers = users.filter((addUser) =>
            additionalUserIds.includes(addUser.id),
          );

          if (attendee.attributes.timezone) {
            setTimezone(attendee.attributes.timezone);
          }

          setSelections({
            additionalUsers: additionalUsers
              ? additionalUsers.map((addUser) => Resources.formatUser(addUser))
              : [],
            appointment: id,
            meetingMethod,
            location: Resources.formatLocation(loc),
            service: Resources.formatService(service),
            user: user ? Resources.formatUser(user) : null,
            date: Resources.formatDate(
              start,
              attendee.attributes.timezone || timezone,
            ),
            attendee: Resources.formatAttendee(attendee),
          });

          setAppointment({
            id,
            confirmCode: attendee.attributes.confirm_code,
            startRaw: start,
            endRaw: end,
            end,
            reschedulable,
            status,
            location: requestLocation.data.id,
            meetingLink,
          });
        } else {
          setAppointment(EMPTY_APPOINTMENT);
        }

        setLoading(false);
      })
      .catch(() => {
        setAppointment(EMPTY_APPOINTMENT);

        setLoading(false);
      });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoadingState />;
  }

  return viewMode === MOBILE ? <MobileFeedback /> : <DesktopFeedback />;
};

export default Feedback;
