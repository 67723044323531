import React, { useContext, useMemo, useState } from 'react';
import { WithWizard } from 'react-albus-react18';
import CenterWrap from '../components/CenterWrap';
import TrackPageView from '../components/TrackPageView';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Confirmation from './check-in/Confirmation';
import LandingPage from './check-in/LandingPage';
import Summary from './check-in/Summary';

const CheckIn = () => {
  const viewMode = useContext(ViewModeContext);

  const [contact, setContact] = useState({});
  const [data, setData] = useState({
    appointment: null,
    checked_in: false,
    optIn: false,
  });

  const checkinContent = useMemo(() => {
    if (!data.appointment) {
      return (
        <LandingPage
          setAppointment={(appointment) => setData({ ...data, appointment })}
          setContact={(contact) => setContact(contact)}
        />
      );
    }
    if (!data.checked_in) {
      return (
        <Summary
          appointment={data.appointment}
          checkIn={(optIn) => setData({ ...data, checked_in: true, optIn })}
          contact={contact}
        />
      );
    }

    return <Confirmation optIn={data.optIn} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (viewMode === MOBILE) {
    return <div className="flex flex-col h-full p-10">{checkinContent}</div>;
  } else {
    return (
      <WithWizard>
        <CenterWrap narrow view={viewMode}>
          <TrackPageView identifier={'/check-in'} />
          <div className="flex flex-col h-full p-10">{checkinContent}</div>
        </CenterWrap>
      </WithWizard>
    );
  }
};

export default CheckIn;
