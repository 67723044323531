import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { HEADER_HEIGHT } from '../constants';
import { ViewModeContext, MOBILE } from '../contexts/ViewModeContext';
import CenterWrap from './CenterWrap';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.primary[400],
    height: `calc(100vh - ${HEADER_HEIGHT.DESKTOP})`,
    display: 'flex',
    overflow: 'auto',
  },
  mobileRoot: {
    height: `calc(100vh - ${HEADER_HEIGHT.MOBILE})`,
    margin: 0,
  },
  main: {
    flexGrow: 1,
  },
  container: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    margin: '2.5rem',
  },
  mobileContent: {
    margin: 0,
  },
}));

const LandingKiosk = ({ children }) => {
  const classes = useStyles({ theme: useTheme() });
  const mode = useContext(ViewModeContext);

  return (
    <section
      className={classNames(
        classes.root,
        mode === MOBILE && classes.mobileRoot,
      )}
    >
      <main className={classes.main}>
        <CenterWrap custom justified view={mode}>
          <div
            className={classNames(
              classes.content,
              mode === MOBILE && classes.mobileContent,
            )}
          >
            {children}
          </div>
        </CenterWrap>
      </main>
    </section>
  );
};

LandingKiosk.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LandingKiosk;
