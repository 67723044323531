import moment from 'moment-timezone';

/**
 * Normalizes a timezone string for manipulation
 * @param name
 * @returns {string}
 */
function normalizeName(name) {
  return (name || '').toLowerCase().replace(/\//g, '_');
}

/**
 * Converts a linked timezone to it's canonical timezone
 * @param timezone
 * @returns {string}
 */
function getCanonicalTimezone(timezone) {
  const normalizedName = normalizeName(timezone);

  // Return if timezone is already a canonical timezone
  if (moment.tz._zones[normalizedName]) {
    return timezone;
  }

  //Return the canonical timezone if canonical exists
  const canonicalZoneNormalized = moment.tz._links[normalizeName(timezone)];
  if (canonicalZoneNormalized !== null) {
    return moment.tz._names[canonicalZoneNormalized];
  }

  return timezone;
}

export { normalizeName, getCanonicalTimezone };
