import axios from 'axios';
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Rating from 'react-rating';
import { useLocation } from 'react-router-dom';
import FeedbackBubble from '../../shared/icons/FeedbackBubble';
import Star from '../../shared/icons/Star';
import { SelectionContext } from '../contexts/SelectionContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Url from '../helpers/Url';
import Alert from './Alert';
import Button from './Button';
import TextareaInput from './forms/TextareaInput';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  mobile: {
    marginBottom: '1.5rem',
  },
  icon: {
    color: theme.palette.primary[400],
    display: 'inline-flex',
    marginRight: '0.875rem',
  },
  root: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    marginTop: '0.5rem',
    padding: '1.5rem',
  },
  body: {
    margin: '0 2.375rem',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
  },
  flex: {
    display: 'flex',
  },
  container: {
    padding: '1.25rem 0 0.75rem',
  },
  marginTop: {
    marginTop: '1rem',
  },
  marginBottom: {
    marginBottom: '0.25rem',
  },
  error: {
    color: theme.palette.danger[400],
  },
}));

const ModernFeedback = () => {
  const classes = useStyles({ theme: useTheme() });

  const mode = useContext(ViewModeContext);
  const [
    {
      location: { reviewLink },
    },
  ] = useContext(SelectionContext);
  const windowLocation = useLocation();

  const [submitError, setSubmitError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [rating, setRating] = useState({
    value: 0,
    description: '',
    touched: false,
  });

  const handleRatingChange = (value) => {
    setRating({
      ...rating,
      value,
      touched: true,
    });
    setSubmitError(false);
  };

  const handleSubmit = () => {
    if (!rating.touched) {
      setSubmitError(true);
      window.setTimeout(() => {
        setSubmitError(false);
      }, 5000);
    } else {
      setSubmitting(true);
      const { appointment, code, client } = Url.params(windowLocation.search);

      return axios
        .post('/feedback', {
          appointment,
          client,
          code,
          experience: rating.description,
          rating: rating.value,
        })
        .then(() => {
          setCompleted(true);
        })
        .catch(() => {
          setSubmitting(false);
        });
    }

    return null;
  };

  return (
    <div
      className={classNames(classes.root, mode === MOBILE && classes.mobile)}
    >
      <Typography classes={{ root: classes.title }} component="h4" variant="h6">
        <span className={classes.icon}>
          <FeedbackBubble />
        </span>
        <FormattedMessage id="Feedback.new_title" />
      </Typography>
      {!completed ? (
        <div className={mode !== MOBILE ? classes.body : ''}>
          <div className={classes.container}>
            <div className={classNames(classes.flex, classes.marginBottom)}>
              <Typography variant="caption1">
                <FormattedMessage id="Feedback.rating" />
              </Typography>
            </div>
            <Rating
              emptySymbol={<Star fill="#B3B3B3" />}
              fractions={2}
              fullSymbol={<Star />}
              id="rating"
              initialRating={rating.value}
              name="rating"
              onChange={handleRatingChange}
            />
          </div>
          <div className={classNames(classes.container, classes.marginBottom)}>
            <Typography variant="caption1">
              <FormattedMessage id="Feedback.experience" />
            </Typography>
            <TextareaInput
              name="notes"
              onBlur={(e) => {
                setRating({ ...rating, description: e.target.value });
              }}
              value={rating.description}
            />
          </div>
          <div className={classes.marginTop}>
            <Button
              disabled={submitting}
              fullWidth={false}
              onClick={handleSubmit}
              variant="smallOutlined"
            >
              {submitting ? (
                <FormattedMessage id="Feedback.sending" />
              ) : (
                <FormattedMessage id="Feedback.send" />
              )}
            </Button>
          </div>
          {submitError ? (
            <Typography variant="error">
              <FormattedMessage id="Feedback.error" />
            </Typography>
          ) : null}
        </div>
      ) : (
        <>
          <Alert
            message={<FormattedMessage id="Feedback.success" />}
            variant="success"
          />
          {reviewLink ? (
            <div className={classNames(classes.body, classes.marginTop)}>
              <Typography variant="subtitle">
                <FormattedMessage id="Feedback.google_description" />
              </Typography>
              <div className={classes.marginTop}>
                <Button
                  fullWidth={false}
                  onClick={() => {
                    window.location.href = reviewLink;
                  }}
                  variant="smallOutlined"
                >
                  <FormattedMessage id="Feedback.google_submit" />
                </Button>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ModernFeedback;
