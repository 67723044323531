import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Select from 'react-select';
import DropDownArrow from '../icons/DropDownArrow';

const DropdownIndicator = ({ selectProps: { classes } }) => (
  <DropDownArrow className={classes.dropdownIndicator} />
);

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
};

const components = { DropdownIndicator };

const useStyles = createUseStyles((theme) => ({
  dropdownIndicator: {
    color: theme.palette.text.secondary,
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: 'calc(50% - 0.75rem)',
  },
}));

const SearchableSelect = ({
  error,
  label,
  onChange,
  options,
  value,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const nonFocusedBorderColour = error
    ? theme.palette.danger[400]
    : theme.palette.neutral[200];
  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.black,
      '& input': {
        font: 'inherit',
      },
    }),
    placeholder: (base) => ({
      ...base,
      // firefox defaults to taking the opacity of placeholders
      // to 50%, so we are overriding to meet the WCAG 4.5 spec
      color: theme.palette.black,
      opacity: 0.8,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    control: (base, state) => ({
      ...base,
      borderRadius: '0.125rem',
      borderColor: state.isFocused
        ? theme.palette.secondary[400]
        : nonFocusedBorderColour,
      boxShadow: state.isFocused ? theme.shadows.input : base.boxShadow,
      '&:hover': {
        borderColor: state.isFocused
          ? theme.palette.secondary[400]
          : nonFocusedBorderColour,
      },
      '&:focus': {
        borderColor: theme.palette.secondary[400],
        boxShadow: theme.shadows.input,
        outline: 0,
      },
    }),
    container: (base) => ({
      ...base,
      '&:focus': {
        borderColor: theme.palette.neutral[200],
        boxShadow: theme.shadows.input,
        outline: 0,
      },
    }),
    option: (base) => ({
      ...base,
      color: theme.palette.black,
      fontSize: theme.textSizes.sm,
      lineHeight: theme.leading.sm,
      '&:hover': {
        background: theme.palette.neutral[200],
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: theme.palette.black,
      fontSize: theme.textSizes.sm,
      lineHeight: theme.leading.sm,
    }),
  };

  return (
    <Select
      aria-label={label}
      classes={classes}
      components={components}
      isClearable
      isLoading={options.length === 0}
      onChange={onChange}
      options={options}
      styles={selectStyles}
      value={value}
      {...props}
    />
  );
};

SearchableSelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  error: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    }),
  ),
  value: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

SearchableSelect.defaultProps = {
  error: false,
  onChange: () => {},
  value: null,
};

export default SearchableSelect;
