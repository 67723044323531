import React from 'react';
import { useVendorColors } from '../../../shared/contexts/VendorColorContext';

const KioskDone = () => {
  const { foreground } = useVendorColors();

  return (
    <svg
      fill="currentColor"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00002 16.2L4.80002 12L3.40002 13.4L9.00002 19L21 6.99998L19.6 5.59998L9.00002 16.2Z"
        fill={foreground}
        stroke={foreground}
      />
    </svg>
  );
};

export default KioskDone;
