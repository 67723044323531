import { NOTIFICATION_RECIPIENT_TYPES, REMINDER_TYPES } from '../constants';

export const VendorActionTypes = {
  UPDATE_BRANCH_LOCATOR_SETTINGS: 'UPDATE_BRANCH_LOCATOR_SETTINGS',
  UPDATE_CLIENT_VIEW_SETTINGS: 'UPDATE_CLIENT_VIEW_SETTINGS',
  UPDATE_CONTACT_CENTRE_SETTINGS: 'UPDATE_CONTACT_CENTRE_SETTINGS',
  UPDATE_NOTIFICATION_SETTINGS: 'UPDATE_NOTIFICATION_SETTINGS',
  UPDATE_SUPPORT_SETTINGS: 'UPDATE_SUPPORT_SETTINGS',
  UPDATE_VIDEO_INTEGRATION_SETTINGS: 'UPDATE_VIDEO_INTEGRATION_SETTINGS',
  DELETE_VENDOR_REMINDER: 'DELETE_VENDOR_REMINDER',
  UPDATE_VENDOR_REMINDER: 'UPDATE_VENDOR_REMINDER',
  ADD_VENDOR_REMINDER: 'ADD_VENDOR_REMINDER',
  DELETE_VENDOR_REMINDER_STAFF: 'DELETE_VENDOR_REMINDER_STAFF',
  DELETE_VENDOR_REMINDER_STAFF_UPCOMING:
    'DELETE_VENDOR_REMINDER_STAFF_UPCOMING',
  UPDATE_VENDOR_REMINDER_STAFF: 'UPDATE_VENDOR_REMINDER_STAFF',
  UPDATE_VENDOR_REMINDER_STAFF_UPCOMING:
    'UPDATE_VENDOR_REMINDER_STAFF_UPCOMING',
  ADD_VENDOR_REMINDER_STAFF: 'ADD_VENDOR_REMINDER_STAFF',
  ADD_VENDOR_REMINDER_STAFF_UPCOMING: 'ADD_VENDOR_REMINDER_STAFF_UPCOMING',
  UPDATE_ADMIN_VIEW_SETTINGS: 'UPDATE_ADMIN_VIEW_SETTINGS',
  UPDATE_NOTIFICATION_CHECK_IN_EMAIL_SETTINGS:
    'UPDATE_NOTIFICATION_CHECK_IN_EMAIL_SETTINGS',
  UPDATE_NOTIFICATION_CHECK_IN_SMS_SETTINGS:
    'UPDATE_NOTIFICATION_CHECK_IN_SMS_SETTINGS',
};

export function updateBranchLocatorSettings(data) {
  return {
    type: VendorActionTypes.UPDATE_BRANCH_LOCATOR_SETTINGS,
    payload: data,
  };
}

export function updateClientViewSettings(data) {
  return {
    type: VendorActionTypes.UPDATE_CLIENT_VIEW_SETTINGS,
    payload: data,
  };
}

export function updateContactCentreSettings(data) {
  return {
    type: VendorActionTypes.UPDATE_CONTACT_CENTRE_SETTINGS,
    payload: data,
  };
}

export function updateSupportSettings(data) {
  return {
    type: VendorActionTypes.UPDATE_SUPPORT_SETTINGS,
    payload: data,
  };
}

export function updateVideoIntegrationSettings(data) {
  return {
    type: VendorActionTypes.UPDATE_VIDEO_INTEGRATION_SETTINGS,
    payload: data,
  };
}

export function updateNotificationSettings(data) {
  return {
    type: VendorActionTypes.UPDATE_NOTIFICATION_SETTINGS,
    payload: data,
  };
}

export function deleteReminder(data, recipient) {
  if (recipient === NOTIFICATION_RECIPIENT_TYPES.USER) {
    if (data.type === REMINDER_TYPES.EMAIL_STAFF_UPCOMING) {
      return {
        type: VendorActionTypes.DELETE_VENDOR_REMINDER_STAFF_UPCOMING,
        payload: data,
      };
    }
    return {
      type: VendorActionTypes.DELETE_VENDOR_REMINDER_STAFF,
      payload: data,
    };
  }

  return {
    type: VendorActionTypes.DELETE_VENDOR_REMINDER,
    payload: data,
  };
}

export function updateReminder(data, recipient) {
  if (recipient === NOTIFICATION_RECIPIENT_TYPES.USER) {
    if (data.type === REMINDER_TYPES.EMAIL_STAFF_UPCOMING) {
      return {
        type: VendorActionTypes.UPDATE_VENDOR_REMINDER_STAFF_UPCOMING,
        payload: data,
      };
    }
    return {
      type: VendorActionTypes.UPDATE_VENDOR_REMINDER_STAFF,
      payload: data,
    };
  }

  return {
    type: VendorActionTypes.UPDATE_VENDOR_REMINDER,
    payload: data,
  };
}

export function addReminder(data, recipient) {
  if (recipient === NOTIFICATION_RECIPIENT_TYPES.USER) {
    if (data.type === REMINDER_TYPES.EMAIL_STAFF_UPCOMING) {
      return {
        type: VendorActionTypes.ADD_VENDOR_REMINDER_STAFF_UPCOMING,
        payload: data,
      };
    }
    return {
      type: VendorActionTypes.ADD_VENDOR_REMINDER_STAFF,
      payload: data,
    };
  }

  return {
    type: VendorActionTypes.ADD_VENDOR_REMINDER,
    payload: data,
  };
}

export function updateAdminViewSettings(data) {
  return {
    type: VendorActionTypes.UPDATE_ADMIN_VIEW_SETTINGS,
    payload: data,
  };
}

export function updateNotificationCheckInSettings(data, type) {
  if (type === 'email_check_in') {
    return {
      type: VendorActionTypes.UPDATE_NOTIFICATION_CHECK_IN_EMAIL_SETTINGS,
      payload: data,
    };
  }

  if (type === 'sms_check_in') {
    return {
      type: VendorActionTypes.UPDATE_NOTIFICATION_CHECK_IN_SMS_SETTINGS,
      payload: data,
    };
  }

  return {
    type: null,
  };
}
