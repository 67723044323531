import { Alert } from '@coconut-software/ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { useFeatureDecisions } from '../../shared/contexts/FeatureDecisionContext';
import Dates from '../../shared/helpers/Dates';
import useDateTime from '../../shared/hooks/useDateTime';
import { MEETING_METHOD_TITLES, STEPS } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { TimezonesContext } from '../contexts/TimezonesContext';
import Settings from '../helpers/Settings';
import { useLobbyAvailability } from '../hooks/useLobbyAvailability';
import BreadcrumbSelection from './BreadcrumbSelection';
import TimezoneLabel from './TimezoneLabel';
import Typography from './Typography';

const TITLES = {
  [STEPS.SERVICE]: 'Steps.service',
  [STEPS.LOCATION]: 'Steps.location',
  [STEPS.MEETING_METHODS]: 'Steps.meeting_methods',
  [STEPS.TIMES]: 'Steps.meeting_details',
  [STEPS.DETAILS]: 'Steps.your_details',
  [STEPS.CHECK_IN]: 'Steps.check_in',
};

const useStyles = createUseStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  active: {
    color: theme.palette.black,
    fontWeight: theme.fontWeights.medium,
  },
  circle: {
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    '&::before': {
      alignItems: 'center',
      background: theme.palette.neutral[350],
      borderRadius: theme.borderRadius.full,
      color: theme.palette.white,
      content: 'counter(sidebar)',
      counterIncrement: 'sidebar',
      display: 'flex',
      flexShrink: 0,
      fontSize: theme.textSizes.xs,
      height: '2em',
      justifyContent: 'center',
      marginRight: '0.5rem',
      width: '2em',
    },
    '&$active::before': {
      background: theme.palette.secondary[400],
    },
    '&:not(:last-child)': {
      marginBottom: '-1.3rem',
      '&::after': {
        background: theme.palette.neutral[200],
        content: '""',
        display: 'block',
        height: '100%',
        left: 'calc(0.75rem - 0.5px)',
        position: 'absolute',
        top: '1.875rem',
        width: 1,
      },
    },
  },
  selectionSpace: {
    marginTop: '0.75rem',
  },
  done: {
    '&::before': {
      backgroundColor: theme.palette.secondary[400],
    },
  },
  doneImg: {
    backgroundColor: theme.palette.secondary[400],
    zIndex: 2,
    marginBottom: '-1.3rem',
    marginLeft: '.2rem',
    borderRadius: '50%',
  },
  empty: {
    height: '1.25rem',
  },
  line: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    left: '2rem',
    '&::before': {
      background: theme.palette.neutral[200],
      color: theme.palette.white,
      content: '""',
      height: 'calc(100% - 0.50rem)',
      left: '-1.25rem',
      position: 'absolute',
      top: '0.25rem',
      width: 1,
    },
  },
}));

const Breadcrumb = ({ current, index, position, step, numberOfSteps }) => {
  const classes = useStyles({ theme: useTheme() });
  const {
    shouldShowRWGClientViewChanges,
    shouldUseCheckInOnCV,
    shouldUseJoinTheLineFromCV,
  } = useFeatureDecisions();
  const intl = useIntl();
  const {
    formatters: { formatTime },
  } = useDateTime(true);
  const [
    {
      bookingWalkIn,
      additionalUsers,
      date,
      location,
      meetingMethod,
      service,
      user,
    },
  ] = useContext(SelectionContext);
  const [timezone] = useContext(TimezoneContext);
  const timezones = useContext(TimezonesContext);
  const { firstStep, showStaffPicker, showStaffAssigned, showWaitTime } =
    useContext(SettingsContext);
  const isNotTheLastStep = index !== numberOfSteps - 1;
  const showStaffName =
    Settings.shouldShowStaffPicker({ showStaffPicker }, location) ||
    Boolean(showStaffAssigned);
  const finish = (timestamp) => {
    const flexible = service.maxDuration && service.minDuration;

    if (user || !flexible) {
      const endTime = Dates.addMinutes(timestamp, service.duration);
      const end = new Date();
      end.setHours(endTime.hour(), endTime.minute());

      return end;
    }

    return null;
  };

  const staffSubtitle = useMemo(() => {
    if (!bookingWalkIn) {
      return user?.name;
    }

    return (
      <Alert severity="info" variant="minimal">
        <Alert.Content>
          <Alert.Description>
            <FormattedMessage
              id="Steps.no_staff_warning"
              values={{ name: user?.name }}
            />
          </Alert.Description>
        </Alert.Content>
      </Alert>
    );
  }, [bookingWalkIn, user?.name]);

  const range = (date) => {
    if (!date) {
      return null;
    }

    const start = new Date();
    start.setHours(date.hour(), date.minute());
    const end = finish(date);

    return (
      <FormattedMessage
        id="AppointmentDetails.appointment_duration"
        values={{
          start: formatTime(start),
          end: formatTime(end),
          timezone: (
            // We are temporarily ignoring the destructuring-assignment rule explicitly.
            // There is a bug that was solved in a newer version of this plugin which
            // we will eventually be able to upgrade to once we can move off of
            // the current version of NodeJS in use.
            //
            // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
            //
            // eslint-disable-next-line react/destructuring-assignment
            <TimezoneLabel fallback={timezone} timezone={timezones[timezone]} />
          ),
        }}
      />
    );
  };

  const shouldFetchAvailability =
    shouldUseJoinTheLineFromCV &&
    bookingWalkIn &&
    current.id === STEPS.DETAILS &&
    step.id === STEPS.TIMES &&
    showWaitTime;

  const { waitTime, isFetching, isError } = useLobbyAvailability({
    enabled: shouldFetchAvailability,
    waitTime: showWaitTime,
  });

  const isCurrentStep = current.id === step.id;
  const isCompletedStep = index < position;

  let ariaLabel = `Step ${index + 1}: ${step.id}`;
  if (isCurrentStep) {
    ariaLabel = `${ariaLabel} current`;
  } else {
    ariaLabel = isCompletedStep
      ? `${ariaLabel} completed`
      : `${ariaLabel} upcoming`;
  }

  return (
    <li className={classes.root} id={`sidebar-step-list-item-${step.id}`}>
      <div aria-current={isCurrentStep} aria-label={ariaLabel}>
        {isCompletedStep ? (
          <img
            alt={intl.formatMessage({ id: 'Svg.alt_text.completed' })}
            className={classNames(
              isCurrentStep && classes.active,
              classes.circle,
              isCompletedStep && classes.doneImg,
            )}
            src="/images/white-check.svg"
          />
        ) : null}
        <Typography
          classes={{
            root: classNames(
              isCurrentStep && classes.active,
              classes.circle,
              isCompletedStep && classes.done,
            ),
          }}
          variant="caption1"
        >
          <div className={classes.step}>
            <FormattedMessage id={TITLES[step.id]} />
          </div>
        </Typography>
      </div>
      <div className={classes.line}>
        {step.id === STEPS.SERVICE && service ? (
          <BreadcrumbSelection title={service.name} />
        ) : null}
        {step.id === STEPS.LOCATION && location && location.physical ? (
          <div id="sidebar-location-details">
            <BreadcrumbSelection
              subtitle={location.formattedAddress}
              title={location.name}
            />
          </div>
        ) : null}
        {step.id === STEPS.MEETING_METHODS && meetingMethod ? (
          <>
            <BreadcrumbSelection
              title={
                <FormattedMessage
                  id={MEETING_METHOD_TITLES[meetingMethod].primary}
                />
              }
            />
            {!shouldShowRWGClientViewChanges &&
            location &&
            location.physical &&
            firstStep &&
            firstStep !== 'location' ? (
              <div id="sidebar-location-details">
                <BreadcrumbSelection
                  classes={{ override: classes.selectionSpace }}
                  subtitle={location.formattedAddress}
                  title={location.name}
                />
              </div>
            ) : null}
          </>
        ) : null}
        {shouldShowRWGClientViewChanges &&
        step.id === STEPS.MEETING_METHODS &&
        location &&
        firstStep &&
        firstStep !== 'location' ? (
          <div id="sidebar-location-details">
            <BreadcrumbSelection
              classes={{ override: classes.selectionSpace }}
              subtitle={location.physical ? location.formattedAddress : ''}
              title={location.name}
            />
          </div>
        ) : null}
        {step.id === STEPS.TIMES ? (
          <>
            {!bookingWalkIn && date ? (
              <BreadcrumbSelection
                subtitle={
                  <>
                    <div>{Dates.toWeekdayDateMonthYear(date)}</div>
                    <div>{range(date)}</div>
                  </>
                }
                title={<FormattedMessage id="Steps.date_time" />}
              />
            ) : null}
            {bookingWalkIn && !isError && !isFetching && showWaitTime ? (
              <BreadcrumbSelection
                subtitle={
                  waitTime === 0 ? (
                    <FormattedMessage id="Steps.date_time_with_zero_wait_times" />
                  ) : (
                    <FormattedMessage
                      id="Steps.date_time_with_wait_times"
                      values={{ time: waitTime }}
                    />
                  )
                }
                title={<FormattedMessage id="Steps.date_time" />}
              />
            ) : null}
            {bookingWalkIn && !showWaitTime ? (
              <BreadcrumbSelection
                subtitle={
                  <FormattedMessage id="Steps.date_time_no_wait_times" />
                }
                title={<FormattedMessage id="Steps.date_time" />}
              />
            ) : null}
            {user && user.name && showStaffName ? (
              <BreadcrumbSelection
                classes={date ? { override: classes.selectionSpace } : {}}
                subtitle={staffSubtitle}
                tertiary={!bookingWalkIn ? user.jobTitle : ''}
                title={
                  !bookingWalkIn ? <FormattedMessage id="Steps.staff" /> : ''
                }
              />
            ) : null}
            {additionalUsers.length > 0 &&
            additionalUsers.length <= 2 &&
            showStaffName
              ? additionalUsers.map((addUser) => {
                  return (
                    <BreadcrumbSelection
                      classes={{ override: classes.selectionSpace }}
                      key={`additional-user${addUser.id}`}
                      subtitle={addUser.name}
                      tertiary={addUser.jobTitle}
                    />
                  );
                })
              : null}
            {additionalUsers.length > 2 && showStaffName ? (
              <>
                <BreadcrumbSelection
                  classes={{ override: classes.selectionSpace }}
                  key={`additional-user${additionalUsers[0].id}`}
                  subtitle={additionalUsers[0].name}
                  tertiary={additionalUsers[0].jobTitle}
                />
                <BreadcrumbSelection
                  classes={{ override: classes.selectionSpace }}
                  subtitle={
                    <FormattedMessage
                      id="AdditionalUsers.plus_more"
                      values={{ number: additionalUsers.length - 1 }}
                    />
                  }
                />
              </>
            ) : null}
          </>
        ) : null}
        {shouldUseCheckInOnCV && step.id === STEPS.CHECK_IN ? (
          <BreadcrumbSelection
            subtitle={
              <FormattedMessage
                id="Steps.check_in_description"
                values={{
                  p: (chunks) => <p className="mb-3">{chunks}</p>,
                }}
              />
            }
          />
        ) : null}
        {shouldUseJoinTheLineFromCV &&
        bookingWalkIn &&
        step.id === STEPS.DETAILS ? (
          <BreadcrumbSelection
            subtitle={
              <FormattedMessage
                id="Steps.details_description"
                values={{
                  p: (chunks) => <p className="mb-3">{chunks}</p>,
                }}
              />
            }
          />
        ) : null}
        {isNotTheLastStep ? <div className={classes.empty} /> : null}
      </div>
    </li>
  );
};

Breadcrumb.propTypes = {
  current: PropTypes.shape({ id: PropTypes.string }).isRequired,
  step: PropTypes.shape({ id: PropTypes.string }).isRequired,
  position: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
};

export default Breadcrumb;
