import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useReducer, useRef } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import useClickAway from '../../hooks/useClickAway';
import Search from '../icons/Search';

const useStyles = createUseStyles((theme) => ({
  container: {
    fontFamily: theme.fontFamilies.sansSerif,
    position: 'relative',
  },
  active: {
    background: theme.palette.neutral[100],
  },
  button: {
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    margin: 0,
    padding: '0.75rem',
    textAlign: 'left',
    width: '100%',
    '&:focus': {
      background: theme.palette.neutral[100],
      outline: `2px solid ${theme.palette.secondary[400]} !important`,
      outlineOffset: '-2px !important',
    },
  },
  focused: {
    boxShadow: theme.shadows.default,
  },
  icon: {
    color: theme.palette.neutral[400],
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translate(-50%, -45%)',
  },
  input: {
    borderRadius: theme.borderRadius.default,
    color: theme.palette.black,
    display: 'block',
    fontSize: theme.textSizes.base,
    padding: '1rem 2.5rem 1rem 0.75rem',
    transition: theme.transitions.boxShadow,
    width: '100%',
    '&:focus': {
      boxShadow: theme.shadows.md,
      outline: `2px solid ${theme.palette.secondary[400]}`,
      outlineOffset: '-2px',
    },
    '&$focused': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&::placeholder': {
      color: theme.palette.neutral[400],
      opacity: 0.9,
    },
  },
  dark: {
    background: theme.palette.neutral[100],
  },
  light: {
    background: theme.palette.white,
  },
  label: {
    display: 'block',
    position: 'relative',
    width: '100%',
  },
  list: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: theme.shadows.md,
    left: 0,
    maxHeight: '50vh',
    overflow: 'hidden',
    overflowY: 'auto',
    position: 'absolute',
    right: 0,
    top: '100%',
    zIndex: 1000,
  },
  shadow: {
    boxShadow: theme.shadows.default,
  },
}));

const SearchableInput = ({
  className,
  expandOnFocus,
  inputRef,
  name,
  onBlur,
  onChange,
  onClick,
  onFocus,
  options,
  scheme,
  shadow,
  optionLabels,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const container = useRef(null);
  const [tabIndex, setTabIndex] = useReducer((state, newState) => newState, 0);
  const [listable, setListable] = useReducer(
    (state, newState) => newState,
    !expandOnFocus,
  );

  const onKeyUp = (e) => {
    switch (e.key) {
      case 'Down':
      case 'ArrowDown': {
        const index = tabIndex + 1;
        const length = options.length - 1;
        setTabIndex(index < length ? index : length);
        break;
      }
      case 'Esc':
      case 'Escape':
        setListable(false);
        break;
      case 'Enter': {
        const selector = `ul > li:nth-child(${tabIndex + 1}) > button`;
        const button = container.current.querySelector(selector);
        const input = container.current.querySelector(`#${name}`);

        if (button) {
          setTabIndex(0);
          button.click();
          input.value = button.innerText;
          input.blur();
        }

        break;
      }
      case 'Up':
      case 'ArrowUp': {
        const index = tabIndex - 1;
        setTabIndex(index > 0 ? index : 0);
        break;
      }
      default: {
        if (!listable) {
          setListable(true);
        }
      }
    }
  };

  const onSearchFocus = () => {
    onFocus();
    setListable(true);
  };

  const onSearchClickAway = () => {
    if (expandOnFocus) {
      setListable(false);
    }
  };

  useClickAway(container, onSearchClickAway);

  return (
    <div
      className={classNames(classes.container, className)}
      data-testid="searchable-input"
      ref={container}
    >
      <label className={classes.label} htmlFor={name}>
        <input
          aria-autocomplete="list"
          aria-controls={`searchable-input-${name}`}
          aria-haspopup="listbox"
          aria-label={intl.formatMessage({ id: 'Ui.search.instructions' })}
          className={classNames(
            classes.input,
            classes[scheme],
            shadow ? classes.shadow : null,
            listable && options.length > 0 ? classes.focused : null,
          )}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onSearchFocus}
          onKeyUp={onKeyUp}
          placeholder={intl.formatMessage({ id: 'Ui.search.plain' })}
          ref={inputRef}
          role="searchbox"
          type="text"
        />
        <span aria-hidden className={classes.icon}>
          <Search />
        </span>
      </label>
      {listable && options.length > 0 ? (
        <ul
          aria-label={name}
          className={classes.list}
          data-testid="searchable-list"
          id={`searchable-input-${name}`}
          role="listbox"
        >
          {options.map((option, index) => (
            <li
              aria-label={optionLabels[index]}
              aria-selected={index === tabIndex ? 'true' : 'false'}
              className={classNames(index === tabIndex && classes.active)}
              key={option.props.identifier}
              role="option"
            >
              <button
                aria-label={optionLabels[index]}
                className={classes.button}
                data-identifier={option.props.identifier}
                onClick={onClick}
                onKeyUp={(e) => {
                  e.key === 'Esc' || e.key === 'Escape'
                    ? setListable(false)
                    : null;
                }}
                type="button"
              >
                {option}
              </button>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

SearchableInput.propTypes = {
  className: PropTypes.string,
  expandOnFocus: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element])),
  shadow: PropTypes.bool,
  scheme: PropTypes.oneOf(['dark', 'light']),
  optionLabels: PropTypes.arrayOf(PropTypes.string),
};

SearchableInput.defaultProps = {
  className: '',
  expandOnFocus: false,
  inputRef: null,
  name: 'searchable',
  onBlur: () => {},
  onChange: () => {},
  onClick: () => {},
  onFocus: () => {},
  optionLabels: [],
  options: [],
  scheme: 'dark',
  shadow: false,
};

export default SearchableInput;
