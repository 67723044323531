import PropTypes from 'prop-types';
import React, { useContext, useReducer } from 'react';
import kiosk from '../../shared/helpers/Mode';
import { APPOINTMENT_STATUSES, ATTENDEE_STATUSES } from '../constants';
import { AppointmentContext } from '../contexts/AppointmentContext';
import { FeatureContext } from '../contexts/FeatureContext';
import { SelectionContext } from '../contexts/SelectionContext';
import Meta from '../helpers/Meta';
import AppointmentCancelled from './AppointmentCancelled';
import AppointmentDetailsCard from './AppointmentDetailsCard';
import ChangesToYourAppointment from './ChangesToYourAppointment';
import Feedback from './Feedback';
import ManageAppointmentDetailsHeader from './ManageAppointmentDetailsHeader';
import ServiceInstructions from './ServiceInstructions';
import WhatNow from './WhatNow';

const ManageAppointmentDetails = ({ confirmation }) => {
  const [{ attendee }] = useContext(SelectionContext);
  const [appointment] = useContext(AppointmentContext);
  const { cvSatisfaction, appointmentBookingChannel } =
    useContext(FeatureContext);
  const [{ cancelled, recentlyCancelled }, setCancelled] = useReducer(
    () => ({ cancelled: true, recentlyCancelled: true }),
    {
      cancelled:
        appointment?.status === APPOINTMENT_STATUSES.CANCELLED ||
        attendee?.status === ATTENDEE_STATUSES.CANCELLED,
      recentlyCancelled: false,
    },
  );

  if (kiosk.isKiosk() && (!attendee || !appointment)) {
    return null;
  }

  const showFeedback =
    cvSatisfaction &&
    Meta.booker() === 0 &&
    Meta.enterprise() &&
    !appointment.rescheduled &&
    confirmation;

  return (
    <div aria-atomic="true" role="status">
      {cancelled ? (
        <AppointmentCancelled recentlyCancelled={recentlyCancelled} />
      ) : (
        <div>
          <ManageAppointmentDetailsHeader
            confirmation={confirmation}
            rescheduled={appointment.rescheduled}
          />
          {showFeedback ? <Feedback /> : null}
          <ServiceInstructions />
          <AppointmentDetailsCard />
          <ChangesToYourAppointment cancel={setCancelled} />
          {appointmentBookingChannel ? <WhatNow /> : null}
        </div>
      )}
    </div>
  );
};

ManageAppointmentDetails.propTypes = {
  confirmation: PropTypes.bool.isRequired,
};

export default ManageAppointmentDetails;
