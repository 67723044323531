import { CAPITALIZATION } from '../constants';

/**
 * @param {string} string
 *
 * @returns {string}
 */
function lowercaseFirst(string) {
  return `${string.charAt(0).toLowerCase()}${string.substring(1)}`;
}

export default {
  lowercaseFirst,

  /**
   * @param {string} string
   *
   * @returns {string}
   */
  uppercaseFirst(string) {
    return `${string.charAt(0).toUpperCase()}${string.substring(1)}`;
  },

  /**
   * @param {string} string
   *
   * @returns {string}
   */
  camelToSnake(string) {
    return string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  },

  handleCapitalization(string, locale) {
    return CAPITALIZATION.SENTENCE[locale] ? string : lowercaseFirst(string);
  },

  /**
   * @param {string} string
   *
   * @returns {string}
   */
  snakeToCamel(string) {
    return string.replace(/([_][a-z])/gi, (subString) =>
      subString.toUpperCase().replace('-', '').replace('_', ''),
    );
  },

  formatList(list, intl, fallback = '') {
    switch (list.length) {
      case 0:
        return fallback;
      case 1:
        return list[0];
      case 2:
        return intl.formatMessage(
          { id: 'Strings.two_items_list' },
          { first: list[0], second: list[1] },
        );
      case 3:
        return `${list[0]}, ${list[1]}, ${list[2]}`;
      default:
        return intl.formatMessage(
          { id: 'Strings.long_list' },
          { first: list[0], second: list[1], count: list.length - 2 },
        );
    }
  },

  /**
   * Hides a value with ***** in between the input
   * @param {string} input
   * @returns a new string containing ** in the middle
   */
  hideValue(input) {
    const inputLength = input?.length || 0;
    const quarter = Math.floor(inputLength / 4);
    const unScrambleLength = Math.min(4, quarter);
    let outputString = '';
    for (let i = 0; i < inputLength; i++) {
      if (
        i < Math.max(0, inputLength - unScrambleLength) &&
        i >= unScrambleLength
      ) {
        outputString = outputString + '*';
      } else {
        outputString = outputString + input[i];
      }
    }
    return outputString;
  },
};
