import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import Button from './Button';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  backdrop: {
    background: theme.palette.overlays.light,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 50,
    '& $container': {
      display: 'flex',
      flexDirection: 'column',
      left: '50%',
      position: 'absolute',
      textAlign: 'center',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '75%',
    },
  },
  card: {
    borderBottom: `0.25rem solid ${theme.palette.neutral[100]}`,
  },
  container: {
    background: theme.palette.white,
    boxShadow: theme.shadows.md,
    padding: '1.25rem',
    '&$inline': {
      boxShadow: 'unset',
    },
  },
  inline: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  rounded: {
    borderRadius: theme.borderRadius.default,
  },
  shadow: {
    boxShadow: theme.shadows.hover,
  },
  title: {
    marginBottom: '1.25rem',
  },
}));

const NoTimesAvailable = ({ month, onClick, rounded, shadow }) => {
  const classes = useStyles({ theme: useTheme() });
  const { shouldUseNextAvailability } = useContext(FeatureDecisionContext);

  return (
    <div
      className={classNames(
        month && classes.backdrop,
        !month && !shadow && classes.card,
        shadow && classes.shadow,
      )}
      data-testid="no-times-available"
    >
      <div
        className={classNames(
          classes.container,
          !month && classes.inline,
          rounded && classes.rounded,
        )}
      >
        <Typography
          classes={{
            root: classNames(
              month && !shouldUseNextAvailability && classes.title,
            ),
          }}
          variant="title"
        >
          {month ? (
            <FormattedMessage
              id="NoTimesAvailable.no_times_in_month"
              values={{ month }}
            />
          ) : (
            <FormattedMessage id="NoTimesAvailable.no_times_in_week" />
          )}
        </Typography>
        {shouldUseNextAvailability ? null : (
          <Button fullWidth={false} onClick={onClick}>
            {month ? (
              <FormattedMessage id="NoTimesAvailable.view_next_month" />
            ) : (
              <FormattedMessage id="NoTimesAvailable.view_next_week" />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

NoTimesAvailable.propTypes = {
  month: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  rounded: PropTypes.bool,
  shadow: PropTypes.bool,
};

NoTimesAvailable.defaultProps = {
  month: null,
  rounded: false,
  shadow: false,
};

export default NoTimesAvailable;
