import Map from '../../frontend/helpers/Map';
import Detective from './Detective';

export default {
  openDirections(address) {
    window.open(
      Map.directions(address),
      Detective.inIFrame()
        ? '_blank'
        : Detective.touchscreen()
        ? '_self'
        : '_blank',
    );
  },
};
