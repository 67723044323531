export default {
  /**
   * Detect that the current browser is not Internet Explorer 11.
   *
   * @returns {boolean}
   */
  notInternetExplorer11() {
    return !(!!window.MSInputMethodContext && !!document.documentMode);
  },

  /**
   * Detect if the device is a touch screen.
   *
   * @returns {boolean}
   */
  touchscreen() {
    if ('maxTouchPoints' in navigator) {
      if (navigator.maxTouchPoints > 0) {
        return true;
      }
    } else if ('msMaxTouchPoints' in navigator) {
      if (navigator.msMaxTouchPoints > 0) {
        return true;
      }
    } else {
      if (
        window.matchMedia &&
        window.matchMedia('(any-pointer:coarse)').matches
      ) {
        return true;
      }

      if (window.TouchEvent || 'ontouchstart' in window) {
        return true;
      }
    }

    return false;
  },

  /**
   * Detect if in an iframe.
   *
   * @returns {boolean}
   */
  inIFrame() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  },
};
