import PropTypes from 'prop-types';
import React, { createContext } from 'react';

const FeatureContext = createContext(window.state?.features || {});

const FeatureProvider = ({ children }) => (
  <FeatureContext.Provider value={window.state?.features || {}}>
    {children}
  </FeatureContext.Provider>
);

FeatureProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { FeatureContext, FeatureProvider };
