import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import DownChevron from '../../shared/icons/DownChevron';
import UpChevron from '../../shared/icons/UpChevron';
import Formatter from '../helpers/Formatter';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    display: 'inline',
  },
  chevron: {
    fill: theme.palette.neutral[350],
    float: 'right',
    transform: 'translateY(-3px)',
  },
  content: {
    maxHeight: '1.25rem',
    overflow: 'hidden',
    transition: 'max-height 0.5s',

    '& > p': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  distance: {
    color: theme.palette.neutral[400],
  },
  dot: {
    color: theme.palette.neutral[400],
    lineHeight: '1rem',
  },
  open: {
    maxHeight: '31.25rem !important',

    '& > p': {
      whiteSpace: 'inherit',
    },
  },
}));

const CollapsibleLocationInfo = ({ location, open }) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  return (
    <div className={classes.root}>
      <div className={classNames(classes.content, open && classes.open)}>
        <div className={classes.chevron}>
          {open ? (
            <UpChevron
              altText={intl.formatMessage({
                id: 'Svg.alt_text.hide_location_details',
              })}
            />
          ) : (
            <DownChevron
              altText={intl.formatMessage({
                id: 'Svg.alt_text.show_location_details',
              })}
            />
          )}
        </div>
        {location.distance ? (
          <Typography component="p" variant="subtitle">
            <>
              <span className={classes.distance}>
                {Formatter.distance(location.distance, location.country)}
              </span>
              <span className={classes.dot}> &bull; </span>
              {location.formattedAddress ? location.formattedAddress : ''}
            </>
          </Typography>
        ) : (
          <Typography component="p" variant="subtitle">
            {location.formattedAddress ? location.formattedAddress : ''}
          </Typography>
        )}
      </div>
    </div>
  );
};

CollapsibleLocationInfo.propTypes = {
  location: PropTypes.shape({
    country: PropTypes.string,
    distance: PropTypes.number,
    formattedAddress: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
};

export default CollapsibleLocationInfo;
