import mode from './Mode';

export default {
  /**
   * @param {Object} params
   * @param {string} start
   *
   * @returns {string}
   */
  getUtmParams(params, start) {
    const utmParams = {};

    if (mode.isInvitation()) {
      utmParams.utm_source = 'invitation';
    }

    Object.keys(params).forEach((key) => {
      if (
        [
          'utm_content',
          'utm_medium',
          'utm_source',
          'utm_campaign',
          'utm_term',
        ].includes(key)
      ) {
        utmParams[key] = params[key];
      }
    });

    if (Object.keys(utmParams).length) {
      return (
        start +
        Object.keys(utmParams)
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                utmParams[key],
              )}`,
          )
          .join('&')
      );
    }

    return '';
  },

  /**
   * @param {Object} params
   *
   * @returns {string}
   */
  getUtmParamsObject(params) {
    const utmParams = {};

    if (mode.isInvitation()) {
      utmParams.utm_source = 'invitation';
    }

    Object.keys(params).forEach((key) => {
      if (
        [
          'utm_content',
          'utm_medium',
          'utm_source',
          'utm_campaign',
          'utm_term',
        ].includes(key)
      ) {
        utmParams[key] = params[key];
      }
    });

    return utmParams;
  },
};
