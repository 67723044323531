import { SHORTCUTS, VISIBILITIES } from '../../constants';
import Shortcuts from '../Shortcuts';

const Services = (client) => ({
  /**
   * @returns {Promise<any>}
   */
  all({
    category,
    location,
    locationCategory,
    method,
    region,
    service,
    settings,
    user,
    userCategory,
  }) {
    const params = {};

    if (category) {
      params['filter[category]'] = category;
    }

    if (location) {
      params['filter[location]'] = location;
    }

    if (locationCategory && !settings.preferred_location) {
      params['filter[location_category]'] = locationCategory;
    }

    if (method) {
      params['filter[meeting_method]'] = method;
    }

    if (region) {
      params['filter[region]'] = region;
    }

    if (service) {
      params['filter[service]'] = service;
    }

    if (user) {
      params['filter[user]'] = user;
    }

    if (userCategory && !settings?.preferred_staff) {
      params['filter[user_category]'] = userCategory;
    }

    if (settings?.invite_only_resources) {
      params['filter[invite_only_resources]'] = true;
    }

    const query = Object.keys(params)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      )
      .join('&');

    return client.get(`/api/v3/open/services${query ? `?${query}` : ''}`);
  },

  /**
   * @returns {Promise<any>}
   */
  find({ locationCategory, service, settings, user = null }) {
    const params = {};

    if (locationCategory) {
      params['filter[location_category'] = locationCategory;
    }

    if (
      settings?.invite_only_resources ||
      Shortcuts.get(SHORTCUTS.SERVICE)?.attributes.visibility ===
        VISIBILITIES.INVITE_ONLY
    ) {
      params['filter[invite_only]'] = 1;
    }

    if (user) {
      params['filter[user]'] = user;
    }

    const query = Object.keys(params)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      )
      .join('&');

    return client.get(
      `/api/v2/open/services/${service}${query ? `?${query}` : ''}`,
    );
  },
});

export default Services;
