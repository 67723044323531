const { parse, stringify } = JSON;
const items = {};
const arrayStore = {
  removeItem: (key) => {
    delete items[key];
  },
  getItem: (key) => {
    return items[key] || null;
  },
  setItem: (key, value) => {
    items[key] = value;
  },
};

let store;

try {
  store = sessionStorage;
} catch (e) {
  store = arrayStore;
}

export default {
  /**
   * @param {string} key
   *
   * @returns {undefined}
   */
  clear(key) {
    store.removeItem(key);
  },

  /**
   * @param {string} key
   * @param {*} fallback
   *
   * @returns {*}
   */
  get(key, fallback = null) {
    return store.getItem(key) !== null ? parse(store.getItem(key)) : fallback;
  },

  /**
   * @param {string} key
   * @param {*} value
   *
   * @returns {undefined}
   */
  set(key, value) {
    store.setItem(key, stringify(value));
  },
};
