import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';

const HeaderContext = createContext([{}, () => {}]);

const HeaderProvider = ({ children }) => {
  const [options, setHeader] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      action: null,
      title: document.title,
    },
  );

  return (
    <HeaderContext.Provider value={[options, setHeader]}>
      {children}
    </HeaderContext.Provider>
  );
};

HeaderProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { HeaderContext, HeaderProvider };
