import axios from 'axios';
import Item from '../Item';
import Sessions from '../Sessions';

const {
  initialState,
  token,
  location: { origin },
} = window;

const Client = axios.create({
  headers: {
    Authorization: `Bearer ${Item.get(initialState || {}, 'jwt')}`,
  },
});

Client.interceptors.request.use((config) => {
  config.baseURL = config.url.startsWith('/')
    ? origin
    : `${origin}/api/internal/v1`;

  return config;
});

const decodeJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
  return JSON.parse(jsonPayload);
};

const refresh = (token) => {
  const { expiry } = decodeJwt(token);

  if (Date.now() / 1000 < expiry) {
    Client.defaults.headers.Authorization = `Bearer ${token}`;

    if (refreshing === false) {
      refreshing = true;

      Sessions.refresh().then(() => (refreshing = false));
    }
  }
};

let refreshing = false;

Client.interceptors.response.use(
  (response) => {
    const token = response.headers?.['x-updated-jwt'];

    if (token) {
      refresh(token);
    }

    return response;
  },
  (error) => {
    const token = error.response?.headers?.['x-updated-jwt'];

    if (token) {
      refresh(token);
    }

    return Promise.reject(error);
  },
);

Client.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

if (token) {
  Client.defaults.headers.common['X-CSRF-TOKEN'] = token;
} else {
  console.error('CSRF token not found');
}

if (initialState) {
  // TODO: This header needs to be updated when the user's locale value changes in state
  Client.defaults.headers.common['Accept-Language'] = initialState.user.locale;
}

export const updateCommonHeaders = (headers = {}) => {
  for (let key in headers) {
    Client.defaults.headers.common[key] = headers[key];
  }
};

export default Client;
