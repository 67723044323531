import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    paddingBottom: '0.25rem',
    position: 'relative',
    background: 'inherit',
    borderRadius: 'inherit',
  },
  horizontal: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const List = ({ children, horizontal, id }) => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <ul
      className={classNames(
        classes.root,
        horizontal ? classes.horizontal : null,
      )}
      data-testid="mobile-list"
      id={id}
      // VoiceOver on iOS doesn't pick up implicit role on ul, adding it
      // manually ensures SR users hear role when entering list.
      role="list"
    >
      {children}
    </ul>
  );
};

List.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  horizontal: PropTypes.bool,
  id: PropTypes.string,
};

List.defaultProps = {
  horizontal: false,
  id: null,
};

export default List;
