import {
  addDays,
  addMinutes,
  format as dateFnsFormat,
  isBefore,
  isValid,
  startOfWeek,
} from 'date-fns';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import ko from 'date-fns/locale/ko';
import pl from 'date-fns/locale/pl';
import pt from 'date-fns/locale/pt-BR';
import ru from 'date-fns/locale/ru';
import zh from 'date-fns/locale/zh-CN';
import { format as formatTimezone } from 'date-fns-tz';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

const locales = {
  en,
  es,
  fr,
  ko,
  pl,
  pt,
  ru,
  zh,
};

export const TIME_FORMATS = {
  '12_HOUR': 1,
  '24_HOUR': 2,
};

const FORMATS = {
  DATE_ISO: 'yyyy-MM-dd',
  DATE_LOCAL: 'PP',
  DATE_LOCAL_FULL: 'PPPP',
  DATE_TIME_ISO: 'yyyy-MM-dd HH:mm:ss',
  DATE_MONTH_DAY_SHORT: 'MMM d',
  DAY_OF_MONTH: 'd',
  DAY_OF_WEEK_ABR: 'EEEEEE',
  DAY_OF_WEEK_LETTER: 'EEEEE',
  DAY_OF_WEEK_NAME: 'EEEE',
  MONTH_YEAR_NAME: 'MMMM yyyy',
  MONTH_NAME: 'MMMM',
  TIME_ISO: 'HH:mm:ss',
  WEEK_OF_YEAR: 'w',
};

export const useDateTimeFormatters = (formatByBrowser = false) => {
  const { formatMessage, locale } = useIntl();

  const timeFormat = useMemo(() => {
    if (formatByBrowser) {
      return new Date().toLocaleTimeString().match('\\s')
        ? TIME_FORMATS['12_HOUR']
        : TIME_FORMATS['24_HOUR'];
    }

    return (
      window.initialState?.user?.time_format ||
      (locale !== 'en' ? TIME_FORMATS['24_HOUR'] : TIME_FORMATS['12_HOUR'])
    );
    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatByBrowser]);

  const format = useCallback(
    (date, formatString) => {
      if (!date) {
        return '';
      }

      return dateFnsFormat(date, formatString, {
        locale: locales[locale] || locales.en,
      });
    },
    [locale],
  );

  const getTimeString = useCallback(
    ({ hours = true, minutes = true, seconds = false }) => {
      let formatString = '';
      const format12Hour = timeFormat === TIME_FORMATS['12_HOUR'];

      if (hours) {
        formatString += format12Hour ? 'h' : 'HH';
      }

      if (minutes) {
        formatString += `${formatString ? ':' : ''}mm`;
      }

      if (seconds) {
        formatString += `${formatString ? ':' : ''}ss`;
      }

      if (hours && format12Hour) {
        formatString += ' aa';
      }

      return formatString;
    },
    [timeFormat],
  );

  return useMemo(() => {
    const formatTimezoneAbr = (date, timezone) =>
      formatTimezone(date, '(zzz)', { timeZone: timezone });

    const formatDateISO = (date) => format(date, FORMATS.DATE_ISO);
    const formatDateLocal = (date) => format(date, FORMATS.DATE_LOCAL);
    const formatDateLocalFull = (date) => format(date, FORMATS.DATE_LOCAL_FULL);
    const formatDateTimeISO = (date) => format(date, FORMATS.DATE_TIME_ISO);
    const formatMonthDayShort = (date) =>
      format(date, FORMATS.DATE_MONTH_DAY_SHORT);
    const formatDayOfMonth = (date) => format(date, FORMATS.DAY_OF_MONTH);
    const formatDayOfWeekAbr = (date) => format(date, FORMATS.DAY_OF_WEEK_ABR);
    const formatDayOfWeekLetter = (date) =>
      format(date, FORMATS.DAY_OF_WEEK_LETTER);
    const formatDayOfWeekName = (date) =>
      format(date, FORMATS.DAY_OF_WEEK_NAME);
    const formatMonthYearName = (date) => format(date, FORMATS.MONTH_YEAR_NAME);
    const formatMonthName = (date) => format(date, FORMATS.MONTH_NAME);
    const formatTimeISO = (date) => format(date, FORMATS.TIME_ISO);
    const formatTime = (date) =>
      format(date, getTimeString({ hours: true, minutes: true }));
    const formatWeekOfYear = (date) => format(date, FORMATS.WEEK_OF_YEAR);
    const formatDateTimeLocal = (date) =>
      `${format(date, FORMATS.DATE_LOCAL)}, ${formatTime(date)}`;
    const formatDateTimeLocalFull = (date) =>
      `${format(date, FORMATS.DATE_LOCAL_FULL)}, ${formatTime(date)}`;
    const formatDateTimeShort = (date) =>
      `${format(date, FORMATS.DATE_MONTH_DAY_SHORT)}, ${formatTime(date)}`;

    const formatDateTimeRangeHumanReadable = (start, end, timezone) => {
      if (!isValid(start) || !isValid(end)) {
        return '';
      }

      const now = new Date();
      const sameDay = formatDateISO(start) === formatDateISO(end);
      const today = sameDay && formatDateISO(start) === formatDateISO(now);
      const thisYear =
        start.getFullYear() === now.getFullYear() &&
        end.getFullYear() === now.getFullYear();
      const allDay =
        formatTimeISO(start) === '00:00:00' &&
        formatTimeISO(end) === '23:59:59';
      // prevent blank space from being included if getAbbreviatedTimezone returns empty string
      const startTimezoneAbr = timezone
        ? formatTimezoneAbr(start, timezone)
        : '';
      const endTimezoneAbr = timezone ? formatTimezoneAbr(end, timezone) : '';

      if (today) {
        return allDay
          ? formatMessage({ id: 'Dates.all_day_today' })
          : timezone
          ? formatMessage(
              { id: 'Dates.range_today_with_timezone' },
              {
                start: formatTime(start),
                end: formatTime(end),
                timezone: endTimezoneAbr,
              },
            )
          : formatMessage(
              { id: 'Dates.range_today' },
              {
                start: formatTime(start),
                end: formatTime(end),
              },
            );
      }

      if (sameDay) {
        return allDay
          ? formatMessage(
              { id: 'Dates.all_day_date' },
              {
                date: thisYear
                  ? formatMonthDayShort(start)
                  : formatDateLocal(start),
              },
            )
          : `${
              thisYear ? formatDateTimeShort(start) : formatDateTimeLocal(start)
            } — ${formatTime(end)} ${endTimezoneAbr}`;
      }

      const isSameTimezoneAbr = startTimezoneAbr === endTimezoneAbr;

      if (thisYear) {
        return isSameTimezoneAbr
          ? `${formatDateTimeShort(start)} — ${formatDateTimeShort(
              end,
            )} ${endTimezoneAbr}`
          : `${formatDateTimeShort(
              start,
            )} ${startTimezoneAbr} — ${formatDateTimeShort(
              end,
            )} ${endTimezoneAbr}`;
      }

      return isSameTimezoneAbr
        ? `${formatDateTimeLocal(start)} — ${formatDateTimeLocal(
            end,
          )} ${endTimezoneAbr}`
        : `${formatDateTimeLocal(
            start,
          )} ${startTimezoneAbr} — ${formatDateTimeLocal(
            end,
          )} ${endTimezoneAbr}`;
    };

    // Formatting:
    // Today, 9:30 PM (GMT+1)
    // Feb 22, 3:30 AM (GMT+5) with includeDay=false
    // Thursday, Feb 22, 2:30 AM (GMT+5:30) with includeDay=true
    const formatDateTimeHumanReadable = (
      date,
      timezone,
      includeDay = false,
    ) => {
      if (!isValid(date)) {
        return '';
      }

      const now = new Date();
      const today = formatDateISO(date) === formatDateISO(now);
      const thisYear = date.getFullYear() === now.getFullYear();
      const timezoneAbr = timezone ? formatTimezoneAbr(date, timezone) : '';

      if (today) {
        return formatMessage(
          { id: 'Dates.today_with_timezone' },
          {
            date: formatTime(date),
            timezone: timezoneAbr,
          },
        );
      }

      if (includeDay) {
        return `${formatDayOfWeekName(date)}, ${
          thisYear ? formatDateTimeShort(date) : formatDateTimeLocal(date)
        } ${timezoneAbr}`;
      }

      return `${
        thisYear ? formatDateTimeShort(date) : formatDateTimeLocal(date)
      } ${timezoneAbr}`;
    };

    return {
      formatDateISO,
      formatDateLocal,
      formatDateLocalFull,
      formatDateTimeHumanReadable,
      formatDateTimeISO,
      formatDateTimeLocal,
      formatDateTimeLocalFull,
      formatDateTimeRangeHumanReadable,
      formatDateTimeShort,
      formatDayOfMonth,
      formatDayOfWeekAbr,
      formatDayOfWeekLetter,
      formatDayOfWeekName,
      formatMonthDayShort,
      formatMonthName,
      formatMonthYearName,
      formatTime,
      formatTimeISO,
      formatTimezoneAbr,
      formatWeekOfYear,
    };
  }, [format, formatMessage, getTimeString]);
};

export const useDateTimeHelpers = (formatByBrowser = false) => {
  const { locale: intlLocale } = useIntl();
  const formatters = useDateTimeFormatters(formatByBrowser);

  const locale = locales[intlLocale] || locales.en;

  return useMemo(() => {
    const nearestTime = (date, interval, raw = false) => {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const rounded = interval * Math.ceil(minutes / interval);

      hours = rounded === 60 ? hours + 1 : hours;
      minutes = rounded === 60 ? 0 : rounded;

      if (raw) {
        const nearest = new Date(date);
        nearest.setHours(hours, minutes, 0);

        return nearest;
      }

      const nearest = `${hours > 9 ? hours.toString() : `0${hours}`}:${
        minutes > 9 ? minutes.toString() : `0${minutes}`
      }:00`;

      return nearest;
    };

    /**
     * Generate an array of formatted/localized times, excluding the midnight at the end of the day
     * Can include manual times that don't lie on the interval
     *
     * @param {number} interval
     * @param {string} [after] time string in 'HH:mm:ss' format
     * @param {[string|Date]} inclusions time strings in 'HH:mm:ss' format
     *
     * @returns {Array.<{value: string, label: string}>} value is ISO, label is localized
     */
    const generateTimes = (interval, after, inclusions = []) => {
      let current = new Date();

      const toObject = (date) => ({
        label: formatters.formatTime(date),
        value: formatters.formatTimeISO(date),
      });

      if (after) {
        let [hours, minutes] = after.split(':');
        let date = new Date();
        date.setHours(hours, minutes, 0, 0);

        date = nearestTime(addMinutes(date, interval), interval);
        [hours, minutes] = date.split(':');

        current.setHours(hours, minutes, 0, 0);
      } else {
        current.setHours(0, 0, 0, 0);
      }

      const nextDay = addDays(current, 1);
      nextDay.setHours(0, 0, 0, 0);
      const times = [];

      do {
        times.push(toObject(current));
        current = addMinutes(current, interval);
      } while (isBefore(current, nextDay));

      inclusions.forEach((item) => {
        let date;

        if (typeof item === 'string') {
          const [hours, minutes, seconds] = item.split(':');
          date = new Date();
          date.setHours(hours, minutes, seconds, 0);
        } else {
          date = item;
        }

        const insert = toObject(date);
        const index = times.findIndex(({ value }) => value >= insert.value);

        if (index < 0 || times[index].value !== insert.value) {
          if (index >= 0) {
            times.splice(index, 0, insert);
          } else {
            times.push(insert);
          }
        }
      });

      return times;
    };

    const daysOfWeekLocal = () => {
      const weekStart = startOfWeek(new Date(), { locale });

      let days = [];

      for (let i = 0; i < 7; i++) {
        const day = addDays(weekStart, i);
        days.push({
          date: day,
          number: `${day.getDay()}`,
        });
      }

      return days;
    };

    function getCurrentLocale(locale) {
      return locales[locale] || locales.en;
    }

    return {
      daysOfWeekLocal,
      generateTimes,
      getCurrentLocale,
      nearestTime,
    };
  }, [formatters, locale]);
};

const useDateTime = (formatByBrowser = false) => {
  const formatters = useDateTimeFormatters(formatByBrowser);
  const helpers = useDateTimeHelpers(formatByBrowser);

  return useMemo(
    () => ({
      formatters,
      helpers,
    }),
    [formatters, helpers],
  );
};

export default useDateTime;
