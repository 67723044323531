import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { PositionContext } from '../contexts/PositionContext';
import Alert from './Alert';
import Button from './Button';

const useStyles = createUseStyles((theme) => ({
  button: {
    '&:focus': {
      outline: 0,
      boxShadow: theme.shadows.input,
      borderColor: theme.palette.secondary[500],
      borderBottomColor: theme.palette.secondary[500],
      color: theme.palette.secondary[500],
      fontWeight: theme.fontWeights.boldWeight,
    },
  },
}));

const PERMISSION_DENIED = 1;
const POSITION_UNAVAILABLE = 2;
const TIMEOUT = 3;

const messages = {
  [PERMISSION_DENIED]: <FormattedMessage id="PositionErrorAlert.denied" />,
  [POSITION_UNAVAILABLE]: <FormattedMessage id="PositionErrorAlert.unable" />,
  [TIMEOUT]: <FormattedMessage id="PositionErrorAlert.unable" />,
};

const PositionErrorAlert = ({ dismissError, white }) => {
  const classes = useStyles({ theme: useTheme() });
  const { error, getPosition } = useContext(PositionContext);
  const action = (
    <Button
      classes={{ override: classes.button }}
      fullWidth={false}
      onClick={getPosition}
      variant="text"
    >
      <FormattedMessage id="PositionErrorAlert.retry" />
    </Button>
  );
  const dismiss = (
    <Button
      classes={{ override: classes.button }}
      fullWidth={false}
      onClick={dismissError}
      variant="text"
    >
      <FormattedMessage id="Ui.dismiss" />
    </Button>
  );

  return (
    <Alert
      action={action}
      dismiss={dismiss}
      message={messages[error]}
      variant="error"
      white={white}
    />
  );
};

PositionErrorAlert.propTypes = {
  dismissError: PropTypes.func.isRequired,
  white: PropTypes.bool,
};

PositionErrorAlert.defaultProps = {
  white: false,
};

export default PositionErrorAlert;
