import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Phone from '../../../shared/helpers/Phone';

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'flex',
    '& > div': {
      flexGrow: 1,
      margin: '0 1rem 0 0',
    },
  },
  input: {
    border: `1px solid ${theme.borderColor.default}`,
    borderRadius: `${theme.borderRadius.sm} !important`,
    color: theme.palette.black,
    display: 'block',
    flexGrow: 1,
    fontSize: theme.textSizes.base,
    height: 'auto !important',
    padding: '0.75rem !important',
    width: '100%',
    '&:focus': {
      borderColor: `${theme.palette.info[400]} !important`,
      boxShadow: theme.shadows.input,
      outline: 0,
    },
    '&:active': {
      borderColor: theme.palette.neutral[350],
    },
  },
  error: {
    borderColor: theme.palette.danger[400],
  },
}));

const PhoneNumberInput = ({
  country: defaultCountry,
  errors,
  id,
  name,
  onChangePhone,
  onSetPhone,
  phoneNumber,
  required,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [valid, setValid] = React.useState(() => true);
  const [country, setCountry] = React.useState(defaultCountry);

  const savePhone = (number) => {
    const phone = Phone.parse(number, {
      country,
      format: false,
    });

    onSetPhone(phone.number);
  };

  const onBlur = (event) => {
    savePhone(event.currentTarget.value);
  };

  const onChange = (event) => {
    let { value } = event.currentTarget;
    const phoneDigits = value.replace(/[^+\d]/g, '');

    // this is the only case that is not handled by the parser to ensure only valid phone
    // characters are captured
    if (phoneDigits.length === 0) {
      onChangePhone('');

      return;
    }

    const prevNumber = phoneNumber || '';

    if (value.length < prevNumber.length) {
      // A very limited "smart caret" implementation: if the last character deleted is not
      // numeric, delete one more character. This case needs to be handled, because otherwise
      // it would not be possible to "backspace" past ")" in a formatted phone number.
      const lastChar = prevNumber.startsWith(value)
        ? prevNumber.charAt(value.length)
        : '';
      if (lastChar.length && Number.isNaN(Number(lastChar))) {
        value = value.slice(0, -1);
      }
    } else if (!country && value[0] !== '+') {
      value = `+${phoneDigits}`;
    }

    const phone = Phone.parse(value, { country });
    onChangePhone(phone.number || '');

    setValid(phone.valid);

    if (phone.country && country !== phone.country) {
      setCountry(phone.country);
    }
  };

  useEffect(() => {
    if (!phoneNumber) {
      return;
    }

    const phone = Phone.parse(phoneNumber, { country });
    onChangePhone(phone.number || '');

    setValid(phone.valid);

    if (phone.country && country !== phone.country) {
      setCountry(phone.country);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      <input
        aria-invalid={!valid}
        aria-required={required}
        autoComplete="tel"
        className={classNames(
          classes.input,
          errors.length > 0 && classes.error,
        )}
        id={id}
        inputMode="tel"
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type="tel"
        value={phoneNumber}
      />
    </div>
  );
};

PhoneNumberInput.propTypes = {
  country: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangePhone: PropTypes.func.isRequired,
  onSetPhone: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  required: PropTypes.oneOf([true, false]).isRequired,
};

PhoneNumberInput.defaultProps = {
  errors: [],
};

export default PhoneNumberInput;
