import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const AtLocation = ({ altText, ariaHidden }) => {
  const intl = useIntl();
  return (
    <svg
      aria-hidden={ariaHidden ? 'true' : 'false'}
      height="20.003"
      width="20.003"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.in_person' })}
      </title>
      <path d="M0 0h20v20H0z" fill="none" />
      <path
        d="M10.002 5.834V2.5H1.667v15h16.669V5.834zm-5 10H3.334v-1.666h1.667zm0-3.334H3.334v-1.665h1.667zm0-3.334H3.334V7.5h1.667zm0-3.334H3.334V4.167h1.667zm3.334 10H6.667v-1.664h1.668zm0-3.334H6.667v-1.663h1.668zm0-3.334H6.667V7.5h1.668zm0-3.334H6.667V4.167h1.668zm8.335 10h-6.669v-1.662h1.665V12.5h-1.665v-1.665h1.665V9.168h-1.665V7.5h6.665zm-1.669-6.662h-1.667v1.667h1.667zm0 3.334h-1.667v1.667h1.667z"
        fill="#616161"
      />
    </svg>
  );
};

AtLocation.propTypes = {
  altText: PropTypes.string,
  ariaHidden: PropTypes.bool,
};

AtLocation.defaultProps = {
  altText: '',
  ariaHidden: false,
};

export default AtLocation;
