import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import LocationPin from './icons/LocationPin';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  container: {
    alignItems: 'center',
    color: theme.palette.neutral[300],
    display: 'flex',
    '& > svg': {
      marginRight: '0.675rem',
    },
  },
}));

const LocationSuggestion = ({ suggestion }) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <span className={classes.container}>
      <LocationPin />
      <Typography dark variant="caption2">
        {suggestion.attributes.name}
      </Typography>
    </span>
  );
};

LocationSuggestion.propTypes = {
  suggestion: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default LocationSuggestion;
