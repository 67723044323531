import PropTypes from 'prop-types';
import React from 'react';
import { useFeatureDecisions } from '../../shared/contexts/FeatureDecisionContext';
import LocationPin from './icons/LocationPin';
import Typography from './Typography';

const LocationHeader = ({ location }) => {
  const locationName = location?.name;
  const locationAddress = location?.physical ? location.formattedAddress : '';
  const { shouldShowRWGClientViewChanges } = useFeatureDecisions();

  return shouldShowRWGClientViewChanges && location ? (
    <header className="bg-white-90 flex mb-4">
      <LocationPin />
      <div>
        <Typography
          className="my-1 mx-1 font-bold"
          component="h3"
          variant="label"
        >
          {locationName}
        </Typography>
        <Typography
          className="my-1 mx-1 font-bold"
          component="h3"
          variant="label"
        >
          {locationAddress}
        </Typography>
      </div>
    </header>
  ) : null;
};

LocationHeader.propTypes = {
  location: PropTypes.objectOf(PropTypes.string),
};

LocationHeader.defaultProps = {
  location: null,
};

export default LocationHeader;
