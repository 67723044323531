import React from 'react';

const NoResults = () => (
  <svg
    data-testid="no-results-icon"
    height="135.461"
    viewBox="0 0 118.648 135.461"
    width="118.648"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      data-name="Group 4156"
      id="Group_4156"
      transform="translate(-50.352 -45.994)"
    >
      <path
        d="M125.395,174.956c-3.115,1.811-15.185,8.753-30.441,5.761-3.241-.634-18.5-3.658-24.9-15.688-8.425-15.929,7.7-30.643,1.7-48.22-5.134-15.1-10.857-13.984-18.548-29.979-4-8.332-3.837-19.932.786-27.091C68.4,37.48,126.038,40.131,148.98,83.482,165.707,115.052,150.6,160.411,125.395,174.956Z"
        data-name="Path 543"
        fill="#f5f5f5"
        id="Path_543"
      />
      <circle
        cx="15.611"
        cy="15.611"
        data-name="Ellipse 9"
        fill="rgba(0,0,0,0.04)"
        id="Ellipse_9"
        r="15.611"
        transform="translate(114.047 61.616)"
      />
      <rect
        data-name="Rectangle 823"
        fill="#ececec"
        height="51.637"
        id="Rectangle_823"
        rx="3.506"
        transform="translate(80.251 89.332) rotate(-8.211)"
        width="51.637"
      />
      <rect
        data-name="Rectangle 824"
        fill="#fcfcfc"
        height="57.641"
        id="Rectangle_824"
        rx="3.506"
        transform="translate(79.222 82.201) rotate(-8.211)"
        width="51.637"
      />
      <rect
        data-name="Rectangle 825"
        fill="#ececec"
        height="51.637"
        id="Rectangle_825"
        rx="3.506"
        transform="translate(82.825 84.432)"
        width="51.637"
      />
      <rect
        data-name="Rectangle 826"
        fill="#fff"
        height="57.641"
        id="Rectangle_826"
        rx="3.506"
        transform="translate(82.825 77.227)"
        width="51.637"
      />
      <rect
        data-name="Rectangle 827"
        fill="#fff"
        height="57.641"
        id="Rectangle_827"
        rx="3.506"
        transform="translate(82.825 77.227)"
        width="51.637"
      />
      <g data-name="Group 4041" id="Group_4041" opacity="0.5">
        <rect
          data-name="Rectangle 829"
          fill="#c4c4c4"
          height="2.109"
          id="Rectangle_829"
          transform="translate(93.35 101.515)"
          width="22.826"
        />
        <rect
          data-name="Rectangle 830"
          fill="#c4c4c4"
          height="2.109"
          id="Rectangle_830"
          transform="translate(93.35 105.206)"
          width="14.674"
        />
        <rect
          data-name="Rectangle 832"
          fill="#c4c4c4"
          height="2.109"
          id="Rectangle_832"
          transform="translate(92.768 114.602)"
          width="23.312"
        />
        <rect
          data-name="Rectangle 833"
          fill="#c4c4c4"
          height="2.109"
          id="Rectangle_833"
          transform="translate(92.768 118.292)"
          width="14.986"
        />
        <rect
          data-name="Rectangle 835"
          fill="#c4c4c4"
          height="2.109"
          id="Rectangle_835"
          transform="translate(93.578 88.428)"
          width="23.798"
        />
        <rect
          data-name="Rectangle 836"
          fill="#c4c4c4"
          height="2.109"
          id="Rectangle_836"
          transform="translate(93.578 92.119)"
          width="15.298"
        />
      </g>
      <path
        d="M139.882,87.738A14.363,14.363,0,1,0,119.57,67.425l-4.35-4.349a20.517,20.517,0,1,1,29.012,29.011Z"
        data-name="Path 544"
        fill="#c8c8c8"
        id="Path_544"
      />
      <path
        d="M119.57,67.425a14.363,14.363,0,0,0,20.312,20.312l4.349,4.349A20.517,20.517,0,0,1,115.22,63.076Z"
        data-name="Path 545"
        fill="#c8c8c8"
        id="Path_545"
      />
      <path
        d="M148.587,96.442l-2.9,2.9L141.042,94.7a20.724,20.724,0,0,0,3.19-2.616Z"
        data-name="Path 548"
        fill="#b5b4ba"
        id="Path_548"
      />
      <path
        d="M144.232,92.087a20.655,20.655,0,0,0,2.615-3.19l4.637,4.647-2.9,2.9Z"
        data-name="Path 549"
        fill="#acabb1"
        id="Path_549"
      />
      <path
        d="M148.586,96.442,167.2,115.053a6.156,6.156,0,0,1-8.7,0l-14.26-14.26,1.455-1.454Z"
        data-name="Path 550"
        fill="#b5b4ba"
        id="Path_550"
      />
      <path
        d="M151.484,93.544l1.454-1.455L167.2,106.348a6.155,6.155,0,0,1,0,8.705L148.586,96.442Z"
        data-name="Path 551"
        fill="#a6a5ab"
        id="Path_551"
      />
    </g>
  </svg>
);

export default NoResults;
