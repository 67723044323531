import { parseISO } from 'date-fns';
import Dates from '../../shared/helpers/Dates';

const WEEK = 'week';
const MONTH = 'month';

class Range {
  static week({ date }) {
    return new Range({ date, unit: WEEK });
  }

  static month({ date }) {
    return new Range({ date, unit: MONTH });
  }

  static override({ range, start, end, startOverride, endOverride }) {
    const windowEnd = end && Dates.parse(parseISO(end).getTime());
    const windowStart = start && Dates.parse(parseISO(start).getTime());

    // If a booking shortcut end date is before the current search range end date, use it as the end of the search range
    if (windowEnd && windowEnd.epoch < range.end.epoch) {
      range.endOverride = windowEnd;
    }
    if (windowStart && windowStart.epoch > range.start.epoch) {
      range.startOverride = windowStart;
    }

    // If a new override is provided, use it for the search range
    if (!range.endOverride && endOverride) {
      range.endOverride = endOverride;
    }
    if (!range.startOverride && startOverride) {
      range.startOverride = startOverride;
    }

    return range;
  }

  constructor({ date, unit }) {
    switch (unit) {
      case WEEK:
        this.start = Dates.startOfWeek(date);
        this.end = Dates.endOfWeek(date);
        this.unit = WEEK;

        break;
      case MONTH:
        this.start = Dates.startOfMonth(date);
        this.end = Dates.endOfMonth(date);
        this.unit = MONTH;

        break;
      default:
        throw new Error('Range can not be created with the given unit');
    }
  }

  next() {
    switch (this.unit) {
      case WEEK:
        return Range.week({ date: this.start.next(this.unit) });
      case MONTH:
        return Range.month({ date: this.start.next(this.unit) });
      default:
        throw new Error(
          'The range unit has been modified and is not recognized',
        );
    }
  }

  previous() {
    switch (this.unit) {
      case WEEK:
        return Range.week({ date: this.start.last(this.unit) });
      case MONTH:
        return Range.month({ date: this.start.last(this.unit) });
      default:
        throw new Error(
          'The range unit has been modified and is not recognized',
        );
    }
  }
}

export default Range;
