import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useContext, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';
import { CountryContext } from '../contexts/CountryContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { TimezonesContext } from '../contexts/TimezonesContext';
import Button from './Button';
import DesktopCountryPicker from './DesktopCountryPicker';
import DesktopTimezonePicker from './DesktopTimezonePicker';
import Modal from './Modal';
import ModalContent from './ModalContent';
import Typography from './Typography';

const CountryTimezoneModal = ({ close, open }) => {
  const timezones = useContext(TimezonesContext);
  const [country, setCountry] = useContext(CountryContext);
  const [timezone, setTimezone] = useContext(TimezoneContext);

  const [selectedTimezone, selectTimezone] = useReducer(
    (state, newState) => newState,
    timezone,
  );
  const [timezoneError, setTimezoneError] = useReducer(
    (state, newState) => newState,
    false,
  );

  const clearError = () => setTimezoneError(false);

  const handleDismiss = useCallback(() => {
    setCountry(country);
    close();

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinue = useCallback(() => {
    // We are temporarily ignoring the destructuring-assignment rule explicitly.
    // There is a bug that was solved in a newer version of this plugin which
    // we will eventually be able to upgrade to once we can move off of
    // the current version of NodeJS in use.
    //
    // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
    //
    // eslint-disable-next-line react/destructuring-assignment
    if (timezones[selectedTimezone]) {
      setTimezone(selectedTimezone);
      close();
    } else {
      setTimezoneError(true);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimezone, timezone, timezones]);

  return (
    <Modal
      isOpen={open}
      modalContent={
        <ModalContent
          buttons={
            <>
              <Button
                fullWidth={false}
                onClick={handleDismiss}
                type="button"
                variant="secondary"
              >
                <FormattedMessage id="Ui.dismiss" />
              </Button>
              <Button fullWidth={false} onClick={handleContinue} type="button">
                <FormattedMessage id="Ui.continue" />
              </Button>
            </>
          }
          header={<FormattedMessage id="CountryTimezoneModal.title" />}
        >
          <Typography component="p" variant="subtitle">
            <FormattedMessage id="CountryTimezoneModal.description" />
          </Typography>
          <div>
            <Typography component="h3" variant="label">
              <FormattedMessage id="CountrySelectInput.label" />
            </Typography>
          </div>
          <DesktopCountryPicker />
          <div>
            <Typography component="h3" variant="label">
              <FormattedMessage id="TimezoneSelectInput.label" />
            </Typography>
          </div>
          <DesktopTimezonePicker
            clearError={clearError}
            error={timezoneError}
            setTimezone={selectTimezone}
            timezone={selectedTimezone}
          />
        </ModalContent>
      }
      onRequestClose={handleDismiss}
    />
  );
};

CountryTimezoneModal.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CountryTimezoneModal;
