import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Alert from '../../Alert';

const useStyles = createUseStyles((theme) => ({
  root: {
    alignItems: 'center',
    borderRadius: theme.borderRadius.default,
    display: 'flex',
    marginBottom: '1rem',
    padding: '0.875rem 1.25rem',
  },
}));

const DuplicateAttendeeAlert = () => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <Alert
      classes={{ root: classes.root }}
      message={<FormattedMessage id="DetailsForm.duplicate_attendee" />}
      name="duplicate"
      variant="error"
    />
  );
};

export default DuplicateAttendeeAlert;
