import haversine from 'haversine';

export default {
  /**
   * @param {{ latitude: float, longitude: float }} start
   * @param {{ latitude: float, longitude: float }} end
   * @param {string} country
   *
   * @returns {array}
   */
  distance(start, end, country) {
    return [
      haversine(start, end, {
        unit: country === 'US' ? 'mile' : 'km',
      }),
      country === 'US' ? 'mi' : 'km',
    ];
  },

  /**
   * @param {number} bytes
   *
   * @returns {string}
   */
  convertBytes(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) {
      return 'n/a';
    }

    const indexSize = parseInt(
      Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
    );

    if (indexSize === 0) {
      return `${bytes} ${sizes[indexSize]}`;
    }

    return `${(bytes / 1024 ** indexSize).toFixed(1)} ${sizes[indexSize]}`;
  },
};
