/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { DEFAULT_COUNTRY } from '../../constants';
import Typography from '../Typography';
import InputErrors from './InputErrors';
import PhoneNumberInput from './PhoneNumberInput';

const useStyles = createUseStyles({
  label: {
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0.5rem',
  },
  optional: {
    marginLeft: '0.25rem',
  },
  subtext: {
    display: 'block',
  },
});

const PhoneInput = ({
  country,
  errors,
  id,
  label,
  mask,
  name,
  optional,
  setFieldValue,
  subtext,
  value,
}) => {
  const classes = useStyles();

  const [val, setVal] = useState(null);

  const handleChange = (phone) => setVal(phone);
  const handleBlur = (phone) => setFieldValue(name, phone || '');

  return (
    <>
      <div className={classes.label}>
        <label htmlFor={id || name}>
          <Typography variant="label">{label}</Typography>
          {optional ? (
            <Typography classes={{ root: classes.optional }} variant="body2">
              <FormattedMessage id="Form.optional" />
            </Typography>
          ) : null}
          {subtext ? (
            <Typography classes={{ root: classes.subtext }} variant="subtitle">
              {subtext}
            </Typography>
          ) : null}
        </label>
      </div>
      <div {...(mask && { 'data-personal': true })}>
        <PhoneNumberInput
          country={country || DEFAULT_COUNTRY}
          errors={errors}
          id={id || name}
          name={name}
          onChangePhone={handleChange}
          onSetPhone={handleBlur}
          phoneNumber={val === null ? value : val}
          required={!optional}
        />
      </div>
      {errors.length > 0 && (
        <InputErrors errors={errors} id={`error-${name}`} />
      )}
    </>
  );
};

PhoneInput.propTypes = {
  country: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  mask: PropTypes.bool,
  name: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  subtext: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string]),
};

PhoneInput.defaultProps = {
  errors: [],
  id: '',
  mask: false,
  optional: false,
  subtext: null,
  value: '',
};

export default PhoneInput;
