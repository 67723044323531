/* eslint-disable jsx-a11y/label-has-for */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import SelectInputOptionShape from '../../shapes/SelectInputOptionShape';
import DropDownArrow from '../icons/DropDownArrow';
import Typography from '../Typography';
import HelpText from './HelpText';
import InputErrors from './InputErrors';
import TextInput from './TextInput';

const useStyles = createUseStyles((theme) => ({
  additional: {
    marginTop: '0.5rem',
  },
  container: {
    position: 'relative',
    '& svg': {
      fill: theme.palette.black,
      pointerEvents: 'none',
      position: 'absolute',
      right: '0.5rem',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  input: {
    appearance: 'none',
    background: theme.palette.white,
    border: `1px solid ${theme.borderColor.default}`,
    borderRadius: theme.borderRadius.sm,
    color: theme.palette.black,
    display: 'block',
    fontSize: theme.textSizes.base,
    padding: '0.75rem',
    paddingRight: '2rem',
    transition: theme.transitions.border,
    width: '100%',
    '&:focus': {
      borderColor: theme.palette.info[400],
      boxShadow: theme.shadows.input,
      outline: 0,
    },
    '&::-ms-expand': {
      display: 'none',
    },
  },
  error: {
    borderColor: theme.palette.danger[400],
  },
  label: {
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0.5rem',
  },
  optional: {
    marginLeft: '0.25rem',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
}));

const SelectInput = ({
  additionalErrors,
  additionalName,
  additionalValue,
  ariaRequired,
  disabled,
  errors,
  firstOption,
  helpText,
  hideLabel,
  id,
  label,
  mask,
  name,
  onBlur,
  onChange,
  optional,
  options,
  value,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const [selected, setSelected] = useState(null);
  const Label = hideLabel ? 'div' : 'label';
  const selectedOption =
    options && options.find((option) => value === String(option.value));

  const handleChange = (event) => {
    setSelected(event.target.selected);
  };

  return (
    <>
      <Label htmlFor={id || name} {...(mask && { 'data-personal': true })}>
        <div
          className={classNames(
            classes.label,
            hideLabel && classes.visuallyHidden,
          )}
        >
          <Typography variant="label">{label}</Typography>
          {optional ? (
            <Typography classes={{ root: classes.optional }} variant="body2">
              <FormattedMessage id="Form.optional" />
            </Typography>
          ) : null}
        </div>
        <div className={classes.container}>
          <select
            aria-required={ariaRequired}
            className={classNames(
              classes.input,
              errors.length > 0 && classes.error,
            )}
            disabled={disabled}
            id={id || name}
            name={name}
            onBlur={onBlur}
            onChange={onChange || handleChange}
            value={selected === null ? value : selected}
            {...(errors.length > 0
              ? { 'aria-invalid': true, 'aria-describedby': `error-${name}` }
              : {})}
          >
            <option value="">
              {firstOption !== null
                ? firstOption
                : intl.formatMessage({ id: 'SelectInput.default' })}
            </option>
            {options
              ? options.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  );
                })
              : null}
          </select>
          <DropDownArrow />
        </div>
        {selectedOption && selectedOption.additional ? (
          <div className={classes.additional}>
            <TextInput
              errors={additionalErrors}
              hideLabel
              key={`${selectedOption.text}-${selectedOption.value}-additional`}
              label={intl.formatMessage({ id: 'Input.additional_input' })}
              name={additionalName}
              onBlur={onBlur}
              onChange={onChange}
              value={additionalValue}
            />
          </div>
        ) : null}
      </Label>
      {errors.length > 0 ? (
        <InputErrors errors={errors} id={`error-${name}`} />
      ) : (
        <HelpText value={helpText} />
      )}
    </>
  );
};

SelectInput.propTypes = {
  additionalErrors: PropTypes.arrayOf(PropTypes.string),
  additionalName: PropTypes.string,
  additionalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ariaRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  firstOption: PropTypes.string,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  mask: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  options: PropTypes.arrayOf(SelectInputOptionShape),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

SelectInput.defaultProps = {
  additionalErrors: [],
  additionalName: '',
  additionalValue: '',
  ariaRequired: false,
  disabled: false,
  errors: [],
  firstOption: null,
  helpText: null,
  hideLabel: false,
  id: '',
  mask: false,
  optional: false,
};

export default SelectInput;
