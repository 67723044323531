import { Notifications } from 'coconut-open-api-js';
import { VISIBILITIES } from '../../constants';
import Url from '../Url';

const MeetingMethods = (client) => ({
  /**
   * @returns {Promise<any>}
   */
  all({
    location,
    locationCategory,
    region,
    service,
    settings,
    timeRangeEnd,
    timeRangeStart,
    timeRangeTimezone,
    user,
    userCategory,
    visibility = VISIBILITIES.PUBLIC,
  } = {}) {
    const filters = {};

    if (location) {
      filters.location = location.id;
    }

    if (locationCategory && !settings?.preferred_location) {
      filters.location_category = locationCategory.id;
    }

    if (region) {
      filters.region = region;
    }

    if (service) {
      filters.service = service.id;
    }

    if (user) {
      filters.user = user.id;
    }

    if (userCategory && !settings?.preferred_staff) {
      filters.user_category = userCategory.id;
    }

    if (visibility !== undefined) {
      filters.visibility = visibility;
    }

    if (settings?.invite_only_resources) {
      filters.invite_only_resources = settings.invite_only_resources;
    }

    if (timeRangeStart && timeRangeEnd && timeRangeTimezone) {
      filters.time_range_start = timeRangeStart;
      filters.time_range_end = timeRangeEnd;
      filters.time_range_timezone = timeRangeTimezone;
    }

    return client.get(Url.url('/api/v2/open/meeting-methods', filters));
  },

  /**
   * @returns {Promise<any>}
   */
  update({ code, id, method }) {
    const query = `?appointment=${id}&code=${code}`;

    return client.patch(`/api/v2/open/appointments/${id}${query}`, {
      data: {
        id: String(id),
        type: 'appointments',
        attributes: {
          meeting_method: Number(method),
        },
      },
      meta: {
        notify: Notifications.ALL,
      },
    });
  },
});

export default MeetingMethods;
