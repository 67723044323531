import React from 'react';

const NoAvailableTimes = () => (
  <svg height="125" width="134.883" xmlns="http://www.w3.org/2000/svg">
    <g data-name="date not found">
      <path
        d="M5.867 26.096c1.68-3.183 8.226-15.458 22.531-21.8 3.038-1.348 17.363-7.666 28.887-1.925 15.219 7.628 9.263 28.265 23.3 38.556 12.038 8.853 26.426-7.546 41.064.551 7.624 4.219 13.43 13.358 13.23 21.567-.652 25.544-60.435 68.526-101.865 47.773C2.826 95.717-7.778 51.766 5.867 26.096z"
        data-name="Path 514"
        fill="#f5f5f5"
      />
      <path
        d="M31.134 29.564c1.991 24.017-9.718 35.468-15.822 38.192l31.644 15.456c16.21-16.343 19.555-37.719 19.2-46.364-7.882-1.303-25.92-4.583-35.022-7.284z"
        data-name="Path 515"
        fill="#d9d9d9"
      />
      <g data-name="Group 4038">
        <g data-name="Group 4037" fill="#b0b0b0" opacity=".3">
          <path
            d="M32.736 42.413l28.715 9.703-.977 2.892-28.715-9.703z"
            data-name="Rectangle 817"
          />
          <path
            d="M47.356 73.15c4.653-7.2 6.8-12.022 9.8-22.046l2.893.977-7.731 23.673z"
            data-name="Path 516"
          />
          <path
            d="M26.143 63.336l6.163-20.353 3.751 1.054c-.07 5.327-2.624 11.622-7.88 20.2z"
            data-name="Path 517"
          />
          <path
            d="M26.135 60.7l27.342 12.543-1.274 2.776-27.342-12.543z"
            data-name="Rectangle 818"
          />
        </g>
      </g>
      <path
        d="M34.399 39.506v-3.579l27.04 6.362-.8 5.169z"
        data-name="Path 518"
        fill="rgba(176,176,176,0.4)"
      />
      <path
        d="M78.22 49.806c-8.685 11.78-26.818 17.283-37.83 21.053 4.352 7.238 14.431 23.973 19.931 33.006 28.833-26.921 30.511-26.393 41.988-35.984A122.966 122.966 0 0 1 78.22 49.806z"
        data-name="Path 519"
        fill="rgba(158,158,158,0.08)"
      />
      <path
        d="M82.872 50.29c-7.478 10.137-28.309 19.163-37.79 22.408 3.746 6.228 12.42 20.626 17.154 28.4 16.687-4.761 40.622-22.462 50.5-30.717-7.01-3.94-22.803-13.47-29.864-20.091z"
        data-name="Path 520"
        fill="#e9e9e9"
      />
      <g data-name="Group 4040">
        <g data-name="Group 4039" opacity=".5">
          <path
            d="M73.806 60.47l18.962 21.015-2.082 1.878-18.962-21.015z"
            data-name="Rectangle 819"
            fill="#b0b0b0"
          />
          <path
            d="M68.546 63.475l17.216 22.471-1.412 1.082-17.215-22.472z"
            data-name="Rectangle 820"
            fill="#b0b0b0"
          />
          <path
            d="M81.928 70.603c-1.513 2.008-6.552 6.863-14.613 10.22"
            data-name="Path 521"
            fill="none"
            stroke="#b0b0b0"
            strokeWidth="2.166"
          />
          <path
            d="M84.553 75.332c-1.513 2.008-6.552 6.863-14.614 10.22"
            data-name="Path 522"
            fill="none"
            stroke="#b0b0b0"
            strokeWidth="2.166"
          />
          <path
            d="M78.388 67.065c-1.56 1.972-6.714 6.707-14.854 9.873"
            data-name="Path 523"
            fill="none"
            stroke="#b0b0b0"
            strokeWidth="2.166"
          />
          <path
            d="M66.069 68.535l16.619 22.919-1.44 1.043L64.63 69.58z"
            data-name="Rectangle 821"
            fill="#b0b0b0"
          />
          <path
            d="M71.778 91.2c6.059-2.243 10.862-5 18.689-11.929l1.652 1.694-17.94 14.283z"
            data-name="Path 524"
            fill="#b0b0b0"
          />
          <path
            d="M59.722 71.857l13.613-11.385 2.422 3.292a36.268 36.268 0 0 1-14.891 9.819z"
            data-name="Path 525"
            fill="#b0b0b0"
          />
          <path
            d="M59.417 69.819l17.253 24.02-2.277 1.636-17.253-24.02z"
            data-name="Rectangle 822"
            fill="#b0b0b0"
          />
        </g>
      </g>
      <path
        d="M81.321 56.208l-3.181 2.386 19.087 17.894 4.772-3.181z"
        data-name="Path 526"
        fill="rgba(196,196,196,0.68)"
      />
      <path
        d="M38.415 55.003l1.027.424a1.155 1.155 0 0 0 1.715-.691 1.106 1.106 0 0 0 .021-.876 1.186 1.186 0 0 0-.7-.636 1.287 1.287 0 0 0-.86-.06.833.833 0 0 0-.575.5l-2.177-.9a2.41 2.41 0 0 1 .96-1.154 2.942 2.942 0 0 1 1.517-.433 4.236 4.236 0 0 1 1.751.338 4.166 4.166 0 0 1 2.172 1.736 2.579 2.579 0 0 1 .084 2.341 2.157 2.157 0 0 1-.85 1 2.853 2.853 0 0 1-1.439.426 2.726 2.726 0 0 1 .834 1.312 2.333 2.333 0 0 1-.137 1.54 2.626 2.626 0 0 1-1.8 1.615 4.351 4.351 0 0 1-2.858-.3 4.581 4.581 0 0 1-1.574-1.059 2.942 2.942 0 0 1-.792-1.47 2.759 2.759 0 0 1 .18-1.589l2.19.9a1.062 1.062 0 0 0 .039.894 1.334 1.334 0 0 0 .729.671 1.4 1.4 0 0 0 1.048.062 1.167 1.167 0 0 0 .681-.679 1.35 1.35 0 0 0 .061-1.154 1.608 1.608 0 0 0-.859-.7l-1.06-.437zm8.18 9.95l-2.184-.9 2.867-6.958-2.4-.259.683-1.653 4.718.277.2.083z"
        data-name="Path 527"
        fill="#c9c9c9"
      />
      <path
        d="M63.722 120.212a18.772 18.772 0 1 0-18.624-18.772 18.7 18.7 0 0 0 18.624 18.772z"
        data-name="Path 528"
        fill="rgba(168,215,229,0.1)"
      />
      <path
        d="M61.041 125a20.28 20.28 0 1 0-20.28-20.28A20.28 20.28 0 0 0 61.041 125z"
        data-name="Path 529"
        fill="#b9b9b9"
      />
      <path
        d="M60.935 121.12a16.032 16.032 0 1 0-16.155-16.032 16.091 16.091 0 0 0 16.155 16.032z"
        data-name="Path 530"
        fill="#fcfcfd"
      />
      <path
        d="M62.401 89.057H60.05v2.332h2.349z"
        data-name="Path 531"
        fill="rgba(100,107,121,0.2)"
      />
      <path
        d="M62.401 118.206H60.05v2.335h2.349z"
        data-name="Path 532"
        fill="rgba(100,107,121,0.2)"
      />
      <path
        d="M76.498 104.215h-2.349v2.327h2.349z"
        data-name="Path 533"
        fill="rgba(100,107,121,0.2)"
      />
      <path
        d="M47.129 104.215h-1.76v2.327h1.76z"
        data-name="Path 534"
        fill="rgba(100,107,121,0.2)"
      />
      <path
        d="M73.195 94.697l-1.661-1.647-1.662 1.649 1.661 1.648z"
        data-name="Path 535"
        fill="rgba(100,107,121,0.2)"
      />
      <path
        d="M52.41 115.31l-1.661-1.647-1.663 1.647 1.661 1.648z"
        data-name="Path 536"
        fill="rgba(100,107,121,0.2)"
      />
      <path
        d="M72.354 115.299l-1.661-1.647-1.663 1.649 1.661 1.647z"
        data-name="Path 537"
        fill="rgba(100,107,121,0.2)"
      />
      <path
        d="M51.589 94.707l-1.246-1.235-1.666 1.649 1.246 1.235z"
        data-name="Path 538"
        fill="rgba(100,107,121,0.2)"
      />
      <path
        d="M60.005 107.412c-.244-.212-.045-.829.448-1.388l6.741-7.639c.493-.559 1.083-.837 1.327-.625s.045.829-.448 1.388l-6.742 7.64c-.492.558-1.082.833-1.326.624z"
        data-name="Path 539"
        fill="#4f5565"
      />
      <path
        d="M62.113 107.236l-.067.076c-.207.235-.811.035-1.374-.453l-6.831-5.927c-.563-.488-.842-1.055-.635-1.29l.066-.076c.207-.235.811-.035 1.374.453l6.831 5.927c.563.489.843 1.056.636 1.29z"
        data-name="Path 540"
        fill="#4f5565"
      />
      <path
        d="M61.019 108.178a1.811 1.811 0 1 0-1.813-1.811 1.812 1.812 0 0 0 1.813 1.811z"
        data-name="Path 541"
        fill="#646b79"
      />
      <path
        d="M61.019 107.19a.823.823 0 1 0-.824-.823.824.824 0 0 0 .824.823z"
        data-name="Path 542"
        fill="#c1c1c1"
      />
    </g>
  </svg>
);

export default NoAvailableTimes;
