const CheckIn = (client) => ({
  /**
   * @returns {Promise<any>}
   */
  find({ location, preference, contact }) {
    const params = {
      [preference]: contact,
      _token: window.state.token,
    };

    return client.post(location, params);
  },

  /**
   * @returns {Promise<any>}
   */
  update({ appointment, location, optIn, phone }) {
    const params = {
      attendee: appointment.attendee,
      appointment: appointment.id,
      code: appointment.code,
      opt_in: optIn,
      cell_phone: phone,
      _token: window.state.token,
    };

    return client.put(location, params);
  },
});

export default CheckIn;
