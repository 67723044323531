import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from '../Typography';
import HelpText from './HelpText';
import InputErrors from './InputErrors';

const useStyles = createUseStyles((theme) => ({
  input: {
    border: `1px solid ${theme.borderColor.default}`,
    borderRadius: theme.borderRadius.sm,
    color: theme.palette.black,
    display: 'block',
    fontSize: theme.textSizes.base,
    padding: '0.75rem',
    transition: theme.transitions.border,
    width: '100%',
    '&:focus': {
      borderColor: theme.palette.info[400],
      boxShadow: theme.shadows.input,
      outline: 0,
    },
    '&:active': {
      borderColor: theme.palette.neutral[350],
    },
  },
  error: {
    borderColor: theme.palette.danger[400],
  },
  label: {
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0.5rem',
  },
  optional: {
    marginLeft: '0.25rem',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
}));

const TextInput = ({
  autocomplete,
  ariaRequired,
  errors,
  helpText,
  hideLabel,
  id,
  label,
  mask,
  name,
  onBlur,
  onChange,
  optional,
  type,
  value,
}) => {
  const [val, setVal] = useState(null);
  const classes = useStyles({ theme: useTheme() });

  const handleChange = (event) => {
    setVal(event.target.value);
  };

  return (
    <>
      <label htmlFor={id || name} {...(mask && { 'data-personal': true })}>
        <div
          className={classNames(
            classes.label,
            hideLabel && classes.visuallyHidden,
          )}
        >
          <Typography variant="label">{label}</Typography>
          {optional ? (
            <Typography classes={{ root: classes.optional }} variant="body2">
              <FormattedMessage id="Form.optional" />
            </Typography>
          ) : null}
        </div>
        <input
          aria-required={ariaRequired}
          autoComplete={autocomplete}
          className={classNames(
            classes.input,
            errors.length > 0 && classes.error,
          )}
          id={id || name}
          name={name}
          onBlur={onBlur}
          onChange={onChange || handleChange}
          type={type}
          value={val === null ? value : val}
          {...(errors.length > 0
            ? { 'aria-invalid': true, 'aria-describedby': `error-${name}` }
            : {})}
        />
      </label>
      {errors.length > 0 ? (
        <InputErrors errors={errors} id={`error-${name}`} />
      ) : (
        <HelpText value={helpText} />
      )}
    </>
  );
};

TextInput.propTypes = {
  autocomplete: PropTypes.string,
  ariaRequired: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  ),
  helpText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  mask: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'email']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

TextInput.defaultProps = {
  ariaRequired: false,
  autocomplete: 'on',
  errors: [],
  helpText: null,
  hideLabel: false,
  id: '',
  mask: false,
  onBlur: () => {},
  onChange: null,
  optional: false,
  type: 'text',
};

export default TextInput;
