import React, { useContext } from 'react';
import { WithWizard } from 'react-albus-react18';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { useFeatureDecisions } from '../../shared/contexts/FeatureDecisionContext';
import Detective from '../../shared/helpers/Detective';
import { STEPS } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import Breadcrumb from './Breadcrumb';
import Footer from './Footer';
import StepCounter from './StepCounter';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    counterReset: 'sidebar',
    margin: '1.25rem',
    maxWidth: '16.5rem',
    minWidth: '13.75rem',
    width: '25%',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    marginBottom: '1.25rem',
    marginTop: Detective.notInternetExplorer11() ? 'auto' : 0,
    overflow: 'hidden',
    paddingLeft: '1.25rem',
    paddingTop: '0.75rem',
  },
  title: {
    marginBottom: '1.25rem',
  },
}));

const Sidebar = () => {
  const classes = useStyles({ theme: useTheme() });
  const { shouldUseCheckInOnCV, shouldUseJoinTheLineFromCV } =
    useFeatureDecisions();
  const [{ bookingWalkIn }] = useContext(SelectionContext);

  const getTitle = (curr) =>
    curr.id === STEPS.CHECK_IN && shouldUseCheckInOnCV
      ? 'Sidebar.title_check_in'
      : bookingWalkIn && shouldUseJoinTheLineFromCV
      ? 'Sidebar.title_join_the_line'
      : 'Sidebar.title';

  return (
    <WithWizard>
      {({ step: current, steps }) => {
        const position = steps.findIndex((step) => step.id === current.id);
        return (
          <>
            <section className={classes.root}>
              <Typography classes={{ root: classes.title }} component="h3">
                <>
                  <FormattedMessage id={getTitle(current)} />
                  &nbsp;(
                  <StepCounter
                    currentStep={position}
                    inline
                    stepsCount={steps.length}
                  />
                  )
                </>
              </Typography>
              <ol data-testid="list">
                {steps.map((step, index) => (
                  <Breadcrumb
                    current={current}
                    index={index}
                    key={`${step.id}-${current.id}`}
                    numberOfSteps={steps.length}
                    position={position}
                    step={step}
                  />
                ))}
              </ol>
            </section>
            <Footer classes={{ root: classes.footer }} />
          </>
        );
      }}
    </WithWizard>
  );
};

export default Sidebar;
