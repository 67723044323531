import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debounce } from 'throttle-debounce';
import { SERVICE_SEARCH_THRESHOLD } from '../constants';
import Tracker from '../helpers/Tracker';

const ACTION = 'search';
const CATEGORY = 'Service';

const ServiceSearchContext = createContext({
  query: '',
  handleSearch: () => {},
  filteredServices: [],
});

const useServiceSearch = () => useContext(ServiceSearchContext);

const ServiceSearchProvider = ({ children, services }) => {
  const [query, setQuery] = useState('');

  const queryRef = useRef(null);
  const countRef = useRef(null);

  const updateQuery = debounce(300, (value) => {
    setQuery(value);
  });

  const handleSearch = ({ currentTarget: { value } }) => {
    updateQuery(value.toLowerCase());
  };

  const filteredServices = useMemo(() => {
    if (services.length < SERVICE_SEARCH_THRESHOLD || query.length < 2) {
      return services;
    }

    return services.filter(
      (service) =>
        service.name.toLowerCase().includes(query) ||
        service.description?.toLowerCase()?.includes(query),
    );
  }, [query, services]);

  const trackResults = () => {
    if (query.length >= 2) {
      Tracker.event(ACTION, CATEGORY, query, filteredServices.length);
    }
  };

  const trackNoResults = debounce(1000, () => {
    if (queryRef.current.length >= 2 && countRef.current === 0) {
      Tracker.event(ACTION, CATEGORY, queryRef.current, 0);
    }
  });

  useEffect(() => {
    queryRef.current = query;
    countRef.current = filteredServices.length;
  }, [query, filteredServices.length]);

  useEffect(() => {
    if (query.length >= 2 && services.length && filteredServices.length === 0) {
      trackNoResults();
    }
    // The event is only tracked when search results first become empty,
    // which avoids sending too many requests.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredServices.length, services.length]);

  return (
    <ServiceSearchContext.Provider
      value={{
        filteredServices,
        handleSearch,
        query,
        trackResults,
      }}
    >
      {children}
    </ServiceSearchContext.Provider>
  );
};

ServiceSearchProvider.propTypes = {
  children: PropTypes.element.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
};

ServiceSearchProvider.defaultProps = {
  services: [],
};

export { ServiceSearchContext, ServiceSearchProvider, useServiceSearch };
