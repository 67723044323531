import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router';
import { useFeatureDecisions } from '../../shared/contexts/FeatureDecisionContext';
import { MEETING_METHODS } from '../constants';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import Url from '../helpers/Url';
import UtmParameters from '../helpers/UtmParameters';
import { useLobbyAvailability } from '../hooks/useLobbyAvailability';
import Button from './Button';
import DirectionsWalk from './icons/DirectionsWalk';
import PhoneCall from './icons/PhoneCall';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  button: {
    marginLeft: '0.9rem',
    '&:focus': {
      outline: 0,
      boxShadow: theme.shadows.input,
      borderColor: theme.palette.secondary[500],
    },
  },
  column: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    color: theme.palette.secondary[400],
    marginRight: '1rem',
  },
  root: {
    backgroundColor: theme.palette.secondary.A400,
    justifyContent: 'space-between',
    marginTop: '-3rem',
    opacity: '0%',
    padding: '0.75rem 1.2rem',
    transition: '0.2s ease',
  },
  visible: {
    marginTop: 0,
    opacity: '100%',
  },
}));

// TODO: Figure out why this component goes through 2 mounting cycle,
// will cause 2 http calls here and any sub components in the tree
const LobbyBanner = ({ next }) => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const { shouldUseJoinTheLineFromCV } = useFeatureDecisions();

  const { showWaitTime, joinLineFromClientView } = useContext(SettingsContext);
  const [selections, setSelections] = useContext(SelectionContext);
  const [locale] = useContext(LocaleContext);
  const { location, service, meetingMethod } = selections;
  const walkInId = history?.location?.search
    ? Url.params(history.location.search)?.queue_appt_id
    : null;

  const DEFAULT_URL = `${window.location.origin}/callback-service/${location?.id}?service=${service?.id}`;

  const [redirectUrl, setRedirectUrl] = useState(DEFAULT_URL);

  const method =
    meetingMethod !== null ? meetingMethod : MEETING_METHODS.AT_LOCATION;

  const { waitTime, available, isError, isFetching } = useLobbyAvailability({
    enabled: joinLineFromClientView,
    waitTime: showWaitTime,
  });
  const showBanner =
    joinLineFromClientView && available && !isError && !isFetching;

  const appendParam = (name, value, url) => url.concat(`&${name}=${value}`);

  useEffect(() => {
    let updatedUrl = DEFAULT_URL;

    if (method === MEETING_METHODS.AT_LOCATION) {
      updatedUrl = appendParam(
        'meeting_method',
        MEETING_METHODS.AT_LOCATION,
        updatedUrl,
      );
    }
    updatedUrl = appendParam('lang', locale, updatedUrl);

    const { location: location_id, rwg_token } = Url.params(
      window.location.search,
    );

    if (rwg_token) {
      updatedUrl = appendParam('rwg_token', rwg_token, updatedUrl);
    }

    if (location_id) {
      updatedUrl = appendParam('location', location_id, updatedUrl);
    }

    const utmCampaign = UtmParameters.get('utm_campaign');
    const utmContent = UtmParameters.get('utm_content');
    const utmMedium = UtmParameters.get('utm_medium');
    const utmTerm = UtmParameters.get('utm_term');
    const utmSource = UtmParameters.get('utm_source');
    const { mode } = Url.params(window.location.search);

    if (utmCampaign) {
      updatedUrl = appendParam('utm_campaign', utmCampaign, updatedUrl);
    }

    if (utmContent) {
      updatedUrl = appendParam('utm_content', utmContent, updatedUrl);
    }

    if (utmMedium) {
      updatedUrl = appendParam('utm_medium', utmMedium, updatedUrl);
    }

    if (utmTerm) {
      updatedUrl = appendParam('utm_term', utmTerm, updatedUrl);
    }

    if (mode) {
      updatedUrl = appendParam('mode', mode, updatedUrl);
    }

    updatedUrl = appendParam(
      'utm_source',
      utmSource || 'clientview',
      updatedUrl,
    );

    setRedirectUrl(updatedUrl);

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBanner, locale]);

  if (
    !joinLineFromClientView ||
    (method !== MEETING_METHODS.AT_LOCATION &&
      method !== MEETING_METHODS.PHONE_CALL) ||
    walkInId
  ) {
    return null;
  }

  const handleClick = () => {
    if (shouldUseJoinTheLineFromCV) {
      setSelections({ bookingWalkIn: true });
      typeof next === 'function' && next();
    } else {
      window.location.assign(redirectUrl);
    }
  };

  return (
    <div
      className={classNames(
        classes.container,
        classes.root,
        showBanner ? classes.visible : '',
      )}
    >
      <div className={classes.container}>
        {method === MEETING_METHODS.AT_LOCATION ? (
          <DirectionsWalk classes={{ root: classes.icon }} />
        ) : (
          <PhoneCall classes={{ root: classes.icon }} />
        )}
        <div className={classes.column}>
          <Typography variant="label">
            <FormattedMessage id="LobbyBanner.title" />
          </Typography>
          {showWaitTime && waitTime !== null ? (
            <Typography>
              <FormattedMessage
                id="LobbyBanner.wait_time"
                values={{ time: waitTime }}
              />
            </Typography>
          ) : null}
        </div>
      </div>
      <div>
        <Button
          aria-label={
            method === MEETING_METHODS.AT_LOCATION
              ? intl.formatMessage({ id: 'LobbyBanner.button_description' })
              : intl.formatMessage({
                  id: 'LobbyBanner.phone_button_description',
                })
          }
          classes={{ override: classes.button }}
          disabled={!showBanner}
          fullWidth={false}
          onClick={handleClick}
          variant="text"
        >
          <FormattedMessage
            id={
              method === MEETING_METHODS.AT_LOCATION
                ? 'LobbyBanner.button'
                : 'LobbyBanner.phone_button'
            }
          />
        </Button>
      </div>
    </div>
  );
};

LobbyBanner.propTypes = {
  next: PropTypes.func,
};

LobbyBanner.defaultProps = {
  next: null, // this doesn't exist in the rescheduling flow, so can't be required
};

export default LobbyBanner;
