import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from './Typography';

const useStyles = createUseStyles({
  root: {
    padding: '1.25rem 0 0.75rem',
    '&:first-child': {
      padding: '0 0 0.75rem',
    },
  },
  body: {
    display: 'block',
    marginTop: '1.25rem',
  },
  label: {
    marginBottom: '.25rem',
  },
  name: {
    display: 'block',
    overflow: 'hidden',
    marginBottom: '.25rem',
  },
  narrowSpacing: {
    paddingTop: '0.5rem',
  },
});

const AppointmentDetailItem = ({
  body,
  label,
  list,
  mask,
  narrow,
  primary,
  secondary,
}) => {
  const classes = useStyles();
  const personal = { ...(mask && { 'data-personal': true }) };

  return (
    <div
      className={classNames(classes.root, narrow ? classes.narrowSpacing : '')}
    >
      {label ? (
        <Typography classes={{ root: classes.label }} variant="caption1">
          {label}
        </Typography>
      ) : null}
      {primary ? (
        <Typography
          classes={{ root: classes.name }}
          {...personal}
          variant="title"
        >
          {primary}
        </Typography>
      ) : null}
      {list.length > 0 &&
        list.map((item) => (
          <Typography
            classes={{ root: classes.name }}
            key={item}
            {...personal}
            variant="title"
          >
            {item}
          </Typography>
        ))}
      {secondary ? (
        <Typography
          classes={{ root: classes.label }}
          {...personal}
          variant="subtitle"
        >
          {secondary}
        </Typography>
      ) : null}
      {body ? (
        <Typography
          classes={{ root: secondary && classes.body }}
          {...personal}
          variant="subtitle"
        >
          {body}
        </Typography>
      ) : null}
    </div>
  );
};

AppointmentDetailItem.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  list: PropTypes.arrayOf(PropTypes.string),
  narrow: PropTypes.bool,
  mask: PropTypes.bool,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

AppointmentDetailItem.defaultProps = {
  body: null,
  label: null,
  list: [],
  mask: false,
  narrow: false,
  primary: null,
  secondary: null,
};

export default AppointmentDetailItem;
