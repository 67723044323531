import ct from 'countries-and-timezones';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { DEFAULT_COUNTRY, SHORTCUTS } from '../constants';
import Api from '../helpers/Api';
import Storage from '../helpers/Storage';
import { DetectedTimezoneContext, STATUSES } from './DetectedTimezoneContext';
import { SelectionContext } from './SelectionContext';

const CountryContext = createContext('');

const CountryProvider = ({ children }) => {
  const [{ location }] = useContext(SelectionContext);
  const [timezone, status] = useContext(DetectedTimezoneContext);
  const countryFromTimezone = ct.getCountryForTimezone(timezone)?.id || '';
  const [detectedCountry, setDetectedCountry] = useReducer(
    (_state, newState) => {
      Storage.set(SHORTCUTS.DETECTED_COUNTRY, newState);

      return newState;
    },
    Storage.get(SHORTCUTS.DETECTED_COUNTRY, countryFromTimezone),
  );

  const [country, setCountry] = useReducer((state, newState) => {
    Storage.set(SHORTCUTS.COUNTRY, newState);

    return newState;
  }, Storage.get(SHORTCUTS.COUNTRY, detectedCountry) || DEFAULT_COUNTRY);

  useEffect(() => {
    if (!detectedCountry && status === STATUSES.FETCHED) {
      if (countryFromTimezone) {
        setDetectedCountry(countryFromTimezone);
        setCountry(countryFromTimezone);
      } else {
        Api.countries()
          .find()
          .then(({ country: detected }) => {
            setDetectedCountry(detected);
            setCountry(detected);
          });
      }
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (location && location.physical && location.country) {
      setCountry(location.country);
    }
  }, [location]);

  return (
    <CountryContext.Provider value={[country, setCountry, detectedCountry]}>
      {children}
    </CountryContext.Provider>
  );
};

CountryProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { CountryContext, CountryProvider };
