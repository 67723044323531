import 'whatwg-fetch';

const prepare = (response) =>
  new Promise((yeah) =>
    response
      .json()
      .then((json) =>
        yeah({
          json,
          ok: response.ok,
        }),
      )
      .catch(() => yeah({ json: {}, ok: false })),
  );

const request = (url, locale, attributes = {}) =>
  new Promise((yeah, nah) => {
    const options = {
      headers: {
        accept: 'application/json',
        'accept-language': locale,
        'content-type': 'application/json',
      },
      ...attributes,
    };

    fetch(url, options)
      .then(prepare)
      .then(({ json, ok }) => {
        if (ok) {
          return yeah(json);
        }

        return nah(json);
      });
  });

const Client = () => ({
  lang: 'en',

  /**
   * @param {string} url
   *
   * @returns {Promise<any>}
   */
  async get(url) {
    return request(url, this.lang);
  },

  /**
   * @param {string} locale
   */
  locale(locale) {
    this.lang = locale;
  },

  /**
   * @param {string} url
   * @param {object} attributes
   *
   * @returns {Promise<any>}
   */
  async patch(url, attributes) {
    return request(url, this.lang, {
      body: JSON.stringify(attributes),
      method: 'PATCH',
    });
  },

  /**
   * @param {string} url
   * @param {object} attributes
   *
   * @returns {Promise<any>}
   */
  async post(url, attributes) {
    return request(url, this.lang, {
      body: JSON.stringify(attributes),
      method: 'POST',
    });
  },

  /**
   * @param {string} url
   * @param {object} attributes
   *
   * @returns {Promise<any>}
   */
  async put(url, attributes) {
    return request(url, this.lang, {
      body: JSON.stringify(attributes),
      method: 'PUT',
    });
  },

  /**
   * @param {string} url
   * @param {object} formData
   *
   * @returns {Promise<any>}
   */
  async postWithFormData(url, formData) {
    return new Promise((yeah, nah) => {
      const options = {
        headers: {
          'accept-language': this.lang,
        },
        body: formData,
        method: 'POST',
      };

      fetch(url, options)
        .then(prepare)
        .then(({ json, ok }) => {
          if (ok) {
            return yeah(json);
          }

          return nah(json);
        });
    });
  },
});

export default Client;
