import { AsYouType, parsePhoneNumber } from 'libphonenumber-js/core';
import defaultMetadata from '../metadata.custom.json';

const metadata = {
  ...defaultMetadata,
  version: String(defaultMetadata.version),
};

export default {
  /**
   * Format the given string as a phone number.
   *
   * @param {string|null} phone
   *
   * @returns {string|null}
   */
  format(phone) {
    let number;

    try {
      number = this.parse(phone).number || phone;
    } catch (e) {
      number = phone;
    }

    return number;
  },

  /**
   * Parse the given string as a phone number.
   * By default, the function will process incomplete numbers as they are typed.
   * Incomplete national numbers require a country to be parsed correctly.
   * When the 'complete' flag is true and no country is provided, the function is able
   * to guess the country based on the area code.
   *
   * @param {string|null} phone
   * @param {string|null|undefined} country
   * @param {boolean} format
   *
   * @returns {Object}
   */
  parse(phone, { country = null, format = true, complete = false } = {}) {
    let number = phone;

    try {
      let phoneNumber;
      let formatted;
      let formatter;

      // Strip all invalid characters
      number = number.replace(/[^+\d]/g, '');

      if (complete) {
        phoneNumber = parsePhoneNumber(number, country || 'US', metadata);
        formatted = phoneNumber?.number;
      } else {
        formatter = new AsYouType(country || null, metadata);
        formatted = formatter.input(number); // will be used for incomplete number
        phoneNumber = formatter.getNumber();
      }

      // Format only those numbers which are both valid and possible for
      // the country in this context
      if (!complete && phoneNumber.isValid() && phoneNumber.isPossible()) {
        // complete national number
        formatted = phoneNumber.formatNational();
      } else if (!complete && !formatter.getTemplate()) {
        // national number has extra digits or doesn't match national prefix if exists
        formatted = phoneNumber.nationalNumber;
      } else if (complete && !phoneNumber) {
        throw Error;
      }

      return {
        number: (format ? formatted : phoneNumber.number) || '',
        country: phoneNumber.country,
        valid: phoneNumber.isValid(),
      };
    } catch (e) {
      return { number, country };
    }
  },
};
