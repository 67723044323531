export default {
  "AdditionalUsers.two": "{first} and {second}",
  "AdditionalUsers.three": "{first}, {second}, and {third}",
  "AdditionalUsers.four_plus": "{first}, {second}, and {number} more",
  "AdditionalUsers.plus_more": "+ {number} more",
  "AppointmentCancelled.your_title": "Your appointment has been cancelled",
  "AppointmentCancelled.this_title": "This appointment has been cancelled",
  "AppointmentCancelled.email_message": "We’ve sent you an email confirming your cancellation.",
  "AppointmentDetails.appointment_details": "Appointment Details",
  "AppointmentDetails.appointment_duration": "{start} - {end} ({timezone})",
  "AppointmentDetails.appointment_duration_without_end": "{start} ({timezone})",
  "AppointmentDetails.attendee": "Attendee",
  "AppointmentDetails.attendees": "Attendees",
  "AppointmentDetails.confirmation_number": "Confirmation Number",
  "AppointmentDetails.date_time": "Date & Time",
  "AppointmentDetails.pin": "PIN: {pin}",
  "AppointmentDetails.join_by_phone": "Join by phone: <a>{number}</a>",
  "AppointmentDetails.join_call": "Join Call",
  "AppointmentDetails.join_the_call": "Join with the link provided",
  "AppointmentDetails.join_with_this_link": "Join using this link",
  "AppointmentDetails.location": "Location",
  "AppointmentDetails.phone_call": "Phone Call",
  "AppointmentDetails.phone_number_ending_in": "Phone number ending in {number}",
  "AppointmentDetails.service": "Service",
  "AppointmentDetails.user": "Staff",
  "AppointmentDetails.video_call": "Video Call",
  "AppointmentDetails.we_will_call": "We will call you at the number you provide",
  "AppointmentDetails.we_will_call_you": "We will call you at the number you provided",
  "AppointmentDetails.we_will_send_video_info": "We will send you information on how to join the meeting",
  "SummaryExpansionPanel.title": "Summary",
  "BookingValidationErrorModal.default_error_title": "An Error Occurred",
  "BookingValidationErrorModal.location_button": "See Locations",
  "BookingValidationErrorModal.location_unavailable_description": "This location is no longer available. You will need to select another location before you can book an appointment.",
  "BookingValidationErrorModal.location_unavailable_title": "Location No Longer Available",
  "BookingValidationErrorModal.modal_title": "Title",
  "BookingValidationErrorModal.service_button": "See Services",
  "BookingValidationErrorModal.service_unavailable_description": "This service is no longer available. Please, see if any other services meet your needs or contact us for assistance at {phone}.",
  "BookingValidationErrorModal.service_unavailable_title": "Service No Longer Available",
  "BookingValidationErrorModal.times_button": "Find A Time",
  "BookingValidationErrorModal.times_unavailable_description": "{time} is no longer available on {date}. You will need to select another time before you can book an appointment.",
  "BookingValidationErrorModal.times_unavailable_title": "Time No Longer Available",
  "AttendeeListItem.delete": "Remove Additional Attendee",
  "AttendeeListItem.deleted": "{name} Removed",
  "AttendeeListItem.edit": "Update Additional Attendee",
  "BackButton.back": "Back",
  "BackButton.back_results": "Back to results",
  "CancelModal.additional_details": "Additional Details",
  "CancelModal.appointment_date": "{date} at {time}",
  "CancelModal.cancel_appointment": "Cancel Appointment",
  "CancelModal.keep": "Keep",
  "CancelModal.message": "Cancel your appointment on {date}. This cannot be undone.",
  "CancelModal.title": "Cancel Appointment",
  "CancelModal.why_cancel_appointment": "Why is this appointment being cancelled?",
  "ChangesToYourAppointment.cancellation_no_longer_available": "This appointment can no longer be cancelled.",
  "ChangesToYourAppointment.cancellation_policy_header": "Cancellation Policy",
  "ChangesToYourAppointment.cannot_cancel": "This appointment cannot be cancelled.",
  "ChangesToYourAppointment.cannot_reschedule": "This appointment cannot be rescheduled.",
  "ChangesToYourAppointment.loading": "Loading...",
  "ChangesToYourAppointment.meeting_method_subtitle": "If you would like to change how you'd like to attend your appointment, please update the meeting method.",
  "ChangesToYourAppointment.meeting_method_subtitle_limited": "If you would like to change how you'd like to attend your appointment you can do so up to {duration} before your scheduled time.",
  "ChangesToYourAppointment.meeting_method_subtitle_locked": "Meeting method cannot be changed",
  "ChangesToYourAppointment.no_meeting_method_options": "No other options available",
  "ChangesToYourAppointment.reschedule_appointment": "Reschedule Appointment",
  "ChangesToYourAppointment.reschedule_header": "Reschedule",
  "ChangesToYourAppointment.reschedule_no_longer_available": "This appointment can no longer be rescheduled.",
  "ChangesToYourAppointment.reschedule_policy_details": "If you need to reschedule your appointment you can do so up to {duration} before your scheduled time.",
  "ChangesToYourAppointment.reschedule_policy_details_no_limit": "If you need to reschedule your appointment you can do so before your scheduled time.",
  "ChangesToYourAppointment.title": "Changes to Your Appointment",
  "ChangesToYourAppointment.update_meeting_method": "Update Meeting Method",
  "ChangesToYourAppointment.update_meeting_method_not_available": "This appointment meeting method can no longer be changed.",
  "CheckIn.check_in": "Check In",
  "CheckIn.check_in.aria_label": "Check into your appointment",
  "CheckIn.confirmation.subtitle": "We'll be with you soon.",
  "CheckIn.confirmation.subtitle_notifiable": "We'll be with you soon. We'll notify you when it's your turn.",
  "CheckIn.confirmation.title": "You're checked in!",
  "CheckIn.email": "Email Address",
  "CheckIn.fetch_failed": "We're sorry, but an error occurred while searching for your appointment. Please refresh the page and try again.",
  "CheckIn.join_line": "JOIN THE LINE",
  "CheckIn.join_line.title": "Don't have an appointment?",
  "CheckIn.join_line.aria_label": "Click here to join the line to be seen instead of booking an appointment",
  "CheckIn.landing_page.title": "Look up your appointment",
  "CheckIn.next": "Next",
  "CheckIn.next.aria_label": "Look up your appointment",
  "CheckIn.phone": "Phone Number",
  "CheckIn.preference": "Using your {preference} to look up your appointment",
  "CheckIn.subtitle": "Use your email address or phone number to look up your appointment",
  "CheckIn.subtitle_email_only": "Use your email address to look up your appointment",
  "CheckIn.summary.fetch_failed": "We're sorry, but an error occurred while checking into your appointment. Please refresh the page and try again.",
  "CheckIn.summary.subtitle": "Confirm your appointment details below and check in",
  "CheckIn.summary.title": "Confirm and check in",
  "CheckIn.summary.sms_updates": "Receive text message updates",
  "CheckIn.summary.sms_updates.message": "We will send you updates about when it's your turn to {phone}",
  "CheckIn.summary.sms_updates.dialog": "Receive text message updates?",
  "CheckIn.summary.sms_updates.dialog.accept": "Yes, text me updates",
  "CheckIn.summary.sms_updates.dialog.deny": "No, check in without getting updates",
  "CheckIn.not_found.email": "We're sorry, but we couldn't find an upcoming appointment that matches the email address you entered. Please note, appointments can only be checked into within 30 minutes of the scheduled start time.",
  "CheckIn.not_found.phone": "We're sorry, but we couldn't find an upcoming appointment that matches the phone number you entered. Please note, appointments can only be checked into within 30 minutes of the scheduled start time.",
  "CircularProgress.loading": "Loading",
  "CookieNotice.accept": "I accept",
  "CookieNotice.closed": "Cookie disclaimer closed",
  "CookieNotice.ignore": "Dismiss",
  "CookieNotice.learn_more": "Learn more",
  "CookieNotice.message": "We use cookies to ensure you get the best experience on our website.",
  "CookieNotice.title": "Cookie disclaimer",
  "CountrySelectInput.label": "Country",
  "CountryTimezoneModal.description": "All times will be displayed according to the time zone selected below.",
  "CountryTimezoneModal.title": "Select Time Zone",
  "Dates.all_day_date": "{date}, all day",
  "Dates.all_day_today": "Today, all day",
  "Dates.plural.day": "{count, plural, one {day} other {days}}",
  "Dates.plural.month": "{count, plural, one {month} other {months}}",
  "Dates.plural.week": "{count, plural, one {week} other {weeks}}",
  "Dates.plural.year": "{count, plural, one {year} other {years}}",
  "Dates.range_today": "Today, {start} - {end}",
  "Dates.range_today_with_timezone": "Today, {start} - {end} {timezone}",
  "Dates.today_with_timezone": "Today, {date} {timezone}",
  "DateTime.mobile_header": "When would you like to have your appointment?",
  "DateTime.no_language_preference": " (no {locale})",
  "DateTime.select_time": "Select a Time",
  "DateTime.select_user": "Select a Staff Member",
  "DateTime.showing_times_for": "Showing times for",
  "DateTime.times_available_for_people_speaking": "Available times for {locale} on {date}",
  "DateTime.times_available_on_date": "Available times on {date}",
  "DateTime.user_mobile_header": "When would you like to meet with {name}?",
  "DateTime.user_schedule": "Schedule a time with {name}",
  "DateTime.who_speaks": " who speaks {locale}",
  "DateTime.locale_badge_label": "{name} speaks {language}",
  "DateTime.locale_badge_tooltip": "Speaks {language}",
  "Details.form_header": "Enter Your Details",
  "DetailsForm.add": "Add",
  "DetailsForm.additional_attendees": "Additional Attendees",
  "DetailsForm.additional_attendees_limit": "(max {limit})",
  "DetailsForm.address": "Address",
  "DetailsForm.add_attendee": "+ Add Additional Attendee",
  "DetailsForm.add_attendee_title": "Add Attendee",
  "DetailsForm.appointment_policy_action": "booking an appointment",
  "DetailsForm.walkin_policy_action": "joining the line",
  "DetailsForm.agree_tos": "By {action}, you agree to Coconut Software's {terms} and {privacy}.",
  "DetailsForm.agree_tos_with_vendor": "By {action}, you agree to Coconut Software's {terms} and {privacy}, and {vendor}'s {vendorTerms} and {vendorPrivacy}.",
  "DetailsForm.agree_tos_with_privacy": "By {action}, you agree to Coconut Software's {terms} and {privacy}, and {vendor}'s {vendorPrivacy}.",
  "DetailsForm.agree_tos_with_tos": "By {action}, you agree to Coconut Software's {terms} and {privacy}, and {vendor}'s {vendorTerms}.",
  "DetailsForm.agree_tos_with_vendor_full_privacy": "By {action}, you agree to {vendor}'s {vendorTerms} and {vendorPrivacy}.",
  "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "By {action}, you agree to {vendor}'s {vendorPrivacy}.",
  "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "By {action}, you agree to {vendor}'s {vendorTerms}.",
  "DetailsForm.cell_phone": "Mobile Number",
  "DetailsForm.cell_phone_context": "We will call you at this number",
  "DetailsForm.city": "City",
  "DetailsForm.coconut_privacy_url": "https://www.coconutsoftware.com/privacy-policy/",
  "DetailsForm.coconut_terms_url": "https://www.coconutsoftware.com/terms-of-use/",
  "DetailsForm.country": "Country",
  "DetailsForm.edit": "Save",
  "DetailsForm.edit_attendee_title": "Edit Attendee",
  "DetailsForm.email": "Email",
  "DetailsForm.explicit_compliance_agreement_label": "I accept",
  "DetailsForm.duplicate_attendee": "An attendee with this name and email already exists",
  "DetailsForm.first_name": "First Name",
  "DetailsForm.home_phone": "Home Phone Number",
  "DetailsForm.last_name": "Last Name",
  "DetailsForm.notes": "Additional Details",
  "DetailsForm.postal_code": "ZIP / Postal Code",
  "DetailsForm.privacy": "Privacy Notice",
  "DetailsForm.province": "Province",
  "DetailsForm.receive_sms": "Receive appointment notifications via text message. Standard messaging rates may apply.",
  "DetailsForm.receive_sms_required": "We will use this information to send you important updates about your appointment",
  "DetailsForm.select_country": "Please select a country",
  "DetailsForm.select_province": "Please select a province",
  "DetailsForm.select_state": "Please select a state",
  "DetailsForm.state": "State",
  "DetailsForm.submit": "Continue",
  "DetailsForm.terms_of_use": "Terms of Use",
  "DetailsForm.work_phone": "Work Phone Number",
  "DetailsForm.sms_error_title": "Opt back in to receive text messages.",
  "Dropzone.delete": "Delete",
  "Dropzone.instructions": "Drag files here or <span>browse to upload</span>",
  "Duration.days": "{duration, plural, one {# day} other {# days}}",
  "Duration.hours": "{duration, plural, one {# hour} other {# hours}}",
  "Duration.minutes": "{duration, plural, one {# minute} other {# minutes}}",
  "Duration.weeks": "{duration, plural, one {# week} other {# weeks}}",
  "EmptyState.contact_vendor": "Please try again or contact us",
  "FastRoute.book_anyway": "Book an appointment instead",
  "FastRoute.title": "No appointment necessary",
  "FastRoute.skip_the_wait": "Skip the wait",
  "Feedback.neutral": "Neutral",
  "Feedback.response_prompt": "Tell us how your experience could have been better.",
  "Feedback.send": "Send feedback",
  "Feedback.subtitle": "Your feedback is anonymous. We will use this information to improve the booking experience.",
  "Feedback.thank_you": "Thanks for your feedback!",
  "Feedback.title": "How was your experience booking this appointment?",
  "Feedback.google_description": "We truly value your feedback and we believe our other customers will benefit from it. If you have a few moments to let them know about your experience with us via a Google Review, click on the button below and we will redirect you.",
  "Feedback.google_submit": "Submit Google Review",
  "Feedback.very_dissatisfied": "Very Dissatisfied",
  "Feedback.very_satisfied": "Very Satisfied",
  "Feedback.rating": "Rating (required)",
  "Feedback.sending": "Sending Feedback",
  "Feedback.error": "Please provide a rating before submitting",
  "Feedback.success": "Feedback successfully sent, thank you!",
  "Feedback.new_title": "Feedback",
  "Feedback.experience": "Your Experience",
  "Feedback.close": "Close feedback section",
  "FetchSlots.error": "Something went wrong, please try again at a later time",
  "FindAvailableDate.earliest_available": "Earliest available",
  "FindAvailableDate.finding_next_time": "Finding next available time",
  "FindAvailableDate.finding_first_time": "Finding first available time",
  "FindAvailableDate.first_available_date": "As soon as possible",
  "FindAvailableDate.next_available": "Next available",
  "FindAvailableDate.next_available_date": "Next available date",
  "Form.field_error.cell_phone_characters": "Please enter a valid cell phone number.",
  "Form.field_error.cell_phone_length": "Please enter a valid cell phone number between 9-20 characters long.",
  "Form.field_error.email_structure": "Please enter a valid email address.",
  "Form.field_error_max_length": "This field cannot exceed {max} characters.",
  "Form.field_error_min_length": "This field cannot be less than {min} characters.",
  "Form.field_required": "This field is required.",
  "Form.optional": "(optional)",
  "GenericError.subtitle": "The page you are looking for cannot be found",
  "GenericError.title": "Oops, something went wrong",
  "Input.additional_input": "Additional message",
  "LandingChoices.book_appointment_title": "Book an Appointment",
  "LandingChoices.book_appointment_subtitle": "Select a specific date and time to see someone",
  "LandingChoices.callback_title": "Join the Line",
  "LandingChoices.callback_subtitle": "See someone today as soon as possible",
  "LandingChoices.check_in_title": "Check In",
  "LandingChoices.check_in_subtitle": "If you’re here for a pre-booked appointment",
  "LandingChoices.choose_another_location_subtitle": "Click here to search for another location",
  "LandingChoices.title": "What would you like to{space}do?",
  "LandingChoices.title.service": "How would you like to book your {service} appointment?",
  "LandingChoices.prefill_title": "Welcome {name}! What would you like to{space}do?",
  "LandingChoices.prefill_title.service": "Welcome {name}! How would you like to book your {service} appointment?",
  "LandingChoices.private_location": "This Location is Private",
  "LandingChoices.virtual_location": "Virtual Location",
  "Label.phone_number": "Phone Number",
  "LandingChoices.welcome_to": "Welcome to",
  "Languages.english": "English",
  "Languages.french": "French",
  "Languages.spanish": "Spanish",
  "Languages.korean": "Korean",
  "Languages.polish": "Polish",
  "Languages.portuguese": "Portuguese",
  "Languages.russian": "Russian",
  "Languages.chinese": "Chinese (Simplified)",
  "LobbyBanner.button": "Join the line",
  "LobbyBanner.button_description": "Click here to join the line to be seen instead of booking an appointment",
  "LobbyBanner.title": "No appointment necessary!",
  "LobbyBanner.phone_button": "Request a callback",
  "LobbyBanner.phone_button_description": "Click here to book a phone callback instead of booking an appointment",
  "LobbyBanner.wait_time": "Estimated wait time: {time} minutes",
  "Location.previously_booked": "Previously booked",
  "Location.search_area": "Search this area",
  "Location.select_location": "Select a Location",
  "Location.select_location_prefill": "{name}, select a Location",
  "LocationDetails.location_details": "Location Details",
  "LocationDetails.select_location": "Select this Location",
  "LocationDetails.see_all_locations": "See all locations",
  "LocationDirectionsButton.text": "Directions",
  "LocationHours.closed": "Closed",
  "LocationHours.title": "Hours of Operation",
  "LocationIdentifier.empty_error": "Please enter the location number",
  "LocationIdentifier.help_text": "Start by entering the location number you see on the door",
  "LocationIdentifier.location_number": "Location Number",
  "LocationIdentifier.not_found_error": "This doesn’t match any of our locations",
  "LocationIdentifier.title": "Welcome!",
  "LocationList.empty_state_message": "No results found",
  "LocationList.empty_state_secondary": "Try broadening your search",
  "LocationList.finding_locations": "We're finding locations near you",
  "LocationList.near_me": "Locations near me",
  "LocationList.no_locations": "No Locations Found",
  "LocationList.title": "Select a Location",
  "LocationList.toggle_map": "Show map",
  "LocationList.toggle_list": "Show list",
  "LocationPhoneCallButton.text": "Call {number}",
  "LocationStepTitle.preferred_location": "What is your preferred location?",
  "LocationStepTitle.preferred_location_helper": "We will match you with staff from the location you choose.",
  "LoginWithGoogle.showing_times_that_match": "Showing times that match your availability",
  "LoginWithGoogle.sign_in": "Sign in with Google",
  "LoginWithGoogle.sign_in_details": "to show times that match your availability",
  "Manage.appointment_details": "Appointment Details",
  "Manage.book_another": "Book another appointment",
  "Manage.can_never_cancel": "Managing online is not available. Please contact us to make changes.",
  "Manage.cancel": "Cancel Appointment",
  "Manage.cancellation_policy": "Cancellation Policy",
  "Manage.cancellation_policy_details": "If you cannot attend your appointment, please cancel up to {duration} before your scheduled time.",
  "Manage.cancellation_policy_details_no_limit": "If you cannot attend your appointment, please cancel before your scheduled time.",
  "Manage.cancelled": "Appointment is cancelled",
  "Manage.cancelled_successfully": "Appointment has been cancelled",
  "Manage.cannot_cancel_anymore": "Managing online is not available at this time. Please contact us to make changes.",
  "Manage.confirmation": "Confirmation",
  "Manage.confirmation_email": "We've sent you an email with your appointment details, along with any additional information related to your appointment.",
  "Manage.details": "Details",
  "Manage.in_the_past": "Appointment is in the past",
  "Manage.reschedule": "Reschedule",
  "Manage.restart": "Restart from home page",
  "Manage.successfully_booked": "Appointment successfully booked",
  "Manage.updated_successfully": "Appointment has been updated",
  "ManageAppointmentDetails.details_below": "The details of your appointment are below. We've emailed a copy to you.",
  "ManageAppointmentDetails.done_title": "Done!",
  "ManageAppointmentDetails.successfully_booked": "Your appointment is booked.",
  "ManageAppointmentDetails.successfully_rescheduled": "Your appointment has been rescheduled.",
  "ManageAppointmentDetails.your_appointment": "Your Appointment",
  "MeetingMethodForm.appointment_date": "{date} at {time}",
  "MeetingMethodForm.keep": "Keep",
  "MeetingMethodForm.message.in_person": "Update the meeting method for the appointment that is scheduled on {date} to meet in person.",
  "MeetingMethodForm.message.off_site": "Update the meeting method for the appointment that is scheduled on {date} to meet where you want.",
  "MeetingMethodForm.message.phone_call": "Update the meeting method for the appointment that is scheduled on {date} as a phone call.",
  "MeetingMethodForm.message.video_call": "Update the meeting method for the appointment that is scheduled on {date} as a video call.",
  "MeetingMethodForm.new_method_label": "How will you be attending the appointment?",
  "MeetingMethodForm.title": "Update Meeting Method",
  "MeetingMethodForm.update_method": "Update Meeting Method",
  "MeetingMethodInfoBanner.only_phone_available": "This meeting is only available by phone call",
  "MeetingMethodInfoBanner.only_video_available": "This meeting is only available by video call",
  "MeetingMethods.at_location_primary": "In Person",
  "MeetingMethods.at_location_secondary": "Choose one of our locations",
  "MeetingMethods.header": "How will you be attending the appointment?",
  "MeetingMethods.off_site_primary": "Off Site",
  "MeetingMethods.off_site_secondary": "We'll meet you",
  "MeetingMethods.phone_call_primary": "Phone",
  "MeetingMethods.phone_call_secondary": "We'll call you",
  "MeetingMethods.video_call_primary": "Video",
  "MeetingMethods.video_call_secondary": "We'll send you a link",
  "MonthlyDatePicker.next_month": "Next Month",
  "MonthlyDatePicker.previous_month": "Previous Month",
  "Navigation.aria_label.card": "Select {navItem}",
  "Navigation.aria_label.date": "Select {date}",
  "NoLocationPreferenceButton.skip": "Skip",
  "NoLocationPreferenceButton.title": "I don't have a preference",
  "NotAvailable.subtitle": "We currently are not taking appointments",
  "NotAvailable.title": "Not Available",
  "NoTimesAvailable.no_times_in_month": "No Times in {month}",
  "NoTimesAvailable.no_times_in_week": "No times this week",
  "NoTimesAvailable.view_next_month": "View Next Month",
  "NoTimesAvailable.view_next_week": "View Next Week",
  "Pages.country": "Country",
  "Pages.staff_preference": "Staff Preference",
  "Pages.supported_language_preference": "Languages",
  "Pages.timezones": "Timezones",
  "PositionErrorAlert.denied": "We couldn't get your location because we don't have your permission. Check your browser settings for this site.",
  "PositionErrorAlert.retry": "Retry",
  "PositionErrorAlert.unable": "We couldn't get your location.",
  "OtherLocationsTimeChunks.available_times_title": "Available Times at Other Locations",
  "OtherLocationsTimeChunks.no_available_times": "No available times at other locations",
  "OtherLocationsTimeChunks.view_available_times": "View available times at other locations ",
  "OtherLocationsTimeChunks.view_more": "View more",
  "RadioButton": "Radio Button",
  "Reschedule.appointment_details": "Appointment Details",
  "Reschedule.confirm_time": "Confirm Time",
  "Reschedule.error": "The appointment cannot be rescheduled",
  "Reschedule.label": "Reschedule Confirmation",
  "Reschedule.reschedule": "Reschedule",
  "SelectInput.default": "Select",
  "Service.all_categories_title": "All Categories",
  "Service.category_title": "Categories",
  "Service.five_minutes": "< 5 minutes",
  "Service.hours": "{hours, plural, one {# hour} other {# hours}}",
  "Service.minutes": "{minutes, plural, one {# minute} other {# minutes}}",
  "Service.mobile_header": "Which area do you need assistance with?",
  "Service.no_services": "No Services Found",
  "Service.read_less": "Read Less",
  "Service.read_more": "Read More",
  "Service.select_title_prefill": "{name},{break}select a Service",
  "Service.select_title": "Select a Service",
  "Service.service_count": "{number, plural, one {# service} other {# services}}",
  "Service.uncategorized_title": "Other",
  "ServiceHeader.group": "Group",
  "ServiceInstructions.title": "Instructions",
  "ShortcutExpired.message": "This link has expired",
  "Sidebar.footer": "Powered By",
  "Sidebar.title": "Book Appointment",
  "Sidebar.title_check_in": "Check In",
  "Sidebar.title_join_the_line": "Join the line",
  "SpecificUserLanguagePreferences.title": "Language Preference",
  "StepCounter.title": "Step {current} of {end}",
  "Steps.check_in": "Check In",
  "Steps.check_in_description": "<p>Check in for your appointment.</p><p>We will notify you when a staff member is ready to serve you.</p>",
  "Steps.date_time": "Date & Time",
  "Steps.date_time_no_wait_times": "As soon as a staff member is ready to serve you",
  "Steps.date_time_with_wait_times": "Estimated wait time: {time} minutes",
  "Steps.date_time_with_zero_wait_times": "No wait time at this moment",
  "Steps.details": "Details",
  "Steps.details_description": "<p>Add yourself to be seen today.</p><p>We will notify you to come back when we will be ready.</p>",
  "Steps.location": "Location",
  "Steps.meeting_details": "Meeting Details",
  "Steps.meeting_methods": "Meeting Method",
  "Steps.no_staff_warning": "By joining the line, you will be served by the first available staff member. This may not be {name}, who you had previously selected",
  "Steps.service": "Service",
  "Steps.services": "Services",
  "Steps.staff": "Staff",
  "Steps.staff_preference": "Staff Preference",
  "Steps.your_details": "Your Details",
  "Summary.book_appointment": "Book Appointment",
  "Summary.join_the_line": "Join the line",
  "Summary.header": "Almost done! Review and book",
  "SupportedLanguagePreferencePicker.anyone": "anyone",
  "SupportedLanguagePreferencePicker.anyone_available": "anyone available",
  "SupportedLanguagePreferencePicker.assigned_staff": "your assigned staff",
  "SupportedLanguagePreferencePicker.language_preference": "language preference",
  "SupportedLanguagePreferencePicker.specific_language": "anyone who speaks {language}",
  "Svg.alt_text.appointment": "Appointment",
  "Svg.alt_text.back_chevron": "Back",
  "Svg.alt_text.back": "Back",
  "Svg.alt_text.checkbox_not_selected": "Checkbox not selected",
  "Svg.alt_text.checkbox_selected": "Checkbox item selected",
  "Svg.alt_text.checkmark": "Checkmark",
  "Svg.alt_text.clock": "Clock",
  "Svg.alt_text.coconut_logo": "Coconut software logo",
  "Svg.alt_text.completed": "Completed",
  "Svg.alt_text.continue": "Continue",
  "Svg.alt_text.country_selected": "Country selected",
  "Svg.alt_text.down_chevron": "Show more",
  "Svg.alt_text.drop_down_arrow": "Drop down arrow",
  "Svg.alt_text.edit_timezone": "Change timezone",
  "Svg.alt_text.edit": "Edit",
  "Svg.alt_text.expand": "Expand",
  "Svg.alt_text.expanded": "Expanded",
  "Svg.alt_text.forward_chevron": "Forward",
  "Svg.alt_text.hide_location_details": "Hide location details",
  "Svg.alt_text.in_person": "At Location",
  "Svg.alt_text.item_selected": "Item selected",
  "Svg.alt_text.language": "Language",
  "Svg.alt_text.location_pin": "Location \\",
  "Svg.alt_text.near_me": "Near me",
  "Svg.alt_text.next_month": "Next month",
  "Svg.alt_text.next_week": "Next week",
  "Svg.alt_text.person": "Person",
  "Svg.alt_text.phone": "Phone",
  "Svg.alt_text.previous_month": "Previous month",
  "Svg.alt_text.previous_week": "Previous week",
  "Svg.alt_text.search": "Search",
  "Svg.alt_text.select_category": "Select a category",
  "Svg.alt_text.select_language": "Select a language",
  "Svg.alt_text.show_location_details": "Show location details",
  "Svg.alt_text.timezone": "Timezone",
  "Svg.alt_text.timezone_selected": "Timezone selected",
  "Svg.alt_text.up_chevron": "Show less",
  "Svg.alt_text.upload": "Upload",
  "Svg.alt_text.user_selected": "User selected",
  "Svg.alt_text.video": "Video",
  "Svg.alt_text.walk_in": "Walk in service icon",
  "TimeChunk.no_spots": "No spots remaining",
  "TimeChunk.spots_remaining": "{number, plural, one {# spot remaining} other {# spots remaining}}",
  "TimeChunk.unlimited_spots": "Unlimited Spots",
  "TimeChunks.afternoon": "Afternoon",
  "TimeChunks.evening": "Evening",
  "TimeChunks.morning": "Morning",
  "TimeChunks.no_available_times": "No available times",
  "TimeChunks.no_available_times_in_month": "No availability found this month.",
  "TimeChunks.no_available_times_in_range_month": "No availability found within a {range} month period.",
  "TimeChunks.no_available_times_in_range_week": "No availability found within a {range} week period.",
  "TimeChunks.no_available_times_in_week": "No availability found this week.",
  "TimeChunks.no_available_times_on": "No available times on {date}",
  "TimeChunks.select_another_day": "Select another day to find available times",
  "TimeChunks.select_another_month": "Try another month.",
  "TimeChunks.select_another_range": "Try another staff member or location.",
  "TimeChunks.select_another_week": "Try another week.",
  "TimeChunks.select_user": "Select a staff member to see their Availability",
  "TimezoneSelectInput.label": "Time Zone Region",
  "TimezoneSelectInput.required": "Timezone is required.",
  "TimezonesShownIn.label": "Showing times in",
  "TooLateToCancelModal.message": "You can no longer cancel this appointment online.",
  "TooLateToCancelModal.title": "Too Late To Cancel",
  "Ui.back": "Back",
  "Ui.back_to_previous": "Back to: {previous}",
  "Ui.cancel": "Cancel",
  "Ui.clear": "Clear",
  "Ui.close": "Close",
  "Ui.confirm": "Confirm",
  "Ui.continue": "Continue",
  "Ui.current_selection": "Current Selection",
  "Ui.details_about_name_and_address": "Details about {name}, at {address}",
  "Ui.details_about_name": "Details about {name}.",
  "Ui.dismiss": "Dismiss",
  "Ui.hours_of_operation": "Hours of Operation",
  "Ui.near_me": "Near me",
  "Ui.next": "Next",
  "Ui.ok": "Ok",
  "Ui.search": "Search ...",
  "Ui.search.instructions": "Please start typing",
  "Ui.search.no_results_found": "No results found",
  "Ui.search.plain": "Search",
  "Ui.select_name": "Select {name}",
  "Ui.select": "Select",
  "Ui.selected": "Selected",
  "Ui.sign_out": "Sign out",
  "Ui.type_here": "Type here...",
  "Ui.undo": "Undo",
  "UserPreference.anyone": "Anyone",
  "UserPreference.anyone_available": "Anyone available",
  "UserPreference.assign_me_subtitle": "Only assign me with someone who speaks {language}",
  "UserPreference.assign_me_title": "Anyone who speaks {language}",
  "UserPreference.assign_me_short_title": "{language}",
  "UserPreference.assigned_title": "Your assigned staff",
  "UserPreference.header": "Do you have a preferred staff member?",
  "UserPreference.language_title": "{language}",
  "UserPreference.no_preference": "No preference",
  "UserPreference.random_subtitle": "I don't have a preference",
  "UserPreference.random_title": "Anyone available",
  "UserPreference.see_all_staff": "See all staff",
  "UserPreference.specific_subtitle": "I know exactly who I want to meet with",
  "UserPreference.specific_title": "A specific staff member",
  "Validator.address": "address",
  "Validator.cell_phone": "mobile number",
  "Validator.city": "city",
  "Validator.country": "country",
  "Validator.email": "email",
  "Validator.first_name": "first name",
  "Validator.home_phone": "home phone",
  "Validator.last_name": "last name",
  "Validator.meeting_method": "meeting method",
  "Validator.notes": "notes",
  "Validator.not_url": "Cannot contain a URL.",
  "Validator.phone_country_code": "Please provide a 1-3 digit country code preceded by '+' and followed by your phone number.",
  "Validator.phone_valid": "Please provide a valid phone number.",
  "Validator.postal_code": "postal code",
  "Validator.province": "province",
  "Validator.state": "state",
  "Validator.work_phone": "work phone",
  "Validator.zip_code": "zip code",
  "ViewOnMapButton.title": "View on map",
  "WaitTime.loading": "Getting walk-in wait time...",
  "WaitTime.no_walkins_currently": "Currently not taking walk-ins",
  "WaitTime.wait": "Current walk-in wait time: {wait}",
  "WeeklyDatePicker.next_week": "Next Week",
  "WeeklyDatePicker.previous_week": "Previous Week",
  "WhatNow.title": "What Now?",
  "WhatNow.homepage": "Go to the {vendor} website"
};
