import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Api from '../helpers/Api';
import Button from './Button';
import Clear from './icons/Clear';
import Neutral from './icons/Neutral';
import Satisfied from './icons/Satisfied';
import VeryDissatisfied from './icons/VeryDissatisfied';
import VerySatisfied from './icons/VerySatisfied';
import List from './List';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.5rem',
    opacity: 1,
    textAlign: 'center',
    transition: 'opacity 0.5s',
    willChange: 'opacity',
  },
  cardBackground: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    padding: '1.5rem',
    width: '100%',
    position: 'relative',
  },
  close: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    color: '#000',
    '& svg': {
      height: '1.5rem',
    },
  },
  complete: {
    height: '0 !important',
    padding: '0 !important',
    overflow: 'hidden',
  },
  fade: {
    opacity: 0,
  },
  input: {
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.borderRadius.sm,
    color: theme.palette.neutral[400],
    display: 'block',
    fontSize: theme.textSizes.sm,
    padding: '0.75rem',
    resize: 'none',
    transition: theme.transitions.border,
    width: '100%',
    '&:focus': {
      borderColor: theme.palette.info[400],
      outline: 0,
    },
  },
  list: {
    margin: '0.5rem',
  },
  open: {
    maxHeight: '40rem !important',
  },
  responseArea: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'max-height 0.5s',
    textAlign: 'center',
    width: '100%',
  },
  satisfied: {
    '& svg': {
      fill: theme.palette.secondary[400],
      height: '2.25rem',
      marginBottom: '1rem',
      width: '2.25rem',
    },
  },
  score: {
    alignItems: 'center',
    background: theme.palette.neutral[200],
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    height: '3rem',
    justifyContent: 'center',
    margin: '0.25rem',
    opacity: 0.6,
    width: '3rem',
    '& svg': {
      fill: theme.palette.black,
      height: '2rem',
      width: '2rem',
    },
    '&:hover': {
      opacity: 1,
    },
    '&:focus': {
      background: theme.palette.secondary.A500,
      opacity: 1,
      outline: 0,
      boxShadow: theme.shadows.input,
      borderColor: theme.palette.secondary[500],
    },
  },
  selectedScore: {
    background: theme.palette.secondary.A500,
    opacity: 1,

    '& svg': {
      fill: theme.palette.secondary[400],
    },
  },
  sent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'height 0.5s',
  },
  text: {
    alignSelf: 'flex-start',
    margin: '0.5rem 0 1rem 0',
  },
  hidden: {
    display: 'none',
  },
}));

const states = {
  UNSELECTED: 1,
  SELECTED: 2,
  SENT: 3,
  COMPLETE: 4,
};

const scores = {
  VERY_UNSATISFIED: {
    id: '1',
    label: 'Feedback.very_dissatisfied',
    svg: <VeryDissatisfied />,
  },
  NEUTRAL: {
    id: '2',
    label: 'Feedback.neutral',
    svg: <Neutral />,
  },
  VERY_SATISFIED: {
    id: '3',
    label: 'Feedback.very_satisfied',
    svg: <VerySatisfied />,
  },
};

const Feedback = () => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const [feedbackState, setFeedbackState] = useState(states.UNSELECTED);
  const [selectedScore, setSelectedScore] = useState(null);
  const [height, setHeight] = useState(0);
  const sectionRef = useRef(null);

  const selectScore = (id) => {
    if (feedbackState !== states.SELECTED) {
      setFeedbackState(states.SELECTED);
    }

    setSelectedScore(id);
  };

  const submitFeedback = () => {
    setHeight(sectionRef.current.clientHeight);
    setFeedbackState(states.SENT);

    Api.feedback().submit({
      score: selectedScore,
      comments: sectionRef.current.querySelector('textarea').value,
    });
  };

  const closeFeedback = () => {
    setFeedbackState(states.COMPLETE);
  };

  return (
    <section
      className={classNames(
        classes.root,
        feedbackState === states.COMPLETE ? classes.fade : '',
      )}
    >
      {feedbackState === states.UNSELECTED ||
      feedbackState === states.SELECTED ? (
        <div
          className={classNames(
            feedbackState === states.SELECTED ? classes.cardBackground : '',
          )}
          ref={sectionRef}
        >
          <Typography component="p" id="feedback-label" variant="label">
            <FormattedMessage id="Feedback.title" />
          </Typography>
          <div
            aria-labelledby="feedback-label"
            className={classes.list}
            role="group"
          >
            <List horizontal>
              {Object.keys(scores).map((score) => (
                <li
                  aria-label={intl.formatMessage({ id: scores[score].label })}
                  id={scores[score].id}
                  key={scores[score].id}
                >
                  <button
                    aria-pressed={selectedScore === scores[score].id}
                    className={classNames(
                      classes.score,
                      selectedScore === scores[score].id
                        ? classes.selectedScore
                        : null,
                    )}
                    onClick={() => selectScore(scores[score].id)}
                    onKeyPress={(e) => {
                      e.key === 'Enter' ? selectScore(scores[score].id) : null;
                    }}
                    title={intl.formatMessage({ id: scores[score].label })}
                    type="button"
                  >
                    {scores[score].svg}
                  </button>
                </li>
              ))}
            </List>
          </div>
          {feedbackState === states.SELECTED ? (
            <div className={classNames(classes.responseArea, classes.open)}>
              <textarea
                className={classes.input}
                label={intl.formatMessage({ id: 'Feedback.response_prompt' })}
                placeholder={intl.formatMessage({
                  id: 'Feedback.response_prompt',
                })}
              />
              <span className={classes.text}>
                <Typography component="p" variant="help">
                  <FormattedMessage id="Feedback.subtitle" />
                </Typography>
              </span>
              <Button fullWidth={false} onClick={submitFeedback}>
                <FormattedMessage id="Feedback.send" />
              </Button>
            </div>
          ) : null}
        </div>
      ) : (
        <div
          className={classNames(
            classes.cardBackground,
            classes.sent,
            feedbackState === states.COMPLETE ? classes.complete : '',
          )}
          role="status"
          style={{ height }}
        >
          <button
            aria-label={intl.formatMessage({ id: 'Feedback.close' })}
            className={classes.close}
            onClick={closeFeedback}
            title={intl.formatMessage({ id: 'Feedback.close' })}
            type="button"
          >
            <Clear />
          </button>
          <span className={classes.satisfied}>
            <Satisfied />
          </span>
          <Typography component="p" variant="label">
            <FormattedMessage id="Feedback.thank_you" />
          </Typography>
        </div>
      )}
    </section>
  );
};

export default Feedback;
