import PropTypes from 'prop-types';

export default PropTypes.shape({
  cellPhone: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  receiveSms: PropTypes.oneOf([false, true]),
  notes: PropTypes.string,
});
