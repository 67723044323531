import { appointmentStatuses } from '../../backend/constants';

const WHITE = '#ffffff';
const BLACK = '#000000';

const colorNames = [
  'default',
  'primary',
  'secondary',
  'blue',
  'error',
  'green',
  'indigo',
  'pink',
  'purple',
  'red',
  'warning',
  'yellow',
];

/**
 * Get a color for Avatar component from a name
 *
 * @param {string} name
 * @returns {string}
 */
const getAvatarColor = (name) => {
  let sum = 0;

  for (let i = 0; i < name.length; i += 1) {
    sum += name.charCodeAt(i);
  }
  const index = sum % colorNames.length;

  return colorNames[index];
};

const getStatusColors = (dark) => ({
  [appointmentStatuses.CONFIRMED]: dark
    ? 'bg-confirmed-dark'
    : 'bg-confirmed-light',
  [appointmentStatuses.IN_PROGRESS]: dark
    ? 'bg-in-progress-dark'
    : 'bg-in-progress-light',
  [appointmentStatuses.SERVED]: dark ? 'bg-served-dark' : 'bg-served-light',
  [appointmentStatuses.STATUS_COMPLETE]: dark
    ? 'bg-completed-dark'
    : 'bg-completed-light',
  [appointmentStatuses.NO_SHOW]: dark ? 'bg-no-show-dark' : 'bg-no-show-light',
  [appointmentStatuses.CANCELLED]: dark
    ? 'bg-cancelled-dark'
    : 'bg-cancelled-light',
});

/**
 * Turns a given hex code into three rgb values
 *
 * @param hex
 * @returns {number[]}
 */
const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`,
    )
    .substring(1)
    .match(/.{1,2}/g)
    .map((x) => parseInt(x, 16));

/**
 * Determine the luminosity of a hex color and return white or black, for optimal contrast
 *
 * @param color
 * @returns {string}
 */
export const getReadableForegroundColor = (color) => {
  const [red, green, blue] = hexToRgb(color);
  const luminosity = 0.299 * red + 0.587 * green + 0.114 * blue;

  return luminosity > 186 ? BLACK : WHITE;
};

/**
 * Return the combo of readable background + foreground colors for a given background color.
 *
 * @param color
 * @returns {{background: null, foreground: null}|{background: string, foreground: string}}
 */
export const readableForegroundBackground = (color) => {
  if (color && /^#([0-9A-F]{3}){1,2}$/i.test(color)) {
    return {
      background: color,
      foreground: getReadableForegroundColor(color),
    };
  }

  return {
    background: BLACK,
    foreground: WHITE,
  };
};

export default {
  getAvatarColor,
  getStatusColors,
  readableForegroundBackground,
};
