import { Visibilities } from 'coconut-open-api-js';
import floor from 'lodash/floor';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import Dates, {
  DAYS_IN_A_YEAR,
  MINUTES_IN_DAY,
  MONTHS_IN_A_YEAR,
} from '../../shared/helpers/Dates';
import { LANGUAGES } from '../constants';
import Open from '../helpers/api/Open';
import Item from '../helpers/Item';
import Slots from '../helpers/Slots';
import Range from '../prototypes/Range';

const useFetchSlotsMethods = ({
  additionalUsers = [],
  exclusion,
  features,
  googleUser,
  location,
  locationCategory,
  meetingMethod,
  merge = true,
  preferred,
  range,
  service,
  setInformation,
  settings,
  shouldUseChunks = false,
  slotsApiIdRef,
  supportedLanguages,
  timezone,
  user,
  userCategory,
  addSnack = () => {},
}) => {
  const Api = Open.api();

  function getApiID() {
    slotsApiIdRef.current += 1;
    return slotsApiIdRef?.current;
  }

  async function fetchSlots(
    rangeStartDate,
    rangeEndDate,
    apiId = slotsApiIdRef?.current,
    shouldSetInformation = true,
  ) {
    const startDate = Slots.startDate(range, rangeStartDate);
    const endDate = Slots.endDate(range, rangeEndDate);

    return Api.slots()
      .in(timezone)
      .for(service.id)
      .when(additionalUsers.length, (api) =>
        api.attendedBy(additionalUsers.map(({ id }) => id)),
      )
      .by(Item.get(user || {}, 'id'))
      .when(userCategory, (api) => api.withinUserCategory(userCategory.id))
      .when(!settings?.preferred_location && locationCategory, (api) =>
        api.withinLocationCategory(locationCategory.id),
      )
      .excluding(exclusion ? Number(exclusion) : null)
      .when(meetingMethod, (api) => api.method(meetingMethod))
      .when(Item.has(location || {}, 'id'), (api) => api.at(location.id))
      .between(startDate.format(), endDate.add(1, 'day').format())
      .when(
        features.spokenLanguages && supportedLanguages?.includes(preferred.id),
        (api) => api.supporting([preferred.id]),
      )
      .when(
        !features.spokenLanguages &&
          Object.keys(LANGUAGES).includes(preferred.id),
        (api) => api.supporting([preferred.id]),
      )
      .when(preferred.id === 'random', (api) => api.supporting(null))
      .when(settings?.invite_only_resources, (api) => api.withInviteOnly())
      .when(window.location.pathname === '/reschedule', (api) =>
        api.visibility(Visibilities.ALL),
      )
      .when(
        features.clientGoogleLogin && googleUser?.matchAvailability,
        (api) => api.google(googleUser.token),
      )
      .get()
      .then(({ data: { data } }) => {
        let slotData = Slots.filterWithinRange(data, startDate, endDate);

        if (service.group) {
          slotData = Slots.filterFilledGroupAppointmentsArray(slotData);
        }
        const slots = Slots.combine(slotData);
        const selected = Slots.getSelectedSlot(slotData, startDate);

        const information = {
          ...{
            error: null,
            loading: false,
            loadingMessage: null,
            merge,
            slots,
            slotsLoading: false,
          },
          ...(!sessionStorage.getItem('default_date') && { selected }),
        };

        // When selecting a specific staff member, the selected date is set differently. This is because for a
        // non-specific staff selection, availabilities are requested by weekly calls and loaded in as soon as they are
        // ready.
        if (!shouldUseChunks && shouldSetInformation) {
          if (slotsApiIdRef?.current === apiId) {
            setInformation(information);
          }
        }

        return information;
      });
  }

  async function processChunks(weekChunks, shouldSetInformation = true) {
    let allPromises = [];
    const apiID = getApiID();

    const processOneResponse = (response, shouldSelectAvailability = false) => {
      const slots = response?.slots;
      // discard any empty or undefined slots
      if (!slots) {
        return {};
      }
      // If there are no availabilities within the whole week, fetchedSlots will be empty.
      // This avoids that case so that the default selected day can be set.
      if (Object.keys(slots)?.length) {
        let newInformation = {
          loading: true,
          merge: true,
          slots,
        };

        const firstAvailableDay = Item.first(slots);
        if (shouldSelectAvailability && firstAvailableDay[0]) {
          newInformation = {
            ...newInformation,
            ...(!sessionStorage.getItem('default_date') && {
              selected: firstAvailableDay[0]?.start,
            }),
          };
          hasSelected = true;
        }

        if (shouldSetInformation && apiID === slotsApiIdRef.current) {
          setInformation(newInformation);
        }
      }

      return slots;
    };

    // Synchronous request
    let oneWeek = weekChunks.shift();
    let syncResponse = await fetchSlots(
      oneWeek[0],
      oneWeek[oneWeek.length - 1],
      apiID,
      shouldSetInformation,
    );
    let fetchedSlots = [];
    let hasSelected = false;

    fetchedSlots[0] = processOneResponse(syncResponse, true);

    // Send Asynchronous requests
    weekChunks.forEach((oneWeek, index) => {
      try {
        let onePromise = fetchSlots(
          oneWeek[0],
          oneWeek[oneWeek.length - 1],
          apiID,
          shouldSetInformation,
        );

        onePromise.then((response) => {
          fetchedSlots[index + 1] = processOneResponse(response);
        });

        allPromises.push(onePromise);
      } catch (error) {
        if (apiID === slotsApiIdRef.current) {
          setInformation({
            ...{ loading: false, slotsLoading: false },
          });
          addSnack(error);
        }
      }
    });

    // When all requests are completed, hide the loading spinners and set the selected state.
    return await Promise.all(allPromises).then(() => {
      const availableSlots = fetchedSlots.flatMap((weekSlots) =>
        Object.keys(weekSlots).length > 0 ? weekSlots : [],
      );
      let newInformation = { loading: false, slotsLoading: false };

      newInformation.slots = availableSlots?.reduce(
        (combinedSlots, availableSlot) => {
          return availableSlot
            ? { ...combinedSlots, ...availableSlot }
            : combinedSlots;
        },
        {},
      );

      // Get and sort the available days for the month, and set selected to the first
      const daySlots = Object.keys(newInformation.slots);
      if (daySlots.length) {
        const sortedSlotDates = daySlots.sort(
          (date1, date2) => new Date(date1) - new Date(date2),
        );

        const selected =
          sortedSlotDates.length > 0
            ? Dates.parse(sortedSlotDates[0])
            : Dates.today();
        if (!hasSelected) {
          newInformation = {
            ...newInformation,
            ...(!sessionStorage.getItem('default_date') && { selected }),
          };
        }
      } else {
        // Set error if there are no available days for the month
        newInformation = {
          ...newInformation,
          error: {
            messageTitleKey: 'TimeChunks.no_available_times_in_month',
            messageSubtitleKey: 'TimeChunks.select_another_month',
          },
        };
      }

      if (shouldSetInformation && apiID === slotsApiIdRef.current) {
        setInformation(newInformation);
      }

      return newInformation;
    });
  }

  async function fetchSlotsChunks(
    start = range.start,
    end = range.end,
    shouldSetInformation = true,
  ) {
    if (googleUser?.refreshing && googleUser?.matchAvailability) {
      return;
    }
    if (!shouldUseChunks) {
      if (shouldSetInformation) {
        setInformation({ loading: true });
      }

      return await fetchSlots(start, end, getApiID(), shouldSetInformation);
    } else {
      if (shouldSetInformation) {
        setInformation({
          error: null,
          loading: true,
          selected: Dates.isBetween(start, end) ? Dates.today() : start,
          slots: {},
          slotsLoading: true,
          merge,
        });
      }
      let weeksWithinRange = Dates.calendarize(start, end);

      weeksWithinRange.forEach((week, i) => {
        weeksWithinRange[i] = week.filter((date) => {
          return (
            date.isSame(start, range.unit) &&
            (date.isAfter(start) || date.isEqual(start)) &&
            (date.isAfter(Dates.today()) || date.isEqual(Dates.today()))
          );
        });
      });

      weeksWithinRange = weeksWithinRange.filter((week) => week.length);

      if (weeksWithinRange.length > 2) {
        if (weeksWithinRange[0].length < 4) {
          let firstWeek = weeksWithinRange.shift();
          weeksWithinRange[0] = [...firstWeek, ...weeksWithinRange[0]];
        }
      }
      if (weeksWithinRange.length === 0) {
        const information = {
          loading: false,
          slotsLoading: false,
        };
        if (shouldSetInformation) {
          setInformation(information);
        }
        return information;
      }

      return await processChunks(weeksWithinRange, shouldSetInformation);
    }
  }

  return { fetchSlots, fetchSlotsChunks, processChunks };
};

const useFetchSlots = ({
  additionalUsers = [],
  exclusion,
  features,
  fetch,
  googleUser,
  location,
  locationCategory,
  meetingMethod,
  merge = true,
  preferred,
  range,
  service,
  setInformation,
  setRange,
  settings,
  shouldUseChunks = false,
  skipNextFetchSlots = false,
  slotsApiIdRef,
  supportedLanguages,
  timezone,
  user,
  userCategory,
  addSnack = () => {},
}) => {
  const { fetchSlotsChunks } = useFetchSlotsMethods({
    additionalUsers,
    exclusion,
    features,
    fetch,
    googleUser,
    location,
    locationCategory,
    meetingMethod,
    merge,
    preferred,
    range,
    service,
    setInformation,
    settings,
    shouldUseChunks,
    slotsApiIdRef,
    supportedLanguages,
    timezone,
    user,
    userCategory,
    addSnack,
  });

  useEffect(() => {
    if (skipNextFetchSlots) {
      setRange({ range, skipNextFetchSlots: false });
      return;
    }
    fetchSlotsChunks();

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    additionalUsers,
    fetch,
    googleUser?.matchAvailability,
    googleUser?.token,
    googleUser?.refreshing,
    location,
    meetingMethod,
    preferred.id,
    service,
    setInformation,
    setRange,
    slotsApiIdRef,
    range.end,
    range.start,
    range,
    timezone,
    user,
    userCategory,
  ]);
};

export const useFindAvailabilityMethods = ({
  additionalUsers = [],
  exclusion,
  features,
  fetch,
  googleUser,
  location,
  locationCategory,
  meetingMethod,
  merge = true,
  preferred,
  range,
  service,
  setInformation,
  setRange = () => {},
  settings,
  shouldUseChunks = false,
  skipNextFetchSlots,
  supportedLanguages,
  timezone,
  user,
  userCategory,
  addSnack = () => {},
}) => {
  const slotsApiIdRef = useRef(0);
  const intl = useIntl();
  const { fetchSlotsChunks, fetchSlots } = useFetchSlotsMethods({
    additionalUsers,
    exclusion,
    features,
    fetch,
    googleUser,
    location,
    locationCategory,
    meetingMethod,
    merge,
    preferred,
    range,
    service,
    setInformation,
    settings,
    shouldUseChunks,
    skipNextFetchSlots,
    slotsApiIdRef,
    supportedLanguages,
    timezone,
    user,
    userCategory,
    addSnack,
  });

  async function fetchNextAvailableSlots(
    startDate,
    timeInAdvance,
    unit = 'month',
  ) {
    let currentStart = startDate.clone().startOf('day');
    let currentEnd = currentStart.clone().endOf('day');
    currentEnd =
      unit === 'month'
        ? Range.month({ date: currentEnd }).end
        : Range.week({ date: currentEnd }).end;

    let end = currentStart.clone().add(timeInAdvance, 'minutes').endOf('day');
    end =
      unit === 'month'
        ? Range.month({ date: end }).end
        : Range.week({ date: end }).end;

    setInformation({
      error: null,
      loading: true,
      slotsLoading: true,
    });
    // iterate through months until we find a slot or when we reach range end.
    while (currentStart.isBefore(end)) {
      let results = {};
      try {
        results =
          unit === 'month'
            ? await fetchSlotsChunks(currentStart, currentEnd, false)
            : await fetchSlots(currentStart, currentEnd, undefined, false);
      } catch (error) {
        console.error(error);
        continue;
      }

      const slotDates = Object.keys(results?.slots || {});
      if (slotDates.length) {
        const sortedSlotDates = slotDates.sort(
          (date1, date2) => new Date(date1) - new Date(date2),
        );
        const firstSlotStart = Dates.parse(sortedSlotDates[0]);

        setInformation({
          ...results,
          error: null,
          loading: false,
          loadingMessage: null,
          selected: firstSlotStart,
          slotsLoading: false,
        });
        setRange({
          range:
            range.unit == 'month'
              ? Range.month({ date: firstSlotStart })
              : Range.week({ date: firstSlotStart }),
        });
        return;
      }

      currentEnd = Dates.add(currentEnd, 1, unit).endOf('day');
      currentStart = currentEnd.startOf(unit).startOf('day');
    }

    if (unit === 'week') {
      timeInAdvance = Dates.formatToUnit(timeInAdvance, 'minute', unit);
    } else {
      let originalTimeInAdvance = timeInAdvance;
      timeInAdvance = floor(
        timeInAdvance / MINUTES_IN_DAY / (DAYS_IN_A_YEAR / MONTHS_IN_A_YEAR),
      );
      if (timeInAdvance === 0) {
        unit = 'week';
        timeInAdvance = Dates.formatToUnit(
          originalTimeInAdvance,
          'minute',
          unit,
        );
      }
    }

    // If no slots were found
    setInformation({
      error: {
        messageTitleKey:
          unit === 'month'
            ? 'TimeChunks.no_available_times_in_range_month'
            : 'TimeChunks.no_available_times_in_range_week',
        messageSubtitleKey: 'TimeChunks.select_another_range',
        messageValues: {
          range: timeInAdvance,
          rangeUnitPlural: intl.formatMessage(
            { id: 'Dates.plural.' + unit },
            { count: timeInAdvance },
          ),
        },
      },
      loading: false,
      merge: false,
      selected:
        range.unit === 'month'
          ? Range.month({ date: end }).end
          : Range.week({ date: end }).end,
      slots: {},
      slotsLoading: false,
    });

    setRange({
      range:
        range.unit === 'month'
          ? Range.month({ date: end })
          : Range.week({ date: end }),
      skipNextFetchSlots: true,
    });

    return null;
  }

  return { fetchNextAvailableSlots };
};

export default useFetchSlots;
