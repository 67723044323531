import PropTypes from 'prop-types';
import React, { useState } from 'react';
import mode from '../helpers/Mode';
import Home from '../icons/Home';

const HomeButton = ({ className, locale }) => {
  const [disabled, setDisabled] = useState(false);
  const handleClick = () => {
    setDisabled(true);
    mode.resetKiosk({}, null, true, locale);
  };

  return (
    <button
      aria-label=""
      className={className}
      data-testid="home"
      disabled={disabled}
      onClick={handleClick}
      title=""
      type="button"
    >
      <Home />
    </button>
  );
};

HomeButton.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string,
};

HomeButton.defaultProps = {
  className: '',
  locale: '',
};

export default HomeButton;
