import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Window from '../../shared/helpers/Window';
import Button from './Button';

const LocationDirectionsButton = ({ location }) => {
  if (location.physical) {
    return (
      <Button
        data-testid="directions-button"
        fullWidth={false}
        onClick={() => Window.openDirections(location.formattedAddress)}
        variant="smallOutlined"
      >
        <FormattedMessage id="LocationDirectionsButton.text" />
      </Button>
    );
  }

  return null;
};

LocationDirectionsButton.propTypes = {
  location: PropTypes.shape({
    formattedAddress: PropTypes.string,
    physical: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  }).isRequired,
};

export default LocationDirectionsButton;
