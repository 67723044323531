import classNames from 'classnames';
import { Notifications } from 'coconut-open-api-js';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { withRouter } from 'react-router-dom';
import Dates from '../../shared/helpers/Dates';
import Reporter from '../../shared/helpers/Reporter';
import useDateTime from '../../shared/hooks/useDateTime';
import GeneralError from '../components/GeneralError';
import RescheduleSidebar from '../components/RescheduleSidebar';
import TimezoneLabel from '../components/TimezoneLabel';
import TrackPageView from '../components/TrackPageView';
import Typography from '../components/Typography';
import { HEADER_HEIGHT, STEPS, USER_PREFERENCE, PAGES } from '../constants';
import { AppointmentContext } from '../contexts/AppointmentContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { TimezonesContext } from '../contexts/TimezonesContext';
import {
  DESKTOP,
  MOBILE,
  TABLET,
  ViewModeContext,
} from '../contexts/ViewModeContext';
import ApiHelper from '../helpers/Api';
import Open from '../helpers/api/Open';
import Item from '../helpers/Item';
import NextAvailability from '../helpers/NextAvailability';
import Resources from '../helpers/Resources';
import { HTTP_CONFLICT, HTTP_UNPROCESSABLE_ENTITY } from '../helpers/Response';
import Step from '../helpers/Step';
import HistoryShape from '../shapes/HistoryShape';
import DesktopReschedule from './desktop/Reschedule';
import MobileReschedule from './mobile/Reschedule';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    minHeight: 1,
    position: 'relative',
  },
  errorMobile: {
    height: `calc(100% - ${HEADER_HEIGHT.MOBILE})`,
  },
  errorDesktop: {
    height: `calc(100% - ${HEADER_HEIGHT.DESKTOP})`,
  },
  fullPage: {
    minHeight: `calc(100vh - ${HEADER_HEIGHT.DESKTOP})`,
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${HEADER_HEIGHT.DESKTOP})`,
    marginRight: '1.25rem',
    maxWidth: '16.5rem',
    minWidth: '16.5rem',
    position: 'sticky',
    top: HEADER_HEIGHT.DESKTOP,
    width: '16.5rem',
  },
});

const updateInformation = (
  { slots: oldSlots, ...state },
  { slots: newSlots, merge = true, ...newState },
) => ({
  ...state,
  ...newState,
  slots: merge ? { ...oldSlots, ...newSlots } : { ...newSlots },
  ...(NextAvailability.shouldSetEarliestDate(
    state.earliestDate,
    newState.earliestDate,
    newState.slotsLoading,
  ) && {
    earliestDate: NextAvailability.shouldUseNewSelectedDate(
      newState.earliestDate,
      newSlots,
    )
      ? newState.selected
      : null,
  }),
});

const Reschedule = ({ history }) => {
  const classes = useStyles();
  const [locale] = useContext(LocaleContext);
  const {
    formatters: { formatDateTimeLocalFull, formatDateTimeISO },
  } = useDateTime(true);
  const Api = Open.api();
  const intl = useIntl();

  const [
    {
      appointment: appointmentId,
      attendee,
      date,
      user,
      userPreference,
      location,
      service,
    },
    setSelections,
  ] = useContext(SelectionContext);
  const mode = useContext(ViewModeContext);
  const [timezone] = useContext(TimezoneContext);
  const timezones = useContext(TimezonesContext);
  const { builderEnabled } = useContext(SettingsContext);
  const [appointment, setAppointment] = useContext(AppointmentContext);
  const slotsApiIdRef = useRef(0);

  const [{ fetch, errorOpen, errorMessage, step }, setError] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      fetch: 0,
      errorOpen: false,
      errorMessage: null,
      step: null,
    },
  );

  const [{ anchor, newTime }, setChosenTime] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { anchor: null, newTime: null },
  );

  const [
    { earliestDate, error, loading, selected, slots, slotsLoading },
    setInformation,
  ] = useReducer(updateInformation, {
    earliestDate: null,
    error: null,
    loading: true,
    selected: date,
    slots: {},
    slotsLoading: true,
  });

  const [rescheduleLoading, setRescheduleLoading] = useState(false);

  useEffect(() => {
    if (
      !user &&
      userPreference &&
      userPreference.id === USER_PREFERENCE.SPECIFIC
    ) {
      setInformation({
        loading: false,
        merge: false,
        slots: {},
        slotsLoading: false,
      });
    }
  }, [user, userPreference]);

  const revertAppointmentSelection = useCallback(() => {
    setSelections({ appointment: appointmentId });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectDate = (date) => {
    setInformation({ selected: date, error: null });
  };

  const selectTimeMobile = ({ currentTarget }) => {
    if (service.group) {
      setSelections({ appointment: currentTarget.dataset.appointment });
    }

    setChosenTime({ newTime: currentTarget.dataset.date });
  };

  const selectTimeDesktopTablet = ({ currentTarget }) => {
    if (service.group) {
      setSelections({ appointment: currentTarget.dataset.appointment });
    }

    setChosenTime({
      anchor: currentTarget,
      newTime: currentTarget.dataset.date,
    });
  };

  const handleClose = () => {
    setError({ errorOpen: false, fetch: fetch + 1 });
    setInformation({ loading: true, slotsLoading: true });
  };

  const handleConfirm = () => {
    setSelections({ date: Resources.formatDate(newTime) });
    setRescheduleLoading(true);
    const parsedNewTime = Dates.parse(newTime);
    const newDate = new Date(
      parsedNewTime.year(),
      parsedNewTime.month(),
      parsedNewTime.date(),
      parsedNewTime.hour(),
      parsedNewTime.minute(),
    );

    if (service.group) {
      ApiHelper.attendees()
        .reschedule(attendee.id, appointmentId)
        .then(({ data, included }) => {
          const appointments = Item.included(included, 'appointments');
          const {
            id: attendeeId,
            attributes: { confirm_code: confirmCode, timezone },
          } = data;
          const {
            id,
            attributes: { client_end: end, reschedulable, start, status },
          } = Item.first(appointments);

          setAppointment({
            id,
            confirmCode,
            end: Dates.parse(end, timezone),
            endRaw: end,
            reschedulable,
            startRaw: start,
            status,
            rescheduled: true,
            fromReschedule: false,
          });
          setSelections({
            appointment: id,
            attendee: {
              ...attendee,
              ...Resources.formatAttendee({
                id: attendeeId,
                attributes: { timezone },
              }),
            },
          });

          history.push('/confirmation');
        })
        .catch(({ errors }) => {
          setRescheduleLoading(false);
          setChosenTime({ newTime: null });

          const errorMsg = Item.first(errors);
          const { detail, title } = errorMsg;
          Reporter.exception(new Error(title));

          revertAppointmentSelection();

          if (detail) {
            setError({
              errorOpen: true,
              errorMessage: detail,
            });

            return;
          }

          setError({
            errorOpen: true,
            errorMessage: <FormattedMessage id="Reschedule.error" />,
          });
        });
    } else {
      Api.locale(locale)
        .appointments()
        .in(timezone)
        .starting(formatDateTimeISO(newDate))
        .notify(Notifications.ALL)
        .reschedule(appointment.id, appointment.confirmCode)
        .then(({ data: { data, included } }) => {
          const includedAttendees = Item.included(included, 'attendees');
          const {
            id,
            attributes: { client_end: end, start, status },
          } = data;
          const {
            attributes: { confirm_code: confirmCode },
          } = Item.first(includedAttendees);

          setAppointment({
            id,
            confirmCode,
            end: Dates.parse(end, timezone),
            endRaw: end,
            status,
            startRaw: start,
            rescheduled: true,
            fromReschedule: false,
          });

          history.push('/confirmation');
        })
        .catch(({ response: { data, status } }) => {
          const errorMsg = Item.first(data.errors);
          const { detail, source, title } = errorMsg;
          Reporter.exception(new Error(title));

          if (
            status === HTTP_UNPROCESSABLE_ENTITY ||
            status === HTTP_CONFLICT
          ) {
            setRescheduleLoading(false);
            setChosenTime({ newTime: null });

            if (source) {
              const missing = Step.getStep(source.pointer);

              if (Item.includes(STEPS, missing)) {
                setError({
                  errorOpen: true,
                  step: {
                    title: missing,
                    action: handleClose,
                  },
                });
              } else {
                setError({
                  errorOpen: true,
                  errorMessage: Item.first(detail),
                });
              }

              return;
            }
          }

          setError({
            errorOpen: true,
            errorMessage:
              detail ||
              intl.formatMessage({
                id: 'Reschedule.error',
              }),
          });
        });
    }
  };

  const handleClear = () => {
    setChosenTime({ newTime: null });
  };

  const previous = history.goBack;
  const { fromReschedule, rescheduled } = appointment;

  if (!fromReschedule && !rescheduled) {
    setAppointment({ fromReschedule: true });
  }

  let newDate;

  if (newTime) {
    const parsedNewTime = Dates.parse(newTime);
    newDate = new Date(
      parsedNewTime.year(),
      parsedNewTime.month(),
      parsedNewTime.date(),
      parsedNewTime.hour(),
      parsedNewTime.minute(),
    );
  }

  const confirmContent = (
    <>
      {newDate ? (
        <Typography component="p" variant="label">
          {formatDateTimeLocalFull(newDate)}
        </Typography>
      ) : null}
      <Typography component="p" variant="subtitle">
        <TimezoneLabel
          fallback={timezone}
          // We are temporarily ignoring the destructuring-assignment rule explicitly.
          // There is a bug that was solved in a newer version of this plugin which
          // we will eventually be able to upgrade to once we can move off of
          // the current version of NodeJS in use.
          //
          // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
          //
          // eslint-disable-next-line react/destructuring-assignment
          timezone={timezones[timezone]}
          withName
        />
      </Typography>
    </>
  );

  if (
    (!builderEnabled && !user && !(service && service.group)) ||
    !location ||
    !service
  ) {
    return (
      <main
        className={classNames(
          classes.errorMobile,
          mode === DESKTOP && classes.errorDesktop,
        )}
      >
        <GeneralError />
      </main>
    );
  }

  const props = {
    confirmContent,
    confirmOpen: Boolean(newTime),
    earliestDate,
    error,
    exclusion: service.group ? appointmentId : null,
    fetch,
    handleClear,
    handleConfirm,
    handleClose,
    initialStartDate: Dates.today(),
    loading,
    errorOpen,
    errorMessage,
    newTime,
    previous,
    previousStep: 'manage',
    rescheduleLoading,
    selectDate,
    selected,
    selectTime: mode !== MOBILE ? selectTimeDesktopTablet : selectTimeMobile,
    setInformation,
    slots,
    slotsApiIdRef,
    slotsLoading,
    step,
  };

  return (
    <>
      <TrackPageView identifier={PAGES.RESCHEDULE} />
      {mode === DESKTOP && (
        <section className={classes.root} data-testid="desktop-reschedule">
          <aside className={classes.sidebar}>
            <RescheduleSidebar />
          </aside>
          <DesktopReschedule anchor={anchor} {...props} />
        </section>
      )}
      {mode === TABLET && (
        <section
          className={classNames(classes.root, classes.fullPage)}
          data-testid="tablet-reschedule"
        >
          <DesktopReschedule anchor={anchor} {...props} />
        </section>
      )}
      {mode === MOBILE && <MobileReschedule {...props} />}
    </>
  );
};

Reschedule.propTypes = {
  history: HistoryShape.isRequired,
};

export default withRouter(Reschedule);
