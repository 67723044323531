import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import CategoryList from '../CategoryList';
import ServiceList from '../ServiceList';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
});

const MultiPageServiceLayout = ({
  categories,
  category,
  classes: injectedClasses,
  expanded,
  firstCategory,
  selectCategory,
  selectService,
  services,
  toggleDescription,
}) => {
  const classes = {
    ...useStyles(),
    ...injectedClasses,
  };

  const selectedCategory = category === null ? firstCategory : category;

  const handleSelectService = (service, selectedCategory) => {
    selectService(service, selectedCategory);
  };

  return (
    <div className={classes.row} data-testid="multi-page-layout">
      {selectedCategory ? (
        <CategoryList
          categories={categories}
          category={category}
          firstCategory={firstCategory}
          selectCategory={selectCategory}
          selectedCategory={selectedCategory}
        />
      ) : null}
      <ServiceList
        expanded={expanded}
        selectedCategory={selectedCategory}
        selectService={handleSelectService}
        services={services}
        toggleDescription={toggleDescription}
      />
    </div>
  );
};

MultiPageServiceLayout.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstCategory: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  selectCategory: PropTypes.func,
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
  toggleDescription: PropTypes.func,
};

MultiPageServiceLayout.defaultProps = {
  categories: [],
  category: null,
  services: [],
};

export default MultiPageServiceLayout;
