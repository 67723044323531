import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import AppointmentDetailsCard from '../../components/AppointmentDetailsCard';
import CenterWrap from '../../components/CenterWrap';
import ManageAppointmentDetailsHeader from '../../components/ManageAppointmentDetailsHeader';
import ModernFeedback from '../../components/ModernFeedback';
import WhatNow from '../../components/WhatNow';
import { HEADER_HEIGHT } from '../../constants';
import { FeatureContext } from '../../contexts/FeatureContext';
import { ViewModeContext } from '../../contexts/ViewModeContext';

const useStyles = createUseStyles({
  main: {
    flexGrow: 1,
  },
  container: {
    minHeight: `calc(100vh - ${HEADER_HEIGHT.DESKTOP})`,
    display: 'flex',
  },
});

const Feedback = ({ classes: injectedClasses }) => {
  const classes = { ...useStyles(), ...injectedClasses };
  const viewMode = useContext(ViewModeContext);
  const { appointmentBookingChannel } = useContext(FeatureContext);

  return (
    <section className={classes.container}>
      <main className={classes.main}>
        <CenterWrap flat justified narrow view={viewMode}>
          <div className={classNames(classes.root, classes.transparent)}>
            <ManageAppointmentDetailsHeader
              confirmation={false}
              rescheduled={false}
            />
            <AppointmentDetailsCard />
            <ModernFeedback />
            {appointmentBookingChannel ? <WhatNow /> : null}
          </div>
        </CenterWrap>
      </main>
    </section>
  );
};

Feedback.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

Feedback.defaultProps = {
  classes: {},
};

export default Feedback;
