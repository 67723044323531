import { Dialog } from '@coconut-software/ui';
import PropTypes from 'prop-types';
import React from 'react';

const ModalContent = ({ buttons, children, header, testId }) => {
  return (
    <div>
      {header ? <Dialog.Header>{header}</Dialog.Header> : null}
      <Dialog.Content>
        <div data-testid={testId}>{children}</div>
      </Dialog.Content>
      {buttons ? <Dialog.Actions>{buttons}</Dialog.Actions> : null}
    </div>
  );
};

ModalContent.propTypes = {
  buttons: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  header: PropTypes.element,
  testId: PropTypes.string,
};

ModalContent.defaultProps = {
  testId: null,
};

export default ModalContent;
