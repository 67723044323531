import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import Typography from '../components/Typography';
import { HEADER_HEIGHT } from '../constants';
import { HeaderContext } from '../contexts/HeaderContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';

const useStyles = createUseStyles({
  desktopContainer: {
    display: 'flex',
    minHeight: `calc(100vh - ${HEADER_HEIGHT.DESKTOP})`,
  },
  mobileContainer: {
    display: 'flex',
    minHeight: `calc(100vh - ${HEADER_HEIGHT.MOBILE})`,
  },
  main: {
    flexGrow: 1,
  },
  center: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  customMessage: {
    padding: '0 1.875rem',
  },
});

const ShortcutExpired = () => {
  const classes = useStyles();

  const mode = useContext(ViewModeContext);
  const [, setHeader] = useContext(HeaderContext);
  const {
    bookingShortcutCustomExpiredMessage,
    bookingShortcutCustomExpiredMessageContent,
  } = useContext(SettingsContext);

  useEffect(() => {
    setHeader({
      action: null,
      title: document.title,
    });
  }, [mode, setHeader]);

  return (
    <section
      className={
        mode !== MOBILE ? classes.desktopContainer : classes.mobileContainer
      }
    >
      <main className={classes.main}>
        <div className={classes.center}>
          <svg
            height="3rem"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            width="3rem"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 7h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1c0 1.43-.98 2.63-2.31 3l1.46 1.44C20.88 15.61 22 13.95 22 12a5 5 0 0 0-5-5m-1 4h-2.19l2 2H16v-2M2 4.27l3.11 3.11A4.991 4.991 0 0 0 2 12a5 5 0 0 0 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1c0-1.59 1.21-2.9 2.76-3.07L8.73 11H8v2h2.73L13 15.27V17h1.73l4.01 4L20 19.74L3.27 3L2 4.27z"
              fill="rgba(0, 0, 0, 0.6)"
            />
          </svg>
          <Typography grey variant="regular">
            {bookingShortcutCustomExpiredMessage ? (
              <div className={classes.customMessage}>
                {bookingShortcutCustomExpiredMessageContent}
              </div>
            ) : (
              <FormattedMessage id="ShortcutExpired.message" />
            )}
          </Typography>
        </div>
      </main>
    </section>
  );
};

export default ShortcutExpired;
