import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Dates from '../../shared/helpers/Dates';
import useDateTime from '../../shared/hooks/useDateTime';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import Api from '../helpers/Api';
import Resources from '../helpers/Resources';
import SpacetimeShape from '../shapes/SpacetimeShape';
import Button from './Button';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  container: {
    background: theme.palette.white,
    borderTop: `0.25rem solid ${theme.palette.neutral[100]}`,
    padding: '1.25rem',
    '&:first-of-type': {
      borderTopWidth: 0,
    },
  },
  groupItem: {
    marginRight: 0,
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.75rem',
  },
  label: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
  },
  item: {
    display: 'inline-block',
    marginBottom: '0.625rem',
    marginRight: '0.625rem',
    minWidth: '5.5rem',
  },
  justified: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  padded: {
    marginBottom: '2.5rem',
  },
}));

const TimeChunk = ({
  bordered,
  different,
  group,
  handleClickTime,
  id,
  label,
  slots,
  specificUser,
  subtitle,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });
  const {
    formatters: { formatTime },
  } = useDateTime(true);
  const [{ service, settings, user }, setSelections] =
    useContext(SelectionContext);

  const [locale] = useContext(LocaleContext);

  if (slots.length === 0) {
    return null;
  }

  const selectTime = ({ currentTarget }) => {
    if (specificUser) {
      Api.locale(locale)
        .services()
        .find({ service: service.id, settings, user: user ? user.id : null })
        .then(({ data }) => {
          setSelections({ service: Resources.formatService(data) });
        });
    }

    handleClickTime({ currentTarget });
    setSelections({ bookingWalkIn: false });
  };

  const start = new Date();

  return (
    <div
      className={classNames(bordered && classes.container)}
      data-testid={`time-chunk-${id}`}
    >
      <header className={classes.header}>
        <div className={classes.label}>
          {label ? (
            <Typography component="h4" variant="title">
              {label}
            </Typography>
          ) : null}
          {different ? (
            <Typography component="p" variant="body1">
              {Dates.toDateMonthYear(slots[0].start)}
            </Typography>
          ) : null}
        </div>
        {subtitle ? (
          <Typography variant="caption1">{subtitle}</Typography>
        ) : null}
      </header>
      <ul className={classNames(!bordered && !group && classes.padded)}>
        {slots.map((slot) => {
          start.setHours(slot.start.hour(), slot.start.minute());
          const formattedTime = formatTime(start);

          return (
            <li
              className={classNames(classes.item, group && classes.groupItem)}
              key={`${slot.start.format('iso')}-${slot.appointment}`}
            >
              <Button
                ariaLabel={intl.formatMessage(
                  { id: 'Navigation.aria_label.date' },
                  { date: Dates.accessibleHumanReadable(slot.start) },
                )}
                data-appointment={slot.appointment}
                data-date={slot.start.format('iso')}
                data-limit={slot.limit ? slot.limit - slot.count : null}
                data-locations={slot.locations}
                disabled={
                  group && slot.limit !== null
                    ? slot.limit - slot.count <= 0
                    : null
                }
                fullWidth
                onClick={selectTime}
                variant={specificUser ? 'smallChip' : 'smallOutlinedChip'}
              >
                {group ? (
                  <div className={classes.justified}>
                    {formattedTime}
                    <span>
                      {slot.limit !== null && slot.limit - slot.count <= 0 ? (
                        <FormattedMessage id="TimeChunk.no_spots" />
                      ) : (
                        <>
                          {slot.limit === null && (
                            <FormattedMessage id="TimeChunk.unlimited_spots" />
                          )}
                          {slot.limit > 0 && (
                            <FormattedMessage
                              id="TimeChunk.spots_remaining"
                              values={{ number: slot.limit - slot.count }}
                            />
                          )}
                        </>
                      )}
                    </span>
                  </div>
                ) : (
                  formattedTime
                )}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

TimeChunk.propTypes = {
  bordered: PropTypes.bool,
  different: PropTypes.bool,
  group: PropTypes.bool,
  handleClickTime: PropTypes.func.isRequired,
  id: PropTypes.oneOf(['morning', 'afternoon', 'evening', 'location']),
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  slots: PropTypes.arrayOf(
    PropTypes.shape({
      end: SpacetimeShape,
      start: SpacetimeShape,
    }),
  ).isRequired,
  specificUser: PropTypes.bool,
  subtitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

TimeChunk.defaultProps = {
  bordered: false,
  different: false,
  group: false,
  id: 'morning',
  label: false,
  specificUser: false,
  subtitle: null,
};

export default TimeChunk;
