import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const ForwardChevron = ({ altText, className, ariaHidden }) => {
  const intl = useIntl();

  return (
    <svg
      aria-hidden={ariaHidden ? 'true' : 'false'}
      className={className}
      height="24"
      style={{ fill: 'currentColor' }}
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.forward_chevron' })}
      </title>
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

ForwardChevron.propTypes = {
  altText: PropTypes.string,
  className: PropTypes.string,
  ariaHidden: PropTypes.bool,
};

ForwardChevron.defaultProps = {
  altText: '',
  ariaHidden: false,
  className: '',
};

export default ForwardChevron;
