import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Button from './Button';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    position: 'relative',
  },
  anchor: {
    background: 'none',
    border: 'none',
    borderBottom: `1px dotted ${theme.palette.secondary[400]}`,
    display: 'inline-block',
    minWidth: 0,
    padding: 0,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '& $buttonText': {
      textTransform: 'none',
    },
    '&:hover': {
      borderBottomColor: theme.palette.secondary[500],
      color: theme.palette.secondary[500],
    },
  },
  button: {
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    color: theme.palette.secondary[400],
    fontWeight: theme.fontWeights.boldWeight,
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    '&:focus': {
      outline: 0,
      boxShadow: theme.shadows.input,
      borderColor: theme.palette.secondary[500],
      borderBottomColor: theme.palette.secondary[500],
      color: theme.palette.secondary[500],
      fontWeight: theme.fontWeights.boldWeight,
    },
  },
  open: {
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
  options: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.16)',
    margin: '0.25rem 0 0 0',
    maxWidth: '21rem',
    minWidth: '160%',
    overflow: 'auto',
    padding: '0.25rem 0',
    position: 'absolute',
    right: '0',
    top: '100%',
    zIndex: 1,
  },
}));

const ListboxPicker = ({ label, mobileOnClick, onChange, options, value }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const mode = useContext(ViewModeContext);

  return mode === MOBILE ? (
    <Button fullWidth={false} onClick={mobileOnClick} variant="anchor">
      {label}
    </Button>
  ) : (
    <span className={classes.buttonContainer}>
      <Listbox as="span" onChange={onChange} value={value}>
        {({ open }) => (
          <span className={classes.root}>
            <Listbox.Button className={classes.button} role="button">
              <Typography
                classes={{ root: classes.anchor }}
                role="link"
                variant="caption1_inherit"
              >
                {label}
              </Typography>
            </Listbox.Button>
            <Listbox.Options as="span">
              <span
                className={classNames(
                  open ? classes.open : classes.hidden,
                  classes.options,
                )}
              >
                {options}
              </span>
            </Listbox.Options>
          </span>
        )}
      </Listbox>
    </span>
  );
};

ListboxPicker.propTypes = {
  label: PropTypes.node.isRequired,
  mobileOnClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.node).isRequired,
  value: PropTypes.string.isRequired,
};

export default ListboxPicker;
