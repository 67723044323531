import spacetime from 'spacetime';
import { ENGLISH } from '../../frontend/constants';

export const WEEKDAY_SHORTER = 1;
export const WEEKDAY_LONGER = 2;

export const MINUTES_IN_DAY = 1440;
export const NUMBER_OF_WEEKDAYS = 7;
export const DAYS_IN_A_YEAR = 365;
export const MONTHS_IN_A_YEAR = 12;

const LOCALES = {
  en: {
    days: {
      long: [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ],
      short: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
    },
    formats: {
      dateMonthYear: 'MMMM dd, yyyy',
      human: 'EEEE, MMMM dd, yyyy - h:mm a',
      // This format with a , is for voice readers to read the time properly.
      // TODO Consolidate accessibleHuman with human
      accessibleHuman: 'EEEE, MMMM dd, yyyy, h:mm a',
      month: 'MMMM',
      monthYear: 'MMMM yyyy',
      weekdayDateMonthYear: 'EEEE, MMMM dd, yyyy',
    },
    months: {
      long: [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
      ],
      short: [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sept',
        'oct',
        'nov',
        'dec',
      ],
    },
    times: {
      short: 'h:mm a',
    },
  },
  es: {
    days: {
      long: [
        'domingo',
        'lunes',
        'martes',
        'miércoles',
        'jueves',
        'viernes',
        'sábado',
      ],
      short: ['dom.', 'lun.', 'mar.', 'mié.', 'jue.', 'vie.', 'sáb.'],
    },
    formats: {
      dateMonthYear: "dd 'de' MMMM 'de' yyyy",
      human: "EEEE, dd 'de' MMMM 'de' yyyy - HH:mm",
      accessibleHuman: "EEEE, dd 'de' MMMM 'de' yyyy - HH:mm",
      month: 'MMMM',
      monthYear: "MMMM 'de' yyyy",
      weekdayDateMonthYear: "EEEE, dd 'de' MMMM 'de' yyyy",
    },
    months: {
      long: [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ],
      short: [
        'enero',
        'feb',
        'marzo',
        'abr',
        'mayo',
        'jun',
        'jul',
        'agosto',
        'sept',
        'oct',
        'nov',
        'dic',
      ],
    },
    times: {
      short: 'HH:mm',
    },
  },
  fr: {
    days: {
      long: [
        'dimanche',
        'lundi',
        'mardi',
        'mercredi',
        'jeudi',
        'vendredi',
        'samedi',
      ],
      short: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
    },
    formats: {
      dateMonthYear: 'dd MMMM yyyy',
      human: "EEEE dd MMMM yyyy - HH 'h' mm",
      accessibleHuman: "EEEE dd MMMM yyyy - HH 'h' mm",
      month: 'MMMM',
      monthYear: 'MMMM yyyy',
      weekdayDateMonthYear: 'EEEE dd MMMM yyyy',
    },
    months: {
      long: [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ],
      short: [
        'janv',
        'févr',
        'mars',
        'avr',
        'mai',
        'juin',
        'juill',
        'août',
        'sept',
        'oct',
        'nov',
        'déc',
      ],
    },
    times: {
      short: "HH 'h' mm",
    },
  },
  ko: {
    days: {
      long: [
        '일요일',
        '월요일',
        '화요일',
        '수요일',
        '목요일',
        '금요일',
        '토요일',
      ],
      short: ['일', '월', '화', '수', '목', '금', '토'],
    },
    formats: {
      dateMonthYear: 'YYYY년 MMMM DD일',
      human: 'EEEE, YYYY년 MMMM DD일 A - h:mm',
      accessibleHuman: 'EEEE, YYYY년 MMMM DD일 A - h:mm',
      month: 'MMMM',
      monthYear: 'MMMM yyyy',
      weekdayDateMonthYear: 'EEEE, YYYY년 MMMM DD일',
    },
    months: {
      long: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월',
      ],
      short: [
        '1월',
        '2월',
        '3월',
        '4월',
        '5월',
        '6월',
        '7월',
        '8월',
        '9월',
        '10월',
        '11월',
        '12월',
      ],
    },
    times: {
      short: 'A h:mm',
    },
  },
  pl: {
    days: {
      long: [
        'niedziela',
        'poniedziałek',
        'wtorek',
        'środa',
        'czwartek',
        'piątek',
        'sobota',
      ],
      short: ['ndz', 'pon', 'wt', 'śr', 'czw', 'pt', 'sob'],
    },
    formats: {
      dateMonthYear: 'DD MMMM YYYY',
      human: 'EEEE, DD MMMM YYYY - HH:mm',
      accessibleHuman: 'EEEE, DD MMMM YYYY - HH:mm',
      month: 'MMMM',
      monthYear: 'MMMM yyyy',
      weekdayDateMonthYear: 'EEEE, DD MMMM YYYY',
    },
    months: {
      long: [
        'styczeń',
        'luty',
        'marzec',
        'kwiecień',
        'maj',
        'czerwiec',
        'lipiec',
        'sierpień',
        'wrzesień',
        'październik',
        'listopad',
        'grudzień',
      ],
      short: [
        'sty',
        'lut',
        'mar',
        'kwi',
        'maj',
        'cze',
        'lip',
        'sie',
        'wrz',
        'paź',
        'lis',
        'gru',
      ],
    },
    times: {
      short: 'HH:mm',
    },
  },
  pt: {
    days: {
      long: [
        'domingo',
        'segunda-feira',
        'terça-feira',
        'quarta-feira',
        'quinta-feira',
        'sexta-feira',
        'sábado',
      ],
      short: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    },
    formats: {
      dateMonthYear: 'DD de MMMM de YYYY',
      human: 'EEEE, DD de MMMM de YYYY - HH:mm',
      accessibleHuman: 'EEEE, DD de MMMM de YYYY - HH:mm',
      month: 'MMMM',
      monthYear: 'MMMM yyyy',
      weekdayDateMonthYear: 'EEEE DD de MMMM de YYYY',
    },
    months: {
      long: [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
      ],
      short: [
        'jan',
        'fev',
        'mar',
        'abr',
        'mai',
        'jun',
        'jul',
        'ago',
        'set',
        'out',
        'nov',
        'dez',
      ],
    },
    times: {
      short: 'HH:mm',
    },
  },
  ru: {
    days: {
      long: [
        'воскресенье',
        'понедельник',
        'вторник',
        'среда',
        'четверг',
        'пятница',
        'суббота',
      ],
      short: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
    },
    formats: {
      dateMonthYear: 'DD MMMM YYYY г.',
      human: 'EEEE, DD MMMM YYYY г. - H:mm',
      accessibleHuman: 'EEEE, DD MMMM YYYY г. - H:mm',
      month: 'MMMM',
      monthYear: 'MMMM YYYY',
      weekdayDateMonthYear: 'EEEE DD MMMM YYYY г.',
    },
    months: {
      long: [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
      ],
      short: [
        'янв.',
        'февр.',
        'мар.',
        'апр.',
        'мая',
        'июня',
        'июля',
        'авг.',
        'сент.',
        'окт.',
        'нояб.',
        'дек.',
      ],
    },
    times: {
      short: 'H:mm',
    },
  },
  zh: {
    days: {
      long: [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ],
      short: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
    },
    formats: {
      dateMonthYear: 'yyyy年MMMM月dd日',
      human: 'EEEE, yyyy年MMMM月dd日 - Ah点mm分',
      accessibleHuman: 'EEEE, yyyy年MMMM月dd日 - Ah点mm分',
      month: 'MMMM',
      monthYear: 'MMMM yyyy',
      weekdayDateMonthYear: 'EEEE yyyy年MMMM月dd日',
    },
    months: {
      long: [
        '一月',
        '二月',
        '三月',
        '四月',
        '五月',
        '六月',
        '七月',
        '八月',
        '九月',
        '十月',
        '十一月',
        '十二月',
      ],
      short: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
    },
    times: {
      short: 'HH:mm',
    },
  },
};

export const WEEKDAY_STARTS = {
  en: 'sunday',
  es: 'lunes',
  fr: 'lundi',
  ko: '일요일',
  pl: 'poniedziałek',
  pt: 'domingo',
  ru: 'воскресенье',
  zh: '星期日',
};

export function localeForMoment(locale) {
  if (locale === 'zh') {
    return 'zh-cn';
  }
  if (locale === 'pt') {
    return 'pt-br';
  }
  return locale;
}

export default {
  /**
   * @param {Spacetime} date
   * @param {number} value
   * @param {TimeUnit} units
   *
   * @returns {Spacetime}
   */
  add(date, value, units) {
    return date.add(value, units);
  },

  /**
   * @param {Spacetime} date
   * @param {number} value
   *
   * @returns {Spacetime}
   */
  addMinutes(date, value) {
    return this.add(date, value, 'minute');
  },

  /**
   * @param {Spacetime} start
   * @param {Spacetime} end
   *
   * @returns {Spacetime[]}
   */
  calendarize(start, end) {
    let dates = [];
    let initial = this.startOfWeek(start);

    const final = end.next('day');
    const difference = Math.ceil(
      initial.diff(final, 'day') / NUMBER_OF_WEEKDAYS,
    );

    for (let i = 0; i < difference; i += 1) {
      let week = [];

      for (let o = 0; o < NUMBER_OF_WEEKDAYS; o += 1) {
        week = [...week, initial];

        initial = initial.next('day');
      }

      dates = [...dates, week];
    }

    return dates;
  },

  /**
   * @param {string} language
   *
   * @returns {string}
   */
  dayName(language = ENGLISH) {
    return LOCALES[language].days.long[this.today().day()];
  },

  /**
   * @param {number} index
   *
   * @returns {string}
   */
  dayOfWeek(index) {
    const weekdays = this.weekdays(WEEKDAY_LONGER);

    return weekdays[index];
  },

  /**
   * @param {Spacetime} start
   * @param {Spacetime} end
   *
   * @return {Number}
   */
  diffMinutes(start, end) {
    return end.diff(start, 'minute');
  },

  /**
   * @param {Spacetime|null} date
   *
   * @returns {Spacetime}
   */
  endOfMonth(date = null) {
    return spacetime(date).endOf('month');
  },

  /**
   * @param {Spacetime|null} date
   *
   * @returns {Spacetime}
   */
  endOfWeek(date = null) {
    return spacetime(date)
      .weekStart(WEEKDAY_STARTS[this.lang] || WEEKDAY_STARTS.en)
      .endOf('week');
  },

  /**
   * @param {number} minutes
   *
   * @returns {Object}
   */
  formatMinutes(minutes) {
    const diff = this.now().diff(this.now().add(minutes, 'minutes'));

    if (diff.hours === 0) {
      return { duration: diff.minutes, id: 'Duration.minutes' };
    }

    if (diff.hours <= 48) {
      return { duration: diff.hours, id: 'Duration.hours' };
    }

    if (diff.days < 7) {
      return { duration: diff.days, id: 'Duration.days' };
    }

    return { duration: diff.weeks, id: 'Duration.weeks' };
  },

  /**
   * @param {number} minutes
   * @param {string} targetUnit
   *
   * @returns {number}
   */
  formatToUnit(value, unit = 'minute', targetUnit = 'month') {
    const diff = this.now().diff(this.now().add(value, unit));

    if (targetUnit === 'minute') {
      return diff.minutes;
    } else if (targetUnit === 'hour') {
      return diff.hours;
    } else if (targetUnit === 'day') {
      return diff.days;
    } else if (targetUnit === 'week') {
      return diff.weeks;
    } else if (targetUnit === 'month') {
      return diff.months;
    } else if (targetUnit === 'year') {
      return diff.years;
    } else {
      return value;
    }
  },

  /**
   * Constructs a human readable formatted string using the provided date/time.
   *
   * @param {Spacetime|string} date
   *
   * @returns {string}
   */
  humanReadable(date) {
    return this.toFormat(date, 'human');
  },

  /**
   * Constructs a accessible human readable formatted string using the provided date/time.
   *
   * @param {Spacetime|string} date
   *
   * @returns {string}
   */
  accessibleHumanReadable(date) {
    return this.toFormat(date, 'accessibleHuman');
  },

  /**
   * @param {Spacetime} date
   * @param {String|null} timezone
   *
   * @return {Boolean}
   */
  isFuture(date, timezone = null) {
    return date.isAfter(this.now(timezone));
  },

  /**
   * Determine if the current language usually would
   * display Monday as the first day of the week.
   *
   * @returns {boolean}
   */
  isIsoLocale() {
    const locales = ['es', 'fr', 'ko', 'pl', 'pt', 'ru', 'zh'];

    return locales.includes(this.lang);
  },

  /**
   * @param {string} locale
   *
   * @returns {undefined}
   */
  locale(locale) {
    this.lang = locale;

    spacetime().i18n(LOCALES[locale] || LOCALES.en);
  },

  /**
   * @param {String} timezone
   *
   * @return {Spacetime}
   */
  now(timezone = null) {
    return spacetime.now(timezone);
  },

  /**
   * @param {string} timestamp
   * @param {string|null} timezone
   *
   * @returns {Spacetime}
   */
  parse(timestamp, timezone = null) {
    let date = spacetime(timestamp);

    if (timezone) {
      date = date.goto(timezone);
    }

    return date;
  },

  /**
   * @param {Spacetime|null} date
   *
   * @returns {Spacetime}
   */
  startOfMonth(date = null) {
    return spacetime(date).startOf('month');
  },

  /**
   * @param {Spacetime|null} date
   *
   * @returns {Spacetime}
   */
  startOfWeek(date = null) {
    return spacetime(date)
      .weekStart(WEEKDAY_STARTS[this.lang] || WEEKDAY_STARTS.en)
      .startOf('week');
  },

  /**
   * Constructs a Spacetime object, with the time set by the provided time string.
   *
   * @param {Spacetime|string} time
   * @param {boolean} format
   *
   * @returns {Spacetime|string}
   */
  time(time, format = false) {
    let date = time;

    if (typeof date === 'string') {
      date = spacetime().time(time);
    }

    if (format) {
      date = date.unixFmt(LOCALES[this.lang || 'en'].times.short);
    }

    return date;
  },

  /**
   * @param {Spacetime|string} date
   *
   * @returns {string}
   */
  toDateMonthYear(date) {
    return this.toFormat(date, 'dateMonthYear');
  },

  /**
   * @param {string} date
   * @param {string|null} timezone
   *
   * @return {string}
   */
  toDateTimeString(date, timezone = null) {
    return this.unixFmt(this.parse(date, timezone), 'yyyy-MM-dd HH:mm:ss');
  },

  /**
   * @param {DateObj} date
   * @param {string|null} timezone
   *
   * @return {string}
   */
  toDateString(date, timezone = null) {
    if (!date) {
      return '';
    }

    return this.unixFmt(this.parse(date, timezone), 'yyyy-MM-dd');
  },

  /**
   * @param {Spacetime|string} date
   *
   * @returns {string}
   */
  toMonth(date) {
    return this.toFormat(date, 'month');
  },

  /**
   * @param {Spacetime|string} date
   *
   * @returns {string}
   */
  toWeekdayDateMonthYear(date) {
    return this.toFormat(date, 'weekdayDateMonthYear');
  },

  /**
   * @param {Spacetime|string} date
   * @param {string} format
   *
   * @returns {string}
   */
  toFormat(date, format) {
    let formatted = date;

    if (typeof date === 'string') {
      formatted = spacetime(date);
    }

    let translated = formatted.unixFmt(
      LOCALES[this.lang || 'en'].formats[format],
    );

    // For the time being, because Spacetime automatically title-cases date formats,
    // we will need to manually lower case french and spanish translations :(
    if (this.isIsoLocale()) {
      translated = translated.toLowerCase();
    }

    return translated;
  },

  /**
   * @param {Spacetime|string} date
   *
   * @returns {string}
   */
  toMonthYear(date) {
    return this.toFormat(date, 'monthYear');
  },

  /**
   * @returns {Spacetime}
   */
  today() {
    return spacetime.today();
  },

  /**
   * @param {Spacetime} date
   * @param {string} format
   *
   * @returns {string}
   */
  unixFmt(date, format) {
    return date.unixFmt(format);
  },

  /**
   * @returns {string[]}
   */
  weekdays(format = WEEKDAY_SHORTER) {
    let weekdays = [];
    let beginning = this.startOfWeek();
    const lengths = { [WEEKDAY_SHORTER]: 2 };

    for (let i = 0; i < NUMBER_OF_WEEKDAYS; i += 1) {
      let day = beginning.format(
        format === WEEKDAY_SHORTER ? 'day-short' : 'day',
      );

      if (this.isIsoLocale()) {
        day = day.toLowerCase();
      }

      weekdays = [
        ...weekdays,
        format > WEEKDAY_LONGER ? day.substr(0, lengths[format]) : day,
      ];

      beginning = beginning.next('day');
    }

    return weekdays;
  },

  isBetween(start = null, end = null, date = this.today()) {
    return start.epoch <= date.epoch && date.epoch <= end.epoch;
  },
};
