import { Tooltip } from '@coconut-software/ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { SettingsContext } from '../../contexts/SettingsContext';
import Strings from '../../helpers/Strings';
import AccountCircle from '../icons/AccountCircle';
import Typography from '../Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    padding: '0 1.25rem',
    '&:last-child $content': {
      borderBottom: '0 none',
    },
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    '&:focus-visible': {
      outline: `2px solid ${theme.palette.secondary[400]}`,
      outlineOffset: '-2px',
    },
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: '0.75rem 0',
    cursor: 'pointer',
    width: '100%',
    '&:focus': {
      '& .adornment': {
        backgroundColor: theme.palette.secondary[100],
      },
      outline: 0,
    },
    '&:hover': {
      '& .adornment': {
        backgroundColor: theme.palette.secondary[100],
      },
    },
  },
  contentNoSecondary: {
    padding: '1rem 0',
    minHeight: '4.25rem',
  },
  itemText: {
    textAlign: 'left',
  },
  primary: {
    display: 'block',
    maxWidth: '31.25rem',
  },
  withMargin: {
    marginBottom: '0.25rem',
  },
  secondary: {
    marginTop: '0.25rem',
  },
  title: {
    alignItems: 'flex-start',
    display: 'flex',
  },
  image: {
    width: '2.5rem',
    height: '2.5rem',
    minWidth: '2.5rem',
    minHeight: '2.5rem',
    marginRight: '1rem',
    fill: theme.palette.neutral[200],
    borderRadius: '50%',
  },
}));

const ListItem = ({
  action,
  adornment,
  badges,
  className,
  id,
  primary,
  secondary,
  imageUrl,
  ariaLabel,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const { showStaffPhotos } = useContext(SettingsContext);
  const [imageError, setImageError] = useState(false);

  const getAccountImage = () => {
    const accountCircle = <AccountCircle className={classes.image} />;
    const image = imageError ? (
      accountCircle
    ) : (
      <img
        alt="profile"
        className={classes.image}
        onError={() => setImageError(true)}
        src={imageUrl}
      />
    );

    return !imageUrl ? accountCircle : image;
  };

  return (
    <li>
      <div
        aria-label={ariaLabel}
        className={classNames(classes.root, className)}
        data-id={id}
        onClick={action}
        onKeyPress={action}
        role="button"
        tabIndex={0}
      >
        {showStaffPhotos ? getAccountImage() : null}
        <div
          className={classNames(
            classes.content,
            !secondary && classes.contentNoSecondary,
          )}
        >
          <div className={classes.itemText}>
            <div className={classes.title}>
              <Typography
                classes={{
                  root: classNames(
                    classes.primary,
                    secondary && classes.withMargin,
                  ),
                }}
                variant="title"
              >
                {primary}
              </Typography>
            </div>
            {secondary ? (
              <Typography variant="caption1">{secondary}</Typography>
            ) : null}
            {badges ? (
              <div className="flex flex-wrap py-1">
                {badges.map((badge) => (
                  <Tooltip
                    key={badge.label}
                    placement="bottom"
                    title={badge.tooltip}
                  >
                    <div className="rounded mb-0.5 mr-1 bg-black-10 p-1 text-xs">
                      {Strings.uppercaseFirst(badge.label)}
                    </div>
                  </Tooltip>
                ))}
              </div>
            ) : null}
          </div>
          {adornment}
        </div>
      </div>
    </li>
  );
};

ListItem.propTypes = {
  action: PropTypes.func,
  adornment: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  badges: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ariaLabel: PropTypes.string,
};

ListItem.defaultProps = {
  action: () => {},
  adornment: null,
  ariaLabel: 'List Item',
  badges: null,
  className: '',
  imageUrl: null,
  secondary: null,
};

export default ListItem;
