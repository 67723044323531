import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import MultiPageServiceLayout from '../../components/mobile/MultiPageServiceLayout';
import SinglePageServiceLayout from '../../components/mobile/SinglePageServiceLayout';
import { HeaderContext } from '../../contexts/HeaderContext';
import { ServiceSearchProvider } from '../../contexts/ServiceSearchContext';
import { SettingsContext } from '../../contexts/SettingsContext';

const Service = ({
  categories,
  currentStep,
  expanded,
  firstCategory,
  loading,
  previous,
  previousStep,
  selectService,
  services,
  stepsCount,
  toggleDescription,
}) => {
  const [, setHeader] = useContext(HeaderContext);
  const { serviceStepLayout } = useContext(SettingsContext);

  const setPageHeader = (category, setCategory) => {
    const action = currentStep !== 0 ? previous : null;
    const back = () => {
      setCategory(null);
    };

    setHeader({
      action: category ? back : action,
      title: <FormattedMessage id="Steps.services" />,
      previousStep,
    });
  };

  const props = {
    categories,
    currentStep,
    expanded,
    firstCategory,
    loading,
    previous,
    previousStep,
    selectService,
    services,
    setPageHeader,
    stepsCount,
    toggleDescription,
  };

  if (serviceStepLayout) {
    return (
      <ServiceSearchProvider services={services}>
        <SinglePageServiceLayout {...props} />
      </ServiceSearchProvider>
    );
  }
  return <MultiPageServiceLayout {...props} />;
};

Service.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  currentStep: PropTypes.number.isRequired,
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstCategory: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  loading: PropTypes.bool,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
  stepsCount: PropTypes.number.isRequired,
  toggleDescription: PropTypes.func,
};

Service.defaultProps = {
  categories: [],
  loading: true,
  previousStep: null,
  services: [],
};

export default Service;
