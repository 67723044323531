import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import BookingValidationErrorModal from '../../components/BookingValidationErrorModal';
import RescheduleConfirmation from '../../components/desktop/RescheduleConfirmation';
import { LANGUAGES, USER_PREFERENCE_OPTIONS } from '../../constants';
import { FeatureContext } from '../../contexts/FeatureContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { UsersContext } from '../../contexts/UsersContext';
import { DESKTOP } from '../../contexts/ViewModeContext';
import RandomUserTime from '../../steps/desktop/RandomUserTime';

const useStyles = createUseStyles({
  step: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 1,
  },
});

const Reschedule = ({
  anchor,
  confirmContent,
  confirmOpen,
  errorMessage,
  errorOpen,
  exclusion,
  handleClear,
  handleClose,
  handleConfirm,
  rescheduleLoading,
  step,
  ...props
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [, setSelections] = useContext(SelectionContext);
  const { supportedLanguages, supportedLanguagesTranslations } =
    useContext(UsersContext);
  const { spokenLanguages } = useContext(FeatureContext);
  const [locale] = useContext(LocaleContext);
  const [initialLoad, setInitialLoad] = useReducer(
    (state, newState) => newState,
    true,
  );

  const assemblePreferences = (languages) => {
    const [random] = USER_PREFERENCE_OPTIONS;
    const preferences = [];

    if (languages.length > 0) {
      preferences.push(...languages);
    } else {
      preferences.push(random);
    }

    return preferences;
  };

  let languages;
  if (spokenLanguages) {
    languages =
      supportedLanguages?.length > 1
        ? supportedLanguages.map((language) => ({
            id: language,
            subtitle: 'UserPreference.assign_me_subtitle',
            testId: `user-preference-${language}`,
            title: 'UserPreference.assign_me_title',
            values: {
              language: supportedLanguagesTranslations[locale][language],
            },
          }))
        : [];
  } else {
    languages =
      supportedLanguages?.length > 1
        ? supportedLanguages.map((abbr) => ({
            id: abbr,
            subtitle: 'UserPreference.assign_me_subtitle',
            testId: `user-preference-${abbr}`,
            title: 'UserPreference.assign_me_title',
            values: {
              language: intl.formatMessage({
                id: `Languages.${LANGUAGES[abbr].toLowerCase()}`,
              }),
            },
          }))
        : [];
  }
  const preferences = assemblePreferences(languages);

  useEffect(() => {
    if (initialLoad && preferences.length > 0) {
      setInitialLoad(false);
      setSelections({ userPreference: { id: preferences[0].id } });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences]);

  return (
    <>
      <main className={classes.step}>
        <RandomUserTime
          exclusion={exclusion}
          mode={DESKTOP}
          preferences={preferences}
          showBack
          {...props}
        />
      </main>
      <RescheduleConfirmation
        anchor={anchor}
        confirmContent={confirmContent}
        handleClear={handleClear}
        handleConfirm={handleConfirm}
        loading={rescheduleLoading}
        open={confirmOpen}
      />
      <BookingValidationErrorModal
        errorMessage={errorMessage}
        handleClose={handleClose}
        open={errorOpen}
        step={step}
      />
    </>
  );
};

Reschedule.propTypes = {
  anchor: PropTypes.instanceOf(Element),
  confirmContent: PropTypes.element.isRequired,
  confirmOpen: PropTypes.bool.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorOpen: PropTypes.bool.isRequired,
  exclusion: PropTypes.string,
  handleConfirm: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  previousStep: PropTypes.string.isRequired,
  rescheduleLoading: PropTypes.bool.isRequired,
  step: PropTypes.shape({
    title: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }),
};

Reschedule.defaultProps = {
  anchor: null,
  errorMessage: null,
  exclusion: null,
};

export default Reschedule;
