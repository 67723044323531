import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Tooltip from './Tooltip';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[100],
    borderRadius: theme.borderRadius.default,
    marginLeft: '0.25rem',
    position: 'relative',
    padding: '0.175rem 0.375rem',
    '&:hover > $tooltip': {
      display: 'block',
    },
  },
  tooltip: {
    display: 'none',
  },
}));

const Badge = ({ children, tooltip, ...rest }) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <Typography classes={{ root: classes.root }} variant="body1" {...rest}>
      <>
        {children}
        {tooltip ? (
          <Tooltip classes={{ root: classes.tooltip }}>{tooltip}</Tooltip>
        ) : null}
      </>
    </Typography>
  );
};

Badge.propTypes = {
  children: PropTypes.string.isRequired,
  tooltip: PropTypes.element,
};

Badge.defaultProps = {
  tooltip: null,
};

export default Badge;
