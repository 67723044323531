import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { SettingsContext } from '../contexts/SettingsContext';
import { Anchor } from './Anchor';
import CoconutLogo from './CoconutLogo';
import Typography from './Typography';

const useStyles = createUseStyles({
  root: {
    paddingTop: '1.25rem',
  },
  link: {
    textDecoration: 'none',
  },
});

const Footer = ({ classes: injectedClasses, component }) => {
  const classes = { ...useStyles(), ...injectedClasses };

  const { privateLabel } = useContext(SettingsContext);
  // privateLabel is undefined on initial render which is falsy,
  // so we must use exactly false to not display it until we have the value
  const showLogo = privateLabel === false;

  return showLogo ? (
    <footer className={classes.root} data-testid="sidebar-footer">
      <Anchor
        external
        href="https://www.coconutsoftware.com/?utm_source=coconut-product&utm_medium=coconut-client-view&utm_campaign=coconut-logo"
        type="area"
        variant="title"
      >
        <Typography component={component}>
          <FormattedMessage id="Sidebar.footer" />
        </Typography>
        <CoconutLogo />
      </Anchor>
    </footer>
  ) : null;
};

Footer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  component: PropTypes.string,
};

Footer.defaultProps = {
  classes: {},
  component: 'p',
};

export default Footer;
