import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  button: {
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: theme.palette.transparent,
    border: 'none',
    borderLeft: '0.125rem solid transparent',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: '0.625rem 1.125rem',
    width: '100%',
    textAlign: 'left',
    '&:focus': {
      background: theme.palette.secondary.A500,
    },
  },
  categories: {
    borderRight: `1px solid ${theme.palette.neutral[200]}`,
    flexShrink: 0,
    minWidth: '12rem',
    maxWidth: '15.625rem',
    width: '25%',
  },
  name: {},
  subtitle: {
    padding: '1.25rem 1.25rem 0',
    marginBottom: '0.625rem',
  },
  selected: {
    background: theme.palette.secondary.A400,
    borderColor: theme.palette.secondary[400],
  },
}));

const CategoryList = ({ categories, selectCategory, selectedCategory }) => {
  const classes = useStyles({ theme: useTheme });
  const setCategory = ({
    currentTarget: {
      dataset: { id },
    },
  }) => selectCategory(id);

  return (
    <section className={classes.categories}>
      <section className={classes.subtitle}>
        <Typography id="category-list-category-listbox-title" variant="body1">
          <FormattedMessage id="Service.category_title" />
        </Typography>
      </section>
      <div
        aria-labelledby="category-list-category-listbox-title"
        role="listbox"
      >
        {categories.map((category) => (
          <button
            aria-selected={category.id === selectedCategory.id}
            className={classNames(
              category.id === selectedCategory.id && classes.selected,
              classes.button,
            )}
            data-id={category.id}
            key={category.id}
            onClick={setCategory}
            role="option"
            type="button"
          >
            <Typography classes={{ root: classes.name }} variant="title">
              {category.name}
            </Typography>
          </button>
        ))}
      </div>
    </section>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  selectCategory: PropTypes.func,
  selectedCategory: PropTypes.shape({ id: PropTypes.string }),
};

export default CategoryList;
