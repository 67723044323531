import PropTypes from 'prop-types';
import React from 'react';
import { useCxSnippets } from '../contexts/CxSnippetContext';

const CxSnippet = ({ targetId, fallback }) => {
  const { [targetId]: cxSnippet } = useCxSnippets();

  return cxSnippet || cxSnippet === '' ? (
    <span dangerouslySetInnerHTML={{ __html: cxSnippet }} />
  ) : (
    fallback
  );
};

CxSnippet.propTypes = {
  targetId: PropTypes.string.isRequired,
  fallback: PropTypes.element,
};

CxSnippet.defaultProps = {
  fallback: null,
};

export default CxSnippet;
