const Countries = (client) => ({
  /**
   * @returns {Promise<any>}
   */
  all() {
    return client.get(`/api/v2/open/countries?lang=${client.lang}`);
  },

  /**
   * @returns {Promise<any>}
   */
  find() {
    return client.get('https://ipinfo.io/json');
  },
});

export default Countries;
