import React, { useContext } from 'react';
import { Step, Steps, Wizard } from 'react-albus-react18';
import mode from '../../shared/helpers/Mode';
import Sidebar from '../components/Sidebar';
import { STEPS } from '../constants';
import {
  DESKTOP,
  MOBILE,
  TABLET,
  ViewModeContext,
} from '../contexts/ViewModeContext';
import CheckInStep from '../steps/CheckIn';

const CheckIn = () => {
  const viewMode = useContext(ViewModeContext);

  return (
    <Wizard history={null} onNext={(x) => x.replace(STEPS.CHECK_IN)}>
      <section
        className={`flex flex-row flex-grow ${
          viewMode === TABLET
            ? mode.isEmbedded()
              ? 'h-screen'
              : 'h-full-minus-header'
            : ''
        }`}
        style={{
          backgroundColor: `${viewMode === MOBILE ? 'white' : 'inherit'}`,
        }}
      >
        {viewMode === DESKTOP && (
          <aside
            className={`flex flex-col sticky mr-5 w-66 max-w-66 min-w-66 ${
              mode.isEmbedded()
                ? 'h-screen top-0'
                : 'min-h-full-minus-header top-header'
            }`}
          >
            <Sidebar />
          </aside>
        )}
        <main className="flex flex-col flex-grow">
          <Steps>
            <Step
              id={STEPS.LOCATION}
              key={STEPS.LOCATION}
              render={() => null}
            />
            <Step
              id={STEPS.CHECK_IN}
              key={STEPS.CHECK_IN}
              render={CheckInStep}
            />
          </Steps>
        </main>
      </section>
    </Wizard>
  );
};

export default CheckIn;
