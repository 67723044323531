export default {
  /**
   * @param {string} query
   *
   * @return {Object<string, string|number>}
   */
  params(query) {
    return query
      .slice(1)
      .split('&')
      .filter(Boolean)
      .reduce((accumulator, value) => {
        const pair = value.split('=');

        accumulator[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);

        return accumulator;
      }, {});
  },

  /**
   * @param {string} base
   * @param {Object} filters
   * @param {Array} sort
   *
   * @returns {string}
   */
  url(base, filters, sort = []) {
    if (Object.keys(filters).length === 0 && sort.length === 0) {
      return base;
    }

    let url = `${base}?`;
    let first = true;

    Object.keys(filters).forEach((key) => {
      if (!first) {
        url += '&';
      }
      url += `filter[${key}]=${filters[key]}`;
      first = false;
    });

    if (sort.length > 0) {
      if (!first) {
        url += '&';
      }
      url += `sort=${sort.join(',')}`;
      first = false;
    }

    return url;
  },
};
