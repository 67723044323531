import { useContext, useEffect, useState } from 'react';
import { MEETING_METHODS, USER_PREFERENCE } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import Api from '../helpers/Api';

/**
 * useLobbyAvailability
 * @param {{ enabled: boolean, waitTime: boolean }} options
 */
export const useLobbyAvailability = (options = {}) => {
  const [result, setResult] = useState({
    available: false,
    waitTime: 0,
    isFetching: false,
    isError: false,
  });
  const [{ userPreference, location, meetingMethod, service }] =
    useContext(SelectionContext);

  const preference = userPreference?.id;
  const language =
    preference === USER_PREFERENCE.SPECIFIC ||
    preference === USER_PREFERENCE.RANDOM
      ? null
      : preference;

  const method =
    meetingMethod !== null ? meetingMethod : MEETING_METHODS.AT_LOCATION;
  const includeWaitTime =
    typeof options?.waitTime === 'boolean' ? options.waitTime : false;
  const enabled = !!(
    (typeof options?.enabled === 'boolean' ? options.enabled : true) &&
    location?.id &&
    service?.id
  );

  useEffect(() => {
    if (enabled) {
      setResult((r) => ({ ...r, isFetching: true }));
      Api.lobbyAvailability()
        .all({
          location: location?.id,
          service: service?.id,
          meetingMethod: method,
          language,
          includeWaitTime,
        })
        .then((data) => {
          setResult((r) => ({
            ...r,
            available: data?.available || false,
            waitTime: data?.wait_time || 0,
            isFetching: false,
            isError: false,
          }));
        })
        .catch(() => {
          setResult((r) => ({
            ...r,
            available: false,
            waitTime: 0,
            isFetching: false,
            isError: true,
          }));
        });
    }
  }, [
    includeWaitTime,
    enabled,
    language,
    location?.id,
    method,
    service?.id,
    setResult,
  ]);

  return result;
};
