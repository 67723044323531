import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { LocaleContext } from '../contexts/LocaleContext';
import { Anchor } from './Anchor';

const useStyles = createUseStyles((theme) => ({
  root: {
    borderBottom: `1px dotted ${theme.palette.white}`,
    textDecoration: 'none',
    '& span': {
      color: theme.palette.white,
      padding: '2px',
    },
    '&:hover': {
      borderBottomColor: theme.palette.neutral[200],
    },
    '&:focus-visible': {
      border: 0,
      outline: `1px white solid`,
    },
  },
}));

const CookieNoticeLearnMore = () => {
  const classes = useStyles({ theme: useTheme() });
  const [locale] = useContext(LocaleContext);

  const href =
    locale === 'fr'
      ? 'https://www.coconutsoftware.com/politique-de-confidentialite/'
      : 'https://www.coconutsoftware.com/privacy-policy/';

  return (
    <Anchor className={classes.root} external href={href} variant="regular">
      <FormattedMessage id="CookieNotice.learn_more" />
    </Anchor>
  );
};

export default CookieNoticeLearnMore;
