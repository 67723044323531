import String from './Strings';

const { isArray } = Array;
const { hasOwnProperty } = Object.prototype;

/**
 * Compare two items by the specified property.
 *
 * @param {object} a
 * @param {object} b
 * @param {string} property
 *
 * @returns {number}
 */
const cmp = (a, b, property) => a[property] - b[property];

export default {
  /**
   * Will unset keys in objects or remove from arrays
   * where any null, empty string, or undefined values exist in an item.
   * Allows boolean false values to remain.
   * @param {object|array} item
   *
   * @returns {object|array}
   */
  clean(item) {
    if (isArray(item)) {
      return item.filter(
        (value) => value !== null && value !== '' && value !== undefined,
      );
    }

    return Object.entries(item)
      .filter(
        ([, value]) => value !== null && value !== '' && value !== undefined,
      )
      .reduce(
        (accumulator, [key, value]) => ({ ...accumulator, [key]: value }),
        {},
      );
  },

  /**
   * @param {object|array} item
   * @param {function} callback
   *
   * @returns {undefined}
   */
  each(item, callback) {
    if (isArray(item)) {
      item.forEach(callback);

      return;
    }

    Object.keys(item).forEach(callback);
  },

  /**
   * @param {object|array} item
   * @param {boolean} strict
   *
   * @returns {boolean}
   */
  empty(item = {}, strict = false) {
    if (strict) {
      const keys = Object.keys(item);

      for (let i = 0; i < keys.length; i += 1) {
        const part = item[keys[i]];

        if (isArray(part) ? this.length(part) > 0 : part !== null) {
          return false;
        }
      }

      return true;
    }

    return Object.keys(item).length === 0 && item.constructor === Object;
  },

  /**
   * @param {object|array} item
   * @param {boolean} strict
   *
   * @returns {boolean}
   */
  filled(item = {}, strict = false) {
    return !this.empty(item, strict);
  },

  /**
   * @param {object|array} item
   * @param {*} fallback
   *
   * @returns {*}
   */
  first(item, fallback = null) {
    if (this.filled(item)) {
      return isArray(item) ? item[0] : item[Object.keys(item).shift()];
    }

    return fallback;
  },

  /**
   * @param {object|array} item
   * @param {*} callback
   *
   * @returns {*[]}
   */
  filter(item = {}, callback) {
    if (isArray(item)) {
      return item.filter(callback);
    }

    return Object.values(item).filter(callback);
  },

  /**
   * @param {object|array} item
   * @param {string|number} property
   * @param {*} fallback
   *
   * @returns {*}
   */
  get(item = {}, property, fallback = null) {
    return this.has(item, property) ? item[property] : fallback;
  },

  /**
   * @param {object|array} item
   * @param {string} property
   *
   * @returns {boolean}
   */
  has(item = {}, property) {
    return hasOwnProperty.call(item, property);
  },

  /**
   * @param {object|array} item
   * @param {*} value
   *
   * @return {boolean}
   */
  includes(item = {}, value) {
    if (isArray(item)) {
      return item.includes(value);
    }

    return Object.values(item).includes(value);
  },

  /**
   * @param {array} resources
   * @param {string} type
   * @param {*} fallback
   *
   * @returns {*|array}
   */
  included(resources, type, fallback = null) {
    return this.filled(resources)
      ? resources.filter((include) => include.type === type)
      : fallback;
  },

  /**
   * @param {*} item
   *
   * @returns {boolean}
   */
  isArray(item) {
    return isArray(item);
  },

  /**
   * @param {object|array} items
   *
   * @returns {object|array}
   */
  keysToCamel(items) {
    if (isArray(items)) {
      return items.map((item) => this.keysToCamel(item));
    }

    return Object.keys(items).reduce((result, key) => {
      result[String.snakeToCamel(key)] = items[key];

      return result;
    }, {});
  },

  /**
   * @param {object|array} item
   *
   * @returns {number}
   */
  length(item = {}) {
    return Object.keys(item).length;
  },

  /**
   * @param {object|array} item
   * @param {function} callback
   *
   * @returns {array}
   */
  map(item, callback) {
    if (isArray(item)) {
      return item.map(callback);
    }

    return Object.keys(item).map(callback);
  },

  /**
   * Returns tuple of elements that pass the given truth test and those that do not.
   *
   * @param {object|array} item
   * @param {function} callback
   *
   * @returns {array}
   */
  partition(item, callback) {
    const reducer = (accumulator, e) => {
      accumulator[callback(e) ? 0 : 1].push(e);

      return accumulator;
    };

    if (isArray(item)) {
      return item.reduce(reducer, [[], []]);
    }

    return Object.values(item).reduce(reducer, [[], []]);
  },

  /**
   * Return a random element from the given array.
   *
   * @param {array} item
   *
   * @returns {*}
   */
  random(item) {
    return item[Math.floor(Math.random() * item.length)];
  },

  /**
   * Resolve a nested object property, returning the specified fallback if the
   * property cannot be resolved.
   *
   * @param {object} item
   * @param {array|string} path
   * @param {*} fallback
   * @param {string} separator
   *
   * @returns {*|undefined}
   */
  resolve(item, path, fallback = undefined, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);

    const value = properties.reduce(
      (prev, curr) => (prev ? prev[curr] : undefined),
      item,
    );

    return value !== undefined ? value : fallback;
  },

  /**
   * @param {array<object>} item
   * @param {string} property
   * @param {'desc'|'asc'} direction
   *
   * @returns {array}
   */
  sort(item, property, direction = 'desc') {
    return item.sort(
      direction === 'desc'
        ? (a, b) => cmp(a, b, property)
        : (a, b) => -cmp(a, b, property),
    );
  },
};
