import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import AddAttendeeModal from '../../components/AddAttendeeModal';
import AppointmentSummaryExpansionPanel from '../../components/AppointmentSummaryExpansionPanel';
import BackButton from '../../components/BackButton';
import CenterWrap from '../../components/CenterWrap';
import TrackPageView from '../../components/TrackPageView';
import Typography from '../../components/Typography';
import { PAGES } from '../../constants';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import { StepAbilityContext } from '../../contexts/StepAbilityContext';
import { TABLET } from '../../contexts/ViewModeContext';
import Step from '../../helpers/Step';
import QuestionShape from '../../shapes/QuestionShape';

const useStyles = createUseStyles((theme) => ({
  form: {
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    // To compensate for a Firefox and IE 11 bug, we need utilize a pseudo
    // element instead of bottom padding on a flexbox container as it
    // is ignored by the browser at the time of writing
    '&::after': {
      content: '""',
      display: 'block',
    },
  },
  header: {
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    flex: '1 0 auto',
    maxHeight: '4rem',
    padding: '1.125rem 0',
    width: '100%',
  },
  summary: {
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: '0.25rem',
    margin: '1.25rem 1.25rem 0',
  },
  title: {
    padding: '1.25rem 1.25rem 0.625rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}));

const Details = ({
  addAdditionalAttendee,
  countries,
  country,
  form,
  loading,
  mode,
  previous,
  previousStep,
  questions,
  selectedAttendee,
  setSelectedAttendee,
  setUploads,
  validate,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const { can } = useContext(StepAbilityContext);
  const { callbackServiceHelp } = useContext(SettingsContext);
  const [{ bookingWalkIn }] = useContext(SelectionContext);

  const header = can.step.backwards ? (
    <header className={classes.header}>
      <BackButton
        previous={previous}
        text={<FormattedMessage id="Ui.back" />}
        title={Step.getBackString(intl, previousStep)}
      />
    </header>
  ) : null;

  if (loading) {
    return (
      <CenterWrap data-testid="details-desktop" narrow view={mode}>
        {header}
      </CenterWrap>
    );
  }

  return (
    <CenterWrap data-testid="details-desktop" narrow view={mode}>
      <TrackPageView identifier={PAGES.DETAILS} />
      {header}
      <section className={classes.form}>
        {bookingWalkIn && callbackServiceHelp ? (
          <section className={classes.title}>
            <div dangerouslySetInnerHTML={{ __html: callbackServiceHelp }} />
          </section>
        ) : null}
        {mode === TABLET ? (
          <div className={classes.summary}>
            <AppointmentSummaryExpansionPanel />
          </div>
        ) : null}
        <header className={classes.title}>
          <Typography component="h2" variant="h5">
            <FormattedMessage id="Details.form_header" />
          </Typography>
        </header>
        <section className={classes.wrapper}>{form}</section>
      </section>
      <AddAttendeeModal
        addAdditionalAttendee={addAdditionalAttendee}
        countries={countries}
        country={country}
        handleClose={() => setSelectedAttendee(null)}
        open={selectedAttendee !== null}
        questions={questions}
        selectedAttendee={selectedAttendee}
        setSelectedAttendee={setSelectedAttendee}
        setUploads={setUploads}
        testId="add-attendee-modal"
        validate={validate}
      />
    </CenterWrap>
  );
};

Details.propTypes = {
  addAdditionalAttendee: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  country: PropTypes.string.isRequired,
  form: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  mode: PropTypes.number.isRequired,
  selectedAttendee: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      ),
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  questions: PropTypes.arrayOf(QuestionShape),
  setSelectedAttendee: PropTypes.func.isRequired,
  setUploads: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

Details.defaultProps = {
  loading: true,
  previousStep: null,
  selectedAttendee: null,
};

export default Details;
