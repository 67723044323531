import PropTypes from 'prop-types';
import React from 'react';

const Star = ({ fill }) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
      fill={fill}
    />
  </svg>
);

Star.propTypes = {
  fill: PropTypes.string,
};

Star.defaultProps = {
  fill: '#FFB400',
};

export default Star;
