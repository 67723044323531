import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from './Typography';

const StepCounter = ({ currentStep, stepsCount, inline = false }) => (
  <Typography
    component={inline ? 'span' : 'div'}
    data-testid="step-counter"
    variant="body1"
  >
    <FormattedMessage
      id="StepCounter.title"
      values={{
        current: currentStep + 1,
        end: stepsCount,
      }}
    />
  </Typography>
);

StepCounter.propTypes = {
  currentStep: PropTypes.number.isRequired,
  stepsCount: PropTypes.number.isRequired,
};

export default StepCounter;
