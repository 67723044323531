import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const VideoCall = ({ altText }) => {
  const intl = useIntl();

  return (
    <svg height="20.004" width="20.004" xmlns="http://www.w3.org/2000/svg">
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.video' })}
      </title>
      <path d="M0 0h20v20H0z" fill="none" />
      <path
        d="M12.501 6.668v6.668H4.168V6.668h8.333m.836-1.667h-10a.836.836 0 00-.836.834v8.335a.836.836 0 00.834.831h10a.836.836 0 00.834-.834v-2.915l3.332 3.334V5.418L14.17 8.752V5.835a.836.836 0 00-.833-.834z"
        fill="#616161"
      />
    </svg>
  );
};

VideoCall.propTypes = {
  altText: PropTypes.string,
};

VideoCall.defaultProps = {
  altText: '',
};

export default VideoCall;
