import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import NoResults from '../../shared/icons/NoResults';
import { HEADER_HEIGHT } from '../constants';
import { FeatureContext } from '../contexts/FeatureContext';
import {
  DESKTOP,
  MOBILE,
  TABLET,
  ViewModeContext,
} from '../contexts/ViewModeContext';
import BookAnotherAppointmentButton from './BookAnotherAppointmentButton';
import EmptyState from './EmptyState';
import Footer from './Footer';

const useStyles = createUseStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: theme.palette.neutral[200],
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  rootDesktop: {
    background: theme.palette.neutral[100],
    height: `calc(100vh - ${HEADER_HEIGHT.DESKTOP})`,
    padding: '1.24rem 2.5rem',
  },
  bookAnother: {
    marginTop: '1.25rem',
  },
  bottomPadding: {
    paddingBottom: '1.25rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentMobile: {
    width: '100%',
  },
  emptyPage: {
    background: theme.palette.white,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    overflowY: 'auto',
    padding: '1.25rem',
    textAlign: 'center',
    width: '100%',
  },
  emptyPageDesktop: {
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    marginTop: 0,
    padding: 0,
  },
  emptyState: {
    marginBottom: '1.875rem',
  },
}));

const GeneralError = ({
  bookAnother,
  BookAnotherButton,
  icon,
  subtitle,
  title,
}) => {
  const classes = useStyles({ theme: useTheme() });
  const mode = useContext(ViewModeContext);
  const { appointmentBookingChannel } = useContext(FeatureContext);
  const desktopOrTablet = mode === DESKTOP || mode === TABLET;

  return (
    <div
      className={classNames(
        classes.root,
        desktopOrTablet && classes.rootDesktop,
      )}
      data-testid="general-error"
      role="status"
    >
      <div
        className={classNames(
          classes.emptyPage,
          desktopOrTablet && classes.emptyPageDesktop,
        )}
      >
        <div
          className={classNames(
            classes.content,
            mode === MOBILE && classes.contentMobile,
          )}
        >
          <EmptyState
            action={
              appointmentBookingChannel && bookAnother ? (
                <div
                  className={classes.bookAnother}
                  id="general-error-book-another"
                >
                  {BookAnotherButton}
                </div>
              ) : (
                ''
              )
            }
            classes={{
              overrides: classNames(
                icon ? classes.emptyState : classes.bottomPadding,
              ),
            }}
            icon={icon ? <NoResults /> : null}
            subtitle={subtitle}
            title={title}
          />
        </div>
      </div>
      {mode === DESKTOP && <Footer component="h5" />}
    </div>
  );
};

GeneralError.propTypes = {
  bookAnother: PropTypes.bool,
  BookAnotherButton: PropTypes.element,
  icon: PropTypes.bool,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

GeneralError.defaultProps = {
  BookAnotherButton: <BookAnotherAppointmentButton />,
  bookAnother: true,
  icon: true,
  subtitle: <FormattedMessage id="GenericError.subtitle" />,
  title: <FormattedMessage id="GenericError.title" />,
};

export default GeneralError;
