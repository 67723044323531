import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
  },
  overrides: {},
  subtitle: {
    margin: '0.5rem auto 0',
    textAlign: 'center',
    width: '100%',
    '& a': {
      color: theme.palette.secondary[400],
      borderBottom: `1px dotted ${theme.palette.secondary[400]}`,
      textDecoration: 'none',
      target: '_blank',
      '&:focus': {
        borderBottomColor: theme.palette.secondary[500],
        outline: 0,
        color: theme.palette.secondary[500],
      },
      '&:hover': {
        borderBottomColor: theme.palette.secondary[500],
        color: theme.palette.secondary[500],
      },
    },
  },
  constrained: {
    maxWidth: '40rem',
  },
  title: {
    marginTop: '1.25rem',
  },
}));

const EmptyState = ({
  action,
  classes: injectedClasses,
  icon,
  subtitle,
  title,
}) => {
  const classes = { ...useStyles({ theme: useTheme() }), ...injectedClasses };

  return (
    <div
      className={classNames(classes.root, classes.overrides)}
      data-testid="empty-state"
    >
      {icon}
      <Typography
        classes={{ root: classes.title }}
        component="h2"
        variant="title"
      >
        {title}
      </Typography>
      {subtitle ? (
        <Typography
          classes={{
            root: classNames(classes.subtitle, !icon && classes.constrained),
          }}
          component="h3"
          variant="caption1"
        >
          {icon ? (
            subtitle
          ) : (
            <div dangerouslySetInnerHTML={{ __html: subtitle }} />
          )}
        </Typography>
      ) : null}
      {action}
    </div>
  );
};

EmptyState.propTypes = {
  action: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.string,
  ]),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  icon: PropTypes.element,
  subtitle: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.string,
  ]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

EmptyState.defaultProps = {
  action: null,
  icon: null,
  subtitle: <FormattedMessage id="EmptyState.contact_vendor" />,
};

export default EmptyState;
