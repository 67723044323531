import { Notifications } from 'coconut-open-api-js';

const Attendees = (client) => ({
  /**
   * @returns {Promise<any>}
   */
  reschedule(attendee, appointment) {
    return client.patch(`/api/v2/open/attendees/${attendee}`, {
      data: {
        id: String(attendee),
        type: 'attendees',
        attributes: {
          appointment_id: appointment,
        },
      },
      meta: {
        notify: Notifications.ALL,
      },
    });
  },
});

export default Attendees;
