import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const ExpandMore = ({ altText }) => {
  const intl = useIntl();

  return (
    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.expand' })}
      </title>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
  );
};

ExpandMore.propTypes = {
  altText: PropTypes.string,
};

ExpandMore.defaultProps = {
  altText: '',
};

export default ExpandMore;
