import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import Dates from '../../shared/helpers/Dates';
import Strings from '../../shared/helpers/Strings';
import LoadingState from '../components/LoadingState';
import {
  LANGUAGES,
  SHORTCUTS,
  STEPS,
  USER_PREFERENCE,
  USER_PREFERENCE_OPTIONS,
  VIRTUAL_MEETING_METHODS,
  STAFF_SELECTIONS,
} from '../constants';
import { FeatureContext } from '../contexts/FeatureContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { PositionContext } from '../contexts/PositionContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { StepAbilityContext } from '../contexts/StepAbilityContext';
import { UsersContext } from '../contexts/UsersContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Api from '../helpers/Api';
import Item from '../helpers/Item';
import Limits from '../helpers/Limits';
import NextAvailability from '../helpers/NextAvailability';
import Resources from '../helpers/Resources';
import Settings from '../helpers/Settings';
import Shortcuts from '../helpers/Shortcuts';
import DesktopTime from './desktop/Time';
import DesktopUserPreference from './desktop/UserPreference';
import MobileTime from './mobile/Time';
import MobileUserPreference from './mobile/UserPreference';

const useStyles = createUseStyles({
  loading: {
    justifyContent: 'inherit',
  },
});

const updateInformation = (
  { slots: oldSlots, ...state },
  { slots: newSlots, merge = true, ...newState },
) => ({
  ...state,
  ...newState,
  slots: merge ? { ...oldSlots, ...newSlots } : { ...newSlots },
  ...(NextAvailability.shouldSetEarliestDate(
    state.earliestDate,
    newState.earliestDate,
    newState.slotsLoading,
  ) && {
    earliestDate: NextAvailability.shouldUseNewSelectedDate(
      newState.earliestDate,
      newSlots,
    )
      ? newState.selected
      : null,
  }),
});

const DateTime = ({
  currentStep,
  getStep,
  goTo,
  next,
  previous,
  previousStep,
  stepsCount,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const mode = useContext(ViewModeContext);
  const { can } = useContext(StepAbilityContext);
  const { spokenLanguages } = useContext(FeatureContext);
  const {
    shouldReorderSpecificStaffBeforeLanguages,
    shouldUseNextAvailability,
    shouldUseNextAvailabilityNewView,
    shouldAllowNearestLocationAlgorithm,
  } = useContext(FeatureDecisionContext);
  const [locale] = useContext(LocaleContext);
  const {
    builderEnabled,
    firstStep,
    reorderSpecificStaffBeforeLanguages,
    showStaffLanguages,
    showStaffPicker,
    staffSelection,
  } = useContext(SettingsContext);
  const {
    getSupportedLanguages,
    loading: languagesLoading,
    setState,
    supportedLanguages,
    supportedLanguagesTranslations,
  } = useContext(UsersContext);
  const [selections, setSelections] = useContext(SelectionContext);
  const { coordinates } = useContext(PositionContext);
  const {
    location,
    meetingMethod,
    settings,
    shortcuts,
    skip,
    user,
    userCategory,
    userPreference,
  } = selections;
  const slotsApiIdRef = useRef(0);

  const [
    {
      earliestDate,
      error,
      loading,
      loadingMessage,
      selected,
      slots,
      slotsLoading,
    },
    setInformation,
  ] = useReducer(updateInformation, {
    earliestDate: null,
    error: null,
    loading: true,
    loadingMessage: null,
    selected: shortcuts?.settings?.booking_window_start
      ? Dates.parse(shortcuts?.settings?.booking_window_start)
      : sessionStorage.getItem('default_date')
      ? Dates.parse(sessionStorage.getItem('default_date'))
      : Dates.today(),
    slots: {},
    slotsLoading: false,
  });

  const hasUserCategory = Shortcuts.exists(SHORTCUTS.USER_CATEGORY);
  const staffPickerShown = Settings.shouldShowStaffPicker(
    { showStaffPicker },
    location,
  );

  let skipMethods = false;
  if (previousStep === STEPS.MEETING_METHODS) {
    skipMethods =
      firstStep === STEPS.SERVICE
        ? skip[STEPS.MEETING_METHODS] && skip[STEPS.LOCATION]
        : skip[STEPS.MEETING_METHODS];
  }

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'Steps.meeting_details' });
    setSelections({ date: null });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasUserCategory && !userPreference && !showStaffLanguages) {
      setSelections({
        userPreference: {
          id: staffPickerShown
            ? USER_PREFERENCE.SPECIFIC
            : USER_PREFERENCE.RANDOM,
        },
      });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStaffLanguages, staffPickerShown, userPreference]);

  useLayoutEffect(() => {
    if (
      (!Shortcuts.exists(SHORTCUTS.USER_CATEGORY) ||
        settings.preferred_staff === null) &&
      supportedLanguages === null
    ) {
      getSupportedLanguages();
    } else if (
      Shortcuts.exists(SHORTCUTS.USER_CATEGORY) &&
      supportedLanguages === null
    ) {
      getSupportedLanguages(userCategory, settings.preferred_staff);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSupportedLanguages, supportedLanguages, settings.preferred_staff]);

  // TODO: when preference is not in the list, select default
  const assemblePreferences = (languages) => {
    const [random, specific] = USER_PREFERENCE_OPTIONS;
    const preferences = [];
    const skipStaffPreferences = !staffPickerShown || builderEnabled;

    if (hasUserCategory && skipStaffPreferences) {
      return [random, ...languages];
    }

    if (hasUserCategory && !skipStaffPreferences) {
      return [specific];
    }

    if (
      !builderEnabled &&
      showStaffLanguages &&
      (languagesLoading || supportedLanguages === null)
    ) {
      return preferences;
    }

    // "random" and "specific" options for user category shortcut are handled separately,
    // so only language options should be added
    if (languages.length > 0) {
      preferences.push(...languages);
    } else {
      preferences.push(random);
    }

    if (!skipStaffPreferences) {
      if (
        reorderSpecificStaffBeforeLanguages &&
        shouldReorderSpecificStaffBeforeLanguages
      ) {
        preferences.unshift(specific);
      } else {
        preferences.push(specific);
      }
    }

    return preferences;
  };

  const goBack = () => {
    let selections = null;
    if (
      skip[STEPS.LOCATION] &&
      !Shortcuts.exists(SHORTCUTS.MEETING_METHOD) &&
      !Shortcuts.exists(SHORTCUTS.USER_CATEGORY)
    ) {
      selections = { meetingMethod: null };
      setSelections(selections);
    }

    if (skipMethods) {
      goTo(currentStep - 2, true, selections);
    } else if (settings.preferred_staff === null && userCategory) {
      setSelections({
        settings: { ...settings, preferred_staff: true },
      });
      setState({ users: null, supportedLanguages: null });

      selections = {
        userCategory: { id: Shortcuts.get(SHORTCUTS.USER_CATEGORY).id },
      };
      previous(selections);
    } else if (
      settings.preferred_staff === null &&
      Shortcuts.exists(SHORTCUTS.USER_CATEGORY) &&
      !userCategory
    ) {
      // clear users list and reapply preferred staff and shortcuts if userCategory exists to get
      // user list containing userCategory filter
      setState({ supportedLanguages: null, users: null });
      setSelections({
        settings: {
          ...settings,
          preferred_staff: true,
          sort: Shortcuts.get(SHORTCUTS.SETTINGS)?.sort,
        },
        userCategory: { id: Shortcuts.get(SHORTCUTS.USER_CATEGORY).id },
      });
    } else if (Shortcuts.exists(SHORTCUTS.USER_CATEGORY) && user) {
      // go back to location selections view if there is a user category on shortcut
      // and user is selected
      setState({ supportedLanguages: null, users: null });
      setSelections({ location: null, user: null });
      previous(selections);
    } else if (
      Shortcuts.exists(SHORTCUTS.USER_CATEGORY) &&
      userCategory &&
      userPreference?.id
    ) {
      setSelections({ user: null, userPreference: null });

      if (
        userPreference?.id === USER_PREFERENCE.RANDOM ||
        userPreference?.id === USER_PREFERENCE.SPECIFIC
      ) {
        previous(selections);
      }
    } else {
      setState({ supportedLanguages: null, users: null });

      // if a booking shortcut user is set, we do not remove it when going back
      if (Shortcuts.exists(SHORTCUTS.USER)) {
        setSelections({ userPreference: null });
      } else {
        setSelections({ user: null, userPreference: null });
      }

      previous(selections);
    }
  };

  const preferences = useMemo(() => {
    let languages;
    if (spokenLanguages && supportedLanguages) {
      languages =
        supportedLanguages?.length > 1
          ? supportedLanguages?.map((language) => ({
              id: language,
              subtitle: 'UserPreference.assign_me_subtitle',
              testId: `user-preference-${language}`,
              title: 'UserPreference.assign_me_title',
              values: {
                language: Strings.handleCapitalization(
                  supportedLanguagesTranslations[locale][language],
                  locale,
                ),
              },
            }))
          : [];
    } else {
      languages =
        supportedLanguages?.length > 1
          ? supportedLanguages?.map((abbr) => ({
              id: abbr,
              subtitle: 'UserPreference.assign_me_subtitle',
              testId: `user-preference-${abbr}`,
              title: 'UserPreference.assign_me_title',
              values: {
                language: intl.formatMessage({
                  id: `Languages.${LANGUAGES[abbr].toLowerCase()}`,
                }),
              },
            }))
          : [];
    }

    return assemblePreferences(languages);

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedLanguages, locale]);

  // skips user preference selection if using a booking shortcut
  // with user or user category and location setting for specific staff matches location type
  const skipUserPreferenceBookingShortcut =
    (shortcuts?.length !== 0 || shortcuts) &&
    staffPickerShown &&
    userPreference?.id !== USER_PREFERENCE.RANDOM &&
    (hasUserCategory || Shortcuts.exists(SHORTCUTS.USER));

  const skipUserPreference =
    Shortcuts.exists(SHORTCUTS.USER) ||
    userPreference ||
    preferences.length === 1 ||
    user ||
    skipUserPreferenceBookingShortcut;

  useEffect(() => {
    if (
      !user &&
      ((userPreference && userPreference.id === USER_PREFERENCE.SPECIFIC) ||
        (preferences.length === 1 &&
          preferences[0].id === USER_PREFERENCE.SPECIFIC))
    ) {
      setInformation({
        loading: false,
        loadingMessage: null,
        merge: false,
        slots: {},
        slotsLoading: false,
        ...(shouldUseNextAvailability &&
          shouldUseNextAvailabilityNewView && {
            earliestDate: null,
            selected: getInitialStartDate(),
          }),
      });

      return;
    }

    if (shouldUseNextAvailability && shouldUseNextAvailabilityNewView) {
      setInformation({ earliestDate: null, selected: getInitialStartDate() });
    }
  }, [preferences, user, userPreference]);

  const onBackFromTimeSelect = () => {
    const selection = { userPreference: null, user: null, date: null };
    const hasUserShortcut = Shortcuts.exists(SHORTCUTS.USER);
    const showLanguagePreference = preferences.length > 1;

    if (hasUserShortcut) {
      selection.user = Resources.formatUser(Shortcuts.get(SHORTCUTS.USER));
    }

    if (hasUserCategory && !staffPickerShown) {
      selection.userCategory = {
        id: Shortcuts.identify(SHORTCUTS.USER_CATEGORY),
      };
    }

    if (staffPickerShown || showLanguagePreference) {
      if (hasUserShortcut || hasUserCategory) {
        goBack();
      }
    } else {
      setInformation({ merge: false, slots: {} });
      goBack();
    }
    setSelections(selection);
  };

  const onBackFromUserPreference = () => {
    setSelections({ date: null });
    goBack();
  };

  const selectDate = (date) => {
    setInformation({
      selected: date,
      error: null,
    });
  };

  const selectTime = async ({ currentTarget: { dataset } }) => {
    const options = {
      appointment: dataset.appointment || null,
      date: Resources.formatDate(dataset.date),
    };

    Limits.set(dataset.limit);

    if (
      meetingMethod &&
      VIRTUAL_MEETING_METHODS.includes(meetingMethod) &&
      !Shortcuts.exists(SHORTCUTS.LOCATION) &&
      location === null
    ) {
      const locations = dataset.locations.split(',');
      let locationId = null;

      if (
        shouldAllowNearestLocationAlgorithm &&
        staffSelection === STAFF_SELECTIONS.NEAREST_AVAILABLE
      ) {
        await Api.locations()
          .nearest(locations, coordinates.latitude, coordinates.longitude)
          .then((response) => {
            locationId = response.id;
          })
          .catch(() => {
            console.error(
              'Unable to find nearest location, selecting random location as fallback',
            );
          });
      }

      options.location = {
        id: Number(locationId || Item.random(locations)),
      };
    }

    setSelections(options);

    next(options);
  };

  const getInitialStartDate = () => {
    // if client view is using a booking shortcut with window then return it.
    if (shortcuts?.settings?.booking_window_start) {
      return Dates.parse(shortcuts?.settings?.booking_window_start);
    }

    // if there's a default date in our session storage then return that.
    if (sessionStorage.getItem('default_date')) {
      return Dates.parse(sessionStorage.getItem('default_date'));
    }

    // otherwise we just return today's date.
    return Dates.today();
  };

  if (showStaffPicker === undefined) {
    return (
      <LoadingState
        classes={{ override: classes.loading }}
        data-testid="settings-loading"
      />
    );
  }

  let canStepBackwards = can.step.backwards;
  if (skipMethods) {
    const prevStep = getStep(currentStep - 2);
    canStepBackwards =
      prevStep.id !== STEPS.MEETING_METHODS && !prevStep.locked;
  }

  const props = {
    canStepBackwards,
    currentStep,
    earliestDate,
    error,
    initialStartDate: getInitialStartDate(),
    loading,
    loadingMessage,
    mode,
    next,
    preferences,
    previous: userPreference ? onBackFromTimeSelect : onBackFromUserPreference,
    previousStep:
      userPreference && !Shortcuts.exists(SHORTCUTS.USER) && !hasUserCategory
        ? 'staff'
        : previousStep,
    selectDate,
    selected,
    selectTime,
    setInformation,
    slots,
    slotsApiIdRef,
    stepsCount,
    slotsLoading,
  };

  const userProps = {
    canStepBackwards,
    currentStep,
    mode,
    preferences,
    previous: goBack,
    previousStep,
    stepsCount,
  };

  if (languagesLoading) {
    return <LoadingState classes={{ override: classes.loading }} />;
  }

  if (skipUserPreference) {
    return mode === MOBILE ? (
      <MobileTime {...props} />
    ) : (
      <DesktopTime {...props} />
    );
  }

  return mode === MOBILE ? (
    <MobileUserPreference {...userProps} />
  ) : (
    <DesktopUserPreference {...userProps} />
  );
};

DateTime.propTypes = {
  currentStep: PropTypes.number.isRequired,
  getStep: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  stepsCount: PropTypes.number.isRequired,
};

DateTime.defaultProps = {
  previousStep: null,
};

export default DateTime;
