import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { TimezonesContext } from '../contexts/TimezonesContext';
import Item from '../helpers/Item';
import InputErrors from './forms/InputErrors';
import SearchableSelect from './forms/SearchableSelect';
import TimezoneLabel from './TimezoneLabel';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    marginTop: '0.5rem',
    marginBottom: '1rem',
  },
});

const DesktopTimezonePicker = ({
  error,
  clearError,
  setTimezone,
  timezone,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const timezones = useContext(TimezonesContext);

  const handleChange = (selectedTimezone) => {
    setTimezone(selectedTimezone.value);
    clearError();
  };

  const options = useMemo(
    () =>
      Item.map(timezones, (timezone) => ({
        value: timezone,
        label: (
          <TimezoneLabel
            fallback={timezone}
            // We are temporarily ignoring the destructuring-assignment rule explicitly.
            // There is a bug that was solved in a newer version of this plugin which
            // we will eventually be able to upgrade to once we can move off of
            // the current version of NodeJS in use.
            //
            // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
            //
            // eslint-disable-next-line react/destructuring-assignment
            timezone={timezones[timezone]}
            withName
          />
        ),
      })),
    [timezones],
  );

  return (
    <section className={classes.root}>
      <SearchableSelect
        error={error}
        // We are temporarily ignoring the destructuring-assignment rule explicitly.
        // There is a bug that was solved in a newer version of this plugin which
        // we will eventually be able to upgrade to once we can move off of
        // the current version of NodeJS in use.
        //
        // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
        //
        // TODO: Clean up all instances of `eslint-disable-next-line react/destructuring-assignment`
        //
        // eslint-disable-next-line react/destructuring-assignment
        getOptionLabel={({ value }) => timezones[value].name}
        isClearable={false}
        label={intl.formatMessage({ id: 'CountryTimezoneModal.title' })}
        onChange={handleChange}
        options={options}
        value={
          // We are temporarily ignoring the destructuring-assignment rule explicitly.
          // There is a bug that was solved in a newer version of this plugin which
          // we will eventually be able to upgrade to once we can move off of
          // the current version of NodeJS in use.
          //
          // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
          //
          // eslint-disable-next-line react/destructuring-assignment
          timezones[timezone]
            ? {
                value: timezone,
                label: (
                  <TimezoneLabel
                    fallback={timezone}
                    // We are temporarily ignoring the destructuring-assignment rule explicitly.
                    // There is a bug that was solved in a newer version of this plugin which
                    // we will eventually be able to upgrade to once we can move off of
                    // the current version of NodeJS in use.
                    //
                    // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
                    //
                    // eslint-disable-next-line react/destructuring-assignment
                    timezone={timezones[timezone]}
                    withName
                  />
                ),
              }
            : null
        }
      />
      {error ? (
        <InputErrors
          errors={[intl.formatMessage({ id: 'TimezoneSelectInput.required' })]}
        />
      ) : null}
    </section>
  );
};

DesktopTimezonePicker.propTypes = {
  clearError: PropTypes.func,
  error: PropTypes.bool,
  setTimezone: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

DesktopTimezonePicker.defaultProps = {
  clearError: () => {},
  error: false,
};

export default DesktopTimezonePicker;
