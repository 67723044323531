import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  subtitle: PropTypes.string,
  testId: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.shape({
    language: PropTypes.string,
  }),
});
