import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { HIDE_BOOK_ANOTHER_APPOINTMENT_LINK } from '../constants';
import { FeatureContext } from '../contexts/FeatureContext';
import { SelectionContext } from '../contexts/SelectionContext';
import BookAnotherAppointmentButton from './BookAnotherAppointmentButton';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    maxWidth: '14.5rem',
    margin: '0 auto',
    textAlign: 'center',
    paddingBottom: '5rem',
  },
  content: {
    maxWidth: '13.5rem',
    margin: '1.5rem auto auto auto',
  },
  icon: {
    fill: theme.palette.secondary[400],
    height: '3.75rem',
    width: '3.75rem',
    marginBottom: '1rem',
  },
  spacing: {
    marginTop: '2rem',
  },
}));

const AppointmentCancelled = ({ recentlyCancelled }) => {
  const classes = useStyles({ theme: useTheme() });
  const [{ service }] = useContext(SelectionContext);
  const { appointmentBookingChannel } = useContext(FeatureContext);

  const showBookAnother =
    appointmentBookingChannel &&
    service?.bookAnotherAppointmentPrefillSetting !==
      HIDE_BOOK_ANOTHER_APPOINTMENT_LINK;

  return (
    <div className={classes.root} data-testid="appointment-cancelled">
      <svg
        className={classes.icon}
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M9.31 17l2.44-2.44L14.19 17l1.06-1.06-2.44-2.44 2.44-2.44L14.19 10l-2.44 2.44L9.31 10l-1.06 1.06 2.44 2.44-2.44 2.44L9.31 17zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z" />
      </svg>
      {recentlyCancelled ? (
        <div>
          <Typography component="h3" variant="h5">
            <FormattedMessage id="AppointmentCancelled.your_title" />
          </Typography>
          <div className={classes.content}>
            <Typography component="p" variant="subtitle">
              <FormattedMessage id="AppointmentCancelled.email_message" />
            </Typography>
          </div>
        </div>
      ) : (
        <Typography component="h3" variant="h5">
          <FormattedMessage id="AppointmentCancelled.this_title" />
        </Typography>
      )}
      {showBookAnother ? (
        <div className={classes.spacing}>
          <BookAnotherAppointmentButton />
        </div>
      ) : null}
    </div>
  );
};

AppointmentCancelled.propTypes = {
  recentlyCancelled: PropTypes.bool.isRequired,
};

export default AppointmentCancelled;
