import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  filterIcon: {
    fill: theme.palette.neutral[400],
    marginRight: '0.75rem',
    maxWidth: '1.25rem',
    maxHeight: '1.25rem',
    minWidth: '1.25rem',
    minHeight: '1.25rem',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.75rem',
  },
}));

const DateTimeFilter = ({ content, icon }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.row}>
      <span className={classes.filterIcon}>{icon}</span>
      {content}
    </div>
  );
};

DateTimeFilter.propTypes = {
  content: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
};

export default DateTimeFilter;
