export default {
  /**
   * @param {string} string
   *
   * @returns {string}
   */
  uppercaseFirst(string) {
    return `${string.charAt(0).toUpperCase()}${string.substring(1)}`;
  },

  /**
   * @param {string} string
   *
   * @returns {string}
   */
  snakeToCamel(string) {
    return string.replace(/([_][a-z])/gi, (subString) =>
      subString.toUpperCase().replace('-', '').replace('_', ''),
    );
  },
};
