import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Reporter from '../helpers/Reporter';

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };

    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    const { scope } = this.props;
    const sentryEventId = Reporter.exception(error, errorInfo, scope);
    this.setState({ ...this.state, error: sentryEventId });
  }

  resetErrorBoundary() {
    this.setState({ error: null, hasError: false });
  }

  render() {
    const { hasError, error } = this.state;
    const { children, fallback: Fallback } = this.props;

    if (hasError) {
      return (
        <Fallback error={error} resetErrorBoundary={this.resetErrorBoundary} />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  fallback: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  scope: PropTypes.shape({
    _tags: PropTypes.shape({ domain: PropTypes.string }),
  }),
};

ErrorBoundary.defaultProps = {
  scope: null,
};

export default ErrorBoundary;
