import { Error } from '@coconut-software/ui/dist/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '../Typography';

const useStyles = createUseStyles({
  root: {
    marginTop: '0.375rem',
    display: 'flex', // to have the icon + text horiz aligned nicely
  },
});

const InputErrors = ({ errors, id }) => {
  const classes = useStyles();

  return errors.map((error) => (
    <Typography
      classes={{ root: classes.root }}
      component="p"
      id={id}
      key={error}
      variant="error"
    >
      <>
        <span className="inline-block w-4 mr-0.5">
          <Error />
        </span>
        {error}
      </>
    </Typography>
  ));
};

InputErrors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  ),
};

InputErrors.defaultProps = {
  errors: [],
};

export default InputErrors;
