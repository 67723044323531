import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import Strings from '../../shared/helpers/Strings';
import { LANGUAGES, SHORTCUTS, USER_PREFERENCE } from '../constants';
import { FeatureContext } from '../contexts/FeatureContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { UsersContext } from '../contexts/UsersContext';
import Resources from '../helpers/Resources';
import Shortcuts from '../helpers/Shortcuts';
import SupportedLocalePreferenceShape from '../shapes/SupportedLocalePreferenceShape';
import ListboxPicker from './ListboxPicker';
import SelectableListItem from './SelectableListItem';

const SupportedLanguagePreferencePicker = ({ preferences, resetRange }) => {
  const intl = useIntl();
  const history = useHistory();
  const [{ settings, user, userPreference }, setSelections] =
    useContext(SelectionContext);
  const { spokenLanguages } = useContext(FeatureContext);
  const [locale] = useContext(LocaleContext);
  const { supportedLanguagesTranslations } = useContext(UsersContext);

  const preferredCategory =
    Shortcuts.exists(SHORTCUTS.USER_CATEGORY) && settings.preferred_staff;
  const hasUserShortcut = Shortcuts.exists(SHORTCUTS.USER);
  const preferred =
    hasUserShortcut && user
      ? { id: USER_PREFERENCE.SPECIFIC }
      : userPreference || { id: USER_PREFERENCE.RANDOM };

  const selectPreference = (preference) => {
    // We have to keep the preference as 'random' so that correct times page shows up
    setSelections({
      user:
        preference === USER_PREFERENCE.SPECIFIC
          ? Resources.formatUser(Shortcuts.get(SHORTCUTS.USER))
          : null,
      userPreference: {
        id:
          preference === USER_PREFERENCE.SPECIFIC
            ? USER_PREFERENCE.RANDOM
            : preference,
      },
    });

    resetRange();
  };

  const showLanguagePage = () => {
    history.push('/language-preferences', { preferences });
  };

  const preferredValue =
    preferred.id === USER_PREFERENCE.SPECIFIC ||
    preferred.id === USER_PREFERENCE.RANDOM
      ? preferred.id
      : spokenLanguages
      ? Strings.handleCapitalization(
          supportedLanguagesTranslations[locale][preferred.id],
          locale,
        )
      : intl.formatMessage({
          id: `Languages.${LANGUAGES[preferred.id].toLowerCase()}`,
        });

  const label =
    preferredValue === USER_PREFERENCE.SPECIFIC ? (
      <FormattedMessage id="SupportedLanguagePreferencePicker.assigned_staff" />
    ) : preferred.id === USER_PREFERENCE.RANDOM ? (
      <FormattedMessage
        id={
          preferredCategory
            ? 'SupportedLanguagePreferencePicker.language_preference'
            : 'SupportedLanguagePreferencePicker.anyone_available'
        }
      />
    ) : preferredCategory ? (
      preferredValue
    ) : (
      <FormattedMessage
        id="SupportedLanguagePreferencePicker.specific_language"
        values={{ language: preferredValue }}
      />
    );

  return (
    <ListboxPicker
      label={label}
      mobileOnClick={showLanguagePage}
      onChange={selectPreference}
      options={preferences.map((preference) => (
        <SelectableListItem
          checked={preference.id === preferred?.id}
          key={preference.id}
          title={
            <FormattedMessage
              id={preference.title}
              values={preference.values || {}}
            />
          }
          value={preference.id}
        />
      ))}
      value={preferred?.id}
    />
  );
};

SupportedLanguagePreferencePicker.propTypes = {
  preferences: PropTypes.arrayOf(SupportedLocalePreferenceShape).isRequired,
  resetRange: PropTypes.func.isRequired,
};

export default SupportedLanguagePreferencePicker;
