import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Dates from '../../shared/helpers/Dates';
import { SelectionContext } from '../contexts/SelectionContext';
import { TABLET, ViewModeContext } from '../contexts/ViewModeContext';
import AppointmentSummary from './AppointmentSummary';
import ExpandMore from './icons/ExpandMore';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.white,
    overflow: 'hidden',
  },
  button: {
    alignItems: 'center',
    background: 'transparent',
    border: '0 none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.125rem 1.25rem',
    width: '100%',
    '&:focus $icon svg': {
      background: theme.palette.neutral[200],
      borderRadius: theme.borderRadius.full,
    },
  },
  expanded: {
    marginBottom: '0.5rem',
  },
  icon: {
    '& svg': {
      transition: 'transform 0.3s',
    },
  },
  panel: {
    maxHeight: 0,
    padding: '0 1.25rem',
    transition: 'max-height 0.3s',
  },
  rotated: {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  tablet: {
    background: 'transparent',
  },
}));

const AppointmentSummaryExpansionPanel = () => {
  const classes = useStyles({ theme: useTheme() });
  const intl = useIntl();

  const panel = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [{ date, service, user }] = useContext(SelectionContext);
  const mode = useContext(ViewModeContext);

  useEffect(() => {
    panel.current.style.maxHeight = expanded
      ? `${panel.current.scrollHeight}px`
      : 0;
  }, [expanded]);

  const finish = (timestamp) => {
    const flexible = service.maxDuration && service.minDuration;

    if (user || !flexible) {
      return Dates.addMinutes(timestamp, service.duration);
    }

    return null;
  };
  const onClick = () => setExpanded((prevState) => !prevState);

  return (
    <div
      className={classNames(classes.root, mode === TABLET && classes.tablet)}
      data-testid="summary-expansion-panel"
    >
      <header>
        <button
          aria-controls="summary"
          aria-expanded={expanded}
          aria-label={intl.formatMessage({
            id: 'SummaryExpansionPanel.title',
          })}
          className={classes.button}
          onClick={onClick}
          type="button"
        >
          <Typography primary variant="label">
            <FormattedMessage id="SummaryExpansionPanel.title" />
          </Typography>
          <div
            className={classNames(classes.icon, expanded && classes.rotated)}
          >
            <ExpandMore
              altText={
                expanded
                  ? intl.formatMessage({ id: 'Svg.alt_text.expanded' })
                  : intl.formatMessage({ id: 'Svg.alt_text.expand' })
              }
            />
          </div>
        </button>
      </header>
      <section
        className={classNames(classes.panel, expanded && classes.expanded)}
        id="summary"
        ref={panel}
      >
        <AppointmentSummary date={date} finish={finish} />
      </section>
    </div>
  );
};

export default AppointmentSummaryExpansionPanel;
