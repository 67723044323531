import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Dates from '../../../shared/helpers/Dates';
import StepCounter from '../../components/StepCounter';
import TrackPageView from '../../components/TrackPageView';
import { SHORTCUTS, STEPS, USER_PREFERENCE, PAGES } from '../../constants';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import Settings from '../../helpers/Settings';
import Shortcuts from '../../helpers/Shortcuts';
import Step from '../../helpers/Step';
import SpacetimeShape from '../../shapes/SpacetimeShape';
import SupportedLocalePreferenceShape from '../../shapes/SupportedLocalePreferenceShape';
import Times from './Times';
import Users from './Users';

const Time = ({
  canStepBackwards,
  currentStep,
  loading,
  initialStartDate,
  stepsCount,
  setInformation,
  preferences,
  previous,
  previousStep,
  ...props
}) => {
  const { builderEnabled, firstStep, showStaffPicker } =
    useContext(SettingsContext);
  const [{ location, skip, settings, user, userPreference }, setSelections] =
    useContext(SelectionContext);

  const noUserShortcut = !Shortcuts.exists(SHORTCUTS.USER);

  const skipUserSelection =
    !(
      !builderEnabled &&
      Settings.shouldShowStaffPicker({ showStaffPicker }, location)
    ) ||
    (userPreference &&
      userPreference.id !== USER_PREFERENCE.SPECIFIC &&
      !settings.preferred_staff) ||
    user;

  const subtitle = (
    <StepCounter currentStep={currentStep} stepsCount={stepsCount} />
  );

  const toUserPreference = () => {
    setSelections({ user: null });
  };

  const canGoToPrevStep = Step.canGoToPrevStep({
    step: STEPS.TIMES,
    previousStep,
    firstStep,
    skip,
    canStepBackwards,
  });
  const toPreviousStep = canGoToPrevStep ? previous : null;
  const backFromTimeStep =
    user && noUserShortcut ? toUserPreference : toPreviousStep;

  return skipUserSelection ? (
    <>
      <TrackPageView identifier={PAGES.TIMES} />
      <Times
        loading={loading}
        preferences={preferences}
        previous={backFromTimeStep}
        previousStep={previousStep}
        setInformation={setInformation}
        subtitle={subtitle}
        {...props}
      />
    </>
  ) : (
    <>
      <TrackPageView identifier={PAGES.USER} />
      <Users
        initialStartDate={initialStartDate}
        loading={loading}
        previous={toPreviousStep}
        previousStep={previousStep}
        setInformation={setInformation}
      />
    </>
  );
};

Time.propTypes = {
  canStepBackwards: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  initialStartDate: SpacetimeShape,
  loading: PropTypes.bool,
  preferences: PropTypes.arrayOf(SupportedLocalePreferenceShape).isRequired,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string.isRequired,
  setInformation: PropTypes.func.isRequired,
  stepsCount: PropTypes.number.isRequired,
};

Time.defaultProps = {
  initialStartDate: Dates.today(),
  loading: true,
};

export default Time;
