import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  tooltip: {
    background: theme.palette.neutral[400],
    borderRadius: theme.borderRadius.sm,
    color: theme.palette.white,
    left: 0,
    opacity: 90,
    padding: '0.375rem 0.5rem',
    position: 'absolute',
    top: '125%',
    whiteSpace: 'nowrap',
    width: 'auto',
    zIndex: 10,
  },
}));

const Tooltip = ({ classes: injectedClasses, children }) => {
  const classes = { ...useStyles({ theme: useTheme() }), ...injectedClasses };

  return (
    <div className={classNames(classes.root, classes.tooltip)}>
      <Typography variant="small" white>
        {children}
      </Typography>
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};

Tooltip.defaultProps = {
  classes: {},
};

export default Tooltip;
