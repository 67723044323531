import { useMemo } from 'react';
import Storage from './Storage';

const useLocalQueue = (key, capacity = 256) =>
  useMemo(
    () => ({
      key,
      capacity,
      all: (fallback = []) => Storage.getPermanent(key, fallback),
      enqueue: (items) => {
        if (!key) {
          return;
        }

        let queue = Storage.getPermanent(key);

        if (Array.isArray(queue)) {
          const itemList = Array.isArray(items) ? items : [items];

          itemList.forEach((item) => {
            const index = queue.findIndex((el) => el === item);

            if (index >= 0) {
              queue.splice(index, 1);
            }
            if (queue.length >= capacity) {
              queue.pop();
            }
            queue.unshift(item);
          });
        } else {
          const itemList = Array.isArray(items)
            ? items.slice(-capacity)
            : [items];
          queue = itemList.reverse();
        }

        Storage.setPermanent(key, queue);
      },
    }),
    [key, capacity],
  );

export default useLocalQueue;
