import React from 'react';
import { FormattedMessage } from 'react-intl';
import Dates from '../../shared/helpers/Dates';

export default {
  /**
   * Formats given start and end dates to display as a
   * time range/duration with the given timezone.
   *
   * @param {Spacetime} start
   * @param {Spacetime|null} end
   * @param {string} timezone
   *
   * @returns {FormattedMessage}
   */
  range(start, end, timezone) {
    const identifier = end
      ? 'AppointmentDetails.appointment_duration'
      : 'AppointmentDetails.appointment_duration_without_end';

    const values = {
      start: Dates.time(start, true),
      timezone,
    };

    if (end) {
      values.end = Dates.time(end, true);
    }

    return <FormattedMessage id={identifier} values={values} />;
  },

  /**
   * Formats a duration in minutes to a string with relevant hour and minute unit abbreviations.
   * Performs localisation.
   *
   * @param {number} duration
   * @param intl
   *
   * @return {string}
   */
  duration(duration, intl) {
    let minutesLabel = intl.formatMessage(
      { id: 'Service.minutes' },
      { minutes: duration },
    );

    if (duration > 90) {
      const hours = Math.floor(duration / 60);
      const minutes = duration - hours * 60;
      const hoursLabel = intl.formatMessage({ id: 'Service.hours' }, { hours });
      minutesLabel = intl.formatMessage({ id: 'Service.minutes' }, { minutes });

      return minutes > 0 ? `${hoursLabel} ${minutesLabel}` : `${hoursLabel}`;
    }

    if (duration < 5) {
      return intl.formatMessage({ id: 'Service.five_minutes' });
    }

    return minutesLabel;
  },

  /**
   * @param {number} distance
   * @param {string} country
   *
   * @returns {string}
   */
  distance(distance, country) {
    let suffix = 'km';

    if (country === 'US') {
      distance = distance / 1.6;
      suffix = 'mi';
    }

    return `${distance.toFixed(1)}${suffix}`;
  },
};
