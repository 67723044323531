import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import ForwardChevron from '../../shared/icons/ForwardChevron';
import { MEETING_METHODS, SHORTCUTS } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import Shortcuts from '../helpers/Shortcuts';
import Button from './Button';

const doesNotRequireLocation = (service) => !service?.requireLocation;
const isVirtual = (method) =>
  method === MEETING_METHODS.PHONE_CALL ||
  method === MEETING_METHODS.VIDEO_CALL;

const useStyles = createUseStyles((theme) => ({
  root: {},
  button: {
    '& > span': {
      alignItems: 'center',
      display: 'flex',
    },
    '&:focus': {
      outline: 0,
      boxShadow: theme.shadows.input,
      borderColor: theme.palette.secondary[500],
      borderBottomColor: theme.palette.secondary[500],
      color: theme.palette.secondary[500],
      fontWeight: theme.fontWeights.boldWeight,
    },
  },
}));

const NoLocationPreferenceButton = ({ layout, next }) => {
  const classes = useStyles({ theme: useTheme() });
  const [{ meetingMethod, service }, setSelections] =
    useContext(SelectionContext);
  const onClick = () => {
    if (!Shortcuts.exists(SHORTCUTS.LOCATION)) {
      const selection = { location: null, preferredLocation: false };

      setSelections(selection);
      next(selection);
    } else {
      next();
    }
  };

  if (isVirtual(meetingMethod) && doesNotRequireLocation(service)) {
    if (layout === 'small') {
      return (
        <Button
          classes={{ override: classes.button }}
          fullWidth={false}
          onClick={onClick}
          variant="text"
        >
          <>
            <FormattedMessage id="NoLocationPreferenceButton.skip" />
            <ForwardChevron />
          </>
        </Button>
      );
    }

    return (
      <div className={classes.root}>
        <Button
          data-testid="no-preference-button"
          onClick={onClick}
          variant="outlined"
        >
          <FormattedMessage id="NoLocationPreferenceButton.title" />
        </Button>
      </div>
    );
  }

  return null;
};

NoLocationPreferenceButton.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  layout: PropTypes.oneOf(['button', 'small']),
  next: PropTypes.func.isRequired,
};

NoLocationPreferenceButton.defaultProps = {
  classes: { root: '' },
  layout: 'button',
};

export default NoLocationPreferenceButton;
