import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Alert from './Alert';

const useStyles = createUseStyles((theme) => ({
  dense: {
    marginTop: '1rem',
  },
  wide: {
    margin: '1rem',
  },
  mobile: {
    boxShadow: theme.shadows.default,
    position: 'fixed',
    bottom: '2.5rem',
    left: '1rem',
    right: '1rem',
  },
}));

const NoLocationsAlert = ({ dense, mobile }) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <div
      className={classNames(
        dense ? classes.dense : classes.wide,
        mobile ? classes.mobile : null,
      )}
    >
      <Alert
        message={<FormattedMessage id="LocationList.empty_state_message" />}
        role="alert"
        secondary={<FormattedMessage id="LocationList.empty_state_secondary" />}
        variant="error"
        white={mobile}
      />
    </div>
  );
};

NoLocationsAlert.propTypes = {
  dense: PropTypes.bool,
  mobile: PropTypes.bool,
};

NoLocationsAlert.defaultProps = {
  dense: false,
  mobile: false,
};

export default NoLocationsAlert;
