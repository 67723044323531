import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { POPULAR_COUNTRIES } from '../constants';
import { CountriesContext } from '../contexts/CountriesContext';
import { CountryContext } from '../contexts/CountryContext';
import Item from '../helpers/Item';
import SearchableSelect from './forms/SearchableSelect';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    marginTop: '0.5rem',
  },
});

const DesktopCountryPicker = () => {
  const classes = useStyles();
  const intl = useIntl();
  const countries = useContext(CountriesContext);
  const [country, setCountry] = useContext(CountryContext);

  const handleChange = (selectedCountry) => {
    setCountry(selectedCountry.value);
  };

  const [popular, options] = useMemo(
    () =>
      Item.partition(
        Item.map(countries, (country) => ({
          // We are temporarily ignoring the destructuring-assignment rule explicitly.
          // There is a bug that was solved in a newer version of this plugin which
          // we will eventually be able to upgrade to once we can move off of
          // the current version of NodeJS in use.
          //
          // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
          //
          // eslint-disable-next-line react/destructuring-assignment
          label: countries[country],
          value: country,
        })),
        ({ value }) => POPULAR_COUNTRIES.includes(value),
      ),
    [countries],
  );

  const groupedOptions = [
    { label: '', options: popular },
    { label: '', options },
  ];

  return (
    <section className={classes.root}>
      <SearchableSelect
        isClearable={false}
        label={intl.formatMessage({ id: 'DetailsForm.select_country' })}
        onChange={handleChange}
        options={groupedOptions}
        value={{
          value: country,
          // We are temporarily ignoring the destructuring-assignment rule explicitly.
          // There is a bug that was solved in a newer version of this plugin which
          // we will eventually be able to upgrade to once we can move off of
          // the current version of NodeJS in use.
          //
          // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
          //
          // eslint-disable-next-line react/destructuring-assignment
          label: countries[country],
        }}
      />
    </section>
  );
};

export default DesktopCountryPicker;
