import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import InputErrors from '../components/forms/InputErrors';
import List from '../components/List';
import MobileCard from '../components/mobile/Card';
import ListHeader from '../components/mobile/ListHeader';
import TimezoneLabel from '../components/TimezoneLabel';
import { CountriesContext } from '../contexts/CountriesContext';
import { CountryContext } from '../contexts/CountryContext';
import { HeaderContext } from '../contexts/HeaderContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { TimezonesContext } from '../contexts/TimezonesContext';
import HistoryShape from '../shapes/HistoryShape';

const useStyles = createUseStyles({
  link: {
    textDecoration: 'none',
  },
  error: {
    padding: '0 1.25rem',
  },
});

const CountryTimezones = ({ history }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [country] = useContext(CountryContext);
  const countries = useContext(CountriesContext);
  const [, setHeader] = useContext(HeaderContext);
  const timezones = useContext(TimezonesContext);
  const [timezone] = useContext(TimezoneContext);

  const [timezoneError, setTimezoneError] = useReducer(
    (state, newState) => newState,
    false,
  );

  const previous = useCallback(() => {
    // We are temporarily ignoring the destructuring-assignment rule explicitly.
    // There is a bug that was solved in a newer version of this plugin which
    // we will eventually be able to upgrade to once we can move off of
    // the current version of NodeJS in use.
    //
    // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
    //
    // eslint-disable-next-line react/destructuring-assignment
    if (timezones[timezone]) {
      history.goBack();
    } else {
      setTimezoneError(true);
    }
  }, [timezone, timezones, history]);

  useEffect(() => {
    setHeader({
      action: previous,
      title: <FormattedMessage id="Pages.timezones" />,
    });
  }, [previous, setHeader]);

  // We are temporarily ignoring the destructuring-assignment rule explicitly.
  // There is a bug that was solved in a newer version of this plugin which
  // we will eventually be able to upgrade to once we can move off of
  // the current version of NodeJS in use.
  //
  // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
  //
  // eslint-disable-next-line react/destructuring-assignment
  const selected = timezones[timezone];
  const zone = selected && <TimezoneLabel timezone={selected} withName />;
  const primary = zone || <FormattedMessage id="Ui.select" />;

  return (
    <List>
      <ListHeader>
        <FormattedMessage id="Pages.country" />
      </ListHeader>
      <Link className={classes.link} to="/country">
        <MobileCard
          icon
          // We are temporarily ignoring the destructuring-assignment rule explicitly.
          // There is a bug that was solved in a newer version of this plugin which
          // we will eventually be able to upgrade to once we can move off of
          // the current version of NodeJS in use.
          //
          // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
          //
          // eslint-disable-next-line react/destructuring-assignment
          primary={countries[country] || <FormattedMessage id="Ui.select" />}
        />
      </Link>
      <ListHeader>
        <FormattedMessage id="Pages.timezones" />
      </ListHeader>
      <Link className={classes.link} to="/timezones">
        <MobileCard
          ariaLabel={
            zone ? (
              intl.formatMessage(
                { id: 'Navigation.aria_label.card' },
                { navItem: selected.name },
              )
            ) : (
              <FormattedMessage id="Ui.select" />
            )
          }
          icon
          primary={primary}
          variant={timezoneError ? 'error' : 'default'}
        />
      </Link>
      {timezoneError ? (
        <div className={classes.error}>
          <InputErrors
            errors={[
              intl.formatMessage({ id: 'TimezoneSelectInput.required' }),
            ]}
          />
        </div>
      ) : null}
    </List>
  );
};

CountryTimezones.propTypes = {
  history: HistoryShape.isRequired,
};

export default CountryTimezones;
