import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import NoResults from '../../../shared/icons/NoResults';
import { PAGES } from '../../constants';
import { HeaderContext } from '../../contexts/HeaderContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import EmptyState from '../EmptyState';
import List from '../List';
import LocationHeader from '../LocationHeader';
import ServiceHeader from '../ServiceHeader';
import StepCounter from '../StepCounter';
import ToggleText from '../ToggleText';
import TrackPageView from '../TrackPageView';
import Typography from '../Typography';
import MobileCard from './Card';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[200],
    minHeight: '100%',
  },
  withBorder: {
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
  },
  header: {
    background: theme.palette.white,
    padding: '1.25rem',
  },
  title: {
    marginTop: '0.25rem',
  },
  emptyPageRoot: {
    display: 'flex',
    background: theme.palette.neutral[200],
    height: '100%',
  },
  emptyPage: {
    background: theme.palette.white,
    display: 'flex',
    padding: 0,
    marginTop: '0.25rem',
    height: '100%',
    width: '100%',
    textAlign: 'center',
  },
}));

const MultiPageServiceLayout = ({
  categories,
  currentStep,
  expanded,
  firstCategory,
  loading,
  previous,
  selectService,
  services,
  setPageHeader,
  stepsCount,
  toggleDescription,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const [, setHeader] = useContext(HeaderContext);
  const [{ location }] = useContext(SelectionContext);
  const [category, setCategory] = useState(null);
  const selectedCategory =
    category === null && categories.length > 0 ? firstCategory : category;

  const selectCategory = ({ currentTarget }) => {
    setCategory(categories.find((c) => c.id === currentTarget.dataset.id));
  };

  useEffect(() => {
    setPageHeader(category, setCategory);

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, currentStep, previous, setHeader]);

  if (loading) {
    return <section className={classes.root} data-testid="empty-state" />;
  }

  if (!services.length) {
    return (
      <section className={classes.emptyPageRoot} data-testid="mobile-service">
        <div className={classes.emptyPage} data-testid="empty-state">
          <EmptyState
            icon={<NoResults />}
            title={<FormattedMessage id="Service.no_services" />}
          />
        </div>
      </section>
    );
  }

  return (
    <section
      className={classNames(
        classes.root,
        (selectedCategory === null || category) && classes.withBorder,
      )}
      data-testid="mobile-service"
    >
      {category ? (
        <header aria-atomic="false" className={classes.header} role="alert">
          <Typography
            classes={{ root: classes.title }}
            component="p"
            variant="caption1"
          >
            <FormattedMessage
              id="Service.service_count"
              values={{ number: category.serviceCount }}
            />
          </Typography>
          <Typography
            classes={{ root: classes.title }}
            component="h1"
            variant="h5"
          >
            {category.name}
          </Typography>
        </header>
      ) : (
        <header aria-atomic="false" className={classes.header} role="alert">
          {location ? <LocationHeader location={location} /> : null}
          <StepCounter currentStep={currentStep} stepsCount={stepsCount} />
          <Typography
            classes={{ root: classes.title }}
            component="h1"
            variant="h5"
          >
            <FormattedMessage id="Service.mobile_header" />
          </Typography>
        </header>
      )}
      <List>
        {selectedCategory === null || category ? (
          <>
            <TrackPageView identifier={PAGES.SERVICE} />
            {services
              .filter((service) =>
                selectedCategory
                  ? service.categories.includes(selectedCategory.id)
                  : true,
              )
              .map((service) => (
                <MobileCard
                  action={() => selectService(service, category)}
                  header={
                    <ServiceHeader
                      duration={service.duration}
                      group={service.group}
                      maximum={service.maxDuration}
                      minimum={service.minDuration}
                    />
                  }
                  icon
                  id={service.id}
                  key={service.id}
                  primary={service.name}
                  secondary={
                    service.description !== null ? (
                      <ToggleText
                        model={service}
                        setVariant={() => toggleDescription(service.id)}
                        variant={expanded}
                      />
                    ) : null
                  }
                />
              ))}
          </>
        ) : (
          <>
            <TrackPageView identifier={PAGES.CATEGORY} />
            {categories.map((item) => (
              <MobileCard
                action={selectCategory}
                icon
                id={item.id}
                key={item.id}
                primary={item.name}
                secondary={
                  <FormattedMessage
                    id="Service.service_count"
                    values={{ number: item.serviceCount }}
                  />
                }
              />
            ))}
          </>
        )}
      </List>
    </section>
  );
};

MultiPageServiceLayout.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  currentStep: PropTypes.number.isRequired,
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstCategory: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  loading: PropTypes.bool,
  previous: PropTypes.func.isRequired,
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
  setPageHeader: PropTypes.func.isRequired,
  stepsCount: PropTypes.number.isRequired,
  toggleDescription: PropTypes.func,
};

MultiPageServiceLayout.defaultProps = {
  categories: [],
  loading: true,
  services: [],
};

export default MultiPageServiceLayout;
