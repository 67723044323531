import React from 'react';
import GeneralError from '../components/GeneralError';
import Header from '../components/Header';

const ErrorPage = () => (
  <>
    <Header />
    <GeneralError />
  </>
);

export default ErrorPage;
